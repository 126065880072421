import { StoresModal } from "@sk/features";
import { AppCard, DateInput, EntitySearchInput, TextInput } from "@sk/uis";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DEFAULT_DATE_RANGE } from "../CoinDashConst";

const defaultFormData = {
  customer: [],
  dateRange: [DEFAULT_DATE_RANGE.from, DEFAULT_DATE_RANGE.to],
  storeName: "",
  store: [],
};

const dtConfig = {
  mode: "range",
  maxDate: new Date(),
};

export const Filter = ({
  callback,
  accessibleStoreIds,
  hideStoreInput,
  hasPredefinedFid,
  selectedStore,
}) => {
  const { control, register, getValues, setValue } = useForm({
    defaultValues: {
      ...defaultFormData,
    },
  });

  const [showStoreModal, setShowStoreModal] = useState(false);

  useEffect(() => {
    if (selectedStore) {
      setValue(
        "storeName",
        selectedStore.name + " (" + selectedStore._id + ")"
      );
      setValue("store", [{ label: selectedStore.name, value: selectedStore }]);
    }
  }, [selectedStore, setValue]);

  const onCustomerChange = (chngFn) => (e) => {
    chngFn(e);

    if (e.length > 0) {
      setValue("dateRange", []);
      if (!hasPredefinedFid && getValues("storeName")) {
        setValue("storeName", "");
        setValue("store", []);
      }
    } else {
      if (getValues("dateRange").length === 0) {
        setValue("dateRange", [DEFAULT_DATE_RANGE.from, DEFAULT_DATE_RANGE.to]);
      }
    }
    triggerCb();
  };

  const onInpChange = (chngFn) => (val) => {
    chngFn(val);
    triggerCb();
  };

  const triggerCb = () => {
    let f = getValues();
    callback({ formData: { ...f } });
  };

  const onStoreChange = (e) => {
    if (e && e.action === "selectedStore") {
      setValue("storeName", e.store[0].name + " (" + e.store[0]._id + ")");
      setValue("store", [{ label: e.store[0].name, value: e.store[0] }]);
      triggerCb();
    }
    setShowStoreModal(false);
  };

  const handleStoreClick = () => {
    setShowStoreModal(true);
  };

  const handleRemoveStoreName = (event) => {
    event.stopPropagation();
    setValue("storeName", "");
    setValue("store", []);
    triggerCb();
  };

  return (
    <>
      <AppCard>
        <div className="row">
          <div className="col-md-3">
            <Controller
              name="dateRange"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DateInput
                  label="Date Range"
                  placeholder="Select start date"
                  value={value}
                  callback={onInpChange(onChange)}
                  config={dtConfig}
                  hideClose={true}
                  gap={0}
                  template={2}
                />
              )}
            />
          </div>
          <div className="col-md-3">
            <Controller
              name="customer"
              control={control}
              render={({ field: { onChange, value } }) => (
                <EntitySearchInput
                  type="customer"
                  label="Customer Name/Mobile Number"
                  placeholder="Search here..."
                  callback={onCustomerChange(onChange)}
                  value={value}
                  gap={0}
                  uid="customer"
                />
              )}
            />
          </div>
          {!hideStoreInput && (
            <div className="col-4" onClick={handleStoreClick}>
              <TextInput
                name="storeName"
                placeholder="Select store"
                label="Store"
                readOnly={true}
                register={register}
                gap={0}
                groupContent={
                  getValues("storeName") ? (
                    <button
                      className="btn btn-link px-1"
                      onClick={handleRemoveStoreName}
                    >
                      <i className="bi bi-x"></i>
                    </button>
                  ) : null
                }
              />
            </div>
          )}
        </div>
      </AppCard>

      <StoresModal
        callback={onStoreChange}
        show={showStoreModal}
        onlyAssignedStore={true}
        accessibleStoreIds={accessibleStoreIds}
        rbackKey="SCDashboard"
      />
    </>
  );
};
