import { sub } from "date-fns";

const dashboardView = {
  stockNotAllocatedTable: {
    tableHeaders: [
      {
        label: "Sl No",
        width: "40px",
        isCentered: true,
        priority: 1,
        styleKey: "cell-slNo",
        enableSort: false,
      },
      {
        label: "Warehouse",
        width: "200px",
        priority: 2,
        enableSort: true,
        key: "whName",
        sort: {
          key: "whName",
        },
      },
      {
        label: "Company Name",
        width: "200px",
        priority: 3,
        enableSort: true,
        key: "companyName",
        sort: {
          key: "companyName",
        },
      },
      {
        label: "Menu",
        width: "150px",
        priority: 4,
        enableSort: true,
        key: "menuName",
        sort: {
          key: "menuName",
        },
      },
      {
        label: "Brand",
        width: "150px",
        priority: 5,
        enableSort: true,
        key: "brandName",
        sort: {
          key: "brandName",
        },
      },
      {
        label: "Category",
        width: "150px",
        priority: 6,
        enableSort: true,
        key: "categoryName",
        sort: {
          key: "categoryName",
        },
      },
      {
        label: "Product",
        width: "300px",
        priority: 7,
        enableSort: true,
        key: "productName",
        sort: {
          key: "productName",
        },
      },
      {
        label: "MRP",
        isCentered: true,
        priority: 8,
        styleKey: "cell-mrp",
        enableSort: true,
        key: "productMrp",
        sort: {
          key: "productMrp",
        },
      },
      {
        label: "Inner Pack Qty",
        width: "200px",
        isCentered: true,
        priority: 9,
        styleKey: "cell-inpkqty",
        enableSort: true,
        key: "totalInnerPackQty",
        sort: {
          key: "totalInnerPackQty",
        },
      },
      {
        label: "Case Qty",
        width: "200px",
        isCentered: true,
        priority: 10,
        styleKey: "cell-caseqty",
        enableSort: true,
        key: "totalCaseQty",
        sort: {
          key: "totalCaseQty",
        },
      },
      {
        label: "Old Order Date",
        priority: 11,
        isCentered: true,
        checked: false,
        width: "200px",
        enableSort: true,
        key: "oldOrderDate",
        styleKey: "cell-old-order-dt",
        sort: {
          key: "oldOrderDate",
        },
      },
      {
        label: "Recent Order Date",
        isCentered: true,
        width: "80px",
        priority: 12,
        key: "newOrderDate",
        checked: false,
        enableSort: true,
        styleKey: "cell-recent-order-dt",
        sort: {
          key: "newOrderDate",
        },
      },
      {
        label: "Instock Qty",
        priority: 13,
        checked: false,
        isCentered: true,
        key: "totalStockQty",
        width: "120px",
        enableSort: true,
        styleKey: "cell-instock",
        sort: {
          key: "totalStockQty",
        },
      },
      {
        label: "Reserve Qty",
        isCentered: true,
        width: "80px",
        priority: 14,
        key: "totalReserveQty",
        checked: false,
        enableSort: true,
        styleKey: "cell-reserve",
        sort: {
          key: "totalReserveQty",
        },
      },
      {
        label: "Open/Partial PO Qty",
        priority: 15,
        isCentered: true,
        checked: false,
        key: "totalPOQty",
        width: "120px",
        enableSort: true,
        styleKey: "cell-open-po",
        sort: {
          key: "totalPOQty",
        },
      },
      {
        label: "Pending Order Qty",
        isCentered: true,
        width: "80px",
        priority: 22,
        key: "totalOrderQty",
        checked: false,
        enableSort: true,
        styleKey: "cell-pending-qty",
        sort: {
          key: "totalOrderQty",
        },
      },
      {
        label: "Pending Order Value",
        priority: 23,
        isCentered: true,
        checked: false,
        key: "totalOrderValue",
        width: "120px",
        enableSort: true,
        styleKey: "cell-pending-value",
        sort: {
          key: "totalOrderValue",
        },
      },
    ],

    moreColumns: [
      {
        label: "Old Order Date",
        priority: 11,
        isCentered: true,
        checked: false,
        key: "oldOrderDate",
        width: "12%",
      },
      {
        label: "Recent Order Date",
        isCentered: true,
        width: "8%",
        priority: 12,
        key: "recentOrderDate",
        checked: false,
      },
      {
        label: "Instock Qty",
        priority: 13,
        checked: false,
        isCentered: true,
        key: "instockQty",
        width: "12%",
      },
      {
        label: "Reserve Qty",
        isCentered: true,
        width: "8%",
        priority: 14,
        key: "reserveQty",
        checked: false,
      },
      {
        label: "Open/Partial PO Qty",
        priority: 15,
        isCentered: true,
        checked: false,
        key: "openPartialPoQty",
        width: "12%",
      },
      {
        label: "Open/Partial PO Value",
        isCentered: true,
        width: "8%",
        priority: 16,
        key: "openPartialPoValue",
        checked: false,
      },
      {
        label: "Unique Open PO Count",
        priority: 17,
        checked: false,
        key: "uniqueOpenPoCount",
        isCentered: true,
        width: "12%",
      },
      {
        label: "Unique Partial PO Count",
        isCentered: true,
        width: "8%",
        priority: 18,
        key: "uniquePartialPoCount",
        checked: false,
      },
      {
        label: "Last 30 Days Sale Qty",
        priority: 19,
        isCentered: true,

        checked: false,
        key: "last30DaysSaleQty",
        width: "12%",
      },
      {
        label: "Last 60 Days Sale Qty",
        isCentered: true,
        width: "8%",
        priority: 20,
        key: "last60DaysSaleQty",
        checked: false,
      },
      {
        label: "Last 90 Days Sale Qty",
        priority: 21,
        checked: false,
        isCentered: true,
        key: "last90DaysSaleQty",
        width: "12%",
      },
      {
        label: "Pending Order Qty",
        isCentered: true,
        width: "8%",
        priority: 22,
        key: "pendingOrderQty",
        checked: false,
      },
      {
        label: "Pending Order Value",
        priority: 23,
        isCentered: true,
        checked: false,
        key: "pendingOrderValue",
        width: "12%",
      },
    ],
    additionalTableDataConfig: [
      {
        key: "createdBy",
        api: "user",
        loadingKey: "userLoading",
        dataKey: "_createdBy",
        filter: (ids) => ({
          page: 1,
          count: ids.length,
          filter: { _id: { $in: ids } },
          select: "name",
        }),
      },
      {
        key: "modifiedBy",
        api: "user",
        loadingKey: "modifiedLoading",
        dataKey: "_modifiedBy",
        filter: (ids) => ({
          page: 1,
          count: ids.length,
          filter: { _id: { $in: ids } },
          select: "name",
        }),
      },
      {
        key: "whId",
        api: "franchise",
        loadingKey: "warehouseLoading",
        dataKey: "_warehouse",
        filter: (ids) => ({
          page: 1,
          count: ids.length,
          filter: { _id: { $in: ids } },
          select: "name,state,district,town,pincode",
        }),
      },
    ],
    pagination: {
      totalRecords: 0,
      rowsPerPage: 10,
      activePage: 1,
      startSlNo: 1,
      endSlNo: 10,
    },
  },

  notAllocatedOrder: {
    formLabels: {
      warehouse: {
        label: "Warehouse",
      },
      product: {
        label: "Product",
        valuePath: "[0].label",
        canRemove: true,
        resetVal: [],
      },
      store: {
        label: "Store",
        valuePath: "[0].label",
        canRemove: true,
        resetVal: [],
      },
      menu: {
        label: "Menu",
        valuePath: "label",
        canRemove: true,
        resetVal: [],
        isMultiple: true,
      },
      category: {
        label: "Category",
        valuePath: "label",
        canRemove: true,
        resetVal: [],
        isMultiple: true,
      },
      brand: {
        label: "Brand",
        valuePath: "label",
        canRemove: true,
        resetVal: [],
        isMultiple: true,
      },
      companyName: {
        label: "Company Name",
        valuePath: "[0].label",
        canRemove: true,
        resetVal: [],
      },
      createdAt: {
        label: "Date Range",
        type: "dateRange",
        canRemove: true,
        resetVal: [],
      },
    },
  },
};

export const defaultDateRangeForOrderPlacedNotAllocated = [
  sub(new Date(), { days: 15 }),
  new Date(),
];

export default dashboardView;
