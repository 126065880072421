import { CoinsDashService, CommonService } from "@sk/services";
import { AppCard, TableHeader, TableSkeletonLoader } from "@sk/uis";
import { endOfMonth, format, set, startOfMonth, sub } from "date-fns";
import { useEffect, useRef, useState } from "react";
import CoinsDashAppliedFilter from "./CoinsDashAppliedFilter";

const cstmStyleBlk = { height: "225px", overflowY: "auto" };

const tableHeaders = [
  {
    label: "Month",
  },
  {
    label: "Coins Rewarded",
    isCentered: true,
  },
  {
    label: "Coins Redeemed",
    isCentered: true,
  },
];

const CoinsDashMonthly = ({
  cid,
  storeId,
  storeName,
  cname,
  accessibleStoreIds,
}) => {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  const filterDataRef = useRef({});

  useEffect(() => {
    filterDataRef.current = { cid, storeId, accessibleStoreIds };
    init();
  }, [cid, storeId, accessibleStoreIds]);

  const init = async () => {
    const f = filterDataRef.current;
    let p = {
      filter: {
        createdAt: {
          $gte: set(sub(startOfMonth(new Date()), { months: 5 }), {
            hours: 0,
            minutes: 0,
            seconds: 0,
          }),
          $lte: set(endOfMonth(new Date()), {
            hours: 23,
            minutes: 59,
            seconds: 59,
          }),
        },
      },
    };

    if (f.cid) {
      p.filter.ownerId = f.cid;
    }

    if (f.storeId) {
      p.filter["ownerLocation.franchise"] = f.storeId;
    } else {
      p.filter["ownerLocation.franchise"] = {
        $in: f.accessibleStoreIds,
      };
    }

    setLoading(true);
    const r = await CoinsDashService.getMonthlySummary(p);
    setData(
      Array.isArray(r.resp)
        ? r.resp.map((e) => ({
            ...e,
            totalCoinsRewarded: CommonService.roundedByDecimalPlace(
              e.totalCoinsRewarded,
              0
            ),
            totalCoinsRedeemed: CommonService.roundedByDecimalPlace(
              e.totalCoinsRedeemed,
              0
            ),
            monthLbl: format(new Date(e.month), "yyyy MMM"),
          }))
        : []
    );
    setLoading(false);
  };

  return (
    <AppCard title="Monthly Coins Summary (Last 6 months)">
      <div className="mb-2">
        <CoinsDashAppliedFilter storeName={storeName} cname={cname} />
      </div>
      <div className="custom-scrollbar" style={cstmStyleBlk}>
        <table className="table table-sm table-striped">
          <TableHeader data={tableHeaders} noBg={true} />
          <tbody className="fs-val-md">
            {loading ? <TableSkeletonLoader /> : null}
            {data.map((e, k) => (
              <tr key={k}>
                <td>{e.monthLbl}</td>
                <td className="text-success text-center">
                  {e.totalCoinsRewarded}
                </td>
                <td className="text-danger text-center">
                  {e.totalCoinsRedeemed}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AppCard>
  );
};

export default CoinsDashMonthly;
