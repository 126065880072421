import React, { useState } from "react";
import { Amount, AppCard, ChartRender, TableHeader } from "@sk/uis";

const topStoreData = [
  {
    name: "Storeking Bangalore Central",
    totalSales: 500000,
    totalOrders: 3000,
    avgOrderValue: 166.67,
    customersParticipated: 1500,
  },
  {
    name: "Storeking Mumbai Hub",
    totalSales: 450000,
    totalOrders: 2800,
    avgOrderValue: 160.71,
    customersParticipated: 1400,
  },
  {
    name: "Storeking Delhi Prime",
    totalSales: 400000,
    totalOrders: 2500,
    avgOrderValue: 160.0,
    customersParticipated: 1250,
  },
  {
    name: "Storeking Chennai Express",
    totalSales: 380000,
    totalOrders: 2400,
    avgOrderValue: 158.33,
    customersParticipated: 1200,
  },
  {
    name: "Storeking Hyderabad Central",
    totalSales: 350000,
    totalOrders: 2200,
    avgOrderValue: 159.09,
    customersParticipated: 1100,
  },
  {
    name: "Storeking Pune Mega",
    totalSales: 320000,
    totalOrders: 2000,
    avgOrderValue: 160.0,
    customersParticipated: 1000,
  },
  {
    name: "Storeking Kolkata City",
    totalSales: 300000,
    totalOrders: 1900,
    avgOrderValue: 157.89,
    customersParticipated: 950,
  },
  {
    name: "Storeking Ahmedabad Prime",
    totalSales: 280000,
    totalOrders: 1800,
    avgOrderValue: 155.56,
    customersParticipated: 900,
  },
  {
    name: "Storeking Jaipur Royal",
    totalSales: 260000,
    totalOrders: 1700,
    avgOrderValue: 152.94,
    customersParticipated: 850,
  },
  {
    name: "Storeking Lucknow Central",
    totalSales: 240000,
    totalOrders: 1600,
    avgOrderValue: 150.0,
    customersParticipated: 800,
  },
  {
    name: "Storeking Chandigarh Hub",
    totalSales: 220000,
    totalOrders: 1500,
    avgOrderValue: 146.67,
    customersParticipated: 750,
  },
  {
    name: "Storeking Indore Express",
    totalSales: 200000,
    totalOrders: 1400,
    avgOrderValue: 142.86,
    customersParticipated: 700,
  },
  {
    name: "Storeking Bhopal Mega",
    totalSales: 180000,
    totalOrders: 1300,
    avgOrderValue: 138.46,
    customersParticipated: 650,
  },
  {
    name: "Storeking Nagpur City",
    totalSales: 160000,
    totalOrders: 1200,
    avgOrderValue: 133.33,
    customersParticipated: 600,
  },
  {
    name: "Storeking Kochi Prime",
    totalSales: 140000,
    totalOrders: 1100,
    avgOrderValue: 127.27,
    customersParticipated: 550,
  },
];

const storeTableColumns = [
  { label: "Store Name", key: "name", enableSort: true },
  { label: "Total Sales", key: "totalSales", enableSort: true },
  { label: "Total Orders", key: "totalOrders", enableSort: true },
  { label: "Avg Order Value", key: "avgOrderValue", enableSort: true },
  {
    label: "Customers Participated",
    key: "customersParticipated",
    enableSort: true,
  },
];

export const TopStore = () => {
  const [viewType, setViewType] = useState("sales");

  const chartData = {
    labels: topStoreData.map((store) => store.name),
    datasets: [
      {
        label:
          viewType === "sales"
            ? "Total Sales"
            : viewType === "orders"
            ? "Total Orders"
            : "Customers Participated",
        data: topStoreData.map((store) =>
          viewType === "sales"
            ? store.totalSales
            : viewType === "orders"
            ? store.totalOrders
            : store.customersParticipated
        ),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };
  return (
    <AppCard title="Top 20 Store Performance">
      <div className="row">
        <div className="col-md-7">
          <div
            className="table-responsive custom-scrollbar"
            style={{ height: "400px", overflowY: "auto" }}
          >
            <table className="table table-hover table-sm">
              <TableHeader data={storeTableColumns} />
              <tbody className="fs-val-md">
                {topStoreData.map((store, index) => (
                  <tr key={index}>
                    <td>{store.name}</td>
                    <td>
                      <Amount value={store.totalSales} />
                    </td>
                    <td>{store.totalOrders.toLocaleString()}</td>
                    <td>
                      <Amount value={store.avgOrderValue} />
                    </td>
                    <td>{store.customersParticipated.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-5">
          <div style={{ height: "400px" }}>
            <ChartRender type="horizontalBar" data={chartData} />
          </div>
        </div>
      </div>
    </AppCard>
  );
};

export default TopStore;
