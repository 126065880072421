import { appConfigs, DashboardService } from "@sk/services";
import {
  AppCard,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
  ChartRender,
} from "@sk/uis";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const chartStyle = {
  height: "300px",
};

const headers = [
  {
    label: "Sl No",
    key: "slNo",
    width: "10%",
    isCentered: true,
  },
  {
    label: "Menu Name",
    key: "menuName",
    width: "45%",
    enableSort: true,
  },
  {
    label: "Products",
    key: "pIdCountWithoutPO",
    width: "15%",
    isCentered: true,
    enableSort: true,
  },
  {
    label: "Order Qty",
    key: "orderQtyWithoutPO",
    width: "15%",
    isCentered: true,
    enableSort: true,
  },
  {
    label: "Percentage",
    key: "percentage",
    width: "15%",
    isRightAligned: true,
  },
];

const getData = async (params, abortSignal) => {
  const response = await DashboardService.fetchStockAnalytics(params, {
    signal: abortSignal,
  });
  return {
    data: Array.isArray(response.resp) ? response.resp : [],
    raw: response.raw,
  };
};

const prepareFilterParams = (filterData, sort) => {
  const params = DashboardService.prepareStockNotAllocatedSummaryParams(
    filterData,
    "getUnallocatedStockByMenu"
  );

  if (sort) {
    params.sort = {
      [sort.key]: sort.value === "asc" ? 1 : -1,
    };
  }

  return params;
};

const StockNotAllocatedMenuTable = ({ filterData, callback }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const filterRef = useRef(filterData);
  const sortDataRef = useRef({ key: "orderQtyWithoutPO", value: "desc" });

  const summary = useMemo(() => {
    let totalOrderQty = 0;
    data.forEach((x) => {
      totalOrderQty += x?.orderQtyWithoutPO || 0;
    });
    return { totalOrderQty };
  }, [data]);

  const dataWithPercentage = useMemo(() => {
    return data.map((item) => ({
      ...item,
      percentage: summary.totalOrderQty
        ? ((item.orderQtyWithoutPO || 0) / summary.totalOrderQty) * 100
        : 0,
    }));
  }, [data, summary.totalOrderQty]);

  const chartConfig = useMemo(() => {
    const sortedData = [...data]
      .sort((a, b) => b.pIdCountWithoutPO - a.pIdCountWithoutPO)
      .slice(0, 10);

    return {
      data: {
        labels: sortedData.map((item) => item.menuName),
        datasets: [
          {
            label: "Products",
            data: sortedData.map((item) => item.pIdCountWithoutPO || 0),
            backgroundColor: "rgba(75, 192, 192, 0.7)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
          {
            label: "Order Quantity",
            data: sortedData.map((item) => item.orderQtyWithoutPO || 0),
            backgroundColor: "rgba(153, 102, 255, 0.7)",
            borderColor: "rgba(153, 102, 255, 1)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: false,
            text: "Menu Wise Stock Summary",
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Menu",
            },
            ticks: {
              maxRotation: 45,
              minRotation: 45,
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Count",
            },
          },
        },
        barPercentage: 0.8,
        categoryPercentage: 0.9,
      },
    };
  }, [data]);

  const loadList = useCallback(async (abortSignal) => {
    setLoading(true);
    setData([]);
    try {
      let r = await getData(
        prepareFilterParams(filterRef.current, sortDataRef.current),
        abortSignal
      );
      if (r.raw?.code == appConfigs.AJAX_REQ_CANCEL) {
        return;
      }
      setData(r.data);
      setLoading(false);
    } catch (error) {
      setData([]);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    filterRef.current = filterData;
    loadList(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [filterData, loadList]);

  const sortCb = useCallback(
    (data) => {
      sortDataRef.current = data;
      loadList();
    },
    [loadList]
  );

  const handleFilterClick = useCallback(
    (type, value) => {
      const formData = {
        ...filterData,
        [type]: [
          {
            label: value.name,
            value: {
              _id: value._id,
              name: value.name,
            },
          },
        ],
      };

      callback({ formData });
    },
    [filterData, callback]
  );

  return (
    <AppCard title="Menu Wise Stock Summary">
      <div className="row g-3">
        {/* Table Column */}
        <div className="col-md-7">
          <div className="table-responsive">
            <table className="table table-sm mb-0">
              <TableHeader
                data={headers}
                noBg
                sortCb={sortCb}
                sort={sortDataRef.current}
              />
              <tbody className="fs-val-md">
                {loading ? (
                  <TableSkeletonLoader cols={headers.length} rows={4} />
                ) : dataWithPercentage.length === 0 ? (
                  <tr>
                    <td colSpan={headers.length} className="text-center">
                      <NoDataFound>
                        <p>No data found</p>
                      </NoDataFound>
                    </td>
                  </tr>
                ) : (
                  dataWithPercentage.map((item, index) => (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>
                        <button
                          className="btn btn-link text-dark text-start fs-val-md p-0"
                          onClick={() =>
                            handleFilterClick("menu", {
                              name: item.menuName,
                              _id: item.menuId,
                            })
                          }
                        >
                          {item.menuName}
                        </button>
                      </td>
                      <td className="text-center">
                        {item.pIdCountWithoutPO || 0}
                      </td>
                      <td className="text-center">
                        {item.orderQtyWithoutPO || 0}
                      </td>
                      <td className="text-end">
                        {item.percentage.toFixed(2)}%
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Chart Column */}
        <div className="col-md-5">
          {chartConfig.data?.datasets?.length > 0 ? (
            <div style={chartStyle}>
              <ChartRender
                data={chartConfig.data}
                type="bar"
                options={chartConfig.options}
              />
            </div>
          ) : null}
        </div>
      </div>
    </AppCard>
  );
};

export default StockNotAllocatedMenuTable;
