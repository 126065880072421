import { PageNotFound, ProtectedRoute } from "@sk/uis";
import { memo } from "react";
import { RouterProvider, createHashRouter } from "react-router-dom";
import Layout from "./pages/Layout";
import RewardedCoinsList from "./pages/coins/rewarded-coins/RewardedCoinsList";
import ReferralCoinsList from "./pages/coins/referral-coins/ReferralCoinsList";
import Reservation from "./pages/reservation/Reservation";
import NotAllocatedOrder from "./pages/stock/NotAllocatedOrder";
import StoreDashboard from "./pages/store/StoreDashboard";
import CoinsDashboard from "./pages/coins/dashboard/CoinsDashboard";
import CoinsDash from "./pages/coins-v2/CoinsDash";
import SalesDashboard from "./pages/sales/SalesDashboard";
import CustomerLifeCycle from "./pages/customer-lifecycle/CustomerLifeCycle";
import InventoryAgeing from "./pages/inventory-ageing/InventoryAgeing";
import InventoryAgeingProducts from "./pages/inventory-ageing/InventoryAgeingProducts";
import CustomerMissedCall from "./pages/customer-missed-call/CustomerMissedCall";

const roles = {
  smartCoins: ["SCDashboard"],
  stores: ["DarkStoreDashboard"],
  reservation: ["ListProductReservation"],
  sales: ["DarkStoreSalesDashboard"],
  customerInsights: ["CustomerInsight"],
  stock: ["StockNotAllocatedDashboard"],
  inventoryAgeing: [
    "InventoryAgeingDashboard",
    "InventoryAgeingStoreDashboard",
  ],
};

const router = createHashRouter([
  {
    path: "/",
    errorElement: <PageNotFound />,
    children: [
      {
        path: "dashboard",
        element: <Layout />,
        children: [
          {
            path: "stores",
            element: (
              <ProtectedRoute roles={roles.stores}>
                <StoreDashboard />
              </ProtectedRoute>
            ),
          },
          {
            path: "reservation",
            element: (
              <ProtectedRoute roles={roles.reservation}>
                <Reservation />
              </ProtectedRoute>
            ),
          },
          {
            path: "stock/not-allocated-orders",
            element: (
              <ProtectedRoute roles={roles.stock}>
                <NotAllocatedOrder />
              </ProtectedRoute>
            ),
          },
          {
            path: "coins/rewarded-coins",
            element: (
              <ProtectedRoute>
                <RewardedCoinsList />
              </ProtectedRoute>
            ),
          },
          {
            path: "coins/referral-coins",
            element: (
              <ProtectedRoute>
                <ReferralCoinsList />
              </ProtectedRoute>
            ),
          },
          {
            path: "coins/dashboard",
            element: (
              <ProtectedRoute roles={roles.smartCoins}>
                <CoinsDashboard />
              </ProtectedRoute>
            ),
          },
          {
            path: "coins-v2",
            element: (
              <ProtectedRoute roles={roles.smartCoins}>
                <CoinsDash />
              </ProtectedRoute>
            ),
          },
          {
            path: "sales",
            element: (
              <ProtectedRoute roles={roles.sales}>
                <SalesDashboard />
              </ProtectedRoute>
            ),
          },
          {
            path: "customer-insights",
            element: (
              <ProtectedRoute roles={roles.customerInsights}>
                <CustomerLifeCycle />
              </ProtectedRoute>
            ),
          },
          {
            path: "inventory-ageing",
            element: (
              <ProtectedRoute roles={roles.inventoryAgeing}>
                <InventoryAgeing />
              </ProtectedRoute>
            ),
          },
          {
            path: "inventory-ageing/products",
            element: (
              <ProtectedRoute roles={roles.inventoryAgeing}>
                <InventoryAgeingProducts />
              </ProtectedRoute>
            ),
          },
          {
            path: "customer-missed-call",
            element: (
              <ProtectedRoute roles={roles.inventoryAgeing}>
                <CustomerMissedCall />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default memo(Router);
