import { AuthService } from "@sk/services";
import {
  Amount,
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo } from "react";

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "Parent Customer ID" },
  { label: "Parent Customer Name" },
  { label: "Parent Earned Coins" },
  { label: "Child Customer ID" },
  { label: "Child Customer Name" },
  { label: "Child Earned Coins" },
  { label: "Child Placed Orders" },
  { label: "Child Transaction Status" },
  { label: "Last Activity of Child" },
  // {
  //   label: "No. Of Orders placed with Order ID",
  //   isCentered: true,
  //   key: "UniqueCustomerCount",
  //   enableSort: true,
  // },
];

const rbac = {
  viewButton: ["ViewRewardedCoins"],
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  viewOrderCb,
  sort = {},
  sortCb,
}) => {
  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />
        <tbody>
          {loading ? (
            <TableSkeletonLoader
              cols={headers.length}
              rows={paginationConfig.rowsPerPage}
            />
          ) : null}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound> No Data Found </NoDataFound>
              </td>
            </tr>
          ) : null}
          {!loading &&
            data.map((x, index) => (
              <tr
                key={x._id}
                className="fs-val-md"
                style={x?._updated ? rowBg : {}}
              >
                {/* serial no. */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                <td className="text-center">{x?.ParentCustomer}</td>

                <td className="text-center">{x?.ParentCustomerName}</td>

                <td className="text-center">{x?.TotalParentEarnedCoins}</td>

                <td className="text-center">{x?.ChildCustomer}</td>

                <td className="text-center">{x?.ChildCustomerName || "N/A"}</td>

                <td className="text-center">{x?.TotalChildEarnedCoins}</td>

                <td className="text-center"></td>

                <td className="text-center"></td>

                <td className="text-center"></td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* pagination block */}
      {!loading && data.length > 0 && (
        <PaginationBlock
          paginationConfig={paginationConfig}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
        />
      )}
    </>
  );
};

export default memo(Table);
