import { NavService } from "@sk/services";
import {
  BtnLink,
  DateFormatter,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo } from "react";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "Customer ID" },
  { label: "First Name" },
  { label: "Last Name" },
  { label: "Mobile No" },
  { label: "State" },
  { label: "District" },
  { label: "Town" },
  { label: "Created On" },
];

const CustomerMissedCallTable = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
}) => {
  return (
    <>
      <PaginationSummary
        loadingTotalRecords={loadingTotalRecords}
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        className="mb-2"
      />
      <div
        className="tbl-scroll-container custom-scrollbar thin-scrollbar mb-2"
        style={containerStyle}
      >
        <table className="table table-sm bg-white">
          <TableHeader data={headers} noBg={true} isSticky={true} />
          <tbody>
            {loading && <TableSkeletonLoader cols={headers.length} />}

            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No data found</NoDataFound>
                </td>
              </tr>
            ) : null}
            {data.map((x, index) => (
              <tr
                key={x._id}
                className="cursor-pointer"
                onClick={() =>
                  NavService.openInNewTab("/users/customer/view", {
                    id: x._id,
                  })
                }
              >
                <td>{paginationConfig.startSlNo + index}</td>
                <td>
                  <BtnLink className="fs-val-md">{x._id || "--"}</BtnLink>
                </td>
                <td>{x.fName || "--"}</td>
                <td>{x.lName || "--"}</td>
                <td>{x.mobile || "--"}</td>
                <td>{x.state || "--"}</td>
                <td>{x.district || "--"}</td>
                <td>{x.town || "--"}</td>
                <td>
                  <DateFormatter date={x.createdAt} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </>
  );
};

const containerStyle = {
  height: "calc(100vh - 300px)",
  overflow: "auto",
};

export default memo(CustomerMissedCallTable);
