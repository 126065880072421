import { CustomerService } from "@sk/services";
import {
  AutoCompleteInput,
  DatePickerInput,
  EntitySearchInput,
  TextInput,
} from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

const createdOnDateConfig = { mode: "range", maxDate: new Date() };
const FilterForm = ({ callback }) => {
  const { getValues, control, register } = useFormContext();

  const onFormChange = useCallback(
    (chngFn) => async (val) => {
      chngFn(val);
      callback({ formData: getValues() });
    },
    []
  );
  // For Searching via Brand
  const onSearch = useCallback(
    debounce(() => {
      callback({ formData: getValues() });
    }, 700),
    [getValues]
  );

  const onCustomerSearch = useCallback(async (val, callback) => {
    console.log(val);

    // let filter = {};

    // if (val) {
    //   let v = "/" + val + "/";
    //   filter = { $or: [{ name: v }, { _id: v }] };
    // }

    let p = {
      page: 1,
      count: 10,
      // select: "name,_id",
      mobileNo: val,
      queryType: "childSummary",
    };

    const r = await CustomerService.getReferralCoinsDataList(p);
    callback(
      (r?.resp || []).map((x) => ({
        label: x?.custData?.name + " (" + x?.custData?.mobileNo + ")",
        value: x,
      }))
    );
  }, []);

  const onMemberChange = (chngFn) => (val) => {
    chngFn(
      val.map((e) => ({
        ...e,
        value: { ...e.value, custData: { id: e.value._id } },
      }))
    );
    callback({ formData: getValues() });
  };

  return (
    <div className="row">
      <div className="col-2">
        <label className="mb-1 fs-val-md">Date Range</label>
        <Controller
          name="dateRange"
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePickerInput
              placeholder="Choose Date Range"
              value={value}
              inpChange={onFormChange(onChange)}
              config={createdOnDateConfig}
            />
          )}
        />
      </div>
      <div className="col-3 mb-0">
        <Controller
          control={control}
          name="franchise"
          render={({ field: { onChange, value } }) => (
            <EntitySearchInput
              label="Search by Franchise"
              type="franchise"
              placeholder="Search by Name/ID"
              value={value}
              callback={onFormChange(onChange)}
              uid="franchise"
              emptyLabel="No Franchise found"
            />
          )}
        />
      </div>
      <div className="col-2 mb-0">
        <Controller
          control={control}
          name="parentCustomer"
          render={({ field: { onChange, value } }) => (
            <EntitySearchInput
              label="Club Owner"
              type="customer"
              placeholder="Search by Name/ID/Mobile No."
              value={value}
              callback={onFormChange(onChange)}
              uid="customer"
              emptyLabel="No Customer found"
            />
          )}
        />
      </div>
      {/* <div className="col-2 mb-0">
        <TextInput
          name="mobileNumber"
          type="number"
          register={register}
          max={9999999999}
          callback={onSearch}
          label="Club Owner Mobile No."
          placeholder="Search By  Mobile No"
        />
      </div> */}
      <div className="col-3 align-self-center">
        <Controller
          control={control}
          name="customerMobile"
          render={({ field: { onChange, value } }) => (
            <EntitySearchInput
              label="Club Member"
              type="customer"
              placeholder="Search by Name/ID/Mobile No."
              value={value}
              callback={onMemberChange(onChange)}
              uid="customer"
              emptyLabel="No Customer found"
            />
          )}
        />
        {/* <Controller
          control={control}
          name="customerMobile"
          render={({ field: { onChange, value } }) => (
            <AutoCompleteInput
              placeholder="Search By Mobile No "
              value={value}
              onSearch={onCustomerSearch}
              label="Club Member Mobile No"
              callback={onFormChange(onChange)}
              uid="customerMobile"
            />
          )}
        /> */}
      </div>
    </div>
  );
};

export default memo(FilterForm, (prev, next) => {
  return prev.isDirty === next.isDirty;
});
