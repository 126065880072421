import { useAttachAdditionalData } from "@sk/hooks";
import { FranchiseService, RolesService } from "@sk/services";
import { DateInput, PageInfo, SummaryCard } from "@sk/uis";
import { set, startOfMonth } from "date-fns";
import { produce } from "immer";
import { orderBy } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import StoreDashboardTable from "./components/StoreDashboardTable";

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Stores Summary",
  },
];

const defaultFilterData = {
  createdAt: [startOfMonth(new Date()), new Date()],
};

const additionalDataConfig = [
  {
    key: "_id",
    api: "franchise",
    loadingKey: "franLoading",
    dataKey: "_fran",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name,state,district,town,pincode",
    }),
  },
];

const defaultDateConfig = {
  maxDate: new Date(),
  mode: "range",
  defaultDate: defaultFilterData.createdAt,
};

const defaultSummaryData = [
  {
    label: "Total Customers",
    value: 0,
    color: "primary",
    key: "cust",
  },
  {
    label: "Total Unique Customers",
    value: 0,
    color: "danger",
    key: "uniqCust",
  },
  {
    label: "Total Orders",
    value: 0,
    color: "primary",
    key: "orders",
  },
  {
    label: "Total Order Value",
    value: 0,
    color: "primary",
    key: "orderValue",
    isValueAmt: true,
  },
  {
    label: "Coins Reward",
    value: 0,
    color: "success",
    key: "reward",
  },
  {
    label: "Coins Redeemed",
    value: 0,
    color: "danger",
    key: "redeem",
  },
];

const getData = async (params = {}) => {
  const { stores } = await RolesService.getDashboardEnabledStores(
    "DarkStoreDashboard"
  );

  if (stores?.length > 0) {
    params.filter["franchiseInfo.id"] = { $in: stores };
  }

  const r = await FranchiseService.getPosSummary(params);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const prepareFilterParams = (filter = {}) => {
  let p = {
    filter: {
      "franchiseInfo.groupType": "COCO",
    },
  };

  if (filter.createdAt?.length == 2) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(filter.createdAt[1], { hours: 23, minutes: 59, seconds: 59 }),
    };
  }

  return p;
};

function StoreDashboard() {
  const navigate = useNavigate();

  const { control, setValue } = useForm({
    defaultValues: { ...defaultFilterData },
  });

  const [data, setData] = useState([]);

  const [summary, setSummary] = useState([...defaultSummaryData]);

  const [loadingData, setLoadingData] = useState(true);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const filterDataRef = useRef({ ...defaultFilterData });

  const applyFilter = useCallback(async () => {
    loadList();
  }, [loadList]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const r = await getData(prepareFilterParams(filterDataRef.current));
    const d = orderBy(r.data || [], ["totalOrderValue"], ["desc"]);

    let tmp = [];
    setAdditionalData(d, additionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == additionalDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    // summary
    setSummary(
      produce((draft) => {
        let o = {
          cust: 0,
          uniqCust: 0,
          orders: 0,
          orderValue: 0,
          reward: 0,
          redeem: 0,
        };

        d.forEach((e) => {
          o.cust += e.totalCustomers || 0;
          o.uniqCust += e.totalUniqueCustomers || 0;
          o.orders += e.totalOrderCount || 0;
          o.orderValue += e.totalOrderValue || 0;
          o.reward += e.totalKCRewardedCustomer || 0;
          o.redeem += e.totalKCRedeemed || 0;
        });

        draft.forEach((e) => {
          e.value = o[e.key];
        });
      })
    );

    setData(d);
    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  const onDtChange = (val) => {
    let v = val;

    if (!v.length) {
      v = [startOfMonth(new Date()), new Date()];
    }

    setValue("createdAt", v);

    filterDataRef.current = {
      ...filterDataRef.current,
      createdAt: v,
    };

    defaultDateConfig.defaultDate = v;

    applyFilter();
  };

  return (
    <>
      <div className="d-flex justify-content-between my-2 align-items-center">
        <PageInfo
          title="Stores Summary"
          breadcrumbs={breadcrumbs}
          navigate={navigate}
          noMargin
        />
        {!loadingData && data.length > 0 && (
          <div className="text-muted fs-val-sm">
            <i className="bi bi-info-circle me-1"></i>
            Viewing assigned store details
          </div>
        )}
      </div>
      <div className="row mb-3">
        <div className="col-3">
          <Controller
            name="createdAt"
            control={control}
            render={({ field: { value } }) => (
              <DateInput
                callback={onDtChange}
                value={value}
                label="Choose Date Range"
                config={defaultDateConfig}
                template={2}
              />
            )}
          />
        </div>
      </div>

      {/* summary */}
      <div className="row align-self-center mb-2">
        {summary.map((e, k) => (
          <div className="col-auto" key={k}>
            <SummaryCard
              value={e.value}
              title={e.label}
              template={3}
              isValueAmt={e.isValueAmt}
              amtDecimalPlace={0}
              loading={loadingData}
              valueColor={e.color}
            />
          </div>
        ))}
      </div>

      <StoreDashboardTable data={data} loading={loadingData} />
    </>
  );
}

export default StoreDashboard;
