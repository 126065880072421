import { StoresModal } from "@sk/features";
import { AppCard, SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { defaultFilterData, inputConfig } from "../constant";
import InventoryAgeingAdvFilterModal from "../modals/InventoryAgeingAdvFilterModal";
import InventoryAgeingAppliedFilters from "./InventoryAgeingAppliedFilters";
import { AuthService } from "@sk/services";
import { produce } from "immer";

const InventoryAgeingFilter = ({
  callback,
  filterData,
  viewType,
  hideWarehouseInput,
  hideStore,
}) => {
  const { register, getValues, setValue } = useForm({
    defaultValues: defaultFilterData,
  });

  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);
  const [showStoreModal, setShowStoreModal] = useState(false);

  const [warehouseOptions, setWarehouseOptions] = useState([
    ...AuthService.getLoggedInEmpWh().map((x) => ({
      label: x.name,
      value: x._id,
    })),
  ]);

  useEffect(() => {
    let t;

    Object.keys(defaultFilterData).forEach((key) => {
      if (key == "store") {
        attachWarehouseOptions(filterData.warehouse);
        if (filterData[key]?.length) {
          setValue("storeName", filterData.store?.[0]?.label || "");
        } else {
          setValue("storeName", "");
          t = setTimeout(() => {
            setValue("warehouse", AuthService.getDefaultWhId());
          }, 0);
        }
      }
      setValue(key, filterData[key]);
    });

    return () => {
      clearTimeout(t);
    };
  }, [filterData, setValue]);

  const debounceSearch = useCallback(
    debounce(() => {
      callback({ action: "apply", formData: getValues() });
    }, 500),
    [callback, getValues]
  );

  const onProductSearch = useCallback(() => {
    debounceSearch();
  }, [debounceSearch]);

  const onInputChange = useCallback(() => {
    callback({ action: "apply", formData: getValues() });
  }, [callback, getValues]);

  const handleMoreFiltersClick = () => {
    setShowAdvFilterModal(true);
  };

  const handleModalCallback = ({ action, formData }) => {
    if (action === "apply" || action === "reset") {
      callback({ action: action, formData });
    }
    setShowAdvFilterModal(false);
  };

  const appliedFilterCallback = ({ action, data }) => {
    if (action === "remove") {
      // callback({ action: "remove", formData: data });
      setValue(data.key, defaultFilterData[data.key]);
      if (data.key === "store") {
        setValue("storeName", "");
        setValue("warehouse", AuthService.getDefaultWhId());
      }
      callback({ action: "apply", formData: getValues() });
    }
  };

  const onWarehouseChange = useCallback(
    (value) => {
      setValue("warehouse", value);
      setValue("storeName", "");
      setValue("store", []);
      callback({ action: "apply", formData: getValues() });
      attachWarehouseOptions(value);
    },
    [callback, getValues, setValue]
  );

  const attachWarehouseOptions = (wh) => {
    setWarehouseOptions(
      produce((draft) => {
        const i = draft.findIndex((e) => e.label === "Choose");
        if (wh) {
          if (i !== -1) {
            draft.splice(i, 1);
          }
        } else {
          if (i === -1) {
            draft.unshift({ label: "Choose", value: "" });
          }
        }
      })
    );
  };

  const storeModalCallback = (e) => {
    setShowStoreModal(false);
    if (e?.action === "selectedStore") {
      const store = e?.store?.[0] || {};
      if (store?._id) {
        setValue("warehouse", "");
        setValue("storeName", store.name);
        setValue("store", [
          {
            label: store.name + " (" + store._id + ")",
            value: { _id: store._id, name: store.name },
          },
        ]);
        callback({ action: "apply", formData: getValues() });

        setWarehouseOptions((prevOptions) => {
          const newOptions = [...prevOptions];
          const i = newOptions.findIndex((e) => e.label === "Choose");
          if (i === -1) {
            newOptions.unshift({ label: "Choose", value: "" });
          }
          return newOptions;
        });
      }
    }
  };

  return (
    <>
      <AppCard>
        <div className="row align-items-center">
          <div className="col-12">
            <div className="row g-3">
              {!hideWarehouseInput && (
                <div className="col-auto">
                  <SelectInput
                    name="warehouse"
                    options={warehouseOptions}
                    register={register}
                    callback={onWarehouseChange}
                    label="Warehouse"
                    size="sm"
                    gap={0}
                  />
                </div>
              )}

              {/* Add Store Filter */}
              {!hideStore && (
                <div
                  className={
                    viewType == "allInventory" ||
                    viewType == "sellableInventory" ||
                    viewType == "nonSellableInventory"
                      ? "col-3"
                      : "col-2"
                  }
                  onClick={() => setShowStoreModal(true)}
                >
                  <TextInput
                    name="storeName"
                    register={register}
                    label="Store"
                    placeholder="Search Store"
                    readOnly
                    size="sm"
                    gap={0}
                  />
                </div>
              )}

              {inputConfig[viewType]?.map((input) => (
                <div
                  className={
                    input.name == "productSearch" && hideStore
                      ? "col-3"
                      : input.colSize
                  }
                  key={input.name}
                >
                  {input.type === "text" ? (
                    <TextInput
                      name={input.name}
                      register={register}
                      callback={onProductSearch}
                      label={
                        input.name == "productSearch"
                          ? filterData?.store?.length > 0
                            ? "Deal"
                            : "Product"
                          : input.label
                      }
                      size="sm"
                      gap={0}
                      placeholder={
                        input.name == "productSearch"
                          ? filterData?.store?.length > 0
                            ? "Search Deal"
                            : "Search Product"
                          : input.placeholder
                      }
                    />
                  ) : (
                    <SelectInput
                      name={input.name}
                      options={input.options}
                      register={register}
                      callback={onInputChange}
                      label={input.label}
                      size="sm"
                      gap={0}
                    />
                  )}
                </div>
              ))}

              <div className="col-auto fs-val-md align-self-end">
                <button
                  className="btn btn-outline-primary btn-sm"
                  type="button"
                  onClick={handleMoreFiltersClick}
                >
                  <i className="bi bi-funnel me-1"></i>
                  More Filters
                </button>
              </div>
            </div>
          </div>
        </div>

        <InventoryAgeingAppliedFilters
          filterData={filterData}
          callback={appliedFilterCallback}
        />
      </AppCard>

      <InventoryAgeingAdvFilterModal
        show={showAdvFilterModal}
        callback={handleModalCallback}
        formData={filterData}
      />

      <StoresModal
        show={showStoreModal}
        callback={storeModalCallback}
        onlyAssignedStore={true}
        rbackKey="InventoryAgeingDashboard"
      />
    </>
  );
};

export default memo(InventoryAgeingFilter);
