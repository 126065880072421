import { useFetchUrlQueryString } from "@sk/hooks";
import {
  AuthService,
  DashboardService,
  NavService,
  PosService,
} from "@sk/services";
import {
  Amount,
  AppCard,
  BusyLoader,
  DateFormatter,
  HighlightText,
  InfoPopover,
  NoDataFound,
  PageInfo,
  PageLoader,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  SummaryCard,
  TableHeader,
  TableSkeletonLoader,
  Tabs,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import InventoryAgeingFilter from "./components/InventoryAgeingFilter";
import {
  defaultFilterData,
  invAgeingInventoryStatusOptions,
  tdStyle,
  FAST_MOVING,
  SLOW_MOVING,
  NON_MOVING,
  NEAR_EXPIRY,
  RECENT_PURCHASE,
  movementStatusDescriptions,
} from "./constant";
import { get } from "lodash";
import { produce } from "immer";
import { StoreCard, AddToPurchaseBasketModal } from "@sk/features";
import {
  headers,
  rbac,
  storeWiseTableColumnKeys,
  tableColumnKeys,
} from "./inventoryAgeingPrdConstants";

const tdStyles = {
  stickyRight: {
    position: "sticky",
    right: 0,
    backgroundColor: "#fff",
    zIndex: 99,
  },
  // Add other styles if needed
};

const InventoryAgeingProducts = () => {
  const router = useNavigate();
  const [searchParams] = useSearchParams();
  const query = useFetchUrlQueryString(searchParams);

  const [data, setData] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState({ loading: true, value: 0 });
  const [summary, setSummary] = useState({
    totalInventorySnapshots: 0,
    totalProducts: 0,
    totalValue: 0,
    totalQuantity: 0,
  });
  const [busyLoader, setBusyLoader] = useState({ show: false });

  const [activeTab, setActiveTab] = useState({ key: "allInventory" });

  const [breadcrumbs, setBreadcrumbs] = useState([
    { name: "Home", link: "/auth/init" },
    { name: "Inventory Analysis", link: "/dashboard/inventory-ageing" },
    { name: "Products" },
  ]);

  const filterRef = useRef({
    ...defaultFilterData,
  });
  const paginationRef = useRef({ ...defaultPaginationConfig });
  const sortDataRef = useRef({ key: "name", value: "asc" });

  const isStoreWise = filterRef.current.store?.length > 0;

  // Memoize the filtered headers to prevent unnecessary recalculations
  const filteredHeaders = useMemo(
    () => getFilteredHeadersByTab(activeTab.key, isStoreWise, query.view),
    [activeTab.key, isStoreWise, query.view]
  );

  const [basketModal, setBasketModal] = useState({
    show: false,
    dealId: null,
  });

  const loadList = useCallback(async () => {
    setLoading(true);
    setData([]);
    const r = await getData(
      prepareFilterParams(
        filterRef.current,
        paginationRef.current,
        sortDataRef.current
      ),
      filterRef.current.store?.length > 0
    );
    setData(r.data);
    setLoading(false);
  }, []);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };
    loadList();

    setRecords({ loading: true, value: 0 });
    const { count, value, quantity, products } = await getCount(
      prepareFilterParams(filterRef.current),
      filterRef.current.store?.length > 0
    );
    setRecords({ loading: false, value: count });
    paginationRef.current.totalRecords = count;

    setSummary({
      totalInventorySnapshots: count,
      totalProducts: products,
      totalValue: value,
      totalQuantity: quantity,
    });
  }, [loadList]);

  useEffect(() => {
    if (isStoreWise) {
      setBreadcrumbs([
        { name: "Home", link: "/auth/init" },
        {
          name: "Inventory Analysis",
          link: "/dashboard/inventory-ageing",
          params: {
            fid: query.fid,
            view: "store",
            fName: query.fName,
          },
        },
        { name: "Products" },
      ]);
    }
  }, [isStoreWise, query.fid, query.fName]);

  useEffect(() => {
    filterRef.current = {
      ...filterRef.current,
      view: query.view || "",
    };

    Object.keys(defaultFilterData).forEach((key) => {
      if (query[key] !== undefined) {
        filterRef.current[key] = query[key];
      }
    });

    if (query.tab) {
      setActiveTab(tabs.find((tab) => tab.key === query.tab));
      if (query.tab === "sellableInventory") {
        filterRef.current.inventoryStatus =
          invAgeingInventoryStatusOptions.find(
            (x) => x.key === "sellable"
          )?.value;
      } else if (query.tab === "nonSellableInventory") {
        filterRef.current.inventoryStatus =
          invAgeingInventoryStatusOptions.find(
            (x) => x.key === "nonSellable"
          )?.value;
      }
    }

    // Helper function to parse multiple values
    const parseMultipleValues = (ids, names) => {
      if (!ids || !names) return [];

      const idArray = ids.split("|");
      const nameArray = names.split("|");

      return idArray.map((id, index) => ({
        label: nameArray[index],
        value: { _id: id, name: nameArray[index] },
      }));
    };

    if (query.fid && query.fName) {
      filterRef.current.store = parseMultipleValues(query.fid, query.fName);
    }

    // Handle multiple menu selections
    if (query.menuId && query.menuName) {
      filterRef.current.menu = parseMultipleValues(
        query.menuId,
        query.menuName
      );
    }

    // Handle multiple category selections
    if (query.categoryId && query.categoryName) {
      filterRef.current.category = parseMultipleValues(
        query.categoryId,
        query.categoryName
      );
    }

    // Handle multiple brand selections
    if (query.brandId && query.brandName) {
      filterRef.current.brand = parseMultipleValues(
        query.brandId,
        query.brandName
      );
    }

    const fetchBatchId = async () => {
      setPageLoading(true);
      try {
        const res = await getBatchId(filterRef.current.store?.length > 0);
        filterRef.current.batchId = res?.batchId;
        filterRef.current.batchDate = res?.batchDate;
      } catch (error) {
        console.error("Error fetching batch details:", error);
      } finally {
        setPageLoading(false);
        applyFilter();
      }
    };

    fetchBatchId();
  }, [applyFilter, query]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current = { ...paginationRef.current, ...data };
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortDataRef.current = data;
      applyFilter();
    },
    [applyFilter]
  );

  const filterCallback = async (data) => {
    if (data.action == "apply" || data.action == "reset") {
      let newBatch = {
        batchId: filterRef.current.batchId,
        batchDate: filterRef.current.batchDate,
      };

      if (data.formData.store?.length) {
        setBusyLoader({ show: true });
        const res = await getBatchId(true);
        newBatch = {
          batchId: res?.batchId,
          batchDate: res?.batchDate,
        };
        setBusyLoader({ show: false });
      } else {
        setBusyLoader({ show: true });
        const res = await getBatchId();
        newBatch = {
          batchId: res?.batchId,
          batchDate: res?.batchDate,
        };
        setBusyLoader({ show: false });
      }

      filterRef.current = {
        ...filterRef.current,
        ...data.formData,
        batchId: newBatch.batchId,
        batchDate: newBatch.batchDate,
      };

      applyFilter();
    } else if (data.action == "remove") {
      filterRef.current = {
        ...filterRef.current,
        [data.formData.key]: defaultFilterData[data.formData.key],
      };
      applyFilter();
    }
  };

  const handleFilterByType = (type, item) => {
    if (type === "menu") {
      filterRef.current.menu = [
        {
          label: item.menuName,
          value: {
            _id: item.menuId,
            name: item.menuName,
          },
        },
      ];
    } else if (type === "category") {
      filterRef.current.category = [
        {
          label: item.parentCategoryName,
          value: {
            _id: item.parentCategoryId,
            name: item.parentCategoryName,
          },
        },
      ];
    } else if (type === "brand") {
      filterRef.current.brand = [
        {
          label: item.brandName,
          value: {
            _id: item.brandId,
            name: item.brandName,
          },
        },
      ];
    } else if (type === "ageingSlab") {
      filterRef.current.ageingSlab = item.inventoryInfo.ageingSlab;
    } else if (type === "product") {
      filterRef.current.product = {
        name: item.productInfo.name,
        id: item.productId,
      };
    }
    applyFilter();
  };

  const onTabCb = ({ value }) => {
    // Set default filter data
    const defaultData = { ...defaultFilterData };

    if (filterRef.current?.store?.length) {
      defaultData.store = filterRef.current?.store;
      defaultData.warehouse = "";
    }

    if (value.key === "sellableInventory") {
      defaultData.inventoryStatus = invAgeingInventoryStatusOptions.find(
        (x) => x.key === "sellable"
      ).value;
    } else if (value.key === "nonSellableInventory") {
      defaultData.inventoryStatus = invAgeingInventoryStatusOptions.find(
        (x) => x.key === "nonSellable"
      ).value;
    } else {
      defaultData.inventoryStatus = "";
    }

    filterRef.current = { ...filterRef.current, ...defaultData };
    setActiveTab(value);
    applyFilter();
  };

  const download = async () => {
    setBusyLoader({ show: true });

    const params = prepareFilterParams(
      filterRef.current,
      paginationRef.current,
      sortDataRef.current
    );

    delete params.sort;

    params.count = 100000000;

    params.outputType = "download";

    const r = await getData(params, filterRef.current.store?.length > 0);

    const path = r?.raw?.data?.filePath;

    setBusyLoader({ show: false });
    if (path) {
      const filePath = await DashboardService.downloadTempFile(path);
      window.open(filePath, "_blank");
      Toaster.success("Downloaded successfully");
    } else {
      Toaster.error("Something went wrong while downloading the file.");
    }
  };

  const handleProductRedirect = (productId) => {
    if (filterRef.current.store?.length) {
      console.log(filterRef.current.store?.[0]?.value);
      NavService.openInNewTab("/pos/store/deal/view", {
        id: productId,
        storeId: filterRef.current.store?.[0]?.value?._id,
        storeName: filterRef.current.store?.[0]?.value?.name,
      });
    } else {
      NavService.openInNewTab("/cms/product/view", {
        id: productId,
      });
    }
  };

  const handleAddToBasket = (item) => {
    setBasketModal({
      show: true,
      dealId: item.productId,
    });
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Inventory Analysis Products / Inventory Snapshot"
            viewType="products"
            breadcrumbs={breadcrumbs}
            navigate={router}
          />
        </div>
        <div className="col-auto fs-val-sm">
          Last data fetched on{" "}
          <DateFormatter date={filterRef.current.batchDate} />
        </div>
      </div>

      {pageLoading ? (
        <PageLoader />
      ) : (
        <>
          {query.fid && query.view === "store" ? (
            <StoreCard
              fid={query.fid}
              navigate={router}
              rbacKey={
                isStoreWise && query.view === "store"
                  ? "InventoryAgeingStoreDashboard"
                  : "InventoryAgeingDashboard"
              }
            />
          ) : null}

          <div className="mb-3">
            <Tabs
              data={tabs}
              callback={onTabCb}
              activeTab={activeTab}
              template={4}
              shadow={true}
            />
          </div>

          <InventoryAgeingFilter
            filterData={filterRef.current}
            callback={filterCallback}
            viewType={activeTab.key + "Products"}
            hideWarehouseInput={query.fid && query.view === "store"}
            hideStore={query.fid && query.view === "store"}
          />

          <div className="row">
            <div className="col-auto">
              <SummaryCard
                title="Total Inventory Snapshots"
                value={summary.totalInventorySnapshots}
                valueColor="info"
                template={3}
              />
            </div>
            <div className="col-auto">
              <SummaryCard
                title="Total Unique Products"
                value={summary.totalProducts}
                valueColor="primary"
                template={3}
              />
            </div>
            <div className="col-auto">
              <SummaryCard
                title="Total Quantity"
                value={summary.totalQuantity}
                valueColor="success"
                template={3}
              />
            </div>
            <div className="col-auto">
              <SummaryCard
                title="Total Value"
                value={summary.totalValue}
                valueColor="danger"
                template={3}
                isValueAmt={true}
              />
            </div>
          </div>

          <AppCard>
            <div className="fs-val-sm text-secondary mb-2">
              Products are displayed based on their inventory snapshots.
            </div>
            <div className="mb-3 row">
              <div className="col">
                <PaginationSummary paginationConfig={paginationRef.current} />
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-sm btn-outline-primary"
                  onClick={download}
                >
                  <i className="bi bi-download"></i> Download
                </button>
              </div>
            </div>

            <div
              className="tbl-scroll-container custom-scrollbar thin-scrollbar border rounded fixed-width-table"
              style={containerStyle}
            >
              <table
                className="table table-sm mb-0 bg-white table-hover"
                style={tableStyle}
              >
                <TableHeader
                  data={filteredHeaders}
                  sortCb={sortCb}
                  sort={sortDataRef.current}
                  isSticky={true}
                />
                <tbody className="fs-val-sm">
                  {loading ? (
                    <TableSkeletonLoader
                      cols={filteredHeaders.length}
                      rows={10}
                    />
                  ) : data.length === 0 ? (
                    <tr>
                      <td colSpan={filteredHeaders.length}>
                        <NoDataFound>No data found</NoDataFound>
                      </td>
                    </tr>
                  ) : (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">
                          {paginationRef.current.startSlNo + index}
                        </td>

                        {!isStoreWise && <td>{item.snapshotId}</td>}

                        <td style={stickyColumnStyle} className="fw-semibold">
                          <button
                            className="btn btn-link text-dark text-start p-0 fs-val-sm text-wrap"
                            onClick={() =>
                              handleProductRedirect(item.productId)
                            }
                          >
                            {item.name}
                          </button>
                        </td>

                        <td>{item.productId}</td>

                        <td>
                          <HighlightText
                            type={
                              item.inventoryStatus === "Sellable"
                                ? "success"
                                : "danger"
                            }
                            template={2}
                            size="xs"
                          >
                            {item.inventoryStatus}
                          </HighlightText>
                        </td>
                        <td>
                          <button
                            className="btn btn-link text-dark text-start p-0 fs-val-sm"
                            onClick={() =>
                              handleFilterByType("ageingSlab", item)
                            }
                          >
                            {item.ageingSlab}
                          </button>
                        </td>

                        {/* Conditionally render stockMovement column */}
                        {activeTab.key !== "nonSellableInventory" && (
                          <td>
                            <HighlightText
                              type={item.movementStatusType}
                              template={2}
                              size="xs"
                            >
                              {item.stockMovement}
                            </HighlightText>
                            <InfoPopover
                              content={
                                movementStatusDescriptions[item.stockMovement]
                              }
                            />
                          </td>
                        )}

                        <td className="text-center" style={tdStyle.qty}>
                          {Math.round(item.stockQty)}
                        </td>

                        <td className="text-center">
                          <Amount value={item.stockValue} />
                        </td>

                        <td className="text-center">
                          {Math.round(item.inventoryHoldingDays)} days
                        </td>

                        <td style={tdStyle.expiryDate}>
                          {item.nearExpiry && item.ageingSlab != "Expired" && (
                            <InfoPopover content="This product is nearing its expiry date.">
                              <span
                                className="animate__animated animate__pulse animate__infinite align-middle me-1"
                                style={circleStyles}
                              ></span>
                            </InfoPopover>
                          )}
                          <DateFormatter
                            date={item.expiryDate}
                            format="dd MMM yyyy"
                          />
                        </td>

                        <td className="text-center">
                          {Math.round(
                            item.productShelfLife || 0
                          ).toLocaleString()}{" "}
                          days
                        </td>
                        <td>
                          {item.grnDate ? (
                            <DateFormatter
                              date={item.grnDate}
                              format="dd MMM yyyy"
                            />
                          ) : (
                            "--"
                          )}
                        </td>
                        <td>
                          <button
                            className="btn btn-link text-dark text-start p-0 fs-val-sm text-wrap"
                            style={{ width: "200px" }}
                            onClick={() => handleFilterByType("menu", item)}
                          >
                            {item.menuName}
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn btn-link text-dark text-start p-0 fs-val-sm text-wrap"
                            onClick={() => handleFilterByType("category", item)}
                          >
                            {item.parentCategoryName}
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn btn-link text-dark text-start p-0 fs-val-sm text-wrap"
                            onClick={() => handleFilterByType("category", item)}
                          >
                            {item.categoryName}
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn btn-link text-dark text-start p-0 fs-val-sm text-wrap"
                            onClick={() => handleFilterByType("brand", item)}
                          >
                            {item.brandName}
                          </button>
                        </td>

                        {!isStoreWise && (
                          <td>
                            <button
                              className="btn btn-link text-dark text-start p-0 fs-val-sm text-wrap"
                              onClick={() =>
                                handleFilterByType(
                                  "companyName",
                                  item.companyName
                                )
                              }
                            >
                              {item.companyName}
                            </button>
                          </td>
                        )}

                        <td className="text-center">
                          {item.last30DaysSalesQty || 0}
                        </td>

                        <td className="text-center">
                          {item.last60DaysSalesQty || 0}
                        </td>

                        <td className="text-center">
                          {item.last90DaysSalesQty || 0}
                        </td>

                        <td>{item.location || "--"}</td>
                        <td>{item.rackId || "--"}</td>
                        <td>{item.binId || "--"}</td>

                        <td>
                          <Amount value={item.mrp || 0} decimalPlace={2} />
                        </td>

                        {!isStoreWise && (
                          <Rbac roles={rbac.purchasePrice}>
                            <td>
                              <Amount
                                value={item.purchasePrice}
                                decimalPlace={2}
                              />
                            </td>
                          </Rbac>
                        )}

                        <td>
                          <Amount value={item.price || 0} decimalPlace={2} />
                        </td>

                        {isStoreWise && (
                          <td>
                            <Amount
                              value={item.rspPrice || 0}
                              decimalPlace={2}
                            />
                          </td>
                        )}

                        <td>{(item.margin || 0).toFixed(2)}%</td>

                        {isStoreWise && (
                          <td>
                            <Amount
                              value={item._pnl?.value || 0}
                              decimalPlace={2}
                            />
                            <span>
                              {item._pnl?.value > 0 ? (
                                <i
                                  className="bi bi-arrow-up fw-bold text-success"
                                  title="Positive"
                                ></i>
                              ) : item._pnl?.value < 0 ? (
                                <i
                                  className="bi bi-arrow-down fw-bold text-danger"
                                  title="Negative"
                                ></i>
                              ) : (
                                <i
                                  className="bi bi-dash-circle fw-bold text-secondary"
                                  title="Neutral"
                                ></i>
                              )}
                            </span>
                          </td>
                        )}

                        {query.fid && query.view === "store" && (
                          <td style={tdStyles.stickyRight}>
                            {item.previousSuggest ? (
                              <div className="d-flex align-items-center justify-content-center">
                                <span className="me-1 text-muted">Added</span>
                                <InfoPopover
                                  header="Added to Basket"
                                  content={
                                    <div>
                                      <strong>Suggested by:</strong>{" "}
                                      {item.previousSuggest.suggestedBy.name}
                                      <br />
                                      <strong>Quantity:</strong>{" "}
                                      {item.previousSuggest.quantity}
                                      <br />
                                      <strong>Date:</strong>{" "}
                                      <DateFormatter
                                        date={item.previousSuggest.createdAt}
                                        format="dd MMM yyyy"
                                      />
                                    </div>
                                  }
                                  placement="top"
                                >
                                  <span className="text-success">
                                    <i className="bi bi-info-circle"></i>
                                  </span>
                                </InfoPopover>
                              </div>
                            ) : (
                              <button
                                className="btn btn-outline-primary btn-sm rounded-pill px-3 fs-val-sm"
                                onClick={() => handleAddToBasket(item)}
                              >
                                <i className="bi bi-cart-plus me-1"></i>
                                Add
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            <div className="text-end mt-3">
              <PaginationBlock
                paginationCb={paginationCb}
                loadingTotalRecords={records.loading}
                paginationConfig={paginationRef.current}
                size="sm"
              />
            </div>
          </AppCard>
          <BusyLoader show={busyLoader.show} />

          <AddToPurchaseBasketModal
            show={basketModal.show}
            dealId={basketModal.dealId}
            storeId={filterRef.current.store?.[0]?.value?._id}
            callback={() => setBasketModal({ show: false, dealId: null })}
          />
        </>
      )}
    </>
  );
};

const containerStyle = {
  maxHeight: "600px",
  overflowY: "auto",
};

const tableStyle = {
  minWidth: "3200px", // Increased table width
};

const tabs = [
  { key: "allInventory", tabName: "All Inventory" },
  { key: "sellableInventory", tabName: "Sellable Inventory" },
  { key: "nonSellableInventory", tabName: "Non Sellable Inventory" },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const stickyColumnStyle = {
  position: "sticky",
  left: 0,
  backgroundColor: "white",
  zIndex: 1,
};

const circleStyles = {
  width: "8px",
  height: "8px",
  borderRadius: "50%",
  backgroundColor: "red",
  display: "inline-block",
};

const getData = async (params, isStoreWise = false) => {
  const response = isStoreWise
    ? await DashboardService.fetchStoreInventoryAnalytics(params)
    : await DashboardService.fetchInventoryAnalytics(params);

  let purchaseBaskets = [];

  const formattedData = Array.isArray(response.resp)
    ? formatResponse(response.resp, isStoreWise)
    : [];

  if (formattedData?.length && isStoreWise) {
    const dealIds = formattedData?.map((item) => item.productId);
    const purchaseBasketResponse = await PosService.getPurchaseBaskets({
      page: 1,
      count: dealIds.length,
      filter: {
        "dealInfo.id": { $in: dealIds },
        franchiseId: params.filter?.franchiseId?.$in?.[0],
        status: "Open",
      },
    });

    purchaseBaskets = purchaseBasketResponse.resp || [];
  }

  // Attach purchase basket data to each item
  const updatedData = formattedData.map((item) => {
    const basketItem = purchaseBaskets.find(
      (basket) => basket.dealInfo.id === item.productId
    );
    return {
      ...item,
      previousSuggest: basketItem ? basketItem : null, // Attach previousSuggest if exists
    };
  });

  return {
    data: updatedData,
    raw: response.raw,
  };
};

const getCount = async (params, isStoreWise = false) => {
  const p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;
  const response = isStoreWise
    ? await DashboardService.fetchStoreInventoryAnalytics(p)
    : await DashboardService.fetchInventoryAnalytics(p);
  return {
    count: response?.resp?.[0]?.totalDocs || 0,
    value: Math.round(response?.resp?.[0]?.totalStockValue || 0),
    quantity: Math.round(response?.resp?.[0]?.totalStockQty || 0),
    products: response?.resp?.[0]?.totalProducts || 0,
  };
};

const prepareFilterParams = (filter, pagination, sort) => {
  let type = "getAllInventoryAgeingAnalytics"; // Default query type

  // Check if store filter is enabled
  if (filter.store?.length > 0) {
    type = "getStoreAllInventoryAgeingAnalyticsList";
  }

  const params = DashboardService.prepareInventoryAnalyticsParams(filter, type);

  if (pagination) {
    params.page = pagination.activePage;
    params.count = pagination.rowsPerPage;
  }

  if (sort) {
    let keys = tableColumnKeys;
    if (filter.store?.length > 0) {
      keys = storeWiseTableColumnKeys;
    }
    let key = keys[sort.key]?.sort;
    if (key) {
      params.sort = {
        [key]: sort.value === "asc" ? 1 : -1,
      };
    }
  }

  if (filter.productSearch) {
    params.filter.$or = [
      { "productInfo.name": { $regex: filter.productSearch, $options: "i" } },
      { productId: { $regex: filter.productSearch, $options: "i" } },
      { "dealInfo.dealName": { $regex: filter.productSearch, $options: "i" } },
      { dealId: { $regex: filter.productSearch, $options: "i" } },
    ];
  }

  return params;
};

// Move this outside the component since it doesn't need to be recreated on every render
const getFilteredHeadersByTab = (tabKey, isStoreWise = false, view = "all") => {
  return produce(headers, (draft) => {
    let i = draft.length;
    while (i--) {
      // Check if the header key is in the rbac array
      if (
        draft[i].rbac?.length &&
        !AuthService.isValidUserRole(draft[i].rbac)
      ) {
        draft.splice(i, 1);
        continue;
      }

      if (isStoreWise) {
        // Remove ignored keys for store-wise view
        if (
          draft[i].key === "snapshotId" ||
          draft[i].key === "companyName" ||
          draft[i].key === "purchasePrice"
        ) {
          draft.splice(i, 1);
          continue;
        }

        // Update labels for store-wise view
        switch (draft[i].key) {
          case "name":
            draft[i].label = "Deal Name";
            break;
          case "productId":
            draft[i].label = "Deal ID";
            break;
          case "margin":
            draft[i].label = "RSP Margin";
            break;
        }

        if (!draft.some((header) => header.key === "Profit")) {
          draft.push({
            label: "Profit",
            key: "Profit",
            width: "12%",
          });
        }

        // Add new column for store-wise view
        if (
          !draft.some((header) => header.key === "action") &&
          view === "store"
        ) {
          draft.push({
            label: "Pur. Basket",
            key: "action",
            width: "12%",
            sortable: false,
            isSticky: true,
            stickyPosition: "right",
            isCentered: true,
          });
        }
      } else {
        if (draft[i].key === "rspPrice") {
          draft.splice(i, 1);
        }
      }

      // else {
      //   // Remove purchase price for non store-wise view
      //   if (draft[i].key === "purchasePrice") {
      //     draft.splice(i, 1);
      //     continue;
      //   }
      // }

      // Remove stockMovement for non-sellable inventory
      if (
        tabKey === "nonSellableInventory" &&
        draft[i].key === "stockMovement"
      ) {
        draft.splice(i, 1);
      }
    }
  });
};

const getBatchId = async (isStoreWise = false) => {
  const res = await DashboardService.getInventoryAgeingBatchId(
    isStoreWise ? { outputType: "storeInventoryAgening" } : {}
  );
  return {
    batchId: res?.resp?.batchId,
    batchDate: res?.resp?.batchTimestamp,
  };
};

const formatResponse = (data, isStoreWise = false) => {
  const movementColorMap = {
    [FAST_MOVING]: "success",
    [SLOW_MOVING]: "warning",
    [NON_MOVING]: "danger",
    [NEAR_EXPIRY]: "info",
    [RECENT_PURCHASE]: "primary",
    // Add other mappings as necessary
  };

  return data.map((item) => {
    let t = {};

    let columnKeys = isStoreWise ? storeWiseTableColumnKeys : tableColumnKeys;

    Object.keys(columnKeys).forEach((key) => {
      t[key] = get(item, columnKeys[key].data);

      if (key === "stockMovement") {
        t.movementStatusType =
          movementColorMap[item.inventoryInfo.stockMovement] || "secondary";
      }

      if (key == "rspPrice" && !t.rspPrice) {
        t.rspPrice = t.mrp;
      }

      if (isStoreWise) {
        const v = t.rspPrice - t.price;
        t._pnl = {
          value: v,
        };
      }

      t._raw = item;
    });

    return t;
  });
};

export default InventoryAgeingProducts;
