import { Controller, useForm } from "react-hook-form";
import { SelectInput, EntitySearchInput, TextInput, DateInput } from "@sk/uis";
import {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { AuthService, FranchiseService } from "@sk/services";
import { debounce, orderBy, sortBy } from "lodash";
import { add, sub } from "date-fns";

const warehouseOptions = AuthService.getLoggedInEmpWh().map((x) => ({
  label: x.name,
  value: x._id,
}));

const dateConfig = {
  mode: "range",
  maxDate: add(new Date(), {
    years: 3,
  }),
};

const statusOptions = orderBy(
  [
    { value: "nearExpiry", label: "Near Expiry", summaryCardKey: "nearExpiry" },
    { value: "expired", label: "Expired", summaryCardKey: "expired" },
    { value: "lowStock", label: "Low Stock", summaryCardKey: "lowStock" },
    { value: "stockOut", label: "Out of Stock", summaryCardKey: "outOfStock" },
    { value: "stopped", label: "Stopped", summaryCardKey: "stopped" },
    { value: "active", label: "Active", summaryCardKey: "active" },
    { value: "pending", label: "Pending", summaryCardKey: "pending" }, // Added Pending status
  ],
  ["label"]
);
statusOptions.unshift({ value: "", label: "All" });

const defaultFormData = {
  warehouse: AuthService.getDefaultWhId(),
  createdAt: [
    sub(new Date(), {
      days: 90,
    }),
    add(new Date(), {
      days: 30,
    }),
  ],
  menu: [],
  brand: [],
  category: [],
  openSearch: "",
  groupCode: "",
  status: "nearExpiry",
  search: "",
};

const ReservationFilter = forwardRef(({ callback }, ref) => {
  const { control, reset, register, getValues, setValue } = useForm({
    defaultValues: { ...defaultFormData },
  });
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [groupCodeOptions, setGroupCodeOptions] = useState([]);

  const loadGroupCodes = useCallback(async () => {
    const r = await FranchiseService.getGroupCodeList({
      page: 1,
      count: 50,
      select: "groupCode",
      filter: { isActive: true },
    });
    let d = Array.isArray(r.resp) ? sortBy(r.resp || [], ["groupCode"]) : [];
    d = d.map((x) => ({ label: x.groupCode, value: x.groupCode }));
    d.unshift({ label: "Choose", value: "" });
    setGroupCodeOptions(d);
  }, []);

  useEffect(() => {
    loadGroupCodes();
  }, [loadGroupCodes]);

  useEffect(() => {
    // Reset the form when the component mounts or when needed
    reset();
  }, [reset]);

  const triggerCb = useCallback(() => {
    if (callback) {
      callback({ formData: getValues() });
    }
  }, [callback, getValues]);

  const handleReset = () => {
    reset({ ...defaultFormData });
    triggerCb();
  };

  // Expose updateForm method via ref
  useImperativeHandle(ref, () => ({
    updateForm: (updates) => {
      Object.entries(updates).forEach(([key, value]) => {
        setValue(key, value, {
          shouldDirty: true,
          shouldTouch: true,
        });
      });
    },
  }));

  const onSearch = useCallback(
    debounce(() => {
      const searchValue = getValues("search");

      // Clear date when search has value
      if (searchValue) {
        setValue("createdAt", []);
      }
      // Reset date to default when search is cleared
      else {
        setValue("createdAt", [
          sub(new Date(), { days: 90 }),
          add(new Date(), { days: 30 }),
        ]);
      }

      triggerCb();
    }, 800),
    [triggerCb, setValue, getValues]
  );

  const onStatusChange = useCallback(() => {
    const statusValue = getValues("status");

    // Clear date when status is pending
    if (statusValue === "pending") {
      setValue("createdAt", []);
    } else {
      setValue("createdAt", [
        sub(new Date(), { days: 90 }),
        add(new Date(), { days: 30 }),
      ]);
    }

    triggerCb();
  }, [triggerCb, setValue, getValues]);

  return (
    <form>
      <div className="row g-2">
        {/* First row - essential filters */}
        <div className="col-3">
          <TextInput
            label="Search"
            placeholder="Search by Product Name/ID/Config ID"
            name="search"
            register={register}
            callback={onSearch}
            gap={0}
          />
        </div>

        <div className="col-3">
          <SelectInput
            label="Status"
            register={register}
            name="status"
            options={statusOptions}
            callback={onStatusChange}
            gap={0}
          />
        </div>

        <div className="col-3">
          <SelectInput
            label="Warehouse"
            register={register}
            name="warehouse"
            options={warehouseOptions}
            callback={triggerCb}
            gap={0}
          />
        </div>

        <div className="col-3">
          <Controller
            control={control}
            name="createdAt"
            render={({ field }) => (
              <DateInput
                label="Validity Period"
                config={dateConfig}
                callback={(value) => {
                  field.onChange(value);
                  triggerCb();
                }}
                value={field.value}
                template={2}
                hideClose={false}
                gap={0}
              />
            )}
          />
        </div>

        {/* Additional filters that can be toggled */}
        {showMoreFilters && (
          <div className="col-12">
            <div className="row g-2">
              <div className="col-3">
                <Controller
                  control={control}
                  name="menu"
                  render={({ field }) => (
                    <EntitySearchInput
                      label="Menu"
                      callback={(value) => {
                        field.onChange(value);
                        triggerCb();
                      }}
                      value={field.value}
                      placeholder="Search by Menu Name/ID"
                      type="menu"
                      uid="reservationMenu"
                      gap={0}
                    />
                  )}
                />
              </div>

              <div className="col-3">
                <Controller
                  control={control}
                  name="category"
                  render={({ field }) => (
                    <EntitySearchInput
                      label="Category"
                      callback={(value) => {
                        field.onChange(value);
                        triggerCb();
                      }}
                      value={field.value}
                      placeholder="Search by Category Name/ID"
                      type="category"
                      uid="reservationCategory"
                      gap={0}
                    />
                  )}
                />
              </div>

              <div className="col-3">
                <Controller
                  control={control}
                  name="brand"
                  render={({ field }) => (
                    <EntitySearchInput
                      label="Brand"
                      callback={(value) => {
                        field.onChange(value);
                        triggerCb();
                      }}
                      value={field.value}
                      placeholder="Search by Brand Name/ID"
                      type="brand"
                      uid="reservationBrand"
                      gap={0}
                    />
                  )}
                />
              </div>

              <div className="col-3">
                <SelectInput
                  label="Group Code"
                  register={register}
                  name="groupCode"
                  options={groupCodeOptions}
                  callback={triggerCb}
                  gap={0}
                />
              </div>
            </div>
          </div>
        )}

        {/* Footer with toggle and reset buttons */}
        <div className="col-12">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <button
                type="button"
                className="btn btn-link text-primary p-0 fs-val-sm text-decoration-none"
                onClick={() => setShowMoreFilters(!showMoreFilters)}
              >
                {showMoreFilters ? (
                  <>
                    <i className="bi bi-chevron-up me-1"></i>
                    Show Less Filters
                  </>
                ) : (
                  <>
                    <i className="bi bi-chevron-down me-1"></i>
                    Show More Filters
                  </>
                )}
              </button>
            </div>
            <div className="ps-3">
              <button
                type="button"
                className="btn btn-warning btn-sm"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});

ReservationFilter.displayName = "ReservationFilter";

export default ReservationFilter;
