import totalImg from "../../../assets/imgs/total.svg";
import activeImg from "../../../assets/imgs/active.svg";
import inactiveImg from "../../../assets/imgs/inactive.svg";
import hasOffer from "../../../assets/imgs/not-linked.svg";

const listView = {
  breadcrumbs: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Referral Coins",
    },
  ],

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  //  Pagination Ends Here
  summaryCardData: [
    {
      label: "Total Count",
      key: "overall",
      filterParams: {},
      color: "primary",
      icon: "bi-check-all",
      img: totalImg,
    },
  ],
  // Summary Card Data ends Here
  formLabels: {
    createdAt: {
      label: "Created At",
      type: "dateRange",
    },
    customer: {
      label: "Category",
      valuePath: "[0].value.name",
    },
    customerType: {
      label: "customerType",
    },
  },
  //   Form Labels ends Here
  filterFormData: {
    dateRange: [],
    hasOffer: "",
    category: "",
    brand: "",
    franchise: [],
    deal: "",
    validityPeriod: [],
    groupType: "",
    status: "",
  },

  additionalTableDataConfig: [
    {
      key: "franchiseId",
      api: "franchise",
      loadingKey: "franchiseLoading",
      dataKey: "_franchise",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name,state,district,town,pincode",
      }),
    },
  ],
};

export default listView;
