import { appConfigs, DashboardService, OmsService } from "@sk/services";
import {
  Alert,
  AppCard,
  BusyLoader,
  DateFormatter,
  DateInput,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
  Toaster,
} from "@sk/uis";
import { set } from "date-fns";
import { debounce, merge } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { defaultDateRangeForOrderPlacedNotAllocated } from "../constantService";

const containerStyle = {
  height: "calc(100vh - 250px)",
  overflowY: "auto",
};

const tableStyle = {
  minWidth: "1300px",
};

const rbac = {
  allocate: ["AllocateOrder"],
};

const defaultDateRange = [...defaultDateRangeForOrderPlacedNotAllocated];

// Define headers similar to StockNotAllocatedTable
const headers = [
  {
    label: "Sl No",
    key: "slNo",
    isCentered: true,
    width: "3%",
  },
  {
    label: "Deal",
    key: "dealName",
    width: "20%",
  },
  {
    label: "Available Stock",
    key: "stock",
    width: "8%",
    isCentered: true,
  },
  {
    label: "Order Qty",
    key: "orderQty",
    width: "6%",
    isCentered: true,
  },
  {
    label: "Order Date",
    key: "orderDate",
    width: "8%",
  },
  {
    label: "Order ID",
    key: "order",
    width: "10%",
  },
  {
    label: "Suborder ID",
    key: "suborderId",
    width: "10%",
  },
  {
    label: "Franchise",
    key: "franchise",
    width: "15%",
  },
  {
    label: "Action",
    key: "action",
    width: "6%",
  },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const getData = async (params) => {
  const response = await DashboardService.getHasStockNotAllocated(params);
  return {
    data: Array.isArray(response.resp) ? response.resp : [],
    raw: response.raw,
  };
};

const getCount = async (params) => {
  const p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;
  const response = await DashboardService.getHasStockNotAllocated(p);
  return response?.resp?.[0]?.total || 0;
};

const prepareFilterParams = (filter, pagination) => {
  const params = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      createdAt: {
        $gte: set(filter.dateRange[0], {
          hours: 0,
          minutes: 0,
          seconds: 0,
        }),
        $lte: set(filter.dateRange[1], {
          hours: 23,
          minutes: 59,
          seconds: 59,
        }),
      },
    },
  };

  return merge(
    {},
    params,
    DashboardService.prepareParamsForOrderPlacedNotAllocated(
      filter.filterData,
      filter
    )
  );
};

const HasStockOrderPlacedNotAllocated = ({ filterData }) => {
  const { register, getValues, control } = useForm({
    defaultValues: {
      dateRange: [...defaultDateRange],
      search: "",
      franchiseSearch: "",
    },
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState({ loading: true, value: 0 });
  const [busyLoader, setBusyLoader] = useState({ show: false });

  const filterRef = useRef(filterData);
  const paginationRef = useRef({ ...defaultPaginationConfig });
  const sortDataRef = useRef({ key: "pendingOrderQty", value: "desc" });

  useEffect(() => {
    filterRef.current = {
      ...filterRef.current,
      filterData: {
        ...filterData,
      },
      dateRange: [...defaultDateRange],
    };
    applyFilter();
  }, [filterData, applyFilter]);

  const onSearch = () => {
    searchDebounceCb();
  };

  const loadList = useCallback(async () => {
    setLoading(true);
    setData([]);
    const r = await getData(
      prepareFilterParams(
        filterRef.current,
        paginationRef.current,
        sortDataRef.current
      )
    );
    if (r.raw?.code == appConfigs.AJAX_REQ_CANCEL) {
      return;
    }
    setData(r.data);
    setLoading(false);
  }, []);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    filterRef.current = {
      ...filterRef.current,
      ...getValues(),
    };

    loadList();

    setRecords({ loading: true, value: 0 });
    const count = await getCount(
      prepareFilterParams(filterRef.current, paginationRef.current)
    );
    setRecords({ loading: false, value: count });
    paginationRef.current.totalRecords = count;
  }, [loadList, getValues]);

  const searchDebounceCb = useCallback(
    debounce(() => {
      applyFilter();
    }, 500),
    []
  );

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortDataRef.current = data;
      applyFilter();
    },
    [applyFilter]
  );

  const allocate = async (item) => {
    const res = await Alert.confirm({
      title: "Are you sure you want to allocate this stock?",
      text: `You are about to allocate ${item.requiredQty} quantity to "${item.orderId}".`,
      okText: "Yes",
      cancelText: "No",
    });

    if (res.isConfirmed) {
      setBusyLoader({ show: true });
      const r = await OmsService.allocateStockForOrder(item.orderId, {});
      setBusyLoader({ show: false });

      if (r.statusCode === 200) {
        Toaster.success("Stock allocated successfully");
        applyFilter();
      } else {
        Toaster.error(r.resp?.message || "Failed to allocate stock");
      }
    }
  };

  const onDateChange = (callback) => (value) => {
    callback(value);
    applyFilter();
  };

  return (
    <>
      <AppCard>
        <div className="mb-1 text-muted fs-val-sm">
          Shows orders placed by stores where stock exists but is not yet
          allocated.
        </div>

        <div className="row mb-3 align-items-center">
          <div className="col-auto me-auto">
            <PaginationSummary
              loadingTotalRecords={records.loading}
              paginationConfig={paginationRef.current}
            />
          </div>

          <div className="col-3">
            <TextInput
              name="search"
              placeholder="Search by ID/Name/Order ID"
              register={register}
              callback={onSearch}
            />
          </div>
          <div className="col-3">
            <TextInput
              name="franchiseSearch"
              placeholder="Search by Franchise Name/ID"
              register={register}
              callback={onSearch}
            />
          </div>
          <div className="col-2">
            <Controller
              name="dateRange"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DateInput
                  callback={onDateChange(onChange)}
                  value={value}
                  placeholder="Select Date Range"
                  template={2}
                  hideClose={true}
                />
              )}
            />
          </div>
        </div>

        <div
          className="tbl-scroll-container custom-scrollbar thin-scrollbar fixed-width-table"
          style={containerStyle}
        >
          <table
            className="table table-sm mb-0 bg-white table-hover"
            style={tableStyle}
          >
            <TableHeader
              data={headers}
              sortCb={sortCb}
              sort={sortDataRef.current}
              isSticky={true}
            />
            <tbody className="fs-val-sm">
              {loading ? (
                <TableSkeletonLoader cols={headers.length} rows={10} />
              ) : data.length === 0 ? (
                <tr>
                  <td colSpan={headers.length} className="text-center">
                    <NoDataFound>No data found</NoDataFound>
                  </td>
                </tr>
              ) : (
                data.map((item, index) => (
                  <tr key={item._id + ":" + index}>
                    <td className="text-center">{index + 1}</td>
                    <td>
                      <div
                        style={{ width: "250px" }}
                        className="text-wrap mb-1 fw-semibold"
                      >
                        {item.dealName}
                      </div>
                      <div className="text-muted fs-val-sm">
                        <span>Deal ID: {item.dealId}</span>
                      </div>
                    </td>
                    <td className="text-center">{item.stockQty}</td>
                    <td className="text-center">{item.orderQty}</td>
                    <td>
                      <DateFormatter
                        date={item.orderDate}
                        format="dd MMM yyyy"
                      />
                    </td>
                    <td>{item.orderId}</td>
                    <td>{item.subOrderId}</td>
                    <td>
                      <div
                        className="mb-1 text-wrap"
                        style={{ width: "200px" }}
                      >
                        {item.franchiseName}
                      </div>
                      <div className="text-muted fs-val-sm">
                        <span>FID: {item.franchiseId}</span>
                      </div>
                    </td>
                    <td>
                      <Rbac roles={rbac.allocate}>
                        <button
                          className="btn btn-sm btn-outline-primary fs-val-sm"
                          onClick={() => allocate(item)}
                        >
                          Allocate
                        </button>
                      </Rbac>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className="text-end mt-3">
          <PaginationBlock
            paginationCb={paginationCb}
            loadingTotalRecords={records.loading}
            paginationConfig={paginationRef.current}
            size="sm"
          />
        </div>
      </AppCard>
      <BusyLoader show={busyLoader.show} />
    </>
  );
};

export default HasStockOrderPlacedNotAllocated;
