import { set, sub } from "date-fns";

export const DEFAULT_DATE_RANGE = {
  from: set(sub(new Date(), { days: 30 }), {
    hours: 0,
    minutes: 0,
    seconds: 0,
  }),
  to: set(new Date(), { hours: 23, minutes: 59, seconds: 59 }),
};
