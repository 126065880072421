import { AppCard, EntitySearchInput } from "@sk/uis";
import { memo, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

const defaultFormValues = {
  menu: [],
  brand: [],
  category: [],
};

const InventoryAgeingAdvFilterModal = ({ show, callback, formData }) => {
  const { control, setValue, getValues, reset } = useForm({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (show) {
      const keys = Object.keys(formData);
      if (keys.length > 0) {
        keys.forEach((x) => {
          setValue(x, formData[x]);
        });
      } else {
        reset();
      }
    }
  }, [formData, setValue, show, reset]);

  const onReset = () => {
    reset({ ...defaultFormValues });
    callback({ action: "reset", formData: { ...defaultFormValues } });
  };

  const onApply = () => {
    callback({ action: "apply", formData: getValues() });
  };

  const onHide = () => {
    callback({ action: "closed" });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="bg-light">
        <Modal.Title className="text-dark mb-0">
          <div className="fs-val-lg">Advance Filter</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-bg">
        <AppCard>
          <div className="row">
            <div className="col-12">
              <Controller
                control={control}
                name="menu"
                render={({ field }) => (
                  <EntitySearchInput
                    label="Menu"
                    callback={field.onChange}
                    value={field.value}
                    placeholder="Search by Menu Name/ID"
                    type="menu"
                    uid="inventoryAgeingMenu"
                    isMultiple={true}
                  />
                )}
              />
            </div>

            <div className="col-12">
              <Controller
                control={control}
                name="category"
                render={({ field }) => (
                  <EntitySearchInput
                    label="Category"
                    callback={field.onChange}
                    value={field.value}
                    placeholder="Search by Category Name/ID"
                    type="category"
                    uid="inventoryAgeingCategory"
                    isMultiple={true}
                  />
                )}
              />
            </div>

            <div className="col-12">
              <Controller
                control={control}
                name="brand"
                render={({ field }) => (
                  <EntitySearchInput
                    label="Brand"
                    callback={field.onChange}
                    value={field.value}
                    placeholder="Search by Brand Name/ID"
                    type="brand"
                    uid="inventoryAgeingBrand"
                    isMultiple={true}
                  />
                )}
              />
            </div>
          </div>
        </AppCard>
      </Modal.Body>

      <Modal.Footer>
        <div className="row justify-content-end col-12 g-0">
          <div className="col-auto">
            <button
              className="btn btn-warning fs-val-md me-3"
              onClick={onReset}
            >
              Reset
            </button>
            <button className="btn btn-primary fs-val-md" onClick={onApply}>
              Apply
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(InventoryAgeingAdvFilterModal);
