import {
  ClubViewModal,
  CustomerDetailModal,
  PosOrderDetailModal,
  StoreCard,
} from "@sk/features";
import { AuthService, RolesService, NavService } from "@sk/services";
import { AppCard, DateFormatter, PageInfo, PageLoader, Tabs } from "@sk/uis";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DEFAULT_DATE_RANGE } from "./CoinDashConst";
import styles from "./CoinsDash.module.scss";
import CoinDashRegCustomer from "./components/CoinDashRegCustomer";
import CoinsDashCategory from "./components/CoinsDashCategory";
import CoinsDashClubList from "./components/CoinsDashCLubList";
import CoinsDashCustomerList from "./components/CoinsDashCustomerList";
import CoinsDashMonthly from "./components/CoinsDashMonthly";
import CoinsDashMonthlyCustomer from "./components/CoinsDashMonthlyCustomer";
import CoinsDashStoreList from "./components/CoinsDashStoreList";
import CoinsDashToday from "./components/CoinsDashToday";
import CoinsRewardSummary from "./components/CoinsRewardSummary";
import CustomerInsight from "./components/customer-insights/CustomerInsight";
import { Filter } from "./components/Filter";
import { useNavigate, useSearchParams } from "react-router-dom";
import { produce } from "immer";

const title = "Coins, Club, Customer";

const breadcrumbs = [{ name: "Home", link: "/" }, { name: title }];

const defaultTabData = [
  // {
  //   key: "transactions",
  //   tabName: "All Transactions",
  //   description:
  //     "This section displays all transactions made by customers, including details on coins earned and redeemed.",
  // },
  {
    key: "customers",
    tabName: "All Customers",
    description:
      "This table will show registered customer list for the given date range, and their coins rewarded and redeemed.",
  },
  {
    key: "clubs",
    tabName: "Clubs",
    description:
      "This section shows club groups created by customers, along with their coins earned and orders.",
  },
  {
    key: "storewise",
    tabName: "Stores",
    description: "This section shows store-wise coins earned and rewards.",
  },
  // {
  //   key: "pincodewise",
  //   tabName: "Pincode",
  //   description: "This section shows store-wise coins earned and rewards.",
  // },
];

const defaultFormData = {
  dateRange: [DEFAULT_DATE_RANGE.from, DEFAULT_DATE_RANGE.to],
};

export default function CoinsDash() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState(defaultTabData[0]);

  const [filterData, setFilterData] = useState({ ...defaultFormData });

  const [clubModal, setClubModal] = useState({ show: false, cid: "" });

  const [customerModal, setCustomerModal] = useState({
    show: false,
    cid: "",
  });

  const [customerOrderModal, setCustomerOrderModal] = useState({
    show: false,
    orderId: "",
  });

  const filterDataRef = useRef({
    ...defaultFormData,
  });

  const [accessibleStoreIds, setAccessibleStoreIds] = useState([]);

  const [searchParams] = useSearchParams();
  const fid = searchParams.get("fid");
  const fName = searchParams.get("fName");
  const tabData = useMemo(() => {
    let t = defaultTabData;
    if (
      AuthService.isValidUserRole(["CustomerInsight"]) &&
      t.findIndex((e) => e.key == "customer-insights") == -1
    ) {
      t.push({
        key: "customer-insights",
        tabName: "Customer Insights",
        description:
          "This section displays registered customer insights for the selected date range, including details on coins earned and redeemed.",
      });
    }
    return t;
  }, []);

  // const accessibleStoreIdsRef = useRef([]);

  const [selectedStore, setSelectedStore] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { stores } = await RolesService.getDashboardEnabledStores(
        "SCDashboard"
      );
      setAccessibleStoreIds(stores);
      setLoading(false);
    };

    if (fid) {
      filterDataRef.current = {
        ...filterDataRef.current,
        store: [{ label: fName, value: { _id: fid, name: fName } }],
      };
      setFilterData(
        produce((draft) => {
          draft.store = [{ label: fName, value: { _id: fid, name: fName } }];
        })
      );

      setSelectedStore({ _id: fid, name: fName });
    }
    fetchData();
  }, [fid, fName]);

  const handleTabChange = ({ value }) => {
    setActiveTab(value);
  };

  const filterCb = (e) => {
    filterDataRef.current = { ...filterDataRef.current, ...e.formData };
    setFilterData((v) => ({ ...v, ...e.formData }));
  };

  const cb = useCallback((e) => {
    const a = e.action;
    if (a == "viewCustomer") {
      NavService.openInNewTab("/users/customer/view", { id: e.id });
    }
    if (a == "viewClub") {
      NavService.openInNewTab("/users/customer/club/view", { id: e.id });
    }
    if (a == "viewOrder") {
      NavService.openInNewTab("/pos/order/view", { id: e.id });
    }
  }, []);

  const customerModalCb = useCallback((e) => {
    if (e.action == "viewClub") {
      NavService.openInNewTab("/users/customer/club/view", { id: e.id });
    } else if (e.action == "viewOrder") {
      NavService.openInNewTab("/pos/order/view", { id: e.id });
    }
  }, []);

  const clubModalCb = (e) => {
    if (e.action == "viewCustomer") {
      NavService.openInNewTab("/users/customer/view", { id: e.id });
    } else if (e.action == "viewOrder") {
      NavService.openInNewTab("/pos/order/view", { id: e.id });
    }
  };

  const customerOrderModalCb = (e) => {
    if (e.action == "viewCustomer") {
      NavService.openInNewTab("/users/customer/view", { id: e.id });
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
        <PageInfo breadcrumbs={breadcrumbs} title={title} noMargin />
        {!loading && accessibleStoreIds.length > 0 && (
          <div className="text-muted fs-val-sm">
            <i className="bi bi-info-circle me-1"></i>
            Viewing assigned store details
          </div>
        )}
      </div>

      {loading ? (
        <PageLoader />
      ) : (
        <>
          {fid && (
            <StoreCard fid={fid} navigate={navigate} rbacKey="SCDashboard" />
          )}

          <div className={classNames(styles["filter-blk"])}>
            <Filter
              callback={filterCb}
              accessibleStoreIds={accessibleStoreIds}
              hideStoreInput={fid}
              hasPredefinedFid={fid ? true : false}
              selectedStore={selectedStore}
            />
          </div>

          <div className="row">
            <div className="col-6">
              <div className="fs-val-lg mb-2">
                Summary
                {filterDataRef.current.dateRange.length > 0 ? (
                  <span className="fs-val-md ms-1">
                    (
                    <DateFormatter
                      date={filterDataRef.current.dateRange[0]}
                      format={"dd MMM yyyy"}
                    />
                    <span> - </span>
                    <DateFormatter
                      date={filterDataRef.current.dateRange[1]}
                      format={"dd MMM yyyy"}
                    />
                    )
                  </span>
                ) : null}
              </div>
              <CoinsRewardSummary
                fromDate={filterData?.dateRange?.[0]}
                toDate={filterData?.dateRange?.[1]}
                cid={filterData?.customer?.[0]?.value?._id}
                storeId={filterData?.store?.[0]?.value?._id}
                storeName={filterData?.store?.[0]?.value?.name}
                accessibleStoreIds={accessibleStoreIds}
              />
            </div>
            <div className="col-md-6">
              <div className="fs-val-lg mb-2">&nbsp;</div>
              <CoinsDashCategory
                fromDate={filterData?.dateRange?.[0]}
                toDate={filterData?.dateRange?.[1]}
                cid={filterData?.customer?.[0]?.value?._id}
                cname={filterData?.customer?.[0]?.value?.fName}
                storeId={filterData?.store?.[0]?.value?._id}
                storeName={filterData?.store?.[0]?.value?.name}
                accessibleStoreIds={accessibleStoreIds}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <CoinsDashToday
                callback={cb}
                storeId={filterData?.store?.[0]?.value?._id}
                storeName={filterData?.store?.[0]?.value?.name}
                accessibleStoreIds={accessibleStoreIds}
              />
            </div>
            <div className="col-6">
              <CoinsDashMonthly
                cid={filterData?.customer?.[0]?.value?._id}
                cname={filterData?.customer?.[0]?.value?.fName}
                storeId={filterData?.store?.[0]?.value?._id}
                storeName={filterData?.store?.[0]?.value?.name}
                accessibleStoreIds={accessibleStoreIds}
              />
            </div>
            <div className="col-12">
              <CoinsDashMonthlyCustomer
                cid={filterData?.customer?.[0]?.value?._id}
                storeId={filterData?.store?.[0]?.value?._id}
                storeName={filterData?.store?.[0]?.value?.name}
                accessibleStoreIds={accessibleStoreIds}
              />
            </div>
          </div>

          <AppCard>
            <div className="mb-3 border-bottom">
              <Tabs
                data={tabData}
                callback={handleTabChange}
                activeTab={activeTab}
                template={4}
              />
            </div>

            <div className="fs-val-sm text-muted mb-2">
              {activeTab.description}
            </div>

            {activeTab.key == "customers" ? (
              <CoinDashRegCustomer
                fromDate={filterData?.dateRange?.[0]}
                toDate={filterData?.dateRange?.[1]}
                cid={filterData?.customer?.[0]?.value?._id}
                cname={filterData?.customer?.[0]?.value?.fName}
                callback={cb}
                storeId={filterData?.store?.[0]?.value?._id}
                storeName={filterData?.store?.[0]?.value?.name}
                accessibleStoreIds={accessibleStoreIds}
              />
            ) : null}

            {activeTab.key == "transactions" ? (
              <CoinsDashCustomerList
                fromDate={filterData?.dateRange?.[0]}
                toDate={filterData?.dateRange?.[1]}
                cid={filterData?.customer?.[0]?.value?._id}
                cname={filterData?.customer?.[0]?.value?.fName}
                callback={cb}
                storeId={filterData?.store?.[0]?.value?._id}
                storeName={filterData?.store?.[0]?.value?.name}
                accessibleStoreIds={accessibleStoreIds}
              />
            ) : null}

            {activeTab.key == "clubs" ? (
              <CoinsDashClubList
                fromDate={filterData?.dateRange?.[0]}
                toDate={filterData?.dateRange?.[1]}
                cid={filterData?.customer?.[0]?.value?._id}
                cname={filterData?.customer?.[0]?.value?.fName}
                callback={cb}
                storeId={filterData?.store?.[0]?.value?._id}
                storeName={filterData?.store?.[0]?.value?.name}
                accessibleStoreIds={accessibleStoreIds}
              />
            ) : null}

            {activeTab.key == "storewise" ? (
              <CoinsDashStoreList
                fromDate={filterData?.dateRange?.[0]}
                toDate={filterData?.dateRange?.[1]}
                cid={filterData?.customer?.[0]?.value?._id}
                cname={filterData?.customer?.[0]?.value?.fName}
                callback={cb}
                storeId={filterData?.store?.[0]?.value?._id}
                storeName={filterData?.store?.[0]?.value?.name}
                accessibleStoreIds={accessibleStoreIds}
              />
            ) : null}

            {activeTab.key == "customer-insights" ? (
              <CustomerInsight
                fromDate={filterData?.dateRange?.[0]}
                toDate={filterData?.dateRange?.[1]}
                cid={filterData?.customer?.[0]?.value?._id}
                cname={filterData?.customer?.[0]?.value?.fName}
                callback={cb}
                storeId={filterData?.store?.[0]?.value?._id}
                storeName={filterData?.store?.[0]?.value?.name}
                accessibleStoreIds={accessibleStoreIds}
              />
            ) : null}
          </AppCard>
        </>
      )}

      <ClubViewModal
        show={clubModal.show}
        cid={clubModal.cid}
        callback={clubModalCb}
      />

      <CustomerDetailModal
        show={customerModal.show}
        cid={customerModal.cid}
        callback={customerModalCb}
      />

      <PosOrderDetailModal
        show={customerOrderModal.show}
        orderId={customerOrderModal.orderId}
        callback={customerOrderModalCb}
      />

      {/* <AppCard>
        <div className="mb-3">
          <div className="border-bottom mb-3">
            <Tabs
              data={tabData}
              callback={handleTabChange}
              activeTab={activeTab}
              template={4}
            />
          </div>
          <div className="fs-val-md text-muted">{activeTab.description}</div>
        </div>

        <div className="row">
          <div className="col-3">
            <TextInput
              name="search"
              register={register}
              placeholder="Search by Customer Name/Mobile No/ID"
            />
          </div>
        </div>
        <div className="mb-1 fs-val-md">Showing 10 results</div>
        {activeTab.key === "storewise" ? (
          <Storewise />
        ) : activeTab.key === "customers" ? (
          renderCustomerTable()
        ) : (
          renderClubTable()
        )}
      </AppCard>
       */}
    </div>
  );
}
