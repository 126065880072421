import { AuthService } from "@sk/services";
import { add, sub } from "date-fns";

export const rbac = {
  create: ["CreateProductReservation"],
  download: ["DownloadProductReservation"],
  view: ["ViewProductReservation"],
  edit: ["EditProductReservation"],
  extend: ["ExtendProductReservation"],
  stop: ["StopProductReservation"],
  start: ["StartProductReservation"],
  sync: ["SyncProductReservation"],
};

export const formLabels = {
  search: {
    label: "Search",
  },
  warehouse: {
    label: "Warehouse",
  },
  menu: {
    label: "Menu",
    valuePath: "[0].label",
  },
  category: {
    label: "Category",
    valuePath: "[0].label",
  },
  brand: {
    label: "Brand",
    valuePath: "[0].label",
  },
  status: {
    label: "Status",
  },
  createdAt: {
    label: "Validity Period",
    type: "dateRange",
  },
  groupCode: {
    label: "Group Code",
  },
};

export const statusDescriptions = {
  nearExpiry: "Items expiring within 15 days",
  expired: "Items which are Expired",
  lowStock: "Active items with low remaining order quantity",
  stockOut: "Active items with remaining quantity is 0",
  stopped: "Items which are Stopped",
  active: "Items which are Live",
  pending: "Configuration not yet started", // Updated Pending status
};

export const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

export const defaultFilter = {
  warehouse: AuthService.getDefaultWhId(),
  createdAt: [
    sub(new Date(), {
      days: 30,
    }),
    add(new Date(), {
      days: 30,
    }),
  ],
  // status: "nearExpiry",
  status: "",
};
