import { CommonService, CustomerService } from "@sk/services";
import { SummaryCard, TableHeader } from "@sk/uis";
import { memo, useEffect, useState } from "react";
import totalImg from "../../../../assets/imgs/total.svg";
import activeImg from "../../../../assets/imgs/active.svg";
import smartCoinImg from "../../../../assets/imgs/smart-coins.svg";

const cardList = [
  {
    label: "Total Club Owners Coins Rewarded",
    key: "totalParentCoins",
    color: "primary",
    count: 0,
    img: smartCoinImg,
  },
  {
    label: "Total Club Members Coins Rewarded",
    key: "totalChildCoins",
    color: "info",
    count: 0,
    img: smartCoinImg,
  },
  // {
  //   label: "Total Orders",
  //   key: "totalOrders",
  //   color: "success",
  //   count: 0,
  // },
  {
    label: "Total Club Owners",
    key: "uniqueParentCount",
    color: "primary",
    count: 0,
    img: totalImg,
  },
  {
    label: "Total Club Members",
    key: "uniqueChildCount",
    color: "info",
    count: 0,
    img: activeImg,
  },
];

const summaryDataTableHeaders = [
  { label: "Type" },
  {
    label: "Total Club Owner Coins",
    isCentered: true,
    key: "totalParentCoins",
    enableSort: false,
  },
  {
    label: "Total Club Member Coins",
    isCentered: true,
    key: "totalChildCoins",
    enableSort: false,
  },
  {
    label: "Total Orders",
    isCentered: true,
    key: "totalOrders",
    enableSort: false,
  },
  // {
  //   label: "Total Club Owners",
  //   isCentered: true,
  //   key: "uniqueParentCount",
  //   enableSort: false,
  // },
  // {
  //   label: "Total Club Member",
  //   isCentered: true,
  //   key: "uniqueChildCount",
  //   enableSort: false,
  // },
];

const SummaryCardBox = ({ filterParams }) => {
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState({ overView: [], typeWiseData: [] });
  const [showTypeWiseData, setShowTypeWiseData] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    loadCount(filterParams);
    loadList(filterParams);
  }, [loadCount, filterParams]);

  const loadCount = async (p) => {
    let params = prepareParams(p);

    setLoading(true);
    const c = await CustomerService.getReferralCoinsDataList({ ...params });
    let v = c.statusCode == 200 && Array.isArray(c.resp) ? c.resp : [];
    // Type wise Data formating
    const l = [...cardList];
    let fl = v.filter((e) => {
      return e?._id?.type != "DirectInviteFirstorder";
    });

    const totals = fl.reduce(
      (acc, item) => {
        acc.totalParentCoins += item.totalParentCoins;
        acc.totalChildCoins += item.totalChildCoins;
        // acc.totalOrders += item.totalOrders || 0;
        acc.uniqueChildCount += item.uniqueChildCount || 0;
        acc.uniqueParentCount += item.uniqueParentCount;
        return acc;
      },
      {
        totalParentCoins: 0,
        totalChildCoins: 0,
        // totalOrders: 0,
        uniqueChildCount: 0,
        uniqueParentCount: 0,
      }
    );

    l.forEach((item) => {
      item.count = totals[item.key];
    });
    let m = [...l];
    // if (params.filter?.senderRefId?.length) {
    //   m = l.filter((e) => {
    //     return ["totalChilds", "totalParents"].indexOf(e.key) == -1;
    //   });
    // }
    setCards({ overView: [...m], typeWiseData: [...v] });
    setLoading(false);
  };

  const loadList = async (p) => {
    let params = prepareParams(p);
    setLoading(true);
    const c = await CustomerService.getReferralCoinsDataList({ ...params });
    let v = c.statusCode == 200 && Array.isArray(c.resp) ? c.resp : [];

    let fl = (v[0]?.typeData || []).filter((e) => {
      return e?._id?.type != "DirectInviteFirstorder";
    });

    setData([...fl]);
  };

  const prepareParams = (p) => {
    let params = { ...p };

    params.queryType = "overall";
    params.outputType = "list";

    delete params.page;
    delete params.count;

    if (p?.filter && p?.franchiseId?.length) {
      params.filter["franchiseId"] = p?.franchiseId;
      delete p.franchiseId;
    }
    return params;
  };

  const getLabelByKey = (d) => {
    return CommonService.getCoinsDashboardTypeLabel(d?._id?.type || "");
  };

  const onToggleView = () => {
    setShowTypeWiseData(!showTypeWiseData);
  };

  return (
    <>
      <div className="row mb-3">
        {cards.overView.map((e, index) => (
          <div className="col-3" key={index}>
            <SummaryCard
              value={e.count}
              title={e.label}
              loading={loading}
              valueColor={e.color}
              // template={3}
              img={e.img}
              template={2}
            />
          </div>
        ))}
        <div className="col">
          {showTypeWiseData ? (
            <div className="bg-light mb-3 p-3 mt-3">
              <table className="table table-md table-hover">
                <TableHeader data={summaryDataTableHeaders} fwSize="md" />
                <tbody>
                  {data.map((e, index) => {
                    return (
                      <tr key={index}>
                        <td>{getLabelByKey(e)}</td>
                        <td className="text-center">
                          {Number(e.totalParentCoins || 0).toLocaleString(
                            "en-IN"
                          )}
                        </td>
                        <td className="text-center">
                          {Number(e.totalChildCoins || 0).toLocaleString(
                            "en-IN"
                          )}
                        </td>
                        <td className="text-center">
                          {Number(e.totalOrders || 0).toLocaleString("en-IN")}
                        </td>
                        {/* <td className="text-center">
                      {Number(e.totalParents || 0).toLocaleString("en-IN")}
                    </td>
                    <td className="text-center">
                      {Number(e.totalChilds || 0).toLocaleString("en-IN")}
                    </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
        <div className="col-auto pt-3 ms-auto">
          <button className="btn btn-outline-primary" onClick={onToggleView}>
            {showTypeWiseData ? "Hide Summary" : "Show Summary"}
          </button>
        </div>
      </div>
    </>
  );
};

export default memo(SummaryCardBox);
