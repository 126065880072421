export const rbac = {
  purchasePrice: ["ViewProductPurchasePrice"],
};

export const headers = [
  {
    label: "Sl No",
    key: "slNo",
    isCentered: true,
    width: "6%",
  },
  {
    label: "Snapshot ID",
    key: "snapshotId",
    enableSort: true,
    width: "14%",
  },
  {
    label: "Product Name",
    key: "name",
    enableSort: true,
    isSticky: true,
    width: "40%",
    stickyPosition: "left",
  },
  {
    label: "Product ID",
    key: "productId",
    enableSort: true,
    width: "14%",
  },
  {
    label: "Inventory Status",
    key: "inventoryStatus",
    enableSort: true,
    width: "18%",
  },
  {
    label: "Ageing Slab",
    key: "ageingSlab",
    enableSort: true,
    width: "14%",
  },
  {
    label: "Stock Movement",
    key: "stockMovement",
    enableSort: true,
    width: "17%",
  },
  {
    label: "Qty",
    key: "stockQty",
    enableSort: true,
    isCentered: true,
    width: "10%",
  },
  {
    label: "Qty Value",
    key: "stockValue",
    enableSort: true,
    isCentered: true,
    width: "14%",
  },
  {
    label: "Inventory Holding",
    key: "inventoryHoldingDays",
    enableSort: true,
    isCentered: true,
    width: "18%",
  },
  {
    label: "Expiry Date",
    key: "expiryDate",
    enableSort: true,
    width: "14%",
  },
  {
    label: "Shelf Life",
    key: "productShelfLife",
    enableSort: true,
    isCentered: true,
    width: "12%",
  },
  {
    label: "Last GRN",
    key: "grnDate",
    enableSort: true,
    width: "14%",
  },
  {
    label: "Menu",
    key: "menuName",
    enableSort: true,
    width: "20%",
  },
  {
    label: "Parent Category",
    key: "parentCategoryName",
    enableSort: true,
    width: "20%",
  },
  {
    label: "Sub Category",
    key: "categoryName",
    enableSort: true,
    width: "20%",
  },
  {
    label: "Brand",
    key: "brandName",
    enableSort: true,
    width: "20%",
  },
  {
    label: "Company",
    key: "companyName",
    enableSort: true,
    width: "20%",
  },
  {
    label: "Last 30d Sales",
    key: "last30DaysSalesQty",
    enableSort: true,
    isCentered: true,
    width: "17%",
  },
  {
    label: "Last 60d Sales",
    key: "last60DaysSalesQty",
    enableSort: true,
    isCentered: true,
    width: "17%",
  },
  {
    label: "Last 90d Sales",
    key: "last90DaysSalesQty",
    enableSort: true,
    isCentered: true,
    width: "17%",
  },
  {
    label: "Location",
    key: "location",
    enableSort: true,
    width: "12%",
  },
  {
    label: "Rack",
    key: "rackId",
    enableSort: true,
    width: "12%",
  },
  {
    label: "Bin",
    key: "binId",
    enableSort: true,
    width: "12%",
  },
  {
    label: "MRP",
    key: "mrp",
    enableSort: true,
    width: "12%",
  },
  {
    label: "Purchase Price",
    key: "purchasePrice",
    enableSort: true,
    width: "15%",
    rbac: rbac.purchasePrice,
  },
  {
    label: "B2B Price",
    key: "price",
    enableSort: true,
    isCentered: true,
    width: "14%",
  },
  {
    label: "RSP",
    key: "rspPrice",
    enableSort: true,
    width: "14%",
  },
  {
    label: "B2B Margin",
    key: "margin",
    enableSort: true,
    width: "12%",
  },
];

export const tableColumnKeys = {
  name: { data: "productInfo.name", sort: "productInfo.name" },
  snapshotId: { data: "snapshotId", sort: "snapshotId" },
  productId: { data: "productId", sort: "productId" },
  inventoryStatus: {
    data: "inventoryInfo.inventoryStatus",
    sort: "inventoryInfo.inventoryStatus",
  },
  ageingSlab: {
    data: "inventoryInfo.ageingSlab",
    sort: "inventoryInfo.ageingSlab",
  },
  stockMovement: {
    data: "inventoryInfo.stockMovement",
    sort: "inventoryInfo.stockMovement",
  },
  stockQty: { data: "inventoryInfo.stockQty", sort: "inventoryInfo.stockQty" },
  stockValue: {
    data: "inventoryInfo.stockValue",
    sort: "inventoryInfo.stockValue",
  },
  inventoryHoldingDays: {
    data: "inventoryInfo.inventoryHoldingDays",
    sort: "inventoryInfo.inventoryHoldingDays",
  },
  expiryDate: {
    data: "productInfo.expiryDate",
    sort: "productInfo.expiryDate",
  },
  productShelfLife: {
    data: "productInfo.productShelfLife",
    sort: "productInfo.productShelfLife",
  },
  grnDate: { data: "sourceInfo.grnDate", sort: "sourceInfo.grnDate" },
  menuName: { data: "productInfo.menuName", sort: "productInfo.menuName" },
  menuId: { data: "productInfo.menuId", sort: "productInfo.menuId" },
  parentCategoryName: {
    data: "productInfo.parentCategoryName",
    sort: "productInfo.parentCategoryName",
  },
  parentCategoryId: {
    data: "productInfo.parentCategoryId",
    sort: "productInfo.parentCategoryId",
  },
  categoryName: {
    data: "productInfo.categoryName",
    sort: "productInfo.categoryName",
  },
  categoryId: {
    data: "productInfo.categoryId",
    sort: "productInfo.categoryId",
  },
  brandName: { data: "productInfo.brandName", sort: "productInfo.brandName" },
  brandId: { data: "productInfo.brandId", sort: "productInfo.brandId" },
  companyName: {
    data: "productInfo.companyName",
    sort: "productInfo.companyName",
  },
  last30DaysSalesQty: {
    data: "salesAnalyticsInfo.last30DaysSalesQty",
    sort: "salesAnalyticsInfo.last30DaysSalesQty",
  },
  last60DaysSalesQty: {
    data: "salesAnalyticsInfo.last60DaysSalesQty",
    sort: "salesAnalyticsInfo.last60DaysSalesQty",
  },
  last90DaysSalesQty: {
    data: "salesAnalyticsInfo.last90DaysSalesQty",
    sort: "salesAnalyticsInfo.last90DaysSalesQty",
  },
  location: {
    data: "sourceInfo.locationInfo.location",
    sort: "sourceInfo.locationInfo.location",
  },
  rackId: {
    data: "sourceInfo.locationInfo.rackId",
    sort: "sourceInfo.locationInfo.rackId",
  },
  binId: {
    data: "sourceInfo.locationInfo.binId",
    sort: "sourceInfo.locationInfo.binId",
  },
  mrp: { data: "productInfo.mrp", sort: "productInfo.mrp" },
  price: { data: "productInfo.b2bPrice", sort: "productInfo.b2bPrice" },
  margin: { data: "productInfo.b2bMargin", sort: "productInfo.b2bMargin" },
  purchasePrice: {
    data: "inventoryInfo.purchasePrice",
    sort: "inventoryInfo.purchasePrice",
  },
};

export const storeWiseTableColumnKeys = {
  name: { data: "dealInfo.dealName", sort: "dealInfo.dealName" },
  productId: { data: "dealId", sort: "dealId" },
  inventoryStatus: {
    data: "inventoryInfo.inventoryStatus",
    sort: "inventoryInfo.inventoryStatus",
  },
  ageingSlab: {
    data: "inventoryInfo.ageingSlab",
    sort: "inventoryInfo.ageingSlab",
  },
  stockMovement: {
    data: "inventoryInfo.stockMovement",
    sort: "inventoryInfo.stockMovement",
  },
  stockQty: { data: "inventoryInfo.stockQty", sort: "inventoryInfo.stockQty" },
  stockValue: {
    data: "inventoryInfo.stockValue",
    sort: "inventoryInfo.stockValue",
  },
  inventoryHoldingDays: {
    data: "inventoryInfo.pendingShelfLifeDays",
    sort: "inventoryInfo.pendingShelfLifeDays",
  },
  expiryDate: {
    data: "inventoryInfo.expiryDate",
    sort: "inventoryInfo.expiryDate",
  },
  productShelfLife: {
    data: "inventoryInfo.shelfLife",
    sort: "inventoryInfo.shelfLife",
  },
  grnDate: {
    data: "latestGrnDetails.latestGrnDate",
    sort: "latestGrnDetails.latestGrnDate",
  },
  menuName: { data: "dealInfo.menuName", sort: "dealInfo.menuName" },
  menuId: { data: "dealInfo.menuId", sort: "dealInfo.menuId" },
  parentCategoryName: {
    data: "dealInfo.parentCategoryName",
    sort: "dealInfo.parentCategoryName",
  },
  parentCategoryId: {
    data: "dealInfo.parentCategoryId",
    sort: "dealInfo.parentCategoryId",
  },
  categoryName: {
    data: "dealInfo.categoryName",
    sort: "dealInfo.categoryName",
  },
  categoryId: {
    data: "dealInfo.categoryId",
    sort: "dealInfo.categoryId",
  },
  brandName: { data: "dealInfo.brandName", sort: "dealInfo.brandName" },
  brandId: { data: "dealInfo.brandId", sort: "dealInfo.brandId" },
  companyName: {
    data: "dealInfo.companyName",
    sort: "dealInfo.companyName",
  },
  last30DaysSalesQty: {
    data: "salesAnalyticsInfo.last30DaysSalesQty",
    sort: "salesAnalyticsInfo.last30DaysSalesQty",
  },
  last60DaysSalesQty: {
    data: "salesAnalyticsInfo.last60DaysSalesQty",
    sort: "salesAnalyticsInfo.last60DaysSalesQty",
  },
  last90DaysSalesQty: {
    data: "salesAnalyticsInfo.last90DaysSalesQty",
    sort: "salesAnalyticsInfo.last90DaysSalesQty",
  },
  location: {
    data: "sourceInfo.locationInfo.location",
    sort: "sourceInfo.locationInfo.location",
  },
  rackId: {
    data: "sourceInfo.locationInfo.rackName",
    sort: "sourceInfo.locationInfo.rackId",
  },
  binId: {
    data: "sourceInfo.locationInfo.binName",
    sort: "sourceInfo.locationInfo.binId",
  },
  mrp: { data: "inventoryInfo.mrp", sort: "inventoryInfo.mrp" },
  price: {
    data: "inventoryInfo.purchasePrice",
    sort: "inventoryInfo.purchasePrice",
  },
  margin: { data: "priceInfo.rspDiscount", sort: "priceInfo.rspDiscount" },
  rspPrice: { data: "priceInfo.rspPrice", sort: "priceInfo.rspPrice" },
};
