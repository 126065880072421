import { CommonService } from "@sk/services";
import { AppliedFilterLabel } from "@sk/uis";

const CoinsDashAppliedFilter = ({ cname, storeName, fromDate, toDate }) => {
  const config = {
    cname: { label: "Customer" },
    storeName: { label: "Store" },
    dateRange: { label: "Date Range", type: "dateRange" },
  };

  const formData = {
    cname,
    storeName,
    dateRange: fromDate && toDate ? [fromDate, toDate] : [],
  };

  const filterLabels = CommonService.prepareAppliedFilterLabels(
    config,
    formData
  );

  return <AppliedFilterLabel labels={filterLabels} />;
};

export default CoinsDashAppliedFilter;
