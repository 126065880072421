// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hiQVZ2cKJ7Y7CsHVqVfC{position:sticky;top:60px;z-index:999}`, "",{"version":3,"sources":["webpack://./src/pages/coins-v2/CoinsDash.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,QAAA,CACA,WAAA","sourcesContent":[".filter-blk {\n  position: sticky;\n  top: 60px;\n  z-index: 999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter-blk": `hiQVZ2cKJ7Y7CsHVqVfC`
};
export default ___CSS_LOADER_EXPORT___;
