import { CommonService, NavService, ProductService } from "@sk/services";
import {
  Alert,
  AppCard,
  AppliedFilterLabel,
  BusyLoader,
  InfoBlk,
  PageInfo,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  Toaster,
} from "@sk/uis";
import { add, set } from "date-fns";
import { produce } from "immer";
import { useCallback, useEffect, useRef, useState } from "react";
import ReservationFilter from "./components/ReservationFilter";
import ReservationTable from "./components/ReservationTable";
import Summary from "./components/Summary";
import ExtendConfigModal from "./modals/ExtendConfigModal";
import ManageReserveConfigModal from "./modals/ManageReserveConfigModal";
import ReservationQuickEdit from "./modals/ReservationQuickEdit";
import SubOrderListModal from "./modals/SubOrderListModal";
import ViewModal from "./modals/ViewModal";
import ViewOpenPoModal from "./modals/ViewOpenPoModal";
import {
  defaultFilter,
  defaultPagination,
  formLabels,
  rbac,
  statusDescriptions,
} from "./reserveConstants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReservationTabs } from "@sk/features";
import { useFetchUrlQueryString } from "@sk/hooks";

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Reservations",
  },
];

const getData = async (params, signal) => {
  const r = await ProductService.getReservationList(params, signal);
  return { data: Array.isArray(r.resp) ? r.resp : [], raw: r?.raw };
};

const getCount = async (params, signal) => {
  let p = { ...params };
  p.isCountRequired = true;
  p.fromNewERP = true;

  delete p.page;
  delete p.count;

  const r = await ProductService.getReservationList(p, signal);
  return { count: r.resp?.[0]?.totalCount || r?.resp?.count || 0 };
};

const prepareFilterParams = (filter, pagination) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {
      whId: {
        $in: [filter.warehouse],
      },
    },
    projectFilter: {},
  };

  if (filter.createdAt?.length > 0) {
    p.filter.applicableFromDate = {
      $gte: set(new Date(filter.createdAt[0]), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }),
    };
    p.filter.endDate = {
      $lte: set(new Date(filter.createdAt[1]), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }),
    };
  }

  const search = filter.search?.trim();

  if (search) {
    p.filter.$or = [
      { productName: { $regex: search, $options: "i" } },
      { productId: search },
      { _id: search },
    ];
  }

  if (filter.menu?.length > 0) {
    p.filter.menuIds = filter.menu.map((m) => m.value._id);
  }

  if (filter.category?.length > 0) {
    p.filter.categoryIds = filter.category.map((c) => c.value._id);
  }

  if (filter.brand?.length > 0) {
    p.filter.brandIds = filter.brand.map((b) => b.value._id);
  }

  if (filter.status == "active") {
    p.filter.status = "Started";
  }

  if (filter.status == "expired") {
    p.filter.status = "Ended";
  }

  if (filter.status == "stopped") {
    p.filter.status = "Stopped";
  }

  if (filter.status == "nearExpiry") {
    p.filter.status = "Started";
    p.sort = {
      remainingOrderCount: 1,
    };
    p.filter.endDate = {
      $lte: set(
        add(new Date(), {
          days: 15,
        }),
        {
          hours: 23,
          minutes: 59,
          seconds: 59,
          milliseconds: 999,
        }
      ),
    };
  }

  if (filter.status == "lowStock") {
    p.filter.status = "Started";
    p.projectFilter.orderCount = {
      $gt: 0,
    };
    p.projectFilter.remainingOrderCount = {
      $gt: 0,
      $lt: 15,
    };
    p.sort = {
      remainingOrderCount: 1,
    };
  }

  if (filter.status == "stockOut") {
    p.filter.status = "Started";
    p.projectFilter.remainingOrderCount = {
      $lte: 0,
    };
  }

  if (filter.status == "pending") {
    p.filter.status = "Pending"; // Added Pending status filter
  }

  if (filter.groupCode) {
    p.filter.groupCode = filter.groupCode;
  }

  return p;
};

const getBulkActionInfo = (action) => {
  switch (action) {
    case "editQty":
      return "Only rows where quantity editing is enabled will be selectable. Selection applies to current page only.";
    case "stop":
      return "Only rows where stopping is enabled will be selectable. Selection applies to current page only.";
    case "extend":
      return "Only rows where extension is enabled will be selectable. Selection applies to current page only.";
    default:
      return "";
  }
};

const Reservation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const query = useFetchUrlQueryString(searchParams);

  const [bulkOption, setBulkOption] = useState(null);

  const [reservationData, setReservationData] = useState({
    loading: false,
    data: [],
  });

  const [busyLoader, setBusyLoader] = useState({
    show: false,
  });

  const [totalRecords, setTotalRecords] = useState({
    loading: false,
    count: 0,
  });

  const [filterLabels, setFilterLabels] = useState([]);

  const [selectedCount, setSelectedCount] = useState(0);

  const [manageReserveConfigModal, setManageReserveConfigModal] = useState({
    show: false,
    configId: null,
  });

  const [openPoModal, setOpenPoModal] = useState({
    show: false,
    details: {},
  });

  const [quickEditModal, setQuickEditModal] = useState({
    show: false,
    id: null,
    poDetails: {},
  });

  const [viewModal, setViewModal] = useState({
    show: false,
    configId: null,
  });

  const [subOrderListModal, setSubOrderListModal] = useState({
    show: false,
    reserveId: null,
  });

  const [extendConfigModal, setExtendConfigModal] = useState({
    show: false,
    data: {},
  });

  const [summaryData, setSummaryData] = useState([
    { title: "Total", value: 0, valueColor: "dark" },
    { title: "Active", value: 0, valueColor: "success" },
    { title: "Expired", value: 0, valueColor: "danger" },
    { title: "Low Stock", value: 0, valueColor: "warning" },
    { title: "Near Expiry", value: 0, valueColor: "info" },
    { title: "Out of Stock", value: 0, valueColor: "danger" },
    { title: "Stopped", value: 0, valueColor: "secondary" },
  ]);

  const [showSelectAll, setShowSelectAll] = useState(false);

  const filterRef = useRef({
    ...defaultFilter,
  });
  const paginationRef = useRef({
    ...defaultPagination,
  });
  const sortRef = useRef({});

  // Add ref for summary container
  const summaryRef = useRef(null);

  // Add abortController ref
  const abortControllerRef = useRef(null);

  const prepareFilterLabels = useCallback(() => {
    const v = { ...filterRef.current };
    const l = CommonService.prepareAppliedFilterLabels(formLabels, v);

    if (v.status && statusDescriptions[v.status]) {
      l.forEach((label) => {
        if (label.key === "status") {
          label.label = "Status";
          label.value = statusDescriptions[v.status];
        }
      });
    }

    setFilterLabels(l);
  }, []);

  const applyFilter = useCallback(async () => {
    try {
      // Cancel previous request if exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create new AbortController
      abortControllerRef.current = new AbortController();

      paginationRef.current = {
        ...defaultPagination,
      };

      prepareFilterLabels();

      loadSummary();
      loadList();

      setTotalRecords({
        loading: true,
      });

      const p = prepareFilterParams(
        filterRef.current,
        paginationRef.current,
        sortRef.current
      );

      const r = await getCount(p, abortControllerRef.current.signal);
      paginationRef.current.totalRecords = r.count;
      setTotalRecords({
        loading: false,
        count: r.count,
      });
    } catch (error) {
      if (error.name !== "AbortError") {
        setTotalRecords({
          loading: false,
          count: 0,
        });
        Toaster.error("Failed to load total records");
      }
    }
  }, [loadList, prepareFilterLabels, loadSummary]);

  const loadList = useCallback(async () => {
    try {
      // Cancel previous request if exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create new AbortController
      abortControllerRef.current = new AbortController();

      setReservationData({
        loading: true,
        data: [],
      });

      const p = prepareFilterParams(
        filterRef.current,
        paginationRef.current,
        sortRef.current
      );

      const r = await getData(p, abortControllerRef.current.signal);

      if (r.raw.code === "ERR_CANCELED") {
        return;
      }

      const { data, showSelectAll } = handleCheckboxDisplay(bulkOption, r.data);

      setReservationData({
        loading: false,
        data: data,
      });
      setShowSelectAll(showSelectAll);
    } catch (error) {
      // Only set error state if it's not an abort error
      if (error.name !== "AbortError") {
        setReservationData({
          loading: false,
          data: [],
        });
      }
    }
  }, [bulkOption]);

  useEffect(() => {
    // Update filterRef with query params
    const searchParam = query.s;
    if (searchParam) {
      filterRef.current.search = searchParam;
      filterRef.current.status = "";
      filterRef.current.createdAt = [];
      filterFormRef.current?.updateForm({ search: searchParam });
      filterFormRef.current?.updateForm({ status: "" });
      filterFormRef.current?.updateForm({ createdAt: [] });
    }

    applyFilter();
  }, [applyFilter, query.s]);

  useEffect(() => {
    setSelectedCount(reservationData.data.filter((d) => d._selected).length);
  }, [reservationData.data]);

  const filterCb = useCallback(
    (filter) => {
      filterRef.current = {
        ...filterRef.current,
        ...filter.formData,
      };
      setBulkOption(null);
      applyFilter();
    },
    [applyFilter]
  );

  const paginationCb = useCallback(
    (pagination) => {
      paginationRef.current = {
        ...paginationRef.current,
        ...pagination,
      };
      // Scroll to summary using CommonService
      if (summaryRef.current) {
        CommonService.scrolltop();
        // CommonService.scrollToView(summaryRef.current);
      }

      // Reset bulk selection state
      setShowSelectAll(false);
      loadList();
    },
    [loadList]
  );

  const handleViewPO = (data) => {
    setOpenPoModal({
      show: true,
      details: data,
    });
  };

  const closeOpenPoModal = () => {
    setOpenPoModal({
      show: false,
      details: {},
    });
  };

  const tableCb = useCallback(
    (e) => {
      switch (e.action) {
        case "editConfig":
          setManageReserveConfigModal({
            show: true,
            configId: e.data._id,
            mode: "edit",
          });
          break;

        case "quickEdit":
          setQuickEditModal({
            show: true,
            id: e.data._id,
            poDetails: e.data.poDetail,
          });
          break;

        case "bulkAction":
          setReservationData(
            produce((draft) => {
              draft.data.forEach((d) => {
                if (d.showCheckbox) {
                  d._selected = e.data;
                }
              });
            })
          );
          break;

        case "select":
          setReservationData(
            produce((draft) => {
              draft.data[e.index]._selected = e.data;
            })
          );
          break;

        case "menuFilter":
          filterRef.current.menu = e.data;
          filterFormRef.current?.updateForm({ menu: e.data });
          applyFilter();
          break;

        case "brandFilter":
          filterRef.current.brand = e.data;
          filterFormRef.current?.updateForm({ brand: e.data });
          applyFilter();
          break;

        case "categoryFilter":
          filterRef.current.category = e.data;
          filterFormRef.current?.updateForm({ category: e.data });
          applyFilter();
          break;

        case "viewPO":
          handleViewPO(e.data);
          break;

        case "viewConfig":
          setViewModal({
            show: true,
            configId: e.data._id,
          });
          break;

        case "startConfig":
          handleStartConfig(e.data);
          break;

        case "extendConfig":
          setExtendConfigModal({
            show: true,
            data: e.data,
          });
          break;

        case "stopConfig":
          handleStopConfig(e.data);
          break;

        case "subOrderList":
          handleSubOrderListModal(e.data);
          break;

        default:
          break;
      }
    },
    [applyFilter, handleStartConfig, handleStopConfig]
  );

  const handleSubOrderListModal = (data) => {
    setSubOrderListModal({
      show: true,
      reserveId: data._id,
    });
  };

  const handleStartConfig = useCallback(
    async (data) => {
      const alert = await Alert.confirm({
        title: "Confirmation Required",
        text: `Are you sure you want to start the configuration with ID #${data._id}?`,
        icon: "info",
        okText: "Yes, Start",
        cancelText: "No, Cancel",
      });
      if (!alert.isConfirmed) {
        return;
      }

      setBusyLoader({ show: true });

      const r = await ProductService.updateReserve(data._id, {
        status: "Started",
      });

      setBusyLoader({ show: false });

      if (r.statusCode !== 200) {
        Toaster.error(r.resp.message);
        return;
      }

      // check in success response has any error
      if (
        r.resp?.response &&
        r.resp?.response.errorResponse &&
        r.resp?.response.errorResponse.length > 0
      ) {
        Toaster.error(
          r.resp?.response.errorResponse.map((e) => e.message).join(",")
        );
        return;
      }

      loadList();

      Toaster.success("Config Started Successfully");
    },
    [loadList]
  );

  const handleStopConfig = useCallback(
    async (data) => {
      const alert = await Alert.confirm({
        title: "Confirmation Required",
        text: `Are you sure you want to stop the configuration with ID #${data._id}?`,
        icon: "info",
        okText: "Yes, Stop",
        cancelText: "No, Cancel",
      });

      if (!alert.isConfirmed) {
        return;
      }

      setBusyLoader({ show: true });
      const r = await ProductService.updateReserve(data._id, {
        status: "Stopped",
      });
      setBusyLoader({ show: false });

      if (r.statusCode !== 200) {
        Toaster.error(r.resp.message);
        return;
      }

      // check in success response has any error
      if (
        r.resp?.response &&
        r.resp?.response.errorResponse &&
        r.resp?.response.errorResponse.length > 0
      ) {
        Toaster.error(
          r.resp?.response.errorResponse.map((e) => e.message).join(",")
        );
        return;
      }

      loadList();

      Toaster.success("Config Stopped Successfully");
    },
    [loadList]
  );

  const filterFormRef = useRef(null);

  const closeManageReserveConfigModal = () => {
    setManageReserveConfigModal({
      show: false,
    });
  };

  const handleCheckboxDisplay = (option, data) => {
    let showSelectAll = false;

    const r = produce(data, (draft) => {
      draft.forEach((d) => {
        d._selected = false;
        d.showCheckbox = false;
        if (option === "editQty" && d._canEdit) {
          d.showCheckbox = d._canEdit;
        } else if (option === "extend" && d._canExtend) {
          d.showCheckbox = true;
        } else if (option === "stop" && d._canStop) {
          d.showCheckbox = true;
        }
      });
    });

    if (option) {
      if (r.filter((d) => d.showCheckbox).length === 0) {
        Toaster.error(
          "No items are available for this bulk action within the range of " +
            paginationRef.current.startSlNo +
            " to " +
            paginationRef.current.endSlNo +
            " records."
        );
        showSelectAll = false;
      } else {
        showSelectAll = true;
      }
    }

    return { data: r, showSelectAll };
  };

  const handleBulkOptionChange = (option) => {
    setBulkOption(option);
    const { data, showSelectAll } = handleCheckboxDisplay(
      option,
      reservationData.data
    );
    setReservationData(
      produce((draft) => {
        draft.data = data;
      })
    );
    setShowSelectAll(showSelectAll);
  };

  const handleBulkCancel = useCallback(() => {
    setBulkOption(null);

    const { data, showSelectAll } = handleCheckboxDisplay(
      null,
      reservationData.data
    );

    setReservationData(
      produce((draft) => {
        draft.data = data;
      })
    );

    setShowSelectAll(showSelectAll);
  }, [reservationData.data]);

  const handleCreate = () => {
    setManageReserveConfigModal({
      show: true,
      mode: "add",
    });
  };

  const handleDownload = async () => {
    const params = prepareFilterParams(
      filterRef.current,
      paginationRef.current,
      sortRef.current
    );

    params.count = 100000;
    params.isDirectDownload = true;

    setBusyLoader({
      show: true,
    });
    const r = await ProductService.getReservationList(params);
    setBusyLoader({
      show: false,
    });

    if (r.resp?.downloadLink) {
      CommonService.downloadAsset(r.resp.downloadLink);
    } else {
      Toaster.error("Failed to download file");
    }
  };

  const handleQuickEditCb = (data) => {
    if (data.status === "success") {
      setReservationData(
        produce((draft) => {
          const i = draft.data.findIndex((d) => d._id === data.configId);
          if (i !== -1) {
            draft.data[i].preOrderLimit = data.qty;
            draft.data[i].remainingQuantity =
              draft.data[i].preOrderLimit - draft.data[i].totalOrderQuantity;
          }
        })
      );
    }

    setQuickEditModal({
      show: false,
      id: null,
      poDetails: {},
    });

    if (data.action === "viewOrders") {
      setSubOrderListModal({
        show: true,
        reserveId: data.configId,
      });
    }
  };

  const handleViewModalCb = () => {
    setViewModal({
      show: false,
      configId: null,
    });
  };

  const handleApplyBulkAction = async () => {
    if (bulkOption === "editQty") {
      handleBulkEditQty();
    }

    if (bulkOption === "extend") {
      handleBulkExtend();
    }

    if (bulkOption === "stop") {
      handleBulkStop();
    }

    // Additional logic for other bulk actions can be added here
  };

  const handleBulkEditQty = async () => {
    const res = await Alert.confirm({
      title: "Confirmation Required",
      text: "Are you sure you want to update the quantity in bulk? This action cannot be undone. The quantity will be updated based on the previously entered quantity.",
      icon: "warning",
      okText: "Yes, Update",
      cancelText: "No, Cancel",
    });
    if (res.isConfirmed) {
      setBusyLoader({ show: true });

      const selectedConfigIds = reservationData.data
        .filter((x) => x._selected)
        .map((e) => ({ _id: e._id, qty: e.preOrderLimit }));

      if (selectedConfigIds.length === 0) {
        Toaster.error("No configurations selected for update.");
        return;
      }

      const r = await ProductService.reserveBulkEditConfig({
        configIds: selectedConfigIds,
      });

      setBusyLoader({ show: false });

      applyFilter();

      if (
        r.resp?.response &&
        r.resp?.response.errorResponse &&
        r.resp?.response.errorResponse.length > 0
      ) {
        Toaster.error(
          r.resp?.response.errorResponse.map((e) => e.message).join(",")
        );
        return;
      }

      Toaster.success(r.resp.message || "Updated successfully");
    }
  };

  const handleBulkExtend = async () => {
    const res = await Alert.confirm({
      title: "Confirmation Required",
      text: "Are you sure you want to extend the configurations in bulk? This action cannot be undone.",
      icon: "warning",
      okText: "Yes, Extend",
      cancelText: "No, Cancel",
    });

    if (res.isConfirmed) {
      setBusyLoader({ show: true });

      const selectedConfigIds = reservationData.data
        .filter((x) => x._selected)
        .map((e) => e._id);

      if (selectedConfigIds.length === 0) {
        Toaster.error("No configurations selected for extension.");
        return;
      }

      const r = await ProductService.extendConfig({
        configIds: selectedConfigIds,
      });

      setBusyLoader({ show: false });

      if (r.statusCode != 200) {
        Toaster.error(r.resp.message || "Failed to extend, please try again");
      } else {
        applyFilter();
        Toaster.success(r.resp.message || "Extended successfully");
      }
    }
  };

  const handleBulkStop = async () => {
    const res = await Alert.confirm({
      title: "Confirmation Required",
      text: "Are you sure you want to stop the configurations in bulk? This action cannot be undone.",
      icon: "warning",
      okText: "Yes, Stop",
      cancelText: "No, Cancel",
    });
    if (res.isConfirmed) {
      setBusyLoader({ show: true });

      const selectedConfigIds = reservationData.data
        .filter((x) => x._selected)
        .map((e) => e._id);

      if (selectedConfigIds.length === 0) {
        Toaster.error("No configurations selected for stopping.");
        return;
      }

      const r = await ProductService.bulkStop({
        configIds: selectedConfigIds,
      });

      setBusyLoader({ show: false });

      if (r.statusCode != 200) {
        Toaster.error(r.resp.message || "Failed to stop, please try again");
      } else {
        applyFilter();
        Toaster.success(r.resp.message || "Stopped successfully");
      }
    }
  };

  const loadSummary = useCallback(async () => {
    try {
      // Create new AbortController for summary requests
      const abortController = new AbortController();

      const activeParams = prepareFilterParams(
        { ...filterRef.current, status: "active" },
        paginationRef.current,
        sortRef.current
      );
      const expiredParams = prepareFilterParams(
        { ...filterRef.current, status: "expired" },
        paginationRef.current,
        sortRef.current
      );
      const lowStockParams = prepareFilterParams(
        { ...filterRef.current, status: "lowStock" },
        paginationRef.current,
        sortRef.current
      );
      const nearExpiryParams = prepareFilterParams(
        { ...filterRef.current, status: "nearExpiry" },
        paginationRef.current,
        sortRef.current
      );
      const stockOutParams = prepareFilterParams(
        { ...filterRef.current, status: "stockOut" },
        paginationRef.current,
        sortRef.current
      );
      const stoppedParams = prepareFilterParams(
        { ...filterRef.current, status: "stopped" },
        paginationRef.current,
        sortRef.current
      );

      // Pass signal to all getCount calls
      const [
        totalCount,
        activeCount,
        expiredCount,
        lowStockCount,
        nearExpiryCount,
        stockOutCount,
        stoppedCount,
      ] = await Promise.all([
        getCount(
          prepareFilterParams({ ...filterRef.current }),
          abortController.signal
        ),
        getCount(activeParams, abortController.signal),
        getCount(expiredParams, abortController.signal),
        getCount(lowStockParams, abortController.signal),
        getCount(nearExpiryParams, abortController.signal),
        getCount(stockOutParams, abortController.signal),
        getCount(stoppedParams, abortController.signal),
      ]);

      setSummaryData([
        { title: "Total", value: totalCount.count, valueColor: "dark" },
        { title: "Active", value: activeCount.count, valueColor: "success" },
        { title: "Expired", value: expiredCount.count, valueColor: "danger" },
        {
          title: "Low Stock",
          value: lowStockCount.count,
          valueColor: "warning",
        },
        {
          title: "Near Expiry",
          value: nearExpiryCount.count,
          valueColor: "info",
        },
        {
          title: "Out of Stock",
          value: stockOutCount.count,
          valueColor: "danger",
        },
        {
          title: "Stopped",
          value: stoppedCount.count,
          valueColor: "secondary",
        },
      ]);
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Failed to load summary:", error);
      }
    }
  }, []);

  useEffect(() => {
    loadSummary();
  }, [loadSummary]);

  const subOrderListModalCb = () => {
    setSubOrderListModal({
      show: false,
      reserveId: null,
    });
  };

  const extendConfigCb = (data) => {
    setExtendConfigModal({
      show: false,
      data: {},
    });

    if (data.status === "submitted") {
      loadList();
    }
  };

  // Clean up AbortController on component unmount
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const handleSync = async () => {
    setBusyLoader({ show: true });
    const r = await ProductService.syncReservation({
      projectFilter: {
        applicableFromDate: {
          $gte: set(new Date(filterRef.current.createdAt[0]), {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          }),
        },
        endDate: {
          $lte: set(new Date(filterRef.current.createdAt[1]), {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          }),
        },
      },
    });
    setBusyLoader({ show: false });
    if (r.statusCode != 200) {
      Toaster.error(r.resp.message || "Failed to sync, please try again");
    } else {
      Toaster.success(r.resp.message || "Synced successfully");
    }
  };

  const onTabChange = (value) => {
    NavService.to(navigate, value.redirect.path);
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <PageInfo title="Reservations" breadcrumbs={breadcrumbs} />
        </div>
        <div className="col-auto align-self-center">
          <Rbac roles={rbac.sync}>
            <button className="btn btn-primary me-2" onClick={handleSync}>
              <i className="bi bi-arrow-clockwise"></i> Sync
            </button>
          </Rbac>
          <Rbac roles={rbac.create}>
            <button className="btn btn-primary me-2" onClick={handleCreate}>
              <i className="bi bi-plus-circle"></i> Create
            </button>
          </Rbac>
          <Rbac roles={rbac.download}>
            <button className="btn btn-success" onClick={handleDownload}>
              <i className="bi bi-download"></i> Download
            </button>
          </Rbac>
        </div>
      </div>

      <div className="mb-2 d-none">
        <ReservationTabs tabKey={"reservation"} onTabChange={onTabChange} />
      </div>

      <AppCard>
        <ReservationFilter callback={filterCb} ref={filterFormRef} />
      </AppCard>

      <div className="summary-cnt" ref={summaryRef}>
        <Summary data={summaryData} />
      </div>

      <AppCard>
        <div className="row">
          <div className="col">
            {filterLabels.length > 0 && (
              <div className="mb-2">
                <AppliedFilterLabel labels={filterLabels} />
              </div>
            )}
            <PaginationSummary
              loadingTotalRecords={totalRecords.loading}
              paginationConfig={paginationRef.current}
              className="mt-2 mb-3"
            />
          </div>
          <div className="col-auto align-self-center">
            <select
              className="form-select form-select-sm"
              onChange={(e) => handleBulkOptionChange(e.target.value)}
              value={bulkOption || ""}
            >
              <option value="">Choose Bulk Action</option>
              <Rbac roles={rbac.edit}>
                <option value="editQty">Bulk Update Qty</option>
              </Rbac>
              <Rbac roles={rbac.stop}>
                <option value="stop">Bulk Stop</option>
              </Rbac>
              <Rbac roles={rbac.extend}>
                <option value="extend">Bulk Extend</option>
              </Rbac>
            </select>
          </div>
        </div>

        {bulkOption && (
          <div className="mb-3 mt-1">
            <InfoBlk>{getBulkActionInfo(bulkOption)}</InfoBlk>
          </div>
        )}

        <ReservationTable
          reservationData={reservationData}
          paginationConfig={paginationRef.current}
          callback={tableCb}
          startSlNo={paginationRef.current.startSlNo}
          showSelectAll={showSelectAll}
        />
        <div className="text-end mt-3">
          <PaginationBlock
            loadingTotalRecords={totalRecords.loading}
            paginationCb={paginationCb}
            paginationConfig={paginationRef.current}
            size="sm"
          />
        </div>
      </AppCard>

      {selectedCount > 0 && (
        <div className="position-sticky bottom-0 w-100">
          <div className="bg-white shadow-lg border rounded-top p-3 mx-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <span className="text-muted me-2">Selected:</span>
                <span className="fw-bold">{selectedCount} items</span>
              </div>
              <div>
                <button
                  className="btn btn-outline-secondary btn-sm me-2"
                  onClick={handleBulkCancel}
                >
                  <i className="bi bi-x-circle me-1"></i>
                  Cancel
                </button>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleApplyBulkAction}
                >
                  <i className="bi bi-check-circle me-1"></i>
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ManageReserveConfigModal
        mode={manageReserveConfigModal.mode}
        configId={manageReserveConfigModal.configId}
        show={manageReserveConfigModal.show}
        closeModal={closeManageReserveConfigModal}
      />

      <ReservationQuickEdit
        show={quickEditModal.show}
        callback={handleQuickEditCb}
        configId={quickEditModal.id}
        poDetails={quickEditModal.poDetails}
      />

      <ViewOpenPoModal
        show={openPoModal.show}
        closeModal={closeOpenPoModal}
        details={openPoModal.details}
      />

      <ViewModal
        show={viewModal.show}
        callback={handleViewModalCb}
        configId={viewModal.configId}
      />

      <SubOrderListModal
        callback={subOrderListModalCb}
        show={subOrderListModal.show}
        reserveId={subOrderListModal.reserveId}
      />

      <ExtendConfigModal
        callback={extendConfigCb}
        show={extendConfigModal.show}
        data={extendConfigModal.data}
      />

      <BusyLoader show={busyLoader.show} />
    </>
  );
};

export default Reservation;
