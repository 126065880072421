import { DashboardService, NavService } from "@sk/services";
import { Amount, AppCard, InfoPopover, TableHeader } from "@sk/uis";
import { useEffect, useMemo, useState } from "react";
import {
  AGEING_121_180,
  AGEING_180,
  AGEING_1_45,
  AGEING_46_90,
  AGEING_91_120,
  FAST_MOVING,
  NEAR_EXPIRY,
  NON_MOVING,
  NON_SELLABLE,
  RECENT_PURCHASE,
  SELLABLE,
  SLOW_MOVING,
  tdStyle,
  VALUE_CALCULATION,
} from "../constant";

const getTotalSummary = (data) => {
  return data.reduce(
    (acc, curr) => {
      acc.totalQty += curr.totalQty;
      acc.totalValue += curr.totalValue;
      acc.allProductsCount += curr.allProductsCount;
      return acc;
    },
    { totalQty: 0, totalValue: 0, allProductsCount: 0 }
  );
};

const getData = async (params, type, isStoreWise = false) => {
  const r = isStoreWise
    ? await DashboardService.fetchStoreInventoryAnalytics(params)
    : await DashboardService.fetchInventoryAnalytics(params);
  const sorting = {
    [AGEING_1_45]: 1,
    [AGEING_46_90]: 2,
    [AGEING_91_120]: 3,
    [AGEING_121_180]: 4,
    [AGEING_180]: 5,
    Expired: 6,
  };
  if (Array.isArray(r.resp)) {
    if (type === "sellableInventory") {
      let t = {};
      r.resp.forEach((e) => {
        let key = e.ageingSlab;
        if (!t[key]) {
          t[key] = {
            ...e,
          };
        } else {
          const fastMoving = getTotalSummary(
            r.resp.filter(
              (e) => e.stockMovement == FAST_MOVING && e.ageingSlab == key
            )
          );
          const slowMoving = getTotalSummary(
            r.resp.filter(
              (e) => e.stockMovement == SLOW_MOVING && e.ageingSlab == key
            )
          );
          const nonMoving = getTotalSummary(
            r.resp.filter(
              (e) => e.stockMovement == NON_MOVING && e.ageingSlab == key
            )
          );
          const recentPurchase = getTotalSummary(
            r.resp.filter(
              (e) => e.stockMovement == RECENT_PURCHASE && e.ageingSlab == key
            )
          );

          t[key][FAST_MOVING] = fastMoving;
          t[key][SLOW_MOVING] = slowMoving;
          t[key][NON_MOVING] = nonMoving;
          t[key][RECENT_PURCHASE] = recentPurchase;
        }
      });
      r.resp = Object.values(t);
    }
  }

  return r.resp
    ?.map((e) => ({
      ...e,
      priority: sorting[e.ageingSlab],
    }))
    .sort((a, b) => a.priority - b.priority);
};

const prepareFilterParams = (filters, type) => {
  let queryType = "getInventoryInfoByAgeingSlab";

  if (filters.store?.length) {
    queryType = "getStoreInventoryInfoByAgeingSlab";
  }

  const params = DashboardService.prepareInventoryAnalyticsParams(
    filters,
    queryType
  );

  if (type === "sellableInventory") {
    params.groupByType = "movement";
  } else if (type === "nonSellableInventory") {
    params.groupByType = "ExpiredAndDamaged";
  }

  return params;
};

const InventoryAgeingSummaryTable = ({ filterData, type }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const r = await getData(
          prepareFilterParams(filterData, type),
          type,
          filterData?.store?.length > 0
        );
        setTableData(r);
      } catch (error) {
        console.error("Error loading inventory ageing data:", error);
      }
    };
    loadData();
  }, [filterData, type]);

  const columns = useMemo(() => {
    if (type === "sellableInventory") {
      return getSellableColumns();
    }
    if (type === "nonSellableInventory") {
      return getNonSellableColumns();
    }
    return getAllColumns();
  }, [type]);

  const renderRow = (item, index) => {
    if (type === "allInventory") {
      return renderAllInventoryRow(item, index);
    }
    if (type === "sellableInventory") {
      return renderSellableInventoryRow(item, index);
    }
    if (type === "nonSellableInventory") {
      return renderNonSellableInventoryRow(item, index);
    }
  };

  const onCellClick = (key, item) => {
    let additionalParams = [{ key: "ageingSlab", value: item.ageingSlab }];

    // Handle different click cases
    switch (key) {
      case "sellable":
        additionalParams.push(
          { key: "inventoryStatus", value: SELLABLE },
          { key: "tab", value: "sellableInventory" }
        );
        break;
      case "nonSellable":
        additionalParams.push(
          { key: "inventoryStatus", value: NON_SELLABLE },
          { key: "tab", value: "nonSellableInventory" }
        );
        break;
      case "fastMoving":
        additionalParams.push(
          { key: "stockMovement", value: FAST_MOVING },
          { key: "tab", value: "sellableInventory" }
        );
        break;
      case "slowMoving":
        additionalParams.push(
          { key: "stockMovement", value: SLOW_MOVING },
          { key: "tab", value: "sellableInventory" }
        );
        break;
      case "nonMoving":
        additionalParams.push(
          { key: "stockMovement", value: NON_MOVING },
          { key: "tab", value: "sellableInventory" }
        );
        break;
      case "nearExpiry":
        additionalParams.push(
          { key: "stockMovement", value: NEAR_EXPIRY },
          { key: "tab", value: "sellableInventory" }
        );
        break;
      case "expired":
        additionalParams.push(
          { key: "nonSellableType", value: "expired" },
          { key: "tab", value: "nonSellableInventory" }
        );
        break;
      case "damaged":
        additionalParams.push(
          { key: "nonSellableType", value: "damaged" },
          { key: "tab", value: "nonSellableInventory" }
        );
        break;
      case "recentPurchase":
        additionalParams.push(
          { key: "stockMovement", value: RECENT_PURCHASE },
          { key: "tab", value: "sellableInventory" }
        );
        break;
      default:
        additionalParams.push({ key: "type", value: key });
    }

    const params = DashboardService.prepareNavParamsForInventoryAgeing(
      filterData,
      additionalParams
    );
    NavService.openInNewTab("/dashboard/inventory-ageing/products", params);
  };

  const renderAllInventoryRow = (item, index) => {
    return (
      <tr key={index}>
        <td>
          <button
            className="btn btn-link fs-val-sm text-dark p-0"
            onClick={() => onCellClick("ageingSlab", item)}
          >
            {item.ageingSlab}
          </button>
        </td>
        <td
          className="text-center text-primary fw-semibold"
          style={tdStyle.total}
        >
          <button
            className="btn btn-link fs-val-sm text-dark p-0"
            onClick={() => onCellClick("totalProducts", item)}
          >
            {item.allProductsCount || 0}
          </button>
        </td>
        <td className="text-center">{item.totalQty || 0}</td>
        <td className="text-center">
          <Amount value={item.totalValue || 0} />
        </td>
        <td
          className="text-center text-success fw-semibold"
          style={tdStyle.sellable}
        >
          <button
            className="btn btn-link fs-val-sm text-dark p-0"
            onClick={() => onCellClick("sellable", item)}
          >
            {item.allSellableProductsCount || 0}
          </button>
        </td>
        <td className="text-center">{item.totalSellableQty || 0}</td>
        <td className="text-center">
          <Amount value={item.totalSellableValue || 0} />
        </td>
        <td
          className="text-center text-danger fw-semibold"
          style={tdStyle.nonSellable}
        >
          <button
            className="btn btn-link fs-val-sm text-dark p-0"
            onClick={() => onCellClick("nonSellable", item)}
          >
            {item.allNonSellableProductsCount || 0}
          </button>
        </td>
        <td className="text-center">{item.totalNonSellableQty || 0}</td>
        <td className="text-center">
          <Amount value={item.totalNonSellableValue || 0} />
        </td>
      </tr>
    );
  };

  const renderSellableInventoryRow = (item, index) => {
    return (
      <tr key={index}>
        <td>
          <button
            className="btn btn-link fs-val-sm text-dark p-0"
            onClick={() => onCellClick("ageingSlab", item)}
          >
            {item.ageingSlab}
          </button>
        </td>

        {/* total */}
        <td
          className="text-center text-primary fw-semibold"
          style={tdStyle.total}
        >
          <button
            className="btn btn-link fs-val-sm text-dark p-0"
            onClick={() => onCellClick("totalProducts", item)}
          >
            {item.allProductsCount || 0}
          </button>
        </td>
        <td className="text-center">{item.totalQty || 0}</td>
        <td className="text-center">
          <Amount value={item.totalValue || 0} />
        </td>

        {/* fast moving */}
        <td
          className="text-center text-success fw-semibold"
          style={tdStyle.fastMoving}
        >
          <button
            className="btn btn-link fs-val-sm text-dark p-0"
            onClick={() => onCellClick("fastMoving", item)}
          >
            {item[FAST_MOVING]?.allProductsCount || 0}
          </button>
        </td>
        <td className="text-center">{item[FAST_MOVING]?.totalQty || 0}</td>
        <td className="text-center">
          <Amount value={item[FAST_MOVING]?.totalValue || 0} />
        </td>

        {/* slow moving */}
        <td
          className="text-center text-danger fw-semibold"
          style={tdStyle.slowMoving}
        >
          <button
            className="btn btn-link fs-val-sm text-dark p-0"
            onClick={() => onCellClick("slowMoving", item)}
          >
            {item[SLOW_MOVING]?.allProductsCount || 0}
          </button>
        </td>
        <td className="text-center">{item[SLOW_MOVING]?.totalQty || 0}</td>
        <td className="text-center">
          <Amount value={item[SLOW_MOVING]?.totalValue || 0} />
        </td>

        {/* non moving */}
        <td className="text-center fw-semibold" style={tdStyle.nonMoving}>
          <button
            className="btn btn-link fs-val-sm text-dark p-0"
            onClick={() => onCellClick("nonMoving", item)}
          >
            {item[NON_MOVING]?.allProductsCount || 0}
          </button>
        </td>
        <td className="text-center">{item[NON_MOVING]?.totalQty || 0}</td>
        <td className="text-center">
          <Amount value={item[NON_MOVING]?.totalValue || 0} />
        </td>

        {/* recent purchase */}
        <td className="text-center fw-semibold" style={tdStyle.recentPurchase}>
          <button
            className="btn btn-link fs-val-sm text-dark p-0"
            onClick={() => onCellClick("recentPurchase", item)}
          >
            {item[RECENT_PURCHASE]?.allProductsCount || 0}
          </button>
        </td>
        <td className="text-center">{item[RECENT_PURCHASE]?.totalQty || 0}</td>
        <td className="text-center">
          <Amount value={item[RECENT_PURCHASE]?.totalValue || 0} />
        </td>
      </tr>
    );
  };

  const renderNonSellableInventoryRow = (item, index) => {
    return (
      <tr key={index}>
        <td>
          <button
            className="btn btn-link fs-val-sm text-dark p-0"
            onClick={() => onCellClick("ageingSlab", item)}
          >
            {item.ageingSlab}
          </button>
        </td>

        {/* total */}
        <td
          className="text-center text-primary fw-semibold"
          style={tdStyle.total}
        >
          <button
            className="btn btn-link fs-val-sm text-dark p-0"
            onClick={() => onCellClick("nonSellable", item)}
          >
            {item.totalProducts || 0}
          </button>
        </td>
        <td className="text-center">{item.totalQty || 0}</td>
        <td className="text-center">
          <Amount value={item.totalValue || 0} />
        </td>

        {/* expired */}
        <td
          className="text-center text-danger fw-semibold"
          style={tdStyle.expired}
        >
          <button
            className="btn btn-link fs-val-sm text-dark p-0"
            onClick={() => onCellClick("expired", item)}
          >
            {item.expiredProducts || 0}
          </button>
        </td>
        <td className="text-center">{item.expiredQty || 0}</td>
        <td className="text-center">
          <Amount value={item.expiredValue || 0} />
        </td>

        {/* damaged */}
        <td
          className="text-center text-danger fw-semibold"
          style={tdStyle.damaged}
        >
          <button
            className="btn btn-link fs-val-sm text-dark p-0"
            onClick={() => onCellClick("damaged", item)}
          >
            {item.damagedProducts || 0}
          </button>
        </td>
        <td className="text-center">{item.damagedQty || 0}</td>
        <td className="text-center">
          <Amount value={item.damagedValue || 0} />
        </td>
      </tr>
    );
  };

  const renderTableHeader = (type) => {
    if (type === "sellableInventory") {
      return (
        <tr className="bg-light">
          <th></th>
          <th className="text-center">Products</th>
          <th className="text-center">Quantity</th>
          <th className="text-center">Value</th>
          <th className="text-center">Products</th>
          <th className="text-center">Quantity</th>
          <th className="text-center">Value</th>
          <th className="text-center">Products</th>
          <th className="text-center">Quantity</th>
          <th className="text-center">Value</th>
          <th className="text-center">Products</th>
          <th className="text-center">Quantity</th>
          <th className="text-center">Value</th>
          <th className="text-center">Products</th>
          <th className="text-center">Quantity</th>
          <th className="text-center">Value</th>
        </tr>
      );
    }
    if (type === "nonSellableInventory") {
      return (
        <tr className="bg-light">
          <th></th>
          <th className="text-center">Products</th>
          <th className="text-center">Quantity</th>
          <th className="text-center">Value</th>
          <th className="text-center">Products</th>
          <th className="text-center">Quantity</th>
          <th className="text-center">Value</th>
          <th className="text-center">Products</th>
          <th className="text-center">Quantity</th>
          <th className="text-center">Value</th>
        </tr>
      );
    }
    return (
      <tr className="bg-light">
        <th></th>
        <th className="text-center">Products</th>
        <th className="text-center">Quantity</th>
        <th className="text-center">Value</th>
        <th className="text-center">Products</th>
        <th className="text-center">Quantity</th>
        <th className="text-center">Value</th>
        <th className="text-center">Products</th>
        <th className="text-center">Quantity</th>
        <th className="text-center">Value</th>
      </tr>
    );
  };

  return (
    <AppCard
      title={
        <div className="d-flex justify-content-between">
          <div className="">Inventory Ageing Summary</div>
          <div>
            <InfoPopover
              content={
                <div className="fs-val-sm">
                  <div className="fw-semibold mb-2">What is this?</div>
                  <div className="d-flex">
                    <i className="bi bi-dot"></i>
                    <span>
                      Categorized into 1-45 days, 46-90 days, etc., based on
                      remaining shelf life.
                    </span>
                  </div>
                  <div className="d-flex">
                    <i className="bi bi-dot"></i>
                    Calculated using the product’s shelf life
                  </div>
                  <div className="d-flex">
                    <i className="bi bi-dot"></i>
                    It shows how many days of shelf life remain before the
                    product expires.
                  </div>
                  <div className="d-flex">
                    <i className="bi bi-dot"></i>
                    <span>
                      The value is calculated based on{" "}
                      <strong> {VALUE_CALCULATION} </strong>.
                    </span>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      }
    >
      <table className="table table-sm table-bordered table-hover">
        <TableHeader data={columns} noBg noBorder>
          {renderTableHeader(type)}
        </TableHeader>
        <tbody className="fs-val-sm">
          {tableData.map((item, index) => renderRow(item, index))}
        </tbody>
      </table>
    </AppCard>
  );
};

export default InventoryAgeingSummaryTable;

function getAllColumns() {
  return [
    { label: "Shelf Life Days", key: "days" },
    { label: "Total", key: "totalProducts", isCentered: true, colSpan: 3 },
    { label: "Sellable", key: "sellable", isCentered: true, colSpan: 3 },
    { label: "Non-Sellable", key: "nonSellable", isCentered: true, colSpan: 3 },
  ];
}

function getSellableColumns() {
  return [
    { label: "Shelf Life Days", key: "days" },
    { label: "Total", key: "totalProducts", isCentered: true, colSpan: 3 },
    {
      label: "Fast Moving Products",
      key: "fastMoving",
      isCentered: true,
      colSpan: 3,
    },
    {
      label: "Slow Moving Products",
      key: "slowMoving",
      isCentered: true,
      colSpan: 3,
    },
    {
      label: "Non-Moving Products",
      key: "nonMoving",
      isCentered: true,
      colSpan: 3,
    },
    {
      label: "Recently Purchased",
      key: "recentPurchase",
      isCentered: true,
      colSpan: 3,
    },
    // {
    //   label: "Near Expiry Products",
    //   key: "nearExpiry",
    //   isCentered: true,
    //   colSpan: 3,
    // },
  ];
}

function getNonSellableColumns() {
  return [
    { label: "Shelf Life Days", key: "days" },
    { label: "Total", key: "totalProducts", isCentered: true, colSpan: 3 },
    { label: "Expired", key: "expiredProducts", isCentered: true, colSpan: 3 },
    { label: "Damaged", key: "damagedProducts", isCentered: true, colSpan: 3 },
  ];
}
