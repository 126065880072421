import { SummaryCard } from "@sk/uis";
import { memo } from "react";

const Summary = ({ data = [], onTap }) => {
  return (
    <div className="row g-3">
      {data.map((item, index) => (
        <div
          className="col-auto"
          key={index}
          onClick={() => item.isClickable && onTap(item)}
        >
          <SummaryCard
            title={item.title}
            value={item.value}
            loading={item.loading}
            template={3}
            valueColor={item.valueColor}
          />
        </div>
      ))}
    </div>
  );
};

export default memo(Summary);
