import * as yup from "yup";

const formValidation = yup.object().shape({
  product: yup.array().when("mode", {
    is: (mode) => mode == "add",
    then: (schema) =>
      schema
        .label("Product")
        .min(1, "Please select Product")
        .required()
        .label("Product"),
  }),

  shipmentTat: yup
    .number()
    .typeError("Shipment TAT is required")
    .when({
      is: (mode) => mode == "add",
      then: (schema) => schema.label("Product").required(),
    }),
});

export default formValidation;
