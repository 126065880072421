import { CommonService, DashboardService, NavService } from "@sk/services";
import {
  Amount,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { debounce, merge } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  FAST_MOVING,
  NON_MOVING,
  NON_SELLABLE,
  RECENT_PURCHASE,
  SELLABLE,
  SLOW_MOVING,
  tdStyle,
} from "../constant";

const getData = async (params, isStoreWise = false) => {
  const r = isStoreWise
    ? await DashboardService.fetchStoreInventoryAnalytics(params)
    : await DashboardService.fetchInventoryAnalytics(params);
  return r.resp;
};

const getCount = async (params, isStoreWise = false) => {
  const p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;
  const response = isStoreWise
    ? await DashboardService.fetchStoreInventoryAnalytics(p)
    : await DashboardService.fetchInventoryAnalytics(p);
  return response?.resp?.[0]?.totalDocs || 0;
};

const prepareFilterParams = (filter, pagination, sort, groupByType) => {
  let type = "";

  if (filter.catalogType == "menu") {
    type =
      filter.store?.length > 0
        ? "getStoreInventoryStockSummaryByMenu"
        : "getInventoryStockSummaryByMenu";
  } else if (filter.catalogType == "category") {
    type =
      filter.store?.length > 0
        ? "getStoreInventoryStockSummaryByCategory"
        : "getInventoryStockSummaryByCategory";
  } else if (filter.catalogType == "brand") {
    type =
      filter.store?.length > 0
        ? "getStoreInventoryStockSummaryByBrand"
        : "getInventoryStockSummaryByBrand";
  } else {
    throw new Error("Invalid catalog type");
  }

  const params = DashboardService.prepareInventoryAnalyticsParams(filter, type);

  if (pagination) {
    params.page = pagination.activePage;
    params.count = pagination.rowsPerPage;
  }

  let additionalParams = {
    filter: {},
  };

  if (groupByType == "allInventory") {
    additionalParams.groupByType = "overall";
    additionalParams.movementType = "OVERALL";
  } else if (groupByType == "sellableInventory") {
    additionalParams.groupByType = "movement";
  } else if (groupByType == "nonSellableInventory") {
    additionalParams.groupByType = "ExpiredAndDamaged";
  }

  const search = (filter.search || "").trim();
  if (search) {
    if (filter.catalogType == "menu") {
      additionalParams.filter.$or = [
        { "productInfo.menuName": { $regex: search, $options: "i" } },
        { "productInfo.menuId": { $regex: search, $options: "i" } },
      ];
    } else if (filter.catalogType == "category") {
      additionalParams.filter.$or = [
        { "productInfo.categoryName": { $regex: search, $options: "i" } },
        { "productInfo.categoryId": { $regex: search, $options: "i" } },
      ];
    } else if (filter.catalogType == "brand") {
      additionalParams.filter.$or = [
        { "productInfo.brandName": { $regex: search, $options: "i" } },
        { "productInfo.brandId": { $regex: search, $options: "i" } },
      ];
    }
  }

  if (sort) {
    let key = sort.key;

    if (key == "name" && filter.catalogType == "menu") {
      key = "menuName";
    } else if (key == "name" && filter.catalogType == "category") {
      key = "categoryName";
    } else if (key == "name" && filter.catalogType == "brand") {
      key = "brandName";
    } else if (
      key == "fastMovingQty" ||
      key == "slowMovingQty" ||
      key == "nonMovingQty" ||
      key == "recentlyPurchasedQty"
    ) {
      key = `sortBlock.totalInfo.totalQty`;
    } else if (
      key == "fastMovingValue" ||
      key == "slowMovingValue" ||
      key == "nonMovingValue" ||
      key == "recentlyPurchasedValue"
    ) {
      key = `sortBlock.totalInfo.totalValue`;
    } else if (
      key == "fastMovingProducts" ||
      key == "slowMovingProducts" ||
      key == "nonMovingProducts" ||
      key == "recentlyPurchasedProducts"
    ) {
      key = `sortBlock.totalInfo.totalProducts`;
    }

    // movement type
    if (
      sort.key == "fastMovingQty" ||
      sort.key == "fastMovingValue" ||
      sort.key == "fastMovingProducts"
    ) {
      additionalParams.movementType = FAST_MOVING;
    } else if (
      sort.key == "slowMovingQty" ||
      sort.key == "slowMovingValue" ||
      sort.key == "slowMovingProducts"
    ) {
      additionalParams.movementType = SLOW_MOVING;
    } else if (
      sort.key == "nonMovingQty" ||
      sort.key == "nonMovingValue" ||
      sort.key == "nonMovingProducts"
    ) {
      additionalParams.movementType = NON_MOVING;
    } else if (
      sort.key == "recentlyPurchasedQty" ||
      sort.key == "recentlyPurchasedValue" ||
      sort.key == "recentlyPurchasedProducts"
    ) {
      additionalParams.movementType = RECENT_PURCHASE;
    }
    params.sort = {
      [key]: sort.value === "asc" ? 1 : -1,
    };
  }

  return merge({}, params, additionalParams);
};

const prepareData = (response, catalogType) => {
  return response.map((item) => {
    const movements = item.movements || [];
    const all = movements.find((m) => m.movement == "OVERALL") || {};

    const fastMoving = movements.find((m) => m.movement == FAST_MOVING) || {};
    const slowMoving = movements.find((m) => m.movement == SLOW_MOVING) || {};
    const nonMoving = movements.find((m) => m.movement == NON_MOVING) || {};
    const recentlyPurchased = movements.find(
      (m) => m.movement == RECENT_PURCHASE
    );

    // Initialize counters
    let totalProducts = item?.totalProducts || 0;
    let totalUnits = item?.totalQty || 0;
    let totalValue = item?.totalValue || 0;

    let sellableProducts = all.sellableInfo?.sellablePidList || 0;
    let sellableUnits = all.sellableInfo?.sellableQty || 0;
    let sellableValue = all.sellableInfo?.sellableValue || 0;

    let nonSellableProducts = all.nonSellableInfo?.nonSellablePidList || 0;
    let nonSellableUnits = all.nonSellableInfo?.nonSellableQty || 0;
    let nonSellableValue = all.nonSellableInfo?.nonSellableValue || 0;

    let fastMovingProducts = fastMoving?.totalInfo?.totalProducts || 0;
    let fastMovingUnits = fastMoving?.totalInfo?.totalQty || 0;
    let fastMovingValue = fastMoving?.totalInfo?.totalValue || 0;

    let slowMovingProducts = slowMoving?.totalInfo?.totalProducts || 0;
    let slowMovingUnits = slowMoving?.totalInfo?.totalQty || 0;
    let slowMovingValue = slowMoving?.totalInfo?.totalValue || 0;

    let nonMovingProducts = nonMoving?.totalInfo?.totalProducts || 0;
    let nonMovingUnits = nonMoving?.totalInfo?.totalQty || 0;
    let nonMovingValue = nonMoving?.totalInfo?.totalValue || 0;

    let recentlyPurchasedProducts =
      recentlyPurchased?.totalInfo?.totalProducts || 0;
    let recentlyPurchasedUnits = recentlyPurchased?.totalInfo?.totalQty || 0;
    let recentlyPurchasedValue = recentlyPurchased?.totalInfo?.totalValue || 0;

    let expiredProducts = item?.expiredInfo?.expiredProducts || 0;
    let expiredUnits = item?.expiredInfo?.expiredQty || 0;
    let expiredValue = item?.expiredInfo?.expiredValue || 0;

    let damagedProducts = item?.damagedInfo?.damagedProducts || 0;
    let damagedUnits = item?.damagedInfo?.damagedQty || 0;
    let damagedValue = item?.damagedInfo?.damagedValue || 0;

    totalProducts =
      sellableProducts +
      nonSellableProducts +
      fastMovingProducts +
      slowMovingProducts +
      nonMovingProducts +
      recentlyPurchasedProducts +
      expiredProducts +
      damagedProducts;

    let name = "";
    let id = "";
    if (catalogType === "category") {
      name = item.categoryName;
      id = item.categoryId;
    } else if (catalogType === "menu") {
      name = item.menuName;
      id = item.menuId;
    } else if (catalogType === "brand") {
      name = item.brandName;
      id = item.brandId;
    }

    return {
      name,
      id,
      totalProducts,
      totalUnits: CommonService.roundedByDecimalPlace(totalUnits, 2),
      totalValue: CommonService.roundedByDecimalPlace(totalValue, 2),

      sellableProducts,
      sellableUnits: CommonService.roundedByDecimalPlace(sellableUnits, 2),
      sellableValue: CommonService.roundedByDecimalPlace(sellableValue, 2),

      nonSellableProducts,
      nonSellableUnits: CommonService.roundedByDecimalPlace(
        nonSellableUnits,
        2
      ),
      nonSellableValue: CommonService.roundedByDecimalPlace(
        nonSellableValue,
        2
      ),

      fastMovingProducts,
      fastMovingUnits: CommonService.roundedByDecimalPlace(fastMovingUnits, 2),
      fastMovingValue: CommonService.roundedByDecimalPlace(fastMovingValue, 2),

      slowMovingProducts,
      slowMovingUnits: CommonService.roundedByDecimalPlace(slowMovingUnits, 2),
      slowMovingValue: CommonService.roundedByDecimalPlace(slowMovingValue, 2),

      nonMovingProducts,
      nonMovingUnits: CommonService.roundedByDecimalPlace(nonMovingUnits, 2),
      nonMovingValue: CommonService.roundedByDecimalPlace(nonMovingValue, 2),

      recentlyPurchasedProducts,
      recentlyPurchasedUnits: CommonService.roundedByDecimalPlace(
        recentlyPurchasedUnits,
        2
      ),
      recentlyPurchasedValue: CommonService.roundedByDecimalPlace(
        recentlyPurchasedValue,
        2
      ),

      expiredProducts,
      expiredUnits: CommonService.roundedByDecimalPlace(expiredUnits, 2),
      expiredValue: CommonService.roundedByDecimalPlace(expiredValue, 2),

      damagedProducts,
      damagedUnits: CommonService.roundedByDecimalPlace(damagedUnits, 2),
      damagedValue: CommonService.roundedByDecimalPlace(damagedValue, 2),

      totalInfo: item.totalInfo,
    };
  });
};

const tableContainerStyle = {
  maxHeight: "400px",
  overflowY: "auto",
  overflowX: "auto", // Added for horizontal scroll
};

function getColumns(type) {
  if (type === "sellableInventory") {
    return getSellableColumns();
  }
  if (type === "nonSellableInventory") {
    return getNonSellableColumns();
  }
  return getAllInventoryColumns();
}

function getAllInventoryColumns() {
  return [
    { label: "", key: "SlNo", width: "3%", enableSort: false, colSpan: 2 },
    {
      label: "Total",
      key: "totalProducts",
      isCentered: true,
      colSpan: 3,
    },
    {
      label: "Sellable",
      key: "sellable",
      isCentered: true,
      colSpan: 3,
    },
    {
      label: "Non-Sellable",
      key: "nonSellable",
      isCentered: true,
      colSpan: 3,
    },
  ];
}

function getSellableColumns() {
  return [
    { label: "", key: "SlNo", width: "3%", colSpan: 2 },
    {
      label: "Total",
      key: "totalProducts",
      isCentered: true,
      colSpan: 3,
    },
    {
      label: "Fast Moving Products",
      key: "fastMoving",
      isCentered: true,
      colSpan: 3,
    },
    {
      label: "Slow Moving Products",
      key: "slowMoving",
      isCentered: true,
      colSpan: 3,
    },
    {
      label: "Non-Moving Products",
      key: "nonMoving",
      isCentered: true,
      colSpan: 3,
    },
    {
      label: "Recently Purchased",
      key: "nearExpiry",
      isCentered: true,
      colSpan: 3,
    },
  ];
}

function getNonSellableColumns() {
  return [
    { label: "", key: "SlNo", width: "3%", colSpan: 2 },
    {
      label: "Total",
      key: "totalProducts",
      isCentered: true,
      colSpan: 3,
    },
    {
      label: "Expired",
      key: "expiredProducts",
      isCentered: true,
      colSpan: 3,
    },
    {
      label: "Damaged",
      key: "damagedProducts",
      isCentered: true,
      colSpan: 3,
    },
  ];
}

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const getSummaryCount = async (params) => {
  const p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;
  const response = await DashboardService.fetchInventoryAnalytics(p);
  return {
    count: response?.resp?.[0]?.totalDocs || 0,
    value: response?.resp?.[0]?.totalStockValue || 0,
    quantity: response?.resp?.[0]?.totalStockQty || 0,
  };
};

const InventoryCatalogTable = ({ filterData, type, catalogType }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loadingTotal, setLoadingTotal] = useState(false);

  const filterRef = useRef({});
  const paginationRef = useRef({
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  });
  const sortRef = useRef({
    key: "name",
    value: "asc",
  });

  const loadList = useCallback(async () => {
    setLoading(true);
    setData([]);
    try {
      const r = await getData(
        prepareFilterParams(
          filterRef.current,
          paginationRef.current,
          sortRef.current,
          type
        ),
        filterData?.store?.length > 0
      );
      const processedData = prepareData(r, filterRef.current.catalogType);
      setData(processedData);
    } catch (error) {
      console.error("Error loading category overview:", error);
    } finally {
      setLoading(false);
    }
  }, [type, filterData?.store?.length]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    loadList();

    setLoadingTotal(true);
    const count = await getCount(
      prepareFilterParams(
        filterRef.current,
        paginationRef.current,
        sortRef.current,
        type
      ),
      filterData?.store?.length > 0
    );
    paginationRef.current.totalRecords = count;
    setLoadingTotal(false);
  }, [loadList, type, filterData?.store?.length]);

  useEffect(() => {
    filterRef.current = { ...filterData, catalogType };
    applyFilter();
  }, [filterData, catalogType, applyFilter]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const searchDebounceCb = useCallback(
    debounce(() => {
      applyFilter();
    }, 500),
    [applyFilter]
  );

  const onSearch = useCallback(
    (e) => {
      filterRef.current = {
        ...filterRef.current,
        search: e.target.value,
      };
      setSearch(e.target.value);
      searchDebounceCb();
    },
    [searchDebounceCb]
  );

  const onSort = useCallback(
    (sort) => {
      // If the same key is clicked, toggle between asc and desc
      if (sortRef.current.key === sort.key) {
        sortRef.current = {
          key: sort.key,
          value: sortRef.current.value === "asc" ? "desc" : "asc",
        };
      } else {
        // If a new key is clicked, default to ascending order
        sortRef.current = {
          key: sort.key,
          value: "asc",
        };
      }

      applyFilter();
    },
    [applyFilter]
  );

  const onLinkClick = (item, additionalParams = {}) => {
    let params;

    const baseParams = [
      { key: "tab", value: type },
      ...Object.entries(additionalParams).map(([key, value]) => ({
        key,
        value,
      })),
    ];

    if (catalogType === "menu") {
      params = DashboardService.prepareNavParamsForInventoryAgeing(
        filterRef.current,
        [
          { key: "menuId", value: item.id },
          { key: "menuName", value: item.name },
          ...baseParams,
        ]
      );
    } else if (catalogType === "category") {
      params = DashboardService.prepareNavParamsForInventoryAgeing(
        filterRef.current,
        [
          { key: "categoryId", value: item.id },
          { key: "categoryName", value: item.name },
          ...baseParams,
        ]
      );
    } else if (catalogType === "brand") {
      params = DashboardService.prepareNavParamsForInventoryAgeing(
        filterRef.current,
        [
          { key: "brandId", value: item.id },
          { key: "brandName", value: item.name },
          ...baseParams,
        ]
      );
    }

    NavService.openInNewTab("/dashboard/inventory-ageing/products", params);
  };

  function renderSubHeader(type) {
    if (type === "sellableInventory") {
      return renderSellableInventorySubHeader();
    }
    if (type === "nonSellableInventory") {
      return renderNonSellableInventorySubHeader();
    }
    return renderAllInventorySubHeader();
  }

  const renderSortIcons = (key) => {
    return (
      <div className="d-inline-flex flex-column ms-1 align-items-center align-middle">
        <span
          className={`bi fs-val-sm ${
            sortRef.current.key === key && sortRef.current.value === "asc"
              ? "bi-caret-up-fill"
              : "bi-caret-up"
          }`}
        />
        <span
          style={{ marginTop: "-7px" }}
          className={`bi fs-val-sm ${
            sortRef.current.key === key && sortRef.current.value === "desc"
              ? "bi-caret-down-fill"
              : "bi-caret-down"
          }`}
        />
      </div>
    );
  };

  function renderAllInventorySubHeader() {
    return (
      <tr className="bg-light">
        <th width="3%">Sl No</th>
        <th
          width="20%"
          onClick={() => onSort({ key: "name", value: sortRef.current.value })}
        >
          Name {renderSortIcons("name")}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "sortBlock.totalInfo.totalProducts",
              value: sortRef.current.value,
            })
          }
        >
          Products {renderSortIcons("sortBlock.totalInfo.totalProducts")}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "sortBlock.totalInfo.totalQty",
              value: sortRef.current.value,
            })
          }
        >
          Units {renderSortIcons("sortBlock.totalInfo.totalQty")}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "sortBlock.totalInfo.totalValue",
              value: sortRef.current.value,
            })
          }
        >
          Value {renderSortIcons("sortBlock.totalInfo.totalValue")}
        </th>

        {/* Sellable */}
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "sortBlock.sellableInfo.sellablePidList",
              value: sortRef.current.value,
            })
          }
        >
          Products {renderSortIcons("sortBlock.sellableInfo.sellablePidList")}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "sortBlock.sellableInfo.sellableQty",
              value: sortRef.current.value,
            })
          }
        >
          Units {renderSortIcons("sortBlock.sellableInfo.sellableQty")}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "sortBlock.sellableInfo.sellableValue",
              value: sortRef.current.value,
            })
          }
        >
          Value {renderSortIcons("sortBlock.sellableInfo.sellableValue")}
        </th>

        {/* Non Sellable */}
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "sortBlock.nonSellableInfo.nonSellablePidList",
              value: sortRef.current.value,
            })
          }
        >
          Products{" "}
          {renderSortIcons("sortBlock.nonSellableInfo.nonSellablePidList")}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "sortBlock.nonSellableInfo.nonSellableQty",
              value: sortRef.current.value,
            })
          }
        >
          Units {renderSortIcons("sortBlock.nonSellableInfo.nonSellableQty")}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "sortBlock.nonSellableInfo.nonSellableValue",
              value: sortRef.current.value,
            })
          }
        >
          Value {renderSortIcons("sortBlock.nonSellableInfo.nonSellableValue")}
        </th>
      </tr>
    );
  }

  function renderSellableInventorySubHeader() {
    return (
      <tr className="bg-light">
        <th width="3%">Sl No</th>

        <th
          width="20%"
          onClick={() => onSort({ key: "name", value: sortRef.current.value })}
        >
          Name {renderSortIcons("name")}
        </th>

        {/* total */}
        <th
          className="text-center"
          onClick={() =>
            onSort({
              key: "totalInfo.totalProductIdCount",
              value: sortRef.current.value,
            })
          }
        >
          Products {renderSortIcons("totalProducts")}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({ key: "totalUnits", value: sortRef.current.value })
          }
        >
          Units {renderSortIcons("totalUnits")}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({ key: "totalValue", value: sortRef.current.value })
          }
        >
          Value {renderSortIcons("totalValue")}
        </th>

        {/* fast moving */}
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "fastMovingProducts",
              value: sortRef.current.value,
            })
          }
        >
          Products {renderSortIcons(`fastMovingProducts`)}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: `fastMovingQty`,
              value: sortRef.current.value,
            })
          }
        >
          Units {renderSortIcons(`fastMovingQty`)}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: `fastMovingValue`,
              value: sortRef.current.value,
            })
          }
        >
          Value {renderSortIcons(`fastMovingValue`)}
        </th>

        {/* slow moving */}
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: `slowMovingProducts`,
              value: sortRef.current.value,
            })
          }
        >
          Products {renderSortIcons(`slowMovingProducts`)}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: `slowMovingQty`,
              value: sortRef.current.value,
            })
          }
        >
          Units {renderSortIcons(`slowMovingQty`)}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: `slowMovingValue`,
              value: sortRef.current.value,
            })
          }
        >
          Value {renderSortIcons(`slowMovingValue`)}
        </th>

        {/* non moving */}
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: `nonMovingProducts`,
              value: sortRef.current.value,
            })
          }
        >
          Products {renderSortIcons(`nonMovingProducts`)}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: `nonMovingQty`,
              value: sortRef.current.value,
            })
          }
        >
          Units {renderSortIcons(`nonMovingQty`)}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: `nonMovingValue`,
              value: sortRef.current.value,
            })
          }
        >
          Value {renderSortIcons(`nonMovingValue`)}
        </th>

        {/* recent purchase */}
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: `recentlyPurchasedProducts`,
              value: sortRef.current.value,
            })
          }
        >
          Products {renderSortIcons(`recentlyPurchasedProducts`)}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: `recentlyPurchasedQty`,
              value: sortRef.current.value,
            })
          }
        >
          Units {renderSortIcons(`recentlyPurchasedQty`)}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: `recentlyPurchasedValue`,
              value: sortRef.current.value,
            })
          }
        >
          Value {renderSortIcons(`recentlyPurchasedValue`)}
        </th>
      </tr>
    );
  }

  function renderNonSellableInventorySubHeader() {
    return (
      <tr className="bg-light">
        <th width="3%">Sl No</th>
        <th
          width="20%"
          onClick={() => onSort({ key: "name", value: sortRef.current.value })}
        >
          Name {renderSortIcons("name")}
        </th>

        {/* Total */}
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "totalInfo.totalProducts",
              value: sortRef.current.value,
            })
          }
        >
          Products {renderSortIcons("totalInfo.totalProducts")}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "totalInfo.totalQty",
              value: sortRef.current.value,
            })
          }
        >
          Units {renderSortIcons("totalInfo.totalQty")}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "totalInfo.totalValue",
              value: sortRef.current.value,
            })
          }
        >
          Value {renderSortIcons("totalInfo.totalValue")}
        </th>

        {/* Expired */}
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "expiredInfo.expiredProducts",
              value: sortRef.current.value,
            })
          }
        >
          Products {renderSortIcons("expiredInfo.expiredProducts")}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "expiredInfo.expiredQty",
              value: sortRef.current.value,
            })
          }
        >
          Units {renderSortIcons("expiredInfo.expiredQty")}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "expiredInfo.expiredValue",
              value: sortRef.current.value,
            })
          }
        >
          Value {renderSortIcons("expiredInfo.expiredValue")}
        </th>

        {/* Damaged */}
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "damagedInfo.damagedProducts",
              value: sortRef.current.value,
            })
          }
        >
          Products {renderSortIcons("damagedInfo.damagedProducts")}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "damagedInfo.damagedQty",
              value: sortRef.current.value,
            })
          }
        >
          Units {renderSortIcons("damagedInfo.damagedQty")}
        </th>
        <th
          className="text-center cursor-pointer"
          onClick={() =>
            onSort({
              key: "damagedInfo.damagedValue",
              value: sortRef.current.value,
            })
          }
        >
          Value {renderSortIcons("damagedInfo.damagedValue")}
        </th>
      </tr>
    );
  }

  const renderRow = (type, item, index) => {
    if (type === "sellableInventory") {
      return renderSellableInventory(item, index);
    }
    if (type === "nonSellableInventory") {
      return renderNonSellableInventory(item, index);
    }
    return renderAllInventory(item, index);
  };

  const renderAllInventory = (item, index) => {
    return (
      <tr key={index}>
        <td>{paginationRef.current.startSlNo + index}</td>
        <td className="align-middle">
          <button
            className="btn btn-link text-dark text-start p-0 fs-val-sm"
            onClick={() => onLinkClick(item)}
          >
            {item.name} - {item.id}
          </button>
        </td>
        <td className="text-center fw-semibold" style={tdStyle.total}>
          {item.totalProducts ? (
            <button
              className="btn btn-link text-dark p-0 fs-val-sm"
              onClick={() => onLinkClick(item)}
            >
              {item.totalProducts}
            </button>
          ) : (
            <span className="text-muted">0</span>
          )}
        </td>
        <td className="text-center">{item.totalUnits}</td>
        <td className="text-center">
          <Amount value={item.totalValue} />
        </td>

        {/* Sellable */}
        <td className="text-center fw-semibold" style={tdStyle.sellable}>
          {item.sellableProducts ? (
            <button
              className="btn btn-link text-dark p-0 fs-val-sm"
              onClick={() =>
                onLinkClick(item, {
                  inventoryStatus: SELLABLE,
                  tab: "sellableInventory",
                })
              }
            >
              {item.sellableProducts}
            </button>
          ) : (
            <span className="text-muted">0</span>
          )}
        </td>
        <td className="text-center">{item.sellableUnits}</td>
        <td className="text-center">
          <Amount value={item.sellableValue} />
        </td>

        {/* Non Sellable */}
        <td className="text-center fw-semibold" style={tdStyle.nonSellable}>
          {item.nonSellableProducts ? (
            <button
              className="btn btn-link text-dark p-0 fs-val-sm"
              onClick={() =>
                onLinkClick(item, {
                  inventoryStatus: NON_SELLABLE,
                  tab: "nonSellableInventory",
                })
              }
            >
              {item.nonSellableProducts}
            </button>
          ) : (
            <span className="text-muted">0</span>
          )}
        </td>
        <td className="text-center">{item.nonSellableUnits}</td>
        <td className="text-center">
          <Amount value={item.nonSellableValue} />
        </td>
      </tr>
    );
  };

  const renderSellableInventory = (item, index) => {
    return (
      <tr key={index}>
        <td>{paginationRef.current.startSlNo + index}</td>
        <td className="align-middle">
          <button
            className="btn btn-link text-dark text-start p-0 fs-val-sm"
            onClick={() => onLinkClick(item)}
          >
            {item.name} - {item.id}
          </button>
        </td>

        {/* Total */}
        <td className="text-center fw-semibold" style={tdStyle.total}>
          {item.totalProducts ? (
            <button
              className="btn btn-link text-dark p-0 fs-val-sm"
              onClick={() => onLinkClick(item)}
            >
              {item.totalProducts}
            </button>
          ) : (
            <span className="text-muted">0</span>
          )}
        </td>
        <td className="text-center">{item.totalUnits}</td>
        <td className="text-center">
          <Amount value={item.totalValue} />
        </td>

        {/* Fast Moving */}
        <td className="text-center fw-semibold" style={tdStyle.sellable}>
          {item.fastMovingProducts ? (
            <button
              className="btn btn-link text-dark p-0 fs-val-sm"
              onClick={() => onLinkClick(item, { stockMovement: FAST_MOVING })}
            >
              {item.fastMovingProducts}
            </button>
          ) : (
            <span className="text-muted">0</span>
          )}
        </td>
        <td className="text-center">{item.fastMovingUnits}</td>
        <td className="text-center">
          <Amount value={item.fastMovingValue} />
        </td>

        {/* Slow Moving */}
        <td className="text-center fw-semibold" style={tdStyle.slowMoving}>
          {item.slowMovingProducts ? (
            <button
              className="btn btn-link text-dark p-0 fs-val-sm"
              onClick={() => onLinkClick(item, { stockMovement: SLOW_MOVING })}
            >
              {item.slowMovingProducts}
            </button>
          ) : (
            <span className="text-muted">0</span>
          )}
        </td>
        <td className="text-center">{item.slowMovingUnits}</td>
        <td className="text-center">
          <Amount value={item.slowMovingValue} />
        </td>

        {/* Non Moving */}
        <td className="text-center fw-semibold" style={tdStyle.nonMoving}>
          {item.nonMovingProducts ? (
            <button
              className="btn btn-link text-dark p-0 fs-val-sm"
              onClick={() => onLinkClick(item, { stockMovement: NON_MOVING })}
            >
              {item.nonMovingProducts}
            </button>
          ) : (
            <span className="text-muted">0</span>
          )}
        </td>
        <td className="text-center">{item.nonMovingUnits}</td>
        <td className="text-center">
          <Amount value={item.nonMovingValue} />
        </td>

        {/* Near Expiry */}
        <td className="text-center fw-semibold" style={tdStyle.recentPurchase}>
          {item.recentlyPurchasedProducts ? (
            <button
              className="btn btn-link text-dark p-0 fs-val-sm"
              onClick={() =>
                onLinkClick(item, { stockMovement: RECENT_PURCHASE })
              }
            >
              {item.recentlyPurchasedProducts}
            </button>
          ) : (
            <span className="text-muted">0</span>
          )}
        </td>
        <td className="text-center">{item.recentlyPurchasedUnits}</td>
        <td className="text-center">
          <Amount value={item.recentlyPurchasedValue || 0} />
        </td>
      </tr>
    );
  };

  const renderNonSellableInventory = (item, index) => {
    return (
      <tr key={index}>
        <td>{paginationRef.current.startSlNo + index}</td>
        <td className="align-middle">
          <button
            className="btn btn-link text-dark text-start p-0 fs-val-sm"
            onClick={() => onLinkClick(item)}
          >
            {item.name} - {item.id}
          </button>
        </td>

        {/* Total */}
        <td className="text-center fw-semibold" style={tdStyle.total}>
          {item.totalInfo?.totalProducts ? (
            <button
              className="btn btn-link text-dark p-0 fs-val-sm"
              onClick={() => onLinkClick(item)}
            >
              {item.totalInfo?.totalProducts}
            </button>
          ) : (
            <span className="text-muted">0</span>
          )}
        </td>
        <td className="text-center">{item.totalInfo?.totalQty}</td>
        <td className="text-center">
          <Amount value={item.totalInfo?.totalValue} />
        </td>

        {/* Expired */}
        <td className="text-center fw-semibold" style={tdStyle.expired}>
          {item.expiredProducts ? (
            <button
              className="btn btn-link text-dark p-0 fs-val-sm"
              onClick={() => onLinkClick(item, { nonSellableType: "Expired" })}
            >
              {item.expiredProducts}
            </button>
          ) : (
            <span className="text-muted">0</span>
          )}
        </td>
        <td className="text-center">{item.expiredUnits}</td>
        <td className="text-center">
          <Amount value={item.expiredValue} />
        </td>

        {/* Damaged */}
        <td className="text-center fw-semibold" style={tdStyle.damaged}>
          {item.damagedProducts ? (
            <button
              className="btn btn-link text-dark p-0 fs-val-sm"
              onClick={() => onLinkClick(item, { nonSellableType: "Damaged" })}
            >
              {item.damagedProducts}
            </button>
          ) : (
            <span className="text-muted">0</span>
          )}
        </td>
        <td className="text-center">{item.damagedUnits}</td>
        <td className="text-center">
          <Amount value={item.damagedValue} />
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="table-responsive px-3 mt-3">
        <div className="row mb-2">
          <div className="col align-self-center">
            <PaginationSummary
              loadingTotalRecords={loadingTotal}
              paginationConfig={paginationRef.current}
            />
          </div>
          <div className="col-md-4 ms-auto">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search by Name"
                value={search}
                onChange={onSearch}
              />
            </div>
          </div>
        </div>

        <div
          style={tableContainerStyle}
          className="custom-scrollbar thin-scrollbar tbl-scroll-container fixed-table-width"
        >
          <table
            className="table table-hover fs-val-sm table-sm table-bordered"
            style={{ minWidth: "1200px" }}
          >
            <TableHeader
              data={getColumns(type)}
              noBorder
              isSticky
              sortCb={onSort}
              sort={sortRef.current}
            >
              {renderSubHeader(type)}
            </TableHeader>
            <tbody className="fs-val-sm">
              {loading ? (
                <TableSkeletonLoader
                  cols={type === "sellableInventory" ? 18 : 11}
                  rows={10}
                />
              ) : null}

              {!loading && !data.length ? (
                <tr>
                  <td colSpan={20}>
                    <NoDataFound>No data to display</NoDataFound>
                  </td>
                </tr>
              ) : null}

              {data.map((item, index) => renderRow(type, item, index))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="text-end mt-3 px-3">
        <PaginationBlock
          paginationCb={paginationCb}
          loadingTotalRecords={paginationRef.current.totalRecords === 0}
          paginationConfig={paginationRef.current}
          size="sm"
        />
      </div>
    </>
  );
};

export default InventoryCatalogTable;
