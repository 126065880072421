import { StoresModal } from "@sk/features";
import { DateInput, SelectInput, TextInput } from "@sk/uis";
import { endOfDay, startOfDay, sub } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const defaultValues = {
  search: "",
  franchise: [],
  status: "",
  storeName: "",
  registeredOn: [
    startOfDay(sub(new Date(), { months: 3 })),
    endOfDay(new Date()),
  ],
};

const genderOptions = [
  { label: "All", value: "" },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  // { label: "Other", value: "Other" },
];

const statusOptions = [
  { label: "All", value: "" },
  { label: "Only placed 1st Order", value: "firstOrder" },
  { label: "Only placed 2nd Order", value: "secondOrder" },
  { label: "In Active", value: "inactive" },
];

const dateConfig = {
  mode: "range",
};

const CustomerLifeCycleFilter = ({ callback, accessibleStoreIds }) => {
  const { register, getValues, setValue, control } = useForm({
    defaultValues: { ...defaultValues },
  });

  const [showStoresModal, setShowStoresModal] = useState(false);

  const onStoresModalCb = (data) => {
    if (data?.store?.length) {
      setValue("franchise", data.store[0]);
      setValue("storeName", data.store[0].name);
      callback({ formData: getValues() });
    }
    setShowStoresModal(false);
  };

  const onSearchChange = useCallback(
    debounce(() => {
      callback({ formData: getValues() });
    }, 800),
    [getValues, callback]
  );

  const onInputChange = () => {
    callback({ formData: getValues() });
  };

  const onDateChange = (changFn) => (data) => {
    changFn(data);
    onInputChange();
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <TextInput
            register={register}
            name="search"
            label="Search"
            placeholder="Search by Customer Name/ID"
            gap={0}
            callback={onSearchChange}
          />
        </div>
        <div className="col" onClick={() => setShowStoresModal(true)}>
          <TextInput
            register={register}
            name="storeName"
            label="Store"
            placeholder="Select Store"
            gap={0}
            callback={onInputChange}
            readOnly={true}
            groupContent={
              getValues("storeName") && (
                <button
                  className="btn px-1 fs-val-md text-dark"
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue("storeName", "");
                  }}
                >
                  <i className="bi bi-x"></i>
                </button>
              )
            }
          />
        </div>
        <div className="col">
          <Controller
            control={control}
            name="registeredOn"
            render={({ field }) => (
              <DateInput
                callback={onDateChange(field.onChange)}
                value={field.value}
                label="Registered On"
                gap={0}
                placeholder="Select Date Range"
                config={dateConfig}
                template={2}
                hideClose={true}
              />
            )}
          />
        </div>
        <div className="col">
          <SelectInput
            register={register}
            name="status"
            label="Status"
            options={statusOptions}
            gap={0}
            callback={onInputChange}
          />
        </div>

        <div className="col">
          <SelectInput
            register={register}
            name="gender"
            label="Gender"
            options={genderOptions}
            gap={0}
            callback={onInputChange}
          />
        </div>
      </div>
      <StoresModal
        show={showStoresModal}
        callback={onStoresModalCb}
        accessibleStoreIds={accessibleStoreIds}
        onlyAssignedStore={true}
      />
    </>
  );
};

export default CustomerLifeCycleFilter;
