import { MarketingDashboardNavComponent } from "@sk/features";
import { useIsFirstRender } from "@sk/hooks";
import { CommonService, CustomerService, DealService } from "@sk/services";
import {
  AppliedFilterLabel,
  BusyLoader,
  PageInfo,
  PaginationSummary,
  Toaster,
} from "@sk/uis";
import { set, sub } from "date-fns";
import { produce } from "immer";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FilterForm from "./components/FilterForm";
import SummaryCardBox from "./components/SummaryCardBox";
import Table from "./components/Table";
import listView from "./constantService";
import AdvanceFilterModal from "./modals/AdvanceFilterModal";
import OrderDetailsListModal from "./modals/OrderDetailsListModal";
import { subDays } from "date-fns";

const breadcrumbs = listView.breadcrumbs;
let startDate = set(subDays(new Date(), 30), {
  hours: 0,
  minutes: 0,
  seconds: 0,
});
let endDate = set(new Date(), {
  hours: 0,
  minutes: 0,
  seconds: 0,
});
const defaultFormData = {
  createdAt: [startDate, endDate],
  isClubCustomer: "",
  customer: [],
};

const defaultPageOpt = listView.pagination;

const defaultSortOpt = { key: "createdAt", value: "dec" };

const formLabelsConfig = listView.formLabels;

const defaultSummaryCardData = listView.summaryCardData;

// To Prepare params from API
const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  if (sort?.key) {
    p.sort = {
      [sort.key]: sort.value == "asc" ? 1 : -1,
    };
  }

  if (filter.status) {
    p.filter.status = filter.status;
  }

  if (filter.createdAt.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (filter.isClubCustomer) {
    p.filter.isClubCustomer = filter.isClubCustomer == "Yes";
  }

  if (filter.customer?.length) {
    p.filter._id = filter.customer[0]?.value?._id;
  }

  return p;
};

const RewardedCoinsList = () => {
  const isFirstRender = useIsFirstRender();

  const formMethods = useForm({
    defaultValues: defaultFormData,
  });

  const [franchise] = useWatch({
    control: formMethods.control,
    name: ["franchise"],
  });

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [busyLoader, setBusyLoader] = useState({ show: false, msg: "" });

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [filterLabels, setFilterLabels] = useState([]);

  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);

  const [showOrderList, setShowOrderList] = useState({ show: false, data: {} });

  const [summaryCards, setSummaryCards] = useState(() =>
    [...defaultSummaryCardData].map((x) => {
      let p = prepareFilterParams(
        defaultPageOpt,
        defaultFormData,
        defaultSortOpt
      );
      if (p.filter) {
        delete p.filter.status;
      }
      return {
        ...x,
        filterParams: { ...p.filter, ...x.defaultFilterParams },
      };
    })
  );

  const filterDataRef = useRef(defaultFormData);

  const sortRef = useRef({ ...defaultSortOpt });

  const paginationRef = useRef({ ...defaultPageOpt });

  const advanceFilterRef = useRef({});

  const navigate = useNavigate();

  const applyFilter = useCallback(
    async (resetPagination = true) => {
      if (resetPagination) {
        paginationRef.current = { ...defaultPageOpt };
      }

      //  Preparing Label Filter
      // prepareFilterLabels();

      loadSummary();

      loadList();

      loadCount();
    },
    [loadList, prepareFilterLabels, loadSummary, loadCount]
  );

  const loadCount = useCallback(async () => {
    const params = getFilterParams();
    // for total records
    setLoadingTotalRecords(true);

    const c = await getCount(params);

    // Setting Total Count
    const t =
      c.statusCode == 200 && Array.isArray(c.resp) ? c.resp[0]?.total : 0;

    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, []);

  const loadSummary = useCallback(() => {
    // setting summary card filterParams
    const params = getFilterParams();
    if (!isFirstRender) {
      setSummaryCards(
        produce((draft) => {
          draft.forEach((x) => {
            x.filterParams = { ...params.filter, ...x.defaultFilterParams };
            if (x.key == "hasOffer" || x.key == "overall") {
              delete x.filterParams.status;
            }
          });
        })
      );
    }
  }, [getFilterParams, isFirstRender]);

  const init = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const r = await getData(getFilterParams());
    if (r.statusCode !== 200) {
      Toaster.error("Failed to fetch list,Please Reload");
      setData([]);
      setLoadingData(false);
      return;
    }
    let d = Array.isArray(r.resp) ? r.resp : [];
    // const u = await CommonService.attachUserInfo(d, ["modifiedBy"]);
    setData([...d]);
    setLoadingData(false);
  }, [getFilterParams]);

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  const paginationCb = useCallback((data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = (params) => {
    return CustomerService.getCustomerCoinDashboardList(params);
  };

  const getCount = (params) => {
    let filter = params.filter;
    return CustomerService.getCustomerCoinDashboardList({
      filter,
      outputType: "count",
    });
  };

  const filterCb = useCallback(() => {
    filterDataRef.current = {
      ...formMethods.getValues(),
      ...advanceFilterRef.current,
    };
    applyFilter();
  }, [applyFilter, formMethods]);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = data;
      applyFilter();
    },
    [applyFilter]
  );

  const prepareFilterLabels = useCallback(() => {
    const v = filterDataRef.current;
    const l = CommonService.prepareAppliedFilterLabels(formLabelsConfig, v);
    setFilterLabels(l);
  }, []);

  const onAdvanceFilterClickCb = useCallback(() => {
    setShowAdvanceFilter(true);
  }, []);

  const onAdvanceFilterCb = useCallback(
    (r) => {
      const status = r.status;
      if (status == "applied" || status == "reset") {
        advanceFilterRef.current = { ...r.formData };
        filterDataRef.current = { ...formMethods.getValues(), ...r.formData };
        setShowAdvanceFilter(false);
        applyFilter();
      }
      if (status == "closed") {
        setShowAdvanceFilter(false);
      }
    },
    [applyFilter, formMethods]
  );

  const viewOrderCb = (d) => {
    setShowOrderList({ show: true, data: {} });
  };

  const orderModalCb = () => {
    setShowOrderList({ show: false, data: {} });
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Marketing Dashboard - Sk Coins"
            breadcrumbs={breadcrumbs}
            navigate={navigate}
          />
        </div>
      </div>

      <MarketingDashboardNavComponent
        activeTab="smartcoin"
        router={navigate}
        subTypeKey="rewarded"
      />

      <div className="p-3 bg-white">
        {/* SUMMARY OVERVIEW */}
        {/* <div className="row mb-2">
          {summaryCards.map((card) => (
            <div className="col-2" key={card.key}>
              <SummaryCardBox
                label={card.label}
                color={card.color}
                filterParams={card.filterParams}
                img={card.img}
              />
            </div>
          ))}
        </div> */}
        <div className="row justify-content-between">
          <div className=" col-6 mb-3">
            <FormProvider {...formMethods}>
              <FilterForm
                callback={filterCb}
                openAdvFilterModal={onAdvanceFilterClickCb}
                isDirty={formMethods.formState.isDirty}
              />
            </FormProvider>
          </div>
          <div className="col-auto"></div>
        </div>

        {filterLabels.length > 0 ? (
          <div className="mb-3">
            <AppliedFilterLabel
              labels={filterLabels}
              callback={onAdvanceFilterCb}
            />
          </div>
        ) : null}

        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />

        <Table
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
          paginationCb={paginationCb}
          sort={sortRef.current}
          sortCb={sortCb}
          viewOrderCb={viewOrderCb}
        />

        <BusyLoader show={busyLoader.show} />
      </div>

      {/* ADVANCE FILTER */}
      <AdvanceFilterModal
        show={showAdvanceFilter}
        callback={onAdvanceFilterCb}
        formData={advanceFilterRef.current}
      />

      <OrderDetailsListModal
        show={showOrderList.show}
        configId={showOrderList.data._id}
      />
    </>
  );
};

export default memo(RewardedCoinsList);
