import {
  DatePickerInput,
  EntitySearchInput,
  SelectInput,
  TextInput,
} from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

const searchTypeDropdownOptions = [
  {
    label: "Deal",
    value: "deal",
    inputLabel: "Search by Deal Name/ID ",
  },
  {
    label: "Category",
    value: "category",
    inputLabel: "Search by Category Name/ID",
  },
  { label: "Brand", value: "brand", inputLabel: "Search by Brand Name/ID" },
];

const customerOptions = [
  { label: "All", value: "" },
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const createdOnDateConfig = { mode: "range", maxDate: new Date() };

// const franchiseSearchFilter = {
//   filter: {
//     "sk_franchise_details.franchise_sub_type": {
//       $in: ["SMARTSF", "SMARTSFCOCO"],
//     },
//   },
// };

const FilterForm = ({ callback, openAdvFilterModal }) => {
  const { register, setValue, getValues, control } = useFormContext();

  const [searchType, setSearchType] = useState(searchTypeDropdownOptions[0]);

  const onSelect = useCallback(
    debounce(() => {
      callback({ formData: getValues() });
    }, 700),
    [getValues, callback]
  );

  const getSearchType = (x) => {
    setSearchType(x);
    ["brand", "category", "franchise", "deal"].forEach((x) => {
      setValue(x, "");
    });
  };

  const onSearch = useCallback(
    (chngFn) => async (val) => {
      chngFn(val);
      callback({ formData: getValues() });
    },
    [callback]
  );

  return (
    <div className="row">
      <div className="col-2">
        <SelectInput
          label="Is Club Customer"
          name="isClubCustomer"
          register={register}
          callback={onSelect}
          placeholder="Is Club Customer"
          options={customerOptions}
        />
      </div>
      {/* <div className="col-3">
        <TextInput
        label=""
          name={searchType.value}
          register={register}
          callback={onSearch}
          placeholder="Search By Config Id"
        />
      </div> */}
      <div className="col-4">
        <label className="mb-1 fs-val-md">Created On</label>
        <Controller
          name="createdAt"
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePickerInput
              placeholder="Choose"
              value={value}
              inpChange={onSearch(onChange)}
              config={createdOnDateConfig}
            />
          )}
        />
      </div>

      <div className="col-5 mb-3">
        <Controller
          control={control}
          name="customer"
          render={({ field: { onChange, value } }) => (
            <EntitySearchInput
              label="Customer"
              type="customer"
              placeholder="Search by Customer Name/ID"
              value={value}
              callback={onSearch(onChange)}
              uid="customer"
              // filterParams={franchiseSearchFilter}
              emptyLabel="No Customer found"
            />
          )}
        />
      </div>
      {/* Filter Button */}
      {/* <div className="col-auto">
        <button
          className="btn app-filter-btn"
          type="button"
          onClick={openAdvFilterModal}
        >
          <i className="bi bi-funnel"></i> FILTER
        </button>
      </div> */}
    </div>
  );
};

export default memo(FilterForm, (prev, next) => {
  return prev.isDirty === next.isDirty;
});
