import { AppCard, InfoPopover, Tabs } from "@sk/uis";
import { useState, useEffect } from "react";
import InventoryCatalogTable from "./InventoryCatalogTable";
import { VALUE_CALCULATION } from "../constant";

const InventoryAgeingCatalog = ({ filterData, callback, type }) => {
  const [activeTab, setActiveTab] = useState({ key: "menu" });
  const [popoverContent, setPopoverContent] = useState("");

  const tabsData = [
    { key: "menu", tabName: "Menu" },
    { key: "category", tabName: "Category" },
    { key: "brand", tabName: "Brand" },
  ];

  const handleTabChange = ({ value }) => {
    setActiveTab(value);
  };

  useEffect(() => {
    switch (activeTab.key) {
      case "menu":
        setPopoverContent(
          <div>
            <div className="d-flex">
              <i className="bi bi-dot"></i>
              <span>Menu wise inventory details</span>
            </div>
            <div className="d-flex">
              <i className="bi bi-dot"></i>
              <span>
                The value is calculated based on{" "}
                <strong> &apos;{VALUE_CALCULATION}&apos; </strong>.
              </span>
            </div>
          </div>
        );
        break;
      case "category":
        setPopoverContent(
          <div>
            <div className="d-flex">
              <i className="bi bi-dot"></i>
              <span>Category wise inventory details</span>
            </div>
            <div className="d-flex">
              <i className="bi bi-dot"></i>
              <span>
                The value is calculated based on{" "}
                <strong> &apos;B2B Price x Qty&apos; </strong>.
              </span>
            </div>
          </div>
        );
        break;
      case "brand":
        setPopoverContent(
          <div>
            <div className="d-flex">
              <i className="bi bi-dot"></i>
              <span>Brand wise inventory details</span>
            </div>
            <div className="d-flex">
              <i className="bi bi-dot"></i>
              <span>
                The value is calculated based on{" "}
                <strong> {VALUE_CALCULATION} </strong>.
              </span>
            </div>
          </div>
        );
        break;
      default:
        setPopoverContent("");
    }
  }, [activeTab]);

  return (
    <AppCard noPad={true}>
      <div className="border-bottom d-flex justify-content-between align-items-center">
        <div>
          <Tabs
            data={tabsData}
            callback={handleTabChange}
            activeTab={activeTab}
            template={4}
          />
        </div>
        <div className="pe-2">
          <InfoPopover content={popoverContent} />
        </div>
      </div>
      <InventoryCatalogTable
        filterData={filterData}
        callback={callback}
        type={type}
        catalogType={activeTab.key}
      />
    </AppCard>
  );
};

export default InventoryAgeingCatalog;
