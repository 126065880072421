import { appConfigs, DashboardService } from "@sk/services";
import { SummaryCard } from "@sk/uis";
import { useEffect, useRef, useState } from "react";

const StockNotAllocatedNoPoSummary = ({ filterData }) => {
  const filterRef = useRef({});
  const [summary, setSummary] = useState({
    pIdCount: 0,
    pendingOrderQty: 0,
    pendingOrderValue: 0,
  });
  const [loading, setLoading] = useState(true);

  const fetchSummary = async (abortSignal) => {
    setLoading(true);
    try {
      const response = await DashboardService.fetchStockAnalytics(
        DashboardService.prepareStockNotAllocatedSummaryParams(
          filterRef.current || {},
          "getUnallocatedStockSummaryWithoutPO"
        ),
        { signal: abortSignal }
      );

      if (response.resp?.raw?.code === appConfigs.AJAX_REQ_CANCEL) {
        return;
      }

      setSummary(
        Array.isArray(response.resp) && response.resp.length
          ? {
              pIdCount: response.resp[0].pIdCount,
              pendingOrderQty: response.resp[0].pendingOrderQty,
              pendingOrderValue: response.resp[0].pendingOrderValue,
            }
          : {
              pIdCount: 0,
              pendingOrderQty: 0,
              pendingOrderValue: 0,
            }
      );
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted:", error);
      } else {
        console.error("Error fetching summary:", error);
        setSummary({
          pIdCount: 0,
          pendingOrderQty: 0,
          pendingOrderValue: 0,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    filterRef.current = filterData;
    fetchSummary(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [filterData]);

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <SummaryCard
            template={1}
            title="Total Products"
            value={summary.pIdCount || 0}
            loading={loading}
            isValueAmt={false}
            valueColor="primary"
          />
        </div>
        <div className="col-md-4">
          <SummaryCard
            template={1}
            title="Total Quantity"
            value={summary.pendingOrderQty || 0}
            loading={loading}
            isValueAmt={false}
            valueColor="success"
          />
        </div>
        <div className="col-md-4">
          <SummaryCard
            template={1}
            title="Total Value"
            value={summary.pendingOrderValue || 0}
            loading={loading}
            isValueAmt={true}
            valueColor="danger"
          />
        </div>
      </div>
    </>
  );
};

export default StockNotAllocatedNoPoSummary;
