import { CoinsDashService } from "@sk/services";
import { AppCard, ChartRender, TableHeader } from "@sk/uis";
import { endOfMonth, format, set, startOfMonth, sub } from "date-fns";
import { produce } from "immer";
import { useEffect, useRef, useState } from "react";
import CoinsDashAppliedFilter from "./CoinsDashAppliedFilter";

const cstmStyleBlk = { height: "280px", overflowY: "auto" };

const lightBgColors = {
  newCustomer: "#F0FFF4", // Light green for new customers
  returningCustomer: "#E6FFFA", // Slightly different green for returning customers
  orderNew: "#EBF8FF", // Light blue for new customer orders
  orderReturning: "#DCEEFB", // Slightly different blue for returning customer orders
  totalCustomers: "#FFF5F7", // Light pink for total customers
  totalOrders: "#FEEBC8", // Light orange for total orders
  header: "#BEE3F8", // Light blue for header row
};

const tableHeaders = [
  {
    label: "Month", // Month name
  },
  {
    label: "Customers", // Number of new customers for the month
    colSpan: 3,
    isCentered: true,
  },
  {
    label: "Orders", // Number of orders placed by new customers
    colSpan: 3,
    isCentered: true,
  },
];

const CoinsDashMonthlyCustomer = ({
  cid,
  storeId,
  storeName,
  accessibleStoreIds,
}) => {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  const [chartConfig, setChartConfig] = useState({
    data: [],
    options: {
      responsive: true,
      plugins: {
        title: {
          display: false,
          text: "Customer Orders Growth",
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Month",
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Count",
          },
        },
      },
    },
  });

  const filterDataRef = useRef({});

  useEffect(() => {
    filterDataRef.current = { cid, storeId, accessibleStoreIds };
    init();
  }, [cid, storeId, accessibleStoreIds]);

  const init = async () => {
    const f = filterDataRef.current;
    let p = {
      filter: {
        createdAt: {
          $gte: set(sub(startOfMonth(new Date()), { months: 5 }), {
            hours: 0,
            minutes: 0,
            seconds: 0,
          }),
          $lte: set(endOfMonth(new Date()), {
            hours: 23,
            minutes: 59,
            seconds: 59,
          }),
        },
      },
    };

    // if (f.cid) {
    //   p.filter.ownerId = f.cid;
    // }

    if (f.storeId) {
      p.filter["franchiseInfo.id"] = f.storeId;
    } else {
      p.filter["franchiseInfo.id"] = {
        $in: f.accessibleStoreIds,
      };
    }

    setLoading(true);
    const r = await CoinsDashService.getMonthlyCustomerGrowth(p);

    const transformedData = (Array.isArray(r.resp) ? r.resp : []).map(
      (item) => {
        // Create an object to hold the new structure
        let newCustomer = {};
        let returningCustomer = {};

        // Loop over customersByType to assign values based on type
        item.customersByType.forEach((customer) => {
          if (customer.type === "New Customer") {
            newCustomer = {
              totalCustomers: customer.totalCustomers,
              totalOrders: customer.totalOrders,
            };
          } else if (customer.type === "Returning Customer") {
            returningCustomer = {
              totalCustomers: customer.totalCustomers,
              totalOrders: customer.totalOrders,
            };
          }
        });

        // Calculate total customers (new + returning)
        const totalCustomers =
          (newCustomer.totalCustomers || 0) +
          (returningCustomer.totalCustomers || 0);

        // Return the transformed object for each month
        return {
          monthLbl: format(new Date(item.month), "yyyy MMM"),
          month: item.month,
          newCustomer,
          returningCustomer,
          totalCustomers, // Add total customers to the result
          totalOrders: item.totalOrders, // Use the total orders from the original data
        };
      }
    );

    setChartConfig(
      produce((draft) => {
        const sortedData = [...transformedData].sort(
          (a, b) => new Date(a.month) - new Date(b.month)
        );
        const months = sortedData.map((item) => item.monthLbl);
        const newCustomerOrdersPlaced = sortedData.map(
          (item) => item.newCustomer.totalOrders
        );
        const oldCustomerOrdersPlaced = sortedData.map(
          (item) => item.returningCustomer.totalOrders
        );
        const newCustomerNotPlacedOrder = sortedData.map(
          (item) =>
            item.newCustomer.totalCustomers - item.newCustomer.totalOrders
        );
        const totalOrders = sortedData.map((item) => item.totalOrders);

        draft.data = {
          labels: months, // X-axis labels (months)
          datasets: [
            {
              label: "New Customer Orders Placed",
              data: newCustomerOrdersPlaced,
              borderColor: "rgba(75, 192, 192, 1)",
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              fill: true,
            },
            {
              label: "Old Customer Orders Placed",
              data: oldCustomerOrdersPlaced,
              borderColor: "rgba(153, 102, 255, 1)",
              backgroundColor: "rgba(153, 102, 255, 0.2)",
              fill: true,
            },
            // {
            //   label: "New Customer Not Placed Order",
            //   data: newCustomerNotPlacedOrder,
            //   borderColor: "rgba(255, 159, 64, 1)",
            //   backgroundColor: "rgba(255, 159, 64, 0.2)",
            //   fill: true,
            // },
            {
              label: "Total Orders",
              data: totalOrders,
              borderColor: "rgba(54, 162, 235, 1)",
              backgroundColor: "rgba(54, 162, 235, 0.2)",
              fill: true,
            },
          ],
        };
      })
    );

    setData(transformedData);
    setLoading(false);
  };

  return (
    <AppCard title="Monthly Customer Growth (Last 6 months)">
      <div className="mb-2">
        <CoinsDashAppliedFilter storeName={storeName} />
      </div>
      <div className="row">
        <div className="col-6">
          <div className="custom-scrollbar" style={cstmStyleBlk}>
            <table className="table table-sm">
              <TableHeader data={tableHeaders} />
              <tbody className="fs-val-md">
                {/* Header Row */}
                <tr style={{ background: lightBgColors.header }}>
                  <td style={{ fontWeight: "bold" }}></td>
                  <td className="text-center fw-semibold">New Customers</td>
                  <td className="text-center fw-semibold">Old Customers</td>
                  <td className="text-center fw-semibold">Total</td>
                  <td className="text-center fw-semibold">New Customers</td>
                  <td className="text-center fw-semibold">Old Customers</td>
                  <td className="text-center fw-semibold">Total</td>
                </tr>

                {/* Data Rows */}
                {data.map((e, k) => (
                  <tr key={k}>
                    {/* Month */}
                    <td>{e.monthLbl}</td>

                    {/* New Customers */}
                    <td
                      className="text-center"
                      style={{ background: lightBgColors.newCustomer }}
                    >
                      {e.newCustomer.totalCustomers || 0}
                    </td>

                    {/* Returning Customers */}
                    <td
                      className="text-center"
                      style={{ background: lightBgColors.returningCustomer }}
                    >
                      {e.returningCustomer.totalCustomers || 0}
                    </td>

                    {/* Total Customers (New + Returning) */}
                    <td
                      className="text-center"
                      style={{ background: lightBgColors.totalCustomers }}
                    >
                      {e.totalCustomers}
                    </td>

                    {/* Orders by New Customers */}
                    <td
                      className="text-center"
                      style={{ background: lightBgColors.orderNew }}
                    >
                      {e.newCustomer.totalOrders || 0}
                    </td>

                    {/* Orders by Returning Customers */}
                    <td
                      className="text-center"
                      style={{ background: lightBgColors.orderReturning }}
                    >
                      {e.returningCustomer.totalOrders || 0}
                    </td>

                    {/* Total Orders */}
                    <td
                      className="text-center"
                      style={{ background: lightBgColors.totalOrders }}
                    >
                      {e.totalOrders}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-6">
          {chartConfig.data?.datasets?.length > 0 ? (
            <div style={{ height: "300px" }}>
              <ChartRender
                data={chartConfig.data}
                type="line"
                options={chartConfig.options}
              />
            </div>
          ) : null}
        </div>
      </div>
    </AppCard>
  );
};

export default CoinsDashMonthlyCustomer;
