import { DataRefreshNote, StoreCard } from "@sk/features"; // Import StoreCard
import { useFetchUrlQueryString } from "@sk/hooks";
import { DashboardService } from "@sk/services";
import { AppCard, PageInfo, PageLoader, Tabs, BusyLoader } from "@sk/uis";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import InventoryAgeingCatalog from "./components/InventoryAgeingCatalog";
import InventoryAgeingFilter from "./components/InventoryAgeingFilter";
import InventoryAgeingMovementSummary from "./components/InventoryAgeingMovementSummary";
import InventoryAgeingNearExpirySummary from "./components/InventoryAgeingNearExpirySummary";
import InventoryAgeingNonSellableSummary from "./components/InventoryAgeingNonSellableSummary";
import InventoryAgeingNonSellableTypeSummary from "./components/InventoryAgeingNonSellableTypeSummary";
import InventoryAgeingSellableSummary from "./components/InventoryAgeingSellableSummary";
import InventoryAgeingSummary from "./components/InventoryAgeingSummary";
import InventoryAgeingSummaryTable from "./components/InventoryAgeingSummaryTable";
import { defaultFilterData, invAgeingInventoryStatusOptions } from "./constant";

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Inventory Analysis",
    link: "/dashboard/inventory-ageing",
  },
];

const appliedFilterStyle = {
  top: "60px", // Adjusted top value
  zIndex: 500,
  position: "sticky",
};

const tabs = [
  { tabName: "All Inventory", key: "allInventory" },
  { tabName: "Sellable Inventory", key: "sellableInventory" },
  { tabName: "Non-Sellable Inventory", key: "nonSellableInventory" },
];

const getTitle = (activeTabKey, queryParams) => {
  const prefix = queryParams?.fid ? "Store " : "";
  switch (activeTabKey) {
    case "sellableInventory":
      return `${prefix}Sellable Inventory Analysis`;
    case "nonSellableInventory":
      return `${prefix}Non-Sellable Inventory Analysis`;
    default:
      return `${prefix}Inventory Analysis`;
  }
};

const getBatchId = async (isStoreWise = false) => {
  const res = await DashboardService.getInventoryAgeingBatchId(
    isStoreWise ? { outputType: "storeInventoryAgening" } : {}
  );
  return {
    batchId: res?.resp?.batchId,
    batchDate: res?.resp?.batchTimestamp,
  };
};

const InventoryAgeing = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryParams = useFetchUrlQueryString(searchParams);

  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState({ ...defaultFilterData });
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const [busyLoader, setBusyLoader] = useState({ show: false });

  useEffect(() => {
    const fetchBatchId = async () => {
      setLoading(true);
      try {
        const res = await getBatchId(queryParams?.fid ? true : false);
        setFilterData(
          produce((draft) => {
            draft.batchId = res?.batchId;
            draft.batchDate = res?.batchDate;
            draft.view = queryParams?.view || "";

            if (queryParams?.fid) {
              draft.store = [
                {
                  label: queryParams?.fName,
                  value: { _id: queryParams?.fid, name: queryParams?.fName },
                },
              ];
              draft.warehouse = "";
            }
          })
        );
      } catch (error) {
        console.error("Error fetching batch details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBatchId();
  }, [queryParams?.fid, queryParams?.fName, queryParams?.view]);

  const handleFilterChange = async (data) => {
    if (data.action === "apply") {
      let newBatch = {
        batchId: filterData?.batchId,
        batchDate: filterData?.batchDate,
      };

      // If store is selected, then fetch new batch id
      if (data.formData.store?.length) {
        setBusyLoader({ show: true });
        const res = await getBatchId(true);
        newBatch = {
          batchId: res?.batchId,
          batchDate: res?.batchDate,
        };
        setBusyLoader({ show: false });
      } else {
        setBusyLoader({ show: true });
        const res = await getBatchId();
        newBatch = {
          batchId: res?.batchId,
          batchDate: res?.batchDate,
        };
        setBusyLoader({ show: false });
      }

      setFilterData(
        produce((draft) => ({
          ...draft,
          ...data.formData,
          batchId: newBatch?.batchId,
          batchDate: newBatch?.batchDate,
        }))
      );
    } else if (data.action === "remove") {
      setFilterData(
        produce((draft) => {
          draft[data.formData.key] = defaultFilterData[data.formData.key];
        })
      );
    }
  };

  const onTabCb = ({ value }) => {
    // Set default filter data
    let defaultData = { ...defaultFilterData };

    if (filterData?.store?.length) {
      defaultData.store = filterData?.store;
      defaultData.warehouse = "";
    }

    if (value.key === "sellableInventory") {
      defaultData.inventoryStatus = invAgeingInventoryStatusOptions.find(
        (x) => x.key === "sellable"
      ).value;
    } else if (value.key === "nonSellableInventory") {
      defaultData.inventoryStatus = invAgeingInventoryStatusOptions.find(
        (x) => x.key === "nonSellable"
      ).value;
    } else {
      defaultData.inventoryStatus = "";
    }

    setFilterData(produce((draft) => ({ ...draft, ...defaultData })));
    setActiveTab(value);
  };

  const catalogCallback = ({ action, data, catalogType }) => {
    if (action === "nameClick") {
      setFilterData(
        produce((draft) => {
          if (catalogType === "menu") {
            draft.menu = [
              { label: data.name, value: { _id: data.id, name: data.name } },
            ];
          } else if (catalogType === "category") {
            draft.category = [
              { label: data.name, value: { _id: data.id, name: data.name } },
            ];
          } else if (catalogType === "brand") {
            draft.brand = [
              { label: data.name, value: { _id: data.id, name: data.name } },
            ];
          }
        })
      );
    }
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            breadcrumbs={breadcrumbs}
            title={getTitle(activeTab.key, queryParams)}
          />
        </div>
        <div className="col-auto fs-val-sm">
          <DataRefreshNote
            lastSyncDate={filterData.batchDate}
            refreshTime={15}
          />
        </div>
      </div>

      {queryParams?.fid ? (
        <StoreCard
          fid={queryParams?.fid}
          navigate={navigate}
          rbacKey={
            queryParams?.fid && queryParams?.view == "store"
              ? "InventoryAgeingStoreDashboard"
              : "InventoryAgeingDashboard"
          }
        />
      ) : null}

      {loading ? (
        <PageLoader />
      ) : (
        <>
          <div className="mb-3">
            <Tabs
              data={tabs}
              callback={onTabCb}
              activeTab={activeTab}
              template={4}
              shadow={true}
            />
          </div>

          <div style={appliedFilterStyle}>
            <InventoryAgeingFilter
              filterData={filterData}
              callback={handleFilterChange}
              viewType={activeTab.key}
              hideWarehouseInput={queryParams?.fid}
              hideStore={queryParams?.fid}
            />
          </div>

          {activeTab.key == "allInventory" ? (
            <InventoryAgeingSummary
              filterData={filterData}
              type={activeTab.key}
            />
          ) : null}

          {activeTab.key === "sellableInventory" ? (
            <>
              <AppCard>
                <div className="row g-3 align-items-center">
                  <div className="col-5">
                    <InventoryAgeingSellableSummary filterData={filterData} />
                  </div>
                  <div className="col-7">
                    <InventoryAgeingMovementSummary filterData={filterData} />
                  </div>
                </div>
              </AppCard>
              <InventoryAgeingNearExpirySummary filterData={filterData} />
            </>
          ) : null}

          {activeTab.key === "nonSellableInventory" ? (
            <>
              <AppCard>
                <div className="row g-3 align-items-center">
                  <div className="col-5">
                    <InventoryAgeingNonSellableSummary
                      filterData={filterData}
                    />
                  </div>
                  <div className="col-7">
                    <InventoryAgeingNonSellableTypeSummary
                      filterData={filterData}
                    />
                  </div>
                </div>
              </AppCard>
            </>
          ) : null}

          <InventoryAgeingSummaryTable
            filterData={filterData}
            type={activeTab.key}
          />
          <InventoryAgeingCatalog
            filterData={filterData}
            type={activeTab.key}
            callback={catalogCallback}
          />
        </>
      )}

      <BusyLoader show={busyLoader.show} />
    </>
  );
};

export default InventoryAgeing;
