import { AuthService } from "@sk/services";

export const FAST_MOVING = "FastMoving";
export const SLOW_MOVING = "SlowMoving";
export const NON_MOVING = "NonMoving";
export const NEAR_EXPIRY = "NearExpiry";
export const NON_SELLABLE = "NonSellable";
export const RECENT_PURCHASE = "RecentPurchase";
export const SELLABLE = "Sellable";
export const EXPIRED = "Expired";
export const DAMAGED = "Damaged";
export const OTHERS = "Others";
export const EXPIRY = "Expiry";
export const DAMAGE = "Damage";

export const AGEING_1_45 = "1-45days";
export const AGEING_46_90 = "46-90days";
export const AGEING_91_120 = "91-120days";
export const AGEING_121_180 = "121-180days";
export const AGEING_180 = ">180days";

export const VALUE_CALCULATION = "B2B Price x Qty";

export const defaultFilterData = {
  warehouse: AuthService.getDefaultWhId(),
  store: [],
  inventoryStatus: "",
  stockMovement: "",
  health: "",
  ageingSlab: "",
  nonSellableType: "",
  menu: [],
  category: [],
  brand: [],
  companyName: "",
};

export const invAgeingInventoryStatusOptions = [
  { label: "All", value: "" },
  { label: "Sellable", value: SELLABLE, key: "sellable" },
  { label: "Non-Sellable", value: NON_SELLABLE, key: "nonSellable" },
];

export const invAgeingStockMovementOptions = [
  { label: "All", value: "" },
  { label: "Fast Moving", value: FAST_MOVING },
  { label: "Slow Moving", value: SLOW_MOVING },
  { label: "Non-Moving", value: NON_MOVING },
  { label: "Near Expiry", value: NEAR_EXPIRY },
  { label: "Recently Purchased", value: RECENT_PURCHASE },
];

export const invAgeingNonSellableTypeOptions = [
  { label: "All", value: "" },
  { label: "Damage", value: "damaged" },
  { label: "Expiry", value: "expired" },
];

export const invAgeingHealthOptions = [
  { label: "All", value: "" },
  { label: "Clearance", value: "clearance" },
  { label: "Push", value: "push" },
  { label: "Monitor", value: "monitor" },
  { label: "Healthy", value: "healthy" },
  { label: "Obsolete", value: "obsolete" },
];

export const invAgeingSlabOptions = [
  { label: "All", value: "" },
  { label: "1-45 days", value: AGEING_1_45 },
  { label: "46-90 days", value: AGEING_46_90 },
  { label: "91-120 days", value: AGEING_91_120 },
  { label: "121-180 days", value: AGEING_121_180 },
  { label: ">180 days", value: AGEING_180 },
  { label: "Expired", value: "Expired" },
];

export const tdStyle = {
  total: {
    backgroundColor: "rgb(243 249 255)",
    backgroundOpacity: 0.1,
  },
  sellable: {
    backgroundColor: "rgb(248 255 248)",
    backgroundOpacity: 0.1,
  },
  nonSellable: {
    backgroundColor: "rgb(255 248 248)", // Lighter red
    backgroundOpacity: 0.1,
  },
  fastMoving: {
    backgroundColor: "rgba(144, 238, 144, 0.1)", // Light green
  },
  slowMoving: {
    backgroundColor: "rgba(255, 255, 224, 0.1)", // Light yellow
  },
  nonMoving: {
    backgroundColor: "rgba(255, 182, 193, 0.1)", // Light pink
  },
  nearExpiry: {
    backgroundColor: "rgba(255, 228, 196, 0.1)", // Light peach
  },
  qty: {
    backgroundColor: "rgba(144, 238, 144, 0.1)", // Light peach
  },
  expiryDate: {
    backgroundColor: "rgba(255, 182, 193, 0.1)",
  },
  expired: {
    backgroundColor: "rgb(255 239 240)", // Lighter light red
  },
  damaged: {
    backgroundColor: "#fff9e6", // Lighter yellow
  },
  recentPurchase: {
    backgroundColor: "#f0faff", // Even lighter blue
  },
};

export const movementStatusDescriptions = {
  [FAST_MOVING]: "Sold in last 30 days and Purchased in last 30 days.",
  [SLOW_MOVING]: "Sold in last 60 days but not in last 30 days.",
  [NON_MOVING]: "Not sold in last 60 days.",
  [RECENT_PURCHASE]: "Not sold in last 60 days but purchased in last 30 days.",
  [EXPIRY]: "Products that have passed their expiration date.",
  [DAMAGE]: "Products that are damaged and cannot be sold.",
};

export const inputConfig = {
  allInventoryProducts: [
    {
      type: "text",
      name: "productSearch",
      label: "Product",
      placeholder: "Search Product",
      colSize: "col",
    },
    {
      type: "select",
      name: "ageingSlab",
      label: "Shelf Life Days",
      options: invAgeingSlabOptions,
      colSize: "col-auto",
    },
    {
      type: "select",
      name: "stockMovement",
      label: "Stock Movement",
      options: invAgeingStockMovementOptions,
      colSize: "col-auto",
    },
    {
      type: "select",
      name: "nonSellableType",
      label: "Non Sellable Location",
      options: invAgeingNonSellableTypeOptions,
      colSize: "col-auto",
    },
  ],
  sellableInventoryProducts: [
    {
      type: "text",
      name: "productSearch",
      label: "Product",
      placeholder: "Search Product",
      colSize: "col",
    },
    {
      type: "select",
      name: "ageingSlab",
      label: "Shelf Life Days",
      options: invAgeingSlabOptions,
      colSize: "col",
    },
    {
      type: "select",
      name: "stockMovement",
      label: "Stock Movement",
      options: invAgeingStockMovementOptions,
      colSize: "col",
    },
  ],
  nonSellableInventoryProducts: [
    {
      type: "text",
      name: "productSearch",
      label: "Product",
      placeholder: "Search Product",
      colSize: "col",
    },
    {
      type: "select",
      name: "ageingSlab",
      label: "Shelf Life Days",
      options: invAgeingSlabOptions,
      colSize: "col",
    },
    {
      type: "select",
      name: "nonSellableType",
      label: "Non Sellable Location",
      options: invAgeingNonSellableTypeOptions,
      colSize: "col",
    },
  ],
  allInventory: [
    {
      type: "select",
      name: "ageingSlab",
      label: "Shelf Life Days",
      options: invAgeingSlabOptions,
      colSize: "col-3",
    },
  ],
  sellableInventory: [
    {
      type: "select",
      name: "ageingSlab",
      label: "Shelf Life Days",
      options: invAgeingSlabOptions,
      colSize: "col-2",
    },
    {
      type: "select",
      name: "stockMovement",
      label: "Stock Movement",
      options: invAgeingStockMovementOptions,
      colSize: "col-2",
    },
  ],
  nonSellableInventory: [
    {
      type: "select",
      name: "ageingSlab",
      label: "Shelf Life Days",
      options: invAgeingSlabOptions,
      colSize: "col-2",
    },
    {
      type: "select",
      name: "nonSellableType",
      label: "Non Sellable Location",
      options: invAgeingNonSellableTypeOptions,
      colSize: "col-2",
    },
  ],
};
