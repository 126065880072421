import { CoinsDashService, NavService } from "@sk/services";
import {
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { set } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import CoinsDashAppliedFilter from "./CoinsDashAppliedFilter";

const tableHeaders = [
  {
    label: "Sl No",
    width: "5%",
  },
  {
    label: "Store Details",
    enableSort: true,
    key: "franchiseName", // Key for sorting
    width: "25%",
  },
  {
    label: "Total Clubs",
    width: "8%",
    template: () => (
      <div>
        <div>Total</div>
        <div>Clubs</div>
      </div>
    ),
    hasCustomTemplate: true,
    enableSort: true,
    key: "totalOwners",
  },
  {
    label: "Total Members",
    width: "8%",
    template: () => (
      <div>
        <div>Total</div>
        <div>Members</div>
      </div>
    ),
    hasCustomTemplate: true,
    enableSort: true,
    key: "totalMembersCount",
  },
  {
    label: "Coins Rewarded",
    enableSort: true,
    key: "totalCoinsEarned", // Key for sorting
    template: () => (
      <div>
        <div>Coins</div>
        <div>Rewarded</div>
      </div>
    ),
    hasCustomTemplate: true,
  },
  {
    label: "Coins Redeemed",
    enableSort: true,
    key: "totalCoinsRedeemed", // Key for sorting
    template: () => (
      <div>
        <div>Coins</div>
        <div>Redeemed</div>
      </div>
    ),
    hasCustomTemplate: true,
  },
  {
    label: "New Customer Order Count",
    enableSort: true,
    key: "totalNewCustomerOrderCount", // Key for sorting
    width: "10%",
    template: () => (
      <div>
        <div>New Customer</div>
        <div>Order Count</div>
      </div>
    ),
    hasCustomTemplate: true,
  },
  {
    label: "Old Customer Order Count",
    enableSort: true,
    key: "totalOldCustomerOrderCount", // Key for sorting
    width: "10%",
    template: () => (
      <div>
        <div>Old Customer</div>
        <div>Order Count</div>
      </div>
    ),
    hasCustomTemplate: true,
  },
  {
    label: "Total Customer",
    enableSort: true,
    key: "uniqueCustomerCount", // Key for sorting
    width: "8%",
    template: () => (
      <div>
        <div>Total</div>
        <div>Customer</div>
      </div>
    ),
    hasCustomTemplate: true,
  },
  {
    label: "Total Orders",
    enableSort: true,
    key: "totalOrders", // Key for sorting
    width: "8%",
    template: () => (
      <div>
        <div>Total</div>
        <div>Orders</div>
      </div>
    ),
    hasCustomTemplate: true,
  },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 30,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 30,
};

const getData = async (params) => {
  const r = await CoinsDashService.getStoreWise(params);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const getCount = async (params) => {
  let p = { ...params, outputType: "count" };

  delete p.page;
  delete p.count;

  const r = await CoinsDashService.getStoreWise(p);
  let count = 0;
  if (Array.isArray(r.resp) && r.resp.length > 0) {
    count = r.resp[0].total;
  }
  return { count };
};

const prepareFilterParams = (
  { fromDate, toDate, cid, storeId, accessibleStoreIds },
  pagination,
  sort = {}
) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {},
  };

  if (fromDate && toDate) {
    p.filter.createdAt = {
      $gte: set(new Date(fromDate), {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(new Date(toDate), {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (sort?.key) {
    p.sort = { [sort.key]: sort.value == "asc" ? 1 : -1 };
  }

  if (storeId) {
    p.filter["franchiseInfo.id"] = storeId;
  } else {
    p.filter["franchiseInfo.id"] = {
      $in: accessibleStoreIds,
    };
  }

  if (cid) {
    p.filter.ownerId = cid;
  }
  return p;
};

const CoinsDashStoreList = ({
  fromDate,
  toDate,
  cid,
  cname,
  storeId,
  storeName,
  callback,
  accessibleStoreIds,
}) => {
  const [loading, setLoading] = useState(true);

  const [ttlRecords, setTtlRecords] = useState({
    loading: true,
    value: 0,
  });

  const [data, setData] = useState([]);

  const filterDataRef = useRef({});

  const paginationConfigRef = useRef({ ...defaultPaginationConfig });

  const sortRef = useRef({});

  useEffect(() => {
    filterDataRef.current = {
      fromDate,
      toDate,
      cid,
      storeId,
      storeName,
      accessibleStoreIds,
    };
    init();
  }, [cid, fromDate, init, storeId, storeName, toDate, accessibleStoreIds]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(async () => {
    paginationConfigRef.current = {
      ...defaultPaginationConfig,
    };
    loadList();
    setTtlRecords({ loading: true, value: 0 });
    const t = await getCount(prepareFilterParams(filterDataRef.current));
    paginationConfigRef.current.totalRecords = t.count;
    setTtlRecords({ loading: false, value: t.count });
  }, []);

  const loadList = async () => {
    setLoading(true);
    const p = prepareFilterParams(
      filterDataRef.current,
      paginationConfigRef.current,
      sortRef.current
    );
    const r = await getData(p);
    setData(r.data);
    setLoading(false);
  };

  const paginationCb = (data) => {
    paginationConfigRef.current.startSlNo = data.startSlNo;
    paginationConfigRef.current.endSlNo = data.endSlNo;
    paginationConfigRef.current.activePage = data.activePage;
    loadList();
  };

  const sortCb = (e) => {
    sortRef.current = e;
    paginationConfigRef.current.activePage = 1;
    loadList();
  };

  const viewStore = (id) => {
    NavService.openInNewTab("/pos/sales-analytics", {
      fid: id,
    });
  };

  return (
    <div>
      <div className="mb-2 row align-items-center g-0">
        <div className="col-auto">
          <PaginationSummary
            loadingTotalRecords={ttlRecords.loading}
            paginationConfig={paginationConfigRef.current}
            className="me-4"
          />
        </div>
        <div className="col-auto">
          <CoinsDashAppliedFilter
            cname={cname}
            storeName={storeName}
            fromDate={fromDate}
            toDate={toDate}
          />
        </div>
        {/* <div className="col-4 ms-auto">
          <Controller
            name="customer"
            control={control}
            render={({ field: { onChange, value } }) => (
              <EntitySearchInput
                type="customer"
                label=""
                placeholder="Customer Name/Mobile Number"
                callback={onCustSearch(onChange)}
                value={value}
                gap={0}
                uid="customer"
              />
            )}
          />
        </div> */}
      </div>

      <table className="table table-hover table-sm table-striped">
        <TableHeader
          data={tableHeaders}
          sortCb={sortCb}
          sort={sortRef.current}
          noBg={true}
        />
        <tbody className="fs-val-md">
          {!loading && !data.length ? (
            <tr>
              <td colSpan={tableHeaders.length}>
                <NoDataFound>No data to display</NoDataFound>
              </td>
            </tr>
          ) : null}

          {loading ? (
            <TableSkeletonLoader
              rows={10}
              height={30}
              cols={tableHeaders.length}
            />
          ) : null}

          {data.map((c, index) => (
            <tr key={c.customerId}>
              <td>{paginationConfigRef.current.startSlNo + index}</td>
              <td>
                <button
                  className="btn btn-link p-0 fs-val-md text-dark"
                  onClick={() => viewStore(c.franchiseId)}
                >
                  {c.franchiseName}
                </button>
                <div className="fs-val-sm text-muted">
                  {c.town}, {c.district}, {c.state} - {c.pincode}
                </div>
              </td>
              <td>{c.totalOwners || 0}</td>
              <td>{c.totalMembersCount || 0}</td>
              <td className="text-success">
                {(c.totalCoinsEarned || 0).toLocaleString()}
              </td>
              <td className="text-danger">
                {(c.totalCoinsRedeemed || 0).toLocaleString()}
              </td>
              <td>{c.totalNewCustomerOrderCount || 0}</td>
              <td>{c.totalOldCustomerOrderCount || 0}</td>
              <td>{c.uniqueCustomerCount || 0}</td>
              <td>{c.totalOrders || 0}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationConfigRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={ttlRecords.loading}
      />
    </div>
  );
};

export default CoinsDashStoreList;
