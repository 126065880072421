import { CommonService } from "@sk/services";
import { AppliedFilterLabel } from "@sk/uis";

const filterLabels = {
  inventoryStatus: {
    label: "Inventory Status",
    canRemove: true,
    resetVal: "",
  },
  stockMovement: {
    label: "Stock Movement",
    canRemove: true,
    resetVal: "",
  },
  health: {
    label: "Health",
    canRemove: true,
    resetVal: "",
  },
  ageingSlab: {
    label: "Ageing Slab",
    canRemove: true,
    resetVal: "",
  },
  warehouse: {
    label: "Warehouse",
  },
  product: {
    label: "Product",
    valuePath: "[0].label",
    canRemove: true,
    resetVal: [],
  },
  menu: {
    label: "Menu",
    valuePath: "label",
    canRemove: true,
    resetVal: [],
    isMultiple: true,
  },
  category: {
    label: "Category",
    valuePath: "label",
    canRemove: true,
    resetVal: [],
    isMultiple: true,
  },
  brand: {
    label: "Brand",
    valuePath: "label",
    canRemove: true,
    resetVal: [],
    isMultiple: true,
  },
  companyName: { label: "Company Name", canRemove: true, resetVal: "" },
  createdAt: {
    label: "Date Range",
    type: "dateRange",
    canRemove: true,
    resetVal: [],
  },
  productSearch: {
    label: "Search",
    canRemove: true,
    resetVal: "",
  },
  nonSellableType: {
    label: "Type",
    canRemove: true,
    resetVal: "",
  },
  store: {
    label: "Store",
    valuePath: "[0].label",
    canRemove: true,
    resetVal: [],
  },
};

const InventoryAgeingAppliedFilters = ({ filterData, callback }) => {
  // Specify the keys to ignore
  const keysToIgnore = [
    "batchId",
    "tab",
    "inventoryStatus",
    "batchDate",
    "storeName",
    "view",
  ];

  // Create a new object excluding the specified keys
  const filteredData = Object.keys(filterData).reduce((acc, key) => {
    if (!keysToIgnore.includes(key)) {
      acc[key] = filterData[key];
    }

    if (filterData.view === "store") {
      delete acc.store;
    }

    return acc;
  }, {});

  // Conditionally include storeName based on the view
  const labels = CommonService.prepareAppliedFilterLabels(
    filterLabels,
    filteredData
  );

  return (
    <AppliedFilterLabel className="mt-3" labels={labels} callback={callback} />
  );
};

export default InventoryAgeingAppliedFilters;
