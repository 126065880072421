import React from "react";
import { AppCard, TableHeader, Amount } from "@sk/uis";

const frequentProductsData = [
  { name: "iPhone 13 Pro", quantity: 1500, revenue: 1875000 },
  { name: "Samsung Galaxy S21", quantity: 1200, revenue: 1080000 },
  { name: "MacBook Air M1", quantity: 800, revenue: 960000 },
  { name: "AirPods Pro", quantity: 2000, revenue: 500000 },
  { name: "iPad Air", quantity: 950, revenue: 570000 },
];

const frequentCategoriesData = [
  { name: "Smartphones", quantity: 5000, revenue: 5000000 },
  { name: "Laptops", quantity: 2000, revenue: 2400000 },
  { name: "Audio Accessories", quantity: 4000, revenue: 800000 },
  { name: "Tablets", quantity: 1500, revenue: 900000 },
  { name: "Smart Home Devices", quantity: 3000, revenue: 600000 },
];

const frequentBrandsData = [
  { name: "Apple", quantity: 6000, revenue: 7200000 },
  { name: "Samsung", quantity: 4500, revenue: 4050000 },
  { name: "Sony", quantity: 2000, revenue: 1000000 },
  { name: "LG", quantity: 1800, revenue: 900000 },
  { name: "Microsoft", quantity: 1500, revenue: 1350000 },
];

const tableColumns = [
  { label: "Name", key: "name", enableSort: true },
  { label: "Quantity Sold", key: "quantity", enableSort: true },
  { label: "Revenue", key: "revenue", enableSort: true },
];

const FrequentSalesTable = ({ title, data }) => (
  <AppCard title={title}>
    <div
      className="table-responsive"
      style={{ height: "300px", overflowY: "auto" }}
    >
      <div className="fs-val-sm text-muted mb-2">
        Data represents the most frequently sold items in the last 6 months.
      </div>
      <table className="table table-hover table-sm">
        <TableHeader data={tableColumns} />
        <tbody className="fs-val-md">
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.quantity.toLocaleString()}</td>
              <td>
                <Amount value={item.revenue} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </AppCard>
);

const FrequentSales = () => {
  return (
    <div className="row">
      <div className="col-md-4 mb-4">
        <FrequentSalesTable
          title="Frequently Sold Products"
          data={frequentProductsData}
        />
      </div>
      <div className="col-md-4 mb-4">
        <FrequentSalesTable
          title="Frequently Sold Categories"
          data={frequentCategoriesData}
        />
      </div>
      <div className="col-md-4 mb-4">
        <FrequentSalesTable
          title="Frequently Sold Brands"
          data={frequentBrandsData}
        />
      </div>
    </div>
  );
};

export default FrequentSales;
