import { CoinsDashService } from "@sk/services";
import {
  AppCard,
  DateFormatter,
  NoDataFound,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { set } from "date-fns";
import { produce } from "immer";
import { useCallback, useEffect, useRef, useState } from "react";
import CoinsDashAppliedFilter from "./CoinsDashAppliedFilter";

const cstmStyleBlk = { height: "200px", overflowY: "auto" };

const tableHeaders = [
  { label: "Sl No", width: "8%" },
  { label: "Customer" },
  { label: "Earned", width: "15%" },
  { label: "Redeemed", width: "18%" },
  { label: "Time", width: "15%" },
];

const getData = async (params) => {
  const r = await CoinsDashService.getRewardList(params);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const getCount = async (params) => {
  let p = { ...params, outputType: "count" };
  const r = await CoinsDashService.getRewardList(p);
  let count = 0;
  if (Array.isArray(r.resp) && r.resp.length > 0) {
    count = r.resp[0].total;
  }
  return { count };
};

const prepareFilterParams = (filterData, pagination) => {
  let p = {
    page: pagination?.page,
    count: pagination?.count,
    filter: {
      createdAt: {
        $gte: set(new Date(), { hours: 0, minutes: 0, seconds: 0 }),
        $lte: set(new Date(), { hours: 23, minutes: 59, seconds: 59 }),
      },
    },
    sort: {
      lastTransactionDate: -1,
    },
  };

  if (filterData?.storeId) {
    p.filter["ownerLocation.franchise"] = filterData.storeId;
  } else {
    p.filter["ownerLocation.franchise"] = {
      $in: filterData.accessibleStoreIds,
    };
  }

  return p;
};

const CoinsDashToday = ({
  callback,
  storeId,
  storeName,
  accessibleStoreIds,
}) => {
  const [data, setData] = useState([]);

  const [summaryData, setSummaryData] = useState({
    totalRewarded: 0,
    totalRedeemed: 0,
  });

  const [loading, setLoading] = useState(true);

  const [loadingMore, setLoadingMore] = useState({
    loading: false,
    hide: false,
  });

  const [ttlRecords, setTtlRecords] = useState({
    loading: true,
    value: 0,
  });

  const filterDataRef = useRef({});

  const paginationRef = useRef({
    page: 1,
    count: 10,
  });

  useEffect(() => {
    filterDataRef.current = { storeId, storeName, accessibleStoreIds };
    init();
  }, [storeId, storeName, init, accessibleStoreIds]);

  const init = useCallback(async () => {
    paginationRef.current = { page: 1, count: 10 };

    loadSummary();

    setLoading(true);
    setTtlRecords({ loading: true, value: 0 });

    const r = await getData(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );
    const t = await getCount(prepareFilterParams(filterDataRef.current));

    setData(r.data);
    setTtlRecords({ loading: false, value: t.count });
    setLoading(false);

    setLoadingMore((v) => ({
      ...v,
      hide: t.count < paginationRef.current.count,
    }));
  }, [loadSummary]);

  const loadSummary = useCallback(async () => {
    const p = prepareFilterParams(filterDataRef.current);

    const [rewardedResponse, redeemedResponse] = await Promise.all([
      CoinsDashService.getCoinsRewardedCount(p),
      CoinsDashService.getCoinsRedeemedCount(p),
    ]);

    const totalRewarded = rewardedResponse?.resp[0]?.totalCoinsRewarded || 0;
    const totalRedeemed = redeemedResponse?.resp[0]?.totalCoinsRedeem || 0;

    setSummaryData({
      totalRewarded,
      totalRedeemed,
    });
  }, []);

  const loadMore = async () => {
    paginationRef.current.page += 1;

    setLoadingMore({ loading: true, hide: false });
    const r = await getData(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );
    setData(
      produce((draft) => {
        r.data.forEach((e) => {
          draft.push(e);
        });
      })
    );
    setLoadingMore({ loading: false, hide: !r.data.length });
  };

  return (
    <>
      <AppCard title="Todays Coins Reward/Redeem">
        <CoinsDashAppliedFilter storeName={storeName} />
        <div className="mb-2 row align-items-end">
          <div className="col">
            <div className="fs-val-sm">Showing {ttlRecords.value} results</div>
          </div>
          <div className="col-auto">
            <button
              className="fs-val-md btn btn-link text-muted p-0 me-2"
              onClick={init}
            >
              <i className="bi bi-arrow-clockwise"></i>
              Refresh
            </button>
            <span className="badge badge-outline-success me-2">
              Rewarded: {summaryData.totalRewarded} coins
            </span>
            <span className="badge badge-outline-danger">
              Redeemed: {summaryData.totalRedeemed} coins
            </span>
          </div>
        </div>

        <div className="custom-scrollbar" style={cstmStyleBlk}>
          <table className="table table-sm table-striped">
            <TableHeader data={tableHeaders} noBg={true} />
            <tbody className="fs-val-md">
              {loading ? (
                <TableSkeletonLoader
                  rows={10}
                  height={30}
                  cols={tableHeaders.length}
                />
              ) : null}

              {!loading && !data.length ? (
                <tr className="align-self-start">
                  <td colSpan={tableHeaders.length}>
                    <NoDataFound>
                      <span className="fs-val-sm">No data found</span>
                    </NoDataFound>
                  </td>
                </tr>
              ) : null}

              {data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <button
                      className="btn btn-link p-0 fs-val-md text-dark"
                      onClick={() =>
                        callback({
                          action: "viewCustomer",
                          id: item.ownerId,
                        })
                      }
                    >
                      {item.customerName} - ({item.ownerId})
                    </button>
                  </td>
                  <td className="text-success"> {item.totalCoinsRewarded}</td>
                  <td className="text-danger">{item.totalCoinsRedeemed}</td>
                  <td>
                    <DateFormatter
                      date={item.lastTransactionDate}
                      format={"hh:mm a"}
                    />
                  </td>
                </tr>
              ))}

              {!loadingMore.hide && data.length > 0 ? (
                <tr className="bg-white">
                  <td colSpan={5} className="text-center">
                    <button
                      onClick={loadMore}
                      disabled={loadingMore.loading}
                      className="btn btn-xs btn-light mt-2"
                    >
                      {loadingMore.loading ? <Spinner isSmall={true} /> : null}
                      <span className="align-middle"> Load More</span>
                    </button>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </AppCard>
    </>
  );
};

export default CoinsDashToday;
