import {
  ClubViewModal,
  CustomerDetailModal,
  PosOrderDetailModal,
} from "@sk/features";
import {
  CommonService,
  CustomerService,
  FranchiseService,
  RolesService,
} from "@sk/services";
import {
  Amount,
  AppCard,
  BusyLoader,
  DateFormatter,
  NoDataFound,
  PageInfo,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
} from "@sk/uis";
import { endOfDay, startOfDay, sub } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import CustomerLifeCycleFilter from "./components/CustomerLifeCycleFilter";
import CustomerLifeCycleNotifyModal from "./modals/CustomerLifeCycleNotifyModal";

const tableStyle = {
  minWidth: "2000px",
};

const breadcrumb = [{ name: "Home", link: "/" }, { name: "Customer Insights" }];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 30,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 30,
};

const headers = [
  { label: "S.No", width: "40px" },
  {
    label: "Customer Details",
    width: "200px",
    enableSort: true,
    key: "customerName",
    isSticky: true,
  },
  { label: "Linked Store", width: "200px" },
  {
    label: "Total Orders",
    width: "150px",
    enableSort: true,
    key: "tillDateOrderCount",
    isCentered: true,
  },
  {
    label: "Total Order Value",
    width: "150px",
    enableSort: true,
    key: "tillDateOrderValue",
    isCentered: true,
  },
  {
    label: "First Order",
    width: "100px",
    enableSort: true,
    key: "firstOrderDate",
  },
  {
    label: "Last Order",
    width: "100px",
    enableSort: true,
    key: "lastOrderDate",
  },
  {
    label: "Available KingCoins",
    width: "150px",
    enableSort: true,
    key: "availableCoins",
    isCentered: true,
  },
  {
    label: "Redeemed KingCoins",
    width: "180px",
    enableSort: true,
    key: "redeemedCoins",
    isCentered: true,
  },
  {
    label: "Earned KingCoins",
    width: "150px",
    enableSort: true,
    key: "earnedCoins",
    isCentered: true,
  },
  { label: "Own Club", width: "200px", enableSort: true, key: "ownClub" },
  {
    label: "Part of Club",
    width: "200px",
    enableSort: true,
    key: "partOfClub",
  },
  { label: "Action", width: "100px" },
];

const customerNameCol = {
  position: "sticky",
  left: 0,
  backgroundColor: "white",
};

const totalOrdersStyle = { backgroundColor: "#fafbff" };
const orderDatesStyle = { backgroundColor: "#fff8f1" };
const kingCoinsStyle = { backgroundColor: "#f7fff7" };

// Function to fetch customer data
const getData = async (params) => {
  const response = await CustomerService.getCustomerInsights(params);
  const data = Array.isArray(response.resp) ? response.resp : [];
  const fids = data.map((e) => e.underFranchise);
  if (fids?.length) {
    const franchisesResp = await FranchiseService.getFranchises({
      filter: {
        _id: { $in: fids },
      },
    });
    const franchises = Array.isArray(franchisesResp.resp)
      ? franchisesResp.resp
      : [];
    data.forEach((e) => {
      e.franchise = franchises.find((f) => f._id == e.underFranchise);
    });
  }
  return { data, raw: response };
};

// Function to get the count of customers
const getCount = async (params) => {
  const response = await CustomerService.getCustomerInsights({
    ...params,
    outputType: "count",
  });
  return {
    count:
      Array.isArray(response.resp) && response.resp.length > 0
        ? response.resp[0].total
        : 0,
  };
};

// Function to prepare filter parameters
const prepareFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      franchise: {
        $in: filter.stores,
      },
      createdAt: {
        $gte: startOfDay(sub(new Date(), { months: 3 })),
        $lte: endOfDay(new Date()),
      },
    },
    sort: { [sort.key]: sort.value == "asc" ? 1 : -1 },
  };

  if (filter.registeredOn?.length) {
    p.filter.createdAt = {
      $gte: startOfDay(filter.registeredOn[0]),
      $lte: endOfDay(filter.registeredOn[1]),
    };
  }

  if (filter.franchise?._id) {
    p.filter.franchise = filter.franchise._id;
  }

  if (filter.gender) {
    p.filter.gender = filter.gender;
  }

  if (filter.search) {
    const s = filter.search.trim();
    p.filter.$or = [
      { fName: { $regex: s, $options: "gi" } },
      { _id: { $regex: s, $options: "gi" } },
      {
        partOfClub: { $regex: s, $options: "gi" },
      },
      {
        ownClub: { $regex: s, $options: "gi" },
      },
    ];
  }

  return p;
};

const CustomerLifeCycle = ({
  fromDate,
  toDate,
  cid,
  cname,
  callback,
  storeId,
  storeName,
  accessibleStoreIds,
}) => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [records, setRecords] = useState({ loading: true, value: 0 });
  const [notifyModal, setNotifyModal] = useState({ show: false, type: "" });
  const [customerDetailModal, setCustomerDetailModal] = useState({
    show: false,
    cid: "",
  });
  const [accessibleStores, setAccessibleStores] = useState([]);
  const [posOrderDetailModal, setPosOrderDetailModal] = useState({
    show: false,
    oid: "",
  });
  const [clubViewModal, setClubViewModal] = useState({
    show: false,
    cid: "",
  });
  const [busyLoader, setBusyLoader] = useState({ show: false });

  const paginationRef = useRef({ ...defaultPaginationConfig });
  const filterDataRef = useRef({});
  const sortDataRef = useRef({
    key: "registeredOn",
    value: "desc",
  });

  const loadList = useCallback(async () => {
    const p = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortDataRef.current
    );
    setLoadingData(true);
    setData([]);
    const response = await getData(p);
    setData(response.data);
    setLoadingData(false);
  }, []);

  const applyFilter = useCallback(async () => {
    paginationRef.current.activePage = 1;
    setLoadingData(true);
    setData([]);
    const p = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortDataRef.current
    );
    const r = await getCount(p);
    setRecords({ loading: false, value: r.count });
    paginationRef.current.totalRecords = r.count;
    loadList();
  }, [loadList]);

  useEffect(() => {
    const fetchStores = async () => {
      setLoadingData(true);
      const { stores } = await RolesService.getDashboardEnabledStores(
        "CustomerInsight"
      );
      filterDataRef.current.stores = stores;
      setAccessibleStores(stores);
      if (filterDataRef.current.stores?.length) {
        applyFilter();
      } else {
        setData([]);
        setLoadingData(false);
      }
    };
    fetchStores();
  }, [applyFilter]);

  const paginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
  };

  const bulkNotify = () => {
    setNotifyModal({ show: true, type: "bulk" });
  };

  const onNotifyModal = () => {
    setNotifyModal({ show: true, type: "single" });
  };

  const onNotifyModalCb = () => {
    setNotifyModal({ show: false, type: "" });
  };

  const viewCustomerDetail = (cid) => {
    setCustomerDetailModal({ show: true, cid });
  };

  const onCustomerDetailModalCb = (e) => {
    setCustomerDetailModal({ show: false, cid: "" });
    if (e.action == "viewOrder") {
      setPosOrderDetailModal({ show: true, oid: e.id });
    } else if (e.action == "viewClub") {
      viewClub(e.id);
    }
  };

  const onPosOrderDetailModalCb = () => {
    setPosOrderDetailModal({ show: false, oid: "" });
  };

  const onFilterCb = (data) => {
    filterDataRef.current = {
      ...filterDataRef.current,
      ...data.formData,
    };
    applyFilter();
  };

  const onSortCb = (data) => {
    sortDataRef.current = {
      key: data.key,
      value: data.value,
    };
    applyFilter();
  };

  const viewClub = (cid) => {
    setClubViewModal({ show: true, cid });
  };

  const onClubViewModalCb = (e) => {
    setClubViewModal({ show: false, cid: "" });
    if (e.action == "viewCustomer") {
      viewCustomerDetail(e.id);
    } else if (e.action == "viewOrder") {
      setPosOrderDetailModal({ show: true, oid: e.id });
    }
  };

  const downloadData = async () => {
    const p = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortDataRef.current
    );
    delete p.page;
    delete p.count;

    p.outputType = "download";

    setBusyLoader({ show: true });
    const response = await getData(p);
    setBusyLoader({ show: false });
    if (response.raw?.statusCode == 200) {
      const url = response.raw.resp?.downloadLink;
      if (url) {
        CommonService.downloadAsset(url);
      } else {
        Toaster.error("Failed to download data");
      }
    } else {
      Toaster.error(response.raw?.message || "Failed to download data");
    }
  };

  const viewOrder = (oid) => {
    setPosOrderDetailModal({ show: true, oid });
  };

  return (
    <div>
      <div className="row mb-2 mt-3 align-items-end">
        <div className="col">
          <PageInfo
            title="Customer Insights"
            breadcrumbs={breadcrumb}
            noMargin={true}
          />
        </div>
        <div className="col-auto align-self-end">
          <button className="btn btn-outline-primary" onClick={downloadData}>
            <i className="bi bi-download" /> Download
          </button>
        </div>
        {/* <div className="col-auto align-self-end">
          <button className="btn btn-danger" onClick={bulkNotify}>
            <i className="bi bi-bell" /> Send Bulk Notification
          </button>
        </div> */}
      </div>

      <AppCard>
        <CustomerLifeCycleFilter
          callback={onFilterCb}
          accessibleStoreIds={accessibleStores}
        />
      </AppCard>

      <AppCard>
        <div className="row mb-2">
          <div className="col">
            <PaginationSummary
              paginationConfig={paginationRef.current}
              loadingTotalRecords={records.loading}
              fwSize="md"
            />
          </div>
          <div className="col-auto align-self-end">
            <div className="text-muted fs-val-sm">
              <i className="bi bi-info-circle me-1"></i>
              Viewing assigned store details
            </div>
          </div>
        </div>

        <div className="table-responsive custom-scrollbar">
          <table
            className="table bg-white table-hover table-sm"
            style={tableStyle}
          >
            <TableHeader
              data={headers}
              noBg={true}
              sort={sortDataRef.current}
              sortCb={onSortCb}
            />
            <tbody className="fs-val-md">
              {loadingData ? (
                <TableSkeletonLoader
                  cols={headers.length}
                  rows={10}
                  height={20}
                />
              ) : null}

              {!loadingData && !data.length ? (
                <tr>
                  <td colSpan={headers.length}>
                    <NoDataFound>No Data found</NoDataFound>
                  </td>
                </tr>
              ) : null}

              {data.map((e, index) => (
                <tr key={e._id + ":" + index}>
                  <td>{paginationRef.current.startSlNo + index}</td>
                  <td style={customerNameCol}>
                    <button
                      className="btn btn-sm btn-link text-dark fw-semibold text-start p-0 mb-1"
                      onClick={() => viewCustomerDetail(e._id)}
                    >
                      {e.customerName}
                    </button>
                    <div className="text-black-50 fs-val-sm mb-1">
                      {e.customerDistrict}, {e.customerState} -{" "}
                      {e.customerPincode}
                    </div>
                  </td>
                  <td>{e.franchise?.name || "N/A"}</td>
                  <td className="text-center" style={totalOrdersStyle}>
                    {e.tillDateOrderCount || 0}
                  </td>
                  <td className="text-center" style={totalOrdersStyle}>
                    <Amount value={e.tillDateOrderValue || 0} />
                  </td>
                  <td style={orderDatesStyle}>
                    {e.firstOrder?.orderDate ? (
                      <>
                        <div className="mb-1">
                          <DateFormatter
                            date={e.firstOrder?.orderDate}
                            format="dd MMM yyyy"
                          />
                        </div>
                        <div>
                          <button
                            className="btn btn-link p-0 fs-val-sm text-black-50 text-start"
                            onClick={() => viewOrder(e.firstOrder?.orderId)}
                          >
                            {e.firstOrder?.orderId}
                          </button>
                        </div>
                      </>
                    ) : (
                      "--"
                    )}
                  </td>
                  <td style={orderDatesStyle}>
                    {e.lastOrder?.orderDate ? (
                      <>
                        <div className="mb-1">
                          <DateFormatter
                            date={e.lastOrder?.orderDate}
                            format="dd MMM yyyy"
                            className="fw-bold"
                          />
                        </div>
                        <div>
                          <button
                            className="btn btn-link p-0 fs-val-sm text-black-50 text-start"
                            onClick={() => viewOrder(e.lastOrder?.orderId)}
                          >
                            {e.lastOrder?.orderId}
                          </button>
                        </div>
                      </>
                    ) : (
                      "--"
                    )}
                  </td>
                  <td style={kingCoinsStyle} className="text-center">
                    {e.availableCoins || 0}
                  </td>
                  <td
                    style={kingCoinsStyle}
                    className="text-center text-danger"
                  >
                    {e.tillNowRedeemedCoins || 0}
                  </td>
                  <td style={kingCoinsStyle} className="text-center">
                    {e.tillNowRewardedCoins || 0}
                  </td>
                  <td>
                    {e.ownClub ? (
                      <button
                        className="btn btn-link p-0 fs-val-md text-dark text-start"
                        onClick={() => viewClub(e._id)}
                      >
                        {e.ownClub || "N/A"}
                      </button>
                    ) : (
                      "--"
                    )}
                  </td>
                  <td>
                    {e.partOfClub ? (
                      <button
                        className="btn btn-link p-0 fs-val-md text-dark text-start"
                        onClick={() => viewClub(e.parentCustomerId)}
                      >
                        {e.partOfClub || "N/A"}
                      </button>
                    ) : (
                      "--"
                    )}
                  </td>
                  <td>
                    <div className="btn-group" role="group">
                      <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={() => viewCustomerDetail(e._id)}
                      >
                        <i className="bi bi-eye" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="text-end">
          <PaginationBlock
            loadingTotalRecords={records.loading}
            paginationCb={paginationCb}
            paginationConfig={paginationRef.current}
            size="sm"
          />
        </div>
      </AppCard>

      <CustomerLifeCycleNotifyModal
        show={notifyModal.show}
        callback={onNotifyModalCb}
        type={notifyModal.type}
      />

      <CustomerDetailModal
        show={customerDetailModal.show}
        callback={onCustomerDetailModalCb}
        cid={customerDetailModal.cid}
      />

      <ClubViewModal
        show={clubViewModal.show}
        callback={onClubViewModalCb}
        cid={clubViewModal.cid}
      />

      <PosOrderDetailModal
        show={posOrderDetailModal.show}
        callback={onPosOrderDetailModalCb}
        orderId={posOrderDetailModal.oid}
      />

      <BusyLoader show={busyLoader.show} />
    </div>
  );
};

export default CustomerLifeCycle;
