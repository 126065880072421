import { differenceInMinutes, format } from "date-fns";
import * as yup from "yup";

const groupCodeValidation = yup.object().shape({
  configs: yup
    .array()
    .of(
      yup.object().shape({
        _preOrderLimit: yup
          .number()
          .nullable()
          .transform((x) => (isNaN(x) ? null : x))
          .test({
            name: "_preOrderLimit",
            test: (value, ctx) => {
              if (!ctx.parent?.selected) {
                return true;
              }

              if (isNaN(value) || value <= 0) {
                return ctx.createError({
                  message: "Reserve Order Qty is required",
                });
              }

              return true;
            },
          }),
        _applicableFromDate: yup.array().test({
          name: "_applicableFromDate",
          test: (value, ctx) => {
            if (!ctx.parent?.selected) {
              return true;
            }

            if (!Array.isArray(value) || !value.length) {
              return ctx.createError({
                message: "Start Date is required",
              });
            }

            return true;
          },
        }),
        _endDate: yup.array().test({
          name: "_endDate",
          test: (value, ctx) => {
            if (!ctx.parent?.selected) {
              return true;
            }

            if (!Array.isArray(value) || !value.length) {
              return ctx.createError({
                message: "End Date is required",
              });
            }

            if (differenceInMinutes(new Date(value[0]), new Date()) < 0) {
              return ctx.createError({
                message:
                  "Invalid Date, must start from " +
                  format(new Date(), "dd MMM yyyy, hh:mm a"),
              });
            }

            return true;
          },
        }),
      })
    )
    .when("mode", {
      is: "add",
      then: (schema) =>
        schema
          .min(1, "Please configure Group Code")
          .required("Please configure Group Code"),
    }),
});

export default groupCodeValidation;
