import { Amount, AppCard, ChartRender, TableHeader } from "@sk/uis";
import { useState } from "react";

const salesTableData = [
  {
    month: "December",
    totalSales: 400000,
    totalOrders: 2285,
    avgOrderValue: 175,
  },
  {
    month: "November",
    totalSales: 380000,
    totalOrders: 2235,
    avgOrderValue: 170,
  },
  {
    month: "October",
    totalSales: 350000,
    totalOrders: 2187,
    avgOrderValue: 160,
  },
  {
    month: "September",
    totalSales: 320000,
    totalOrders: 2065,
    avgOrderValue: 155,
  },
  {
    month: "August",
    totalSales: 300000,
    totalOrders: 1935,
    avgOrderValue: 155,
  },
  { month: "July", totalSales: 280000, totalOrders: 1866, avgOrderValue: 150 },
];

const salesTableColumns = [
  { label: "Month", key: "month", enableSort: true },
  { label: "Total Sales", key: "totalSales", enableSort: true },
  { label: "Total Orders", key: "totalOrders", enableSort: true },
  { label: "Avg Order Value", key: "avgOrderValue", enableSort: true },
];

export const SalesTable = () => {
  const [viewType, setViewType] = useState("monthly");

  const chartData = {
    labels: salesTableData.map((row) => row.month).reverse(),
    datasets: [
      {
        label: "Total Sales",
        data: salesTableData.map((row) => row.totalSales).reverse(),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <AppCard>
      <div className="row">
        <div className="col-md-7">
          <div className="mb-3">
            <div
              className="btn-group"
              role="group"
              aria-label="View type selection"
            >
              <button
                type="button"
                onClick={() => setViewType("monthly")}
                className={`btn btn-sm ${
                  viewType === "monthly" ? "btn-primary" : "btn-outline-primary"
                }`}
              >
                Monthly
              </button>
              <button
                type="button"
                onClick={() => setViewType("weekly")}
                className={`btn btn-sm ${
                  viewType === "weekly" ? "btn-primary" : "btn-outline-primary"
                }`}
              >
                Weekly
              </button>
            </div>
          </div>
          <table className="table table-hover table-sm">
            <TableHeader data={salesTableColumns} />
            <tbody className="fs-val-md">
              {salesTableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.month}</td>
                  <td>
                    <Amount value={row.totalSales} />
                  </td>
                  <td>{row.totalOrders.toLocaleString()}</td>
                  <td>
                    <Amount value={row.avgOrderValue} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-md-5">
          <div style={{ height: "300px" }}>
            <ChartRender type="line" data={chartData} />
          </div>
        </div>
      </div>
    </AppCard>
  );
};
