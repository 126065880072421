import { appConfigs, DashboardService, NavService } from "@sk/services";
import {
  Amount,
  AppCard,
  BusyLoader,
  DateFormatter,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
  BtnLink,
  InfoPopover,
  KeyVal,
} from "@sk/uis";
import classNames from "classnames";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

const containerStyle = {
  height: "600px",
  overflowY: "auto",
};

const headers = [
  // Serial Number
  {
    label: "Sl No",
    key: "slNo",
    isCentered: true,
    width: "50px",
    isSticky: true,
    stickyPosition: "left",
  },
  // Warehouse Information Group
  // {
  //   label: "Warehouse",
  //   key: "whId",
  //   enableSort: true,
  //   width: "100px",
  // },

  // Product Information Group
  {
    label: "Product Name",
    key: "productName",
    enableSort: true,
    isSticky: true,
    stickyPosition: "left",
    width: "200px",
    style: {
      left: "60px",
    },
  },
  {
    label: "Product ID",
    key: "productId",
    enableSort: true,
    width: "100px",
  },

  {
    label: "Shelf Life",
    key: "shelfLife",
    enableSort: true,
    width: "100px",
  },

  // Stock Information Group
  {
    label: "Pending Order Qty",
    key: "pendingOrderQty",
    isCentered: true,
    enableSort: true,
    width: "120px",
  },
  {
    label: "Pending Order Value",
    key: "pendingOrderValue",
    isCentered: true,
    enableSort: true,
    width: "130px",
  },

  // Order Dates Group
  {
    label: "Latest Order Date",
    key: "recentOrderDate",
    enableSort: true,
    width: "120px",
  },

  {
    label: "Oldest Order Date",
    key: "oldestOrderDate",
    enableSort: true,
    width: "120px",
  },
  // New columns for latest PO details
  {
    label: "Latest PO Date",
    key: "latestPoDate",
    enableSort: true,
    width: "120px",
  },
  {
    label: "Latest PO ID",
    key: "latestPoId",
    enableSort: true,
    width: "120px",
  },
  {
    label: "Latest PO Qty",
    key: "latestPoQty",
    enableSort: true,
    width: "120px",
  },
  {
    label: "Vendor",
    key: "latestPoVendorName",
    enableSort: true,
    width: "200px",
  },
  {
    label: "Latest GRN Date",
    key: "latestGrnDate",
    enableSort: true,
    width: "120px",
  },
  {
    label: "Latest GRN ID",
    key: "latestGrnId",
    enableSort: true,
    width: "120px",
  },
  {
    label: "Latest GRN Qty",
    key: "latestGrnQty",
    enableSort: true,
    width: "120px",
  },

  {
    label: "In Stock Qty",
    key: "inStockQty",
    isCentered: true,
    enableSort: true,
    width: "80px",
  },
  {
    label: "Reserve Qty",
    key: "reserveQty",
    isCentered: true,
    enableSort: true,
    width: "80px",
  },

  // PO Information Group
  {
    label: "Open PO Qty",
    key: "openPOQty",
    isCentered: true,
    enableSort: true,
    width: "100px",
  },
  {
    label: "Partial PO Qty",
    key: "partialPOQty",
    isCentered: true,
    enableSort: true,
    width: "80px",
  },
  {
    label: "PO Value",
    key: "openPOValue",
    isCentered: true,
    enableSort: true,
    width: "80px",
  },
  {
    label: "Unique Open PO",
    key: "uniqueOpenPOCount",
    isCentered: true,
    enableSort: true,
    width: "120px",
  },
  {
    label: "Unique Partial PO",
    key: "uniquePartialPOCount",
    isCentered: true,
    enableSort: true,
    width: "100px",
  },

  // Sales History Group
  {
    label: "Last 30d Sales",
    key: "last30DaysSaleQty",
    isCentered: true,
    enableSort: true,
    width: "100px",
  },
  {
    label: "Last 60d Sales",
    key: "last60DaysSaleQty",
    isCentered: true,
    enableSort: true,
    width: "100px",
  },
  {
    label: "Last 90d Sales",
    key: "last90DaysSaleQty",
    isCentered: true,
    enableSort: true,
    width: "100px",
  },

  {
    label: "Menu Name",
    key: "menuName",
    enableSort: true,
    width: "120px",
  },
  {
    label: "Category Name",
    key: "categoryName",
    enableSort: true,
    width: "100px",
  },
  {
    label: "Brand Name",
    key: "brandName",
    enableSort: true,
    width: "100px",
  },
  {
    label: "Company Name",
    key: "companyName",
    enableSort: true,
    width: "100px",
  },

  {
    label: "MRP",
    key: "mrp",
    isCentered: true,
    enableSort: true,
    width: "80px",
  },
  {
    label: "Inner Pack Qty",
    key: "innerPackQty",
    isCentered: true,
    enableSort: true,
    width: "80px",
  },
  {
    label: "Case Qty",
    key: "caseQty",
    isCentered: true,
    enableSort: true,
    width: "80px",
  },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const getData = async (params) => {
  const response = await DashboardService.fetchStockAnalytics(params);
  return {
    data: Array.isArray(response.resp) ? response.resp : [],
    raw: response.raw,
  };
};

const getCount = async (params) => {
  const p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;
  const response = await DashboardService.fetchStockAnalytics(p);
  return response?.resp?.[0]?.totalDocs || 0;
};

const prepareFilterParams = (filterData, pagination, sort) => {
  const params = DashboardService.prepareStockNotAllocatedSummaryParams(
    filterData,
    filterData.isNoPo
      ? "getUnallocatedStockWithoutPOProductWise"
      : "getUnallocatedStockProductWise"
  );

  const search = (filterData.search || "").trim();
  if (search) {
    params.filter.$or = [
      { "productInfo.name": { $regex: search, $options: "i" } },
      { productId: search },
      { "productInfo.companyName": { $regex: search, $options: "i" } },
    ];
  }

  // Add vendor search filter
  const vendorSearch = (filterData.vendorSearch || "").trim();
  if (vendorSearch) {
    params.filter.$or = [
      ...(params.filter.$or || []),
      { "latestPoInfo.vendorId": vendorSearch },
      { "latestPoInfo.vendorName": { $regex: vendorSearch, $options: "i" } },
    ];
  }

  if (pagination) {
    params.page = pagination.activePage;
    params.count = pagination.rowsPerPage;
  }

  if (sort) {
    params.sort = {
      [sort.key]: sort.value === "asc" ? 1 : -1,
    };
  }

  return params;
};

const tableStyle = {
  minWidth: "4300px", // Updated width
};

// Define a style object for different groups
const groupStyles = {
  sales: {
    backgroundColor: "#f9f9f9", // Light color for sales-related cells
  },
  po: {
    backgroundColor: "#f4fbff", // Light blue for PO-related cells
  },
  order: {
    backgroundColor: "#f7fff4", // Light orange for order-related cells
  },
  qty: {
    backgroundColor: "white", // Light cyan for quantity-related cells
  },
  // New styles for specified columns
  pending: {
    backgroundColor: "#fff3f3", // Very light blue for pending order columns
  },
  date: {
    backgroundColor: "#fffaf0", // Very light cream for order date columns
  },
  unique: {
    backgroundColor: "#fffff4", // Very light beige for unique PO columns
  },
  grn: {
    backgroundColor: "#f4fff4", // Very light green for GRN columns
  },
};

const stickySerialNumberStyle = {
  left: 0,
};

const stickyProductNameStyle = {
  left: "60px",
};

const StockNotAllocatedTable = ({ filterData, callback }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState({ loading: true, value: 0 });
  const [search, setSearch] = useState("");
  const [vendorSearch, setVendorSearch] = useState("");
  const [busyLoader, setBusyLoader] = useState({ show: false });

  const filterRef = useRef(filterData);
  const paginationRef = useRef({ ...defaultPaginationConfig });
  const sortDataRef = useRef({ key: "pendingOrderQty", value: "desc" });

  const loadList = useCallback(async () => {
    setLoading(true);
    setData([]);
    const r = await getData(
      prepareFilterParams(
        filterRef.current,
        paginationRef.current,
        sortDataRef.current
      )
    );
    if (r.raw?.code == appConfigs.AJAX_REQ_CANCEL) {
      return;
    }
    setData(r.data);
    setLoading(false);
  }, []);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    loadList();

    setRecords({ loading: true, value: 0 });
    const count = await getCount(prepareFilterParams(filterRef.current));
    setRecords({ loading: false, value: count });
    paginationRef.current.totalRecords = count;
  }, [loadList]);

  const searchDebounceCb = useCallback(
    debounce(() => {
      applyFilter();
    }, 500),
    []
  );

  const onSearch = useCallback(
    (e) => {
      filterRef.current = {
        ...filterRef.current,
        search: e.target.value,
      };
      setSearch(e.target.value);
      searchDebounceCb();
    },
    [searchDebounceCb]
  );

  const onVendorSearch = useCallback(
    (e) => {
      const value = e.target.value;
      setVendorSearch(value);
      filterRef.current = {
        ...filterRef.current,
        vendorSearch: value,
      };
      searchDebounceCb();
    },
    [searchDebounceCb]
  );

  useEffect(() => {
    filterRef.current = filterData;
    applyFilter();
    setSearch("");
  }, [filterData, applyFilter]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortDataRef.current = data;
      applyFilter();
    },
    [applyFilter]
  );

  const handleFilterClick = useCallback(
    (type, value) => {
      const formData = {
        ...filterData,
        [type]: [
          {
            label: value.name,
            value: {
              _id: value._id || "",
              name: value.name,
            },
          },
        ],
      };

      callback({ formData });
    },
    [filterData, callback]
  );

  const handleDownload = useCallback(async () => {
    setBusyLoader({ show: true });
    const p = prepareFilterParams(filterRef.current);
    p.outputType = "download";
    p.count = 100000000;
    const res = await getData(p);
    if (res.raw?.data?.filePath) {
      window.open(
        DashboardService.getDealStockMasterDownloadLink(res.raw?.data?.filePath)
      );
      Toaster.success("Download started");
    } else {
      Toaster.error("Something went wrong");
    }
    setBusyLoader({ show: false });
  }, []);

  const viewProduct = (id) => {
    NavService.openInNewTab("/cms/product/view", {
      id: id,
    });
  };

  const viewVendorDetails = (vendorId) => {
    NavService.openInNewTab("/vendor/view", {
      id: vendorId,
    });
  };

  const viewGrnDetails = (grnId) => {
    NavService.openInNewTab(`/purchase-order/grn/view`, {
      id: grnId,
    });
  };

  return (
    <>
      <AppCard title="Product wise Stock Not Allocated">
        <div className="row mb-3 align-items-center">
          <PaginationSummary
            loadingTotalRecords={records.loading}
            paginationConfig={paginationRef.current}
            className="col"
          />
          <div className="row col-5 ms-auto">
            <div className="form-group col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Search by Product Name/ID"
                value={search}
                onChange={onSearch}
              />
            </div>
            <div className="form-group col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Search by Vendor Name/ID"
                value={vendorSearch}
                onChange={onVendorSearch}
              />
            </div>
          </div>
          <div className="col-auto">
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={handleDownload}
            >
              <i className="bi bi-download me-2"></i>
              Download
            </button>
          </div>
        </div>

        <div
          className="tbl-scroll-container custom-scrollbar thin-scrollbar fixed-width-table"
          style={containerStyle}
        >
          <table
            className="table table-sm mb-0 bg-white table-hover"
            style={tableStyle}
          >
            <TableHeader
              data={headers}
              sortCb={sortCb}
              sort={sortDataRef.current}
              isSticky={true}
            />
            <tbody className="fs-val-sm">
              {loading ? (
                <TableSkeletonLoader cols={headers.length} rows={10} />
              ) : data.length === 0 ? (
                <tr>
                  <td colSpan={headers.length} className="text-center">
                    <NoDataFound>No data found</NoDataFound>
                  </td>
                </tr>
              ) : (
                data.map((item, index) => (
                  <tr key={index}>
                    {/* Serial Number */}
                    <td
                      className="text-center sticky-column bg-white"
                      style={stickySerialNumberStyle}
                    >
                      {paginationRef.current.startSlNo + index}
                    </td>

                    {/* Product Information Group */}
                    <td
                      style={stickyProductNameStyle}
                      className="sticky-column bg-white"
                    >
                      <button
                        className="btn btn-link text-dark text-start fs-val-sm p-0 text-wrap"
                        onClick={() => viewProduct(item.productId)}
                      >
                        {item.productName}
                      </button>
                    </td>
                    <td>{item.productId}</td>

                    <td>{(item?.shelfLife || 0).toLocaleString()} days</td>

                    {/* Stock Information Group */}
                    <td className="text-center" style={groupStyles.pending}>
                      {item.pendingOrderQty}
                      <InfoPopover
                        content={
                          <div>
                            <div className="fs-val-md mb-3">
                              Sub-Type wise Pending Order Qty
                            </div>
                            <KeyVal
                              label="COCO"
                              labelCol="col-6"
                              contentCol="col-6"
                            >
                              : {item.pendingOrderQtyCOCO}
                            </KeyVal>
                            <KeyVal
                              label="BPCL"
                              labelCol="col-6"
                              contentCol="col-6"
                            >
                              : {item.pendingOrderQtyBPCL}
                            </KeyVal>
                            <KeyVal
                              label="BrandSF"
                              labelCol="col-6"
                              contentCol="col-6"
                            >
                              : {item.pendingOrderQtyBrandSF}
                            </KeyVal>
                            <KeyVal
                              label="OLA"
                              labelCol="col-6"
                              contentCol="col-6"
                            >
                              : {item.pendingOrderQtyOlasf}
                            </KeyVal>
                            <KeyVal
                              label="Pincode"
                              labelCol="col-6"
                              contentCol="col-6"
                            >
                              : {item.pendingOrderQtyPincodeSF}
                            </KeyVal>
                            <KeyVal
                              label="SMARTSF"
                              labelCol="col-6"
                              contentCol="col-6"
                            >
                              : {item.pendingOrderQtySmartSF}
                            </KeyVal>
                          </div>
                        }
                      />
                    </td>
                    <td className="text-center">
                      <Amount value={item.pendingOrderValue} />
                    </td>

                    {/* Order Dates Group */}
                    <td style={groupStyles.date}>
                      <DateFormatter
                        date={item.recentOrderDate}
                        format="dd MMM yyyy"
                      />
                      <div className="text-black-50 fs-val-sm d-inline-block">
                        ,{" "}
                        <DateFormatter
                          date={item.recentOrderDate}
                          format="hh:mm a"
                        />
                      </div>
                    </td>
                    <td style={groupStyles.date}>
                      <DateFormatter
                        date={item.oldestOrderDate}
                        format="dd MMM yyyy"
                      />
                      <div className="text-black-50 fs-val-sm d-inline-block">
                        ,{" "}
                        <DateFormatter
                          date={item.oldestOrderDate}
                          format="hh:mm a"
                        />
                      </div>
                    </td>
                    {/* New columns for latest PO details */}
                    <td style={groupStyles.po}>
                      {item.latestPoDate ? (
                        <>
                          <DateFormatter
                            date={item.latestPoDate}
                            format="dd MMM yyyy"
                          />
                          <div className="text-black-50 fs-val-sm d-inline-block">
                            ,{" "}
                            <DateFormatter
                              date={item.latestPoDate}
                              format="hh:mm a"
                            />
                          </div>
                        </>
                      ) : (
                        <span className="text-black-50">--</span>
                      )}
                    </td>
                    <td style={groupStyles.po}>{item.latestPoId || "--"}</td>
                    <td style={groupStyles.po}>{item.latestPoQty || "--"}</td>
                    {/* Vendor */}
                    <td>
                      {item.latestPoVendorId ? (
                        <>
                          <BtnLink
                            className="fs-val-sm text-wrap"
                            onClick={(e) => {
                              e.stopPropagation();
                              viewVendorDetails(item.latestPoVendorId);
                            }}
                          >
                            {item.latestPoVendorName}
                          </BtnLink>
                          <div className="fs-val-sm text-muted">
                            ID: {item.latestPoVendorId}
                          </div>
                        </>
                      ) : (
                        <span className="text-black-50">--</span>
                      )}
                    </td>
                    {/* New columns for latest GRN details */}
                    <td style={groupStyles.grn}>
                      {item.latestGrnDate ? (
                        <>
                          <DateFormatter
                            date={item.latestGrnDate}
                            format="dd MMM yyyy"
                          />
                          <div className="text-black-50 fs-val-sm d-inline-block">
                            ,{" "}
                            <DateFormatter
                              date={item.latestGrnDate}
                              format="hh:mm a"
                            />
                          </div>
                        </>
                      ) : (
                        <span className="text-black-50">--</span>
                      )}
                    </td>
                    <td style={groupStyles.grn}>
                      {item.latestGrnId ? (
                        <BtnLink
                          className="fs-val-sm text-wrap"
                          onClick={(e) => {
                            e.stopPropagation();
                            viewGrnDetails(item.latestGrnId);
                          }}
                        >
                          {item.latestGrnId}
                        </BtnLink>
                      ) : (
                        <span className="text-black-50">--</span>
                      )}
                    </td>
                    <td style={groupStyles.grn}>{item.latestGrnQty || "--"}</td>

                    <td className="text-center" style={groupStyles.qty}>
                      {item.inStockQty}
                    </td>
                    <td className="text-center" style={groupStyles.qty}>
                      {item.reserveQty}
                    </td>

                    {/* PO Information Group */}
                    <td className="text-center" style={groupStyles.po}>
                      {item.openPOQty || 0}
                    </td>
                    <td className="text-center" style={groupStyles.po}>
                      {item.partialPOQty || 0}
                    </td>
                    <td className="text-center" style={groupStyles.po}>
                      <Amount
                        value={
                          (item.openPOValue || 0) + (item.partialPOValue || 0)
                        }
                      />
                    </td>
                    <td className="text-center" style={groupStyles.unique}>
                      {item.uniqueOpenPOCount || 0}
                    </td>
                    <td className="text-center" style={groupStyles.unique}>
                      {item.uniquePartialPOCount || 0}
                    </td>

                    {/* Sales History Group */}
                    <td
                      className={classNames("text-center", {
                        "text-muted": !item.last30DaysSaleQty,
                      })}
                    >
                      {item.last30DaysSaleQty}
                    </td>
                    <td
                      className={classNames("text-center", {
                        "text-muted": !item.last60DaysSaleQty,
                      })}
                    >
                      {item.last60DaysSaleQty}
                    </td>
                    <td
                      className={classNames("text-center", {
                        "text-muted": !item.last90DaysSaleQty,
                      })}
                    >
                      {item.last90DaysSaleQty}
                    </td>

                    <td>
                      <button
                        className="btn btn-link text-dark text-start fs-val-sm p-0 text-wrap"
                        onClick={() =>
                          handleFilterClick("menu", {
                            name: item.menuName,
                            _id: item.menuId,
                          })
                        }
                      >
                        {item.menuName}
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-link text-dark text-start fs-val-sm p-0 text-wrap"
                        onClick={() =>
                          handleFilterClick("category", {
                            name: item.categoryName,
                            _id: item.categoryId,
                          })
                        }
                      >
                        {item.categoryName}
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-link text-dark text-start fs-val-sm p-0 text-wrap"
                        onClick={() =>
                          handleFilterClick("brand", {
                            name: item.brandName,
                            _id: item.brandId,
                          })
                        }
                      >
                        {item.brandName}
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-link text-dark text-start fs-val-sm p-0 text-wrap"
                        onClick={() =>
                          handleFilterClick("companyName", {
                            name: item.companyName,
                          })
                        }
                      >
                        {item.companyName}
                      </button>
                    </td>

                    <td className="text-center">
                      <Amount value={item.mrp} decimalPlace={2} />
                    </td>
                    <td className="text-center">{item.innerPackQty}</td>
                    <td className="text-center">{item.caseQty}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className="text-end mt-3">
          <PaginationBlock
            paginationCb={paginationCb}
            loadingTotalRecords={records.loading}
            paginationConfig={paginationRef.current}
            size="sm"
          />
        </div>
      </AppCard>
      <BusyLoader show={busyLoader.show} />
    </>
  );
};

export default StockNotAllocatedTable;
