import { DashboardService, NavService } from "@sk/services";
import { Amount, TableHeader, InfoPopover } from "@sk/uis"; // Import InfoPopover
import classNames from "classnames";
import { produce } from "immer";
import { useEffect, useState } from "react";
import {
  FAST_MOVING,
  NON_MOVING,
  RECENT_PURCHASE,
  SLOW_MOVING,
  movementStatusDescriptions, // Import movementStatusDescriptions
} from "../constant";

const columns = [
  { label: "Movement Type", key: "movementType", width: "20%" },
  { label: "Products", key: "totalProducts", isCentered: true },
  { label: "Units", key: "totalUnits", isCentered: true },
  { label: "Value", key: "totalValue", isCentered: true },
];

const prepareFilterParams = (filters) => {
  let type = "getSellableSummaryByMovement";

  // Change query type if store filter is enabled
  if (filters.store?.length > 0) {
    type = "getStoreSellableSummaryByMovement";
  }

  const params = DashboardService.prepareInventoryAnalyticsParams(
    filters,
    type
  );

  return params;
};

const getData = async (params, isStoreWise = false) => {
  const response = isStoreWise
    ? await DashboardService.fetchStoreInventoryAnalytics(params)
    : await DashboardService.fetchInventoryAnalytics(params);
  return response.resp;
};

const InventoryAgeingMovementSummary = ({ filterData }) => {
  const [data, setData] = useState([
    {
      icon: "bi bi-speedometer", // Icon for Fast Moving
      movementType: FAST_MOVING,
      totalProducts: 0,
      totalUnits: 0,
      totalValue: 0,
      iconColor: "success",
    },
    {
      icon: "bi bi-hourglass-split", // Icon for Slow Moving
      movementType: SLOW_MOVING,
      totalProducts: 0,
      totalUnits: 0,
      totalValue: 0,
      iconColor: "warning",
    },
    {
      icon: "bi bi-box", // Icon for Non Moving
      movementType: NON_MOVING,
      totalProducts: 0,
      totalUnits: 0,
      totalValue: 0,
      iconColor: "danger",
    },
    {
      icon: "bi bi-cart-plus", // Icon for Recent Purchase
      movementType: RECENT_PURCHASE,
      totalProducts: 0,
      totalUnits: 0,
      totalValue: 0,
      iconColor: "info",
    },
  ]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const params = prepareFilterParams(filterData); // Prepare parameters using filterData
        const apiData = await getData(params, filterData?.store?.length > 0);

        setData(
          produce((draft) => {
            draft.forEach((item) => {
              // Find the corresponding item in apiData
              const apiItem = apiData.find(
                (apiItem) => apiItem.stockMovement === item.movementType
              );

              if (apiItem) {
                // Update existing item values
                item.totalProducts = apiItem.productIdCount;
                item.totalUnits = apiItem.totalQty;
                item.totalValue = apiItem.totalValue;
              } else {
                item.totalProducts = 0;
                item.totalUnits = 0;
                item.totalValue = 0;
              }
            });
          })
        ); // Update the state with the new data
      } catch (error) {
        console.error("Error loading movement summary:", error);
        setData((prevData) =>
          prevData.map((item) => ({
            ...item,
            totalProducts: 0,
            totalUnits: 0,
            totalValue: 0,
          }))
        ); // Reset product, total, and unit values on error
      }
    };
    loadData(); // Call the loadData function
  }, [filterData]); // Dependency on filterData

  const onMovementClick = (movementType) => {
    const params = DashboardService.prepareNavParamsForInventoryAgeing(
      filterData,
      [
        { key: "stockMovement", value: movementType },
        { key: "tab", value: "sellableInventory" },
      ]
    );
    NavService.openInNewTab("/dashboard/inventory-ageing/products", params);
  };

  return (
    <>
      <table className="table table-sm">
        <TableHeader data={columns} noBg />
        <tbody className="fs-val-md">
          {data.map((item, index) => (
            <tr key={index}>
              <td className="fw-semibold">
                <div className="d-inline-flex align-items-center">
                  <i
                    className={classNames(
                      "bi me-1",
                      item.icon,
                      "text-" + item.iconColor
                    )}
                  />
                  <button
                    className="btn btn-link text-dark text-start p-0 fs-val-md flex-shrink-1"
                    onClick={() => onMovementClick(item.movementType)}
                  >
                    {item.movementType}
                  </button>
                  <InfoPopover
                    content={movementStatusDescriptions[item.movementType]} // Add InfoPopover with description
                  />
                </div>
              </td>
              <td className="text-center">{item.totalProducts}</td>
              <td className="text-center">{item.totalUnits}</td>
              <td className="text-center">
                <Amount value={item.totalValue} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default InventoryAgeingMovementSummary;
