import { DataRefreshNote } from "@sk/features";
import { AuthService, CommonService, DashboardService } from "@sk/services";
import { AppCard, AppliedFilterLabel, PageInfo, PageLoader } from "@sk/uis";
import { produce } from "immer";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AgeingTable from "./components/AgeingTable";
import Filter from "./components/Filter";
import MainTabs from "./components/MainTabs";
import StockNotAllocatedDateWise from "./components/StockNotAllocatedDateWise";
import StockNotAllocatedMenuTable from "./components/StockNotAllocatedMenuTable";
import StockNotAllocatedNoPoSummary from "./components/StockNotAllocatedNoPoSummary";
import StockNotAllocatedNoPoTable from "./components/StockNotAllocatedNoPoTable";
import StockNotAllocatedSummary from "./components/StockNotAllocatedSummary";
import StockNotAllocatedTable from "./components/StockNotAllocatedTable";
import dashboardView from "./constantService";
import HasStockOrderPlacedNotAllocated from "./components/HasStockOrderPlacedNotAllocated";

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Order Placed No Stock",
  },
];

const appliedFilterStyle = {
  top: "64px",
  zIndex: 500,
};

const defaultFilterLabels = dashboardView.notAllocatedOrder.formLabels;

const defaultFilterData = {
  companyName: [],
  product: [],
  category: [],
  menu: [],
  brand: [],
  warehouse: AuthService.getDefaultWhId(),
};

const NotAllocatedOrder = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState({
    key: "overview",
    tabName: "Overview",
  });

  const [loading, setLoading] = useState(true);

  const [batchDetails, setBatchDetails] = useState({});

  const [filterLabels, setFilterLabels] = useState([]);

  const [filterData, setFilterData] = useState({ ...defaultFilterData });

  const [isFilterSticky, setIsFilterSticky] = useState(false);
  const filterRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (filterRef.current) {
        const scrollPosition = window.scrollY;
        const triggerPosition = 100;
        setIsFilterSticky(scrollPosition > triggerPosition);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        prepareFilterLabels(defaultFilterData);
        setLoading(true);
        const res = await DashboardService.getBatchId();
        setBatchDetails(res?.resp);
        setFilterData((prev) => ({ ...prev, batchId: res?.resp?.batchId }));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching batch details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [prepareFilterLabels]);

  useEffect(() => {
    if (filterData.store && filterData.store.length > 0) {
      setActiveTab({
        key: "overview",
        tabName: "Overview",
      });
    }
  }, [filterData.store]);

  const filterCb = useCallback(
    async (data) => {
      const d = produce(filterData, (draft) => {
        return { ...draft, ...data.formData };
      });
      setFilterData(d);
      prepareFilterLabels(d);
    },
    [filterData, prepareFilterLabels]
  );

  const removeFilterCb = useCallback(
    (data) => {
      const d = produce(filterData, (draft) => {
        draft[data.data.key] = defaultFilterData[data.data.key];

        if (data.data.key === "store") {
          draft.storeName = "";
        }

        return draft;
      });
      setFilterData(d);
      prepareFilterLabels(d);
    },
    [filterData, prepareFilterLabels]
  );

  const prepareFilterLabels = useCallback((data) => {
    const v = { ...data };

    delete v.batchId;
    delete v.storeName;

    if (!v.hideUnlinkedBrands) {
      delete v.hideUnlinkedBrands;
    }

    const l = CommonService.prepareAppliedFilterLabels(defaultFilterLabels, v);

    setFilterLabels(l);
  }, []);

  const tabCb = useCallback((e) => {
    setActiveTab(e);
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div>
      <div className="d-flex my-3">
        <div className="flex-grow-1">
          <PageInfo
            title="Dashboard - Order Placed No Stock"
            breadcrumbs={breadcrumbs}
            navigate={navigate}
            noMargin={true}
          />
        </div>
        <DataRefreshNote
          lastSyncDate={batchDetails.batchTimestamp}
          refreshTime={15}
        />
      </div>

      <AppCard>
        <Filter callback={filterCb} filterData={filterData} />
      </AppCard>

      {filterLabels.length > 0 ? (
        <div
          ref={filterRef}
          style={appliedFilterStyle}
          className={`mb-3 position-sticky ${
            isFilterSticky ? "bg-white shadow-sm px-3 py-2" : ""
          }`}
        >
          <AppliedFilterLabel labels={filterLabels} callback={removeFilterCb} />
        </div>
      ) : null}

      <div className="row mb-2">
        <div className="col-md-6">
          <div className="fs-val-lg fw-semibold mb-1">Overall Summary</div>
          <StockNotAllocatedSummary filterData={filterData} />
        </div>
        <div className="col-md-6">
          <div className="fs-val-lg fw-semibold mb-1">Summary (No PO)</div>
          <StockNotAllocatedNoPoSummary filterData={filterData} />
        </div>
      </div>

      {!filterData.store || filterData.store.length === 0 ? (
        <div>
          <MainTabs
            callback={tabCb}
            activeTab={activeTab}
            filterData={filterData}
          />
        </div>
      ) : null}

      {activeTab.key == "overview" ? (
        <div className="row">
          {!filterData.store || filterData.store.length === 0 ? (
            <>
              <div className="col-md-6">
                <AgeingTable filterData={filterData} />
              </div>
              <div className="col-md-6">
                <StockNotAllocatedDateWise filterData={filterData} />
              </div>
            </>
          ) : null}
          <div className="col-md-12">
            <StockNotAllocatedTable
              filterData={filterData}
              callback={filterCb}
            />
          </div>
        </div>
      ) : null}

      {activeTab.key == "stock" ? (
        <div className="row">
          <div className="col-md-12">
            <StockNotAllocatedMenuTable
              filterData={filterData}
              callback={filterCb}
            />
          </div>
          <div className="col-md-12">
            <StockNotAllocatedNoPoTable
              filterData={filterData}
              callback={filterCb}
            />
          </div>
        </div>
      ) : null}

      {activeTab.key == "hasStockNotAllocated" ? (
        <div className="row">
          <div className="col-md-12">
            <HasStockOrderPlacedNotAllocated filterData={filterData} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NotAllocatedOrder;
