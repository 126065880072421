import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo } from "react";

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "Customer ID", isCentered: true },
  { label: "Customer Name", isCentered: true },
  { label: "Total Coins", isCentered: true },
  { label: "Is Club Customer", isCentered: true },
  { label: "Customer State", isCentered: true },
  { label: "Customer District", isCentered: true },
  { label: "Customer Town", isCentered: true },
  {
    label: "No. Of Orders placed with Order ID",
    isCentered: true,
    // key: "UniqueCustomerCount",
    // enableSort: true,
  },
  { label: "Created On", isCentered: true, key: "createdAt", enableSort: true },
];

const rbac = {
  viewButton: ["ViewRewardedCoins"],
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  viewOrderCb,
  sort = {},
  sortCb,
}) => {
  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />
        <tbody>
          {loading ? (
            <TableSkeletonLoader
              cols={headers.length}
              rows={paginationConfig.rowsPerPage}
            />
          ) : null}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound> No Data Found </NoDataFound>
              </td>
            </tr>
          ) : null}
          {!loading &&
            data.map((x, index) => (
              <tr
                key={index}
                className="fs-val-md"
                style={x?._updated ? rowBg : {}}
              >
                {/* serial no. */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                <td className="text-center">{x?.customerId}</td>

                <td className="text-center">{x?.customerName}</td>

                <td className="text-center">{x?.smartCoinsBalance}</td>

                <td className="text-center">
                  <HighlightText
                    status={x?.isClubCustomer ? "Yes" : "No"}
                    isBadge={true}
                  />
                </td>

                <td className="text-center">{x?.customerState || "N/A"}</td>

                <td className="text-center">{x?.customerDistrict || "N/A"}</td>

                <td className="text-center">{x?.customerCity || "N/A"}</td>

                <td className="text-center">{x?.totalOrders}</td>

                <td className="text-center">
                  <DateFormatter date={x?.createdAt} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* pagination block */}
      {!loading && data.length > 0 && (
        <PaginationBlock
          paginationConfig={paginationConfig}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
        />
      )}
    </>
  );
};

export default memo(Table);
