import React, { useState, useCallback } from "react";
import {
  AppCard,
  Tabs,
  TableHeader,
  TextInput,
  SelectInput,
  Amount,
  DateFormatter,
} from "@sk/uis";
import { useForm } from "react-hook-form";
import debounce from "lodash/debounce";

const tabData = [
  { key: "store", tabName: "Store Wise" },
  { key: "catalog", tabName: "Catalog Wise" },
  { key: "customer", tabName: "Customer Wise" },
];

const catalogOptions = [
  { value: "product", label: "Product" },
  { value: "menu", label: "Menu" },
  { value: "brand", label: "Brand" },
  { value: "category", label: "Category" },
];

const tableHeaders = {
  store: [
    { label: "Store Name", key: "name", enableSort: true },
    { label: "State", key: "state", enableSort: true },
    { label: "District", key: "district", enableSort: true },
    { label: "Town", key: "town", enableSort: true },
    { label: "Pincode", key: "pincode", enableSort: true },
    { label: "ID", key: "id", enableSort: true },
    { label: "Total Sales", key: "totalSales", enableSort: true },
    { label: "Total Orders", key: "totalOrders", enableSort: true },
    { label: "Avg Order Value", key: "avgOrderValue", enableSort: true },
    { label: "Unique Customers", key: "uniqueCustomers", enableSort: true },
    { label: "Inactive Customers", key: "inactiveCustomers", enableSort: true },
  ],
  catalog: [
    { label: "Name", key: "name", enableSort: true },
    { label: "Quantity Sold", key: "quantitySold", enableSort: true },
    { label: "Total Revenue", key: "totalRevenue", enableSort: true },
    { label: "Avg Price", key: "avgPrice", enableSort: true },
    { label: "Unique Customers", key: "uniqueCustomers", enableSort: true },
  ],
  customer: [
    { label: "Customer ID", key: "id", enableSort: true },
    { label: "Customer Name", key: "name", enableSort: true },
    { label: "Registered On", key: "registeredOn", enableSort: true },
    { label: "Total Purchases", key: "totalPurchases", enableSort: true },
    { label: "Total Spent", key: "totalSpent", enableSort: true },
    { label: "Avg Order Value", key: "avgOrderValue", enableSort: true },
  ],
};

// Updated mock data
const mockData = {
  store: [
    {
      name: "Store A",
      state: "Karnataka",
      district: "Bangalore Urban",
      town: "Bangalore",
      pincode: "560001",
      id: "ST001",
      totalSales: 500000,
      totalOrders: 1000,
      avgOrderValue: 500,
      uniqueCustomers: 750,
      inactiveCustomers: 50,
    },
    {
      name: "Store B",
      state: "Maharashtra",
      district: "Mumbai City",
      town: "Mumbai",
      pincode: "400001",
      id: "ST002",
      totalSales: 400000,
      totalOrders: 800,
      avgOrderValue: 500,
      uniqueCustomers: 600,
      inactiveCustomers: 40,
    },
    {
      name: "Store C",
      state: "Tamil Nadu",
      district: "Chennai",
      town: "Chennai",
      pincode: "600001",
      id: "ST003",
      totalSales: 450000,
      totalOrders: 900,
      avgOrderValue: 500,
      uniqueCustomers: 700,
      inactiveCustomers: 45,
    },
    {
      name: "Store D",
      state: "Delhi",
      district: "New Delhi",
      town: "New Delhi",
      pincode: "110001",
      id: "ST004",
      totalSales: 550000,
      totalOrders: 1100,
      avgOrderValue: 500,
      uniqueCustomers: 800,
      inactiveCustomers: 55,
    },
    {
      name: "Store E",
      state: "West Bengal",
      district: "Kolkata",
      town: "Kolkata",
      pincode: "700001",
      id: "ST005",
      totalSales: 350000,
      totalOrders: 700,
      avgOrderValue: 500,
      uniqueCustomers: 550,
      inactiveCustomers: 35,
    },
    {
      name: "Store F",
      state: "Telangana",
      district: "Hyderabad",
      town: "Hyderabad",
      pincode: "500001",
      id: "ST006",
      totalSales: 420000,
      totalOrders: 840,
      avgOrderValue: 500,
      uniqueCustomers: 650,
      inactiveCustomers: 42,
    },
    {
      name: "Store G",
      state: "Gujarat",
      district: "Ahmedabad",
      town: "Ahmedabad",
      pincode: "380001",
      id: "ST007",
      totalSales: 380000,
      totalOrders: 760,
      avgOrderValue: 500,
      uniqueCustomers: 580,
      inactiveCustomers: 38,
    },
    {
      name: "Store H",
      state: "Rajasthan",
      district: "Jaipur",
      town: "Jaipur",
      pincode: "302001",
      id: "ST008",
      totalSales: 320000,
      totalOrders: 640,
      avgOrderValue: 500,
      uniqueCustomers: 500,
      inactiveCustomers: 32,
    },
    {
      name: "Store I",
      state: "Uttar Pradesh",
      district: "Lucknow",
      town: "Lucknow",
      pincode: "226001",
      id: "ST009",
      totalSales: 300000,
      totalOrders: 600,
      avgOrderValue: 500,
      uniqueCustomers: 450,
      inactiveCustomers: 30,
    },
    {
      name: "Store J",
      state: "Madhya Pradesh",
      district: "Indore",
      town: "Indore",
      pincode: "452001",
      id: "ST010",
      totalSales: 280000,
      totalOrders: 560,
      avgOrderValue: 500,
      uniqueCustomers: 420,
      inactiveCustomers: 28,
    },
  ],
  catalog: {
    product: [
      {
        name: "Product X",
        quantitySold: 500,
        totalRevenue: 50000,
        avgPrice: 100,
        uniqueCustomers: 300,
      },
      {
        name: "Product Y",
        quantitySold: 400,
        totalRevenue: 40000,
        avgPrice: 100,
        uniqueCustomers: 250,
      },
      {
        name: "Product Z",
        quantitySold: 600,
        totalRevenue: 72000,
        avgPrice: 120,
        uniqueCustomers: 350,
      },
      {
        name: "Product A",
        quantitySold: 300,
        totalRevenue: 45000,
        avgPrice: 150,
        uniqueCustomers: 200,
      },
      {
        name: "Product B",
        quantitySold: 450,
        totalRevenue: 54000,
        avgPrice: 120,
        uniqueCustomers: 280,
      },
      {
        name: "Product C",
        quantitySold: 550,
        totalRevenue: 66000,
        avgPrice: 120,
        uniqueCustomers: 320,
      },
      {
        name: "Product D",
        quantitySold: 350,
        totalRevenue: 35000,
        avgPrice: 100,
        uniqueCustomers: 220,
      },
      {
        name: "Product E",
        quantitySold: 480,
        totalRevenue: 57600,
        avgPrice: 120,
        uniqueCustomers: 290,
      },
      {
        name: "Product F",
        quantitySold: 420,
        totalRevenue: 50400,
        avgPrice: 120,
        uniqueCustomers: 260,
      },
      {
        name: "Product G",
        quantitySold: 380,
        totalRevenue: 45600,
        avgPrice: 120,
        uniqueCustomers: 240,
      },
    ],
    // You can add similar data for menu, brand, and category if needed
  },
  customer: [
    {
      id: "CUST001",
      name: "Rahul Sharma",
      registeredOn: "2022-01-15T10:30:00Z",
      totalPurchases: 20,
      totalSpent: 10000,
      avgOrderValue: 500,
    },
    {
      id: "CUST002",
      name: "Priya Patel",
      registeredOn: "2022-02-20T14:45:00Z",
      totalPurchases: 15,
      totalSpent: 7500,
      avgOrderValue: 500,
    },
    {
      id: "CUST003",
      name: "Amit Kumar",
      registeredOn: "2022-03-10T09:15:00Z",
      totalPurchases: 18,
      totalSpent: 9000,
      avgOrderValue: 500,
    },
    {
      id: "CUST004",
      name: "Sneha Gupta",
      registeredOn: "2022-04-05T16:20:00Z",
      totalPurchases: 22,
      totalSpent: 11000,
      avgOrderValue: 500,
    },
    {
      id: "CUST005",
      name: "Vikram Singh",
      registeredOn: "2022-05-12T11:00:00Z",
      totalPurchases: 17,
      totalSpent: 8500,
      avgOrderValue: 500,
    },
    {
      id: "CUST006",
      name: "Neha Reddy",
      registeredOn: "2022-06-18T13:30:00Z",
      totalPurchases: 19,
      totalSpent: 9500,
      avgOrderValue: 500,
    },
    {
      id: "CUST007",
      name: "Rajesh Verma",
      registeredOn: "2022-07-22T10:45:00Z",
      totalPurchases: 16,
      totalSpent: 8000,
      avgOrderValue: 500,
    },
    {
      id: "CUST008",
      name: "Anita Desai",
      registeredOn: "2022-08-30T15:15:00Z",
      totalPurchases: 21,
      totalSpent: 10500,
      avgOrderValue: 500,
    },
    {
      id: "CUST009",
      name: "Sanjay Mehta",
      registeredOn: "2022-09-14T12:00:00Z",
      totalPurchases: 14,
      totalSpent: 7000,
      avgOrderValue: 500,
    },
    {
      id: "CUST010",
      name: "Pooja Choudhury",
      registeredOn: "2022-10-25T09:30:00Z",
      totalPurchases: 23,
      totalSpent: 11500,
      avgOrderValue: 500,
    },
  ],
};

const Sales = () => {
  const [activeTab, setActiveTab] = useState(tabData[0]);
  const [catalogType, setCatalogType] = useState("product");
  const [searchTerm, setSearchTerm] = useState("");
  const [sort, setSort] = useState({ key: "", value: "" });
  const { register } = useForm();

  const handleSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      // Here you would typically fetch filtered data based on the search term
    }, 300),
    []
  );

  const getTableData = () => {
    if (activeTab.key === "catalog") {
      return mockData.catalog[catalogType];
    }
    return mockData[activeTab.key];
  };

  return (
    <AppCard>
      <div className="border-bottom">
        <Tabs
          data={tabData}
          callback={({ value }) => setActiveTab(value)}
          activeTab={activeTab}
          template={4}
        />
      </div>
      <div className="my-3 row">
        <div className="col-4">
          <TextInput
            type="text"
            placeholder="Search..."
            register={register}
            name="search"
            callback={(e) => handleSearch(e.target.value)}
            gap={0}
          />
        </div>
        {activeTab.key === "catalog" && (
          <div className="col-3">
            <SelectInput
              options={catalogOptions}
              register={register}
              name="catalogType"
              callback={(value) => setCatalogType(value)}
              defaultValue={catalogType}
              gap={0}
            />
          </div>
        )}
      </div>
      <div className="text-muted fs-val-md mb-1">Showing 10 results</div>
      <div className="table-responsive">
        <table className="table table-hover table-sm">
          <TableHeader
            data={tableHeaders[activeTab.key]}
            sort={sort}
            sortCb={setSort}
          />
          <tbody className="fs-val-md">
            {getTableData().map((item, index) => (
              <tr key={index}>
                {Object.keys(item).map((key) => (
                  <td key={key}>
                    {key === "registeredOn" ? (
                      <DateFormatter date={item[key]} format="dd MMM yyyy" />
                    ) : key.includes("total") ||
                      key.includes("avg") ||
                      key === "totalSpent" ? (
                      <Amount value={item[key]} />
                    ) : key === "inactiveCustomers" ? (
                      <span className="text-danger">{item[key]}</span>
                    ) : (
                      item[key]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AppCard>
  );
};

export default Sales;
