import {
  CommonService,
  CustomerService,
  FranchiseService,
  NavService,
} from "@sk/services";
import {
  Amount,
  BusyLoader,
  DateFormatter,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
  BtnLink,
} from "@sk/uis";
import { endOfDay, startOfDay, sub } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import CoinsDashAppliedFilter from "../CoinsDashAppliedFilter";

const tableStyle = {
  minWidth: "2200px",
};

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 30,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 30,
};

const headers = [
  { label: "S.No", width: "40px" },
  {
    label: "Customer Details",
    width: "200px",
    enableSort: true,
    key: "customerName",
    isSticky: true,
  },
  { label: "Registered Store", width: "200px" },
  {
    label: "Registered On",
    width: "150px",
    enableSort: true,
    key: "registeredOn",
  },
  {
    label: "Total Orders",
    width: "150px",
    enableSort: true,
    key: "tillDateOrderCount",
    isCentered: true,
  },
  {
    label: "Total Order Value",
    width: "150px",
    enableSort: true,
    key: "tillDateOrderValue",
    isCentered: true,
  },
  {
    label: "First Order",
    width: "100px",
    enableSort: true,
    key: "firstOrder.orderDate",
  },
  {
    label: "Last Order",
    width: "100px",
    enableSort: true,
    key: "lastOrder.orderDate",
  },
  {
    label: "Available KingCoins",
    width: "150px",
    enableSort: true,
    key: "availableCoins",
    isCentered: true,
  },
  {
    label: "Redeemed KingCoins",
    width: "180px",
    enableSort: true,
    key: "tillNowRedeemedCoins",
    isCentered: true,
  },
  {
    label: "Earned KingCoins",
    width: "150px",
    enableSort: true,
    key: "tillNowRewardedCoins",
    isCentered: true,
  },
  { label: "Own Club", width: "200px", enableSort: true, key: "ownClub" },
  {
    label: "Part of Club",
    width: "200px",
    enableSort: true,
    key: "partOfClub",
  },
  { label: "Action", width: "100px" },
];

const customerNameCol = {
  position: "sticky",
  left: 0,
  backgroundColor: "white",
  zIndex: 992,
};

const totalOrdersStyle = { backgroundColor: "#fafbff" };
const orderDatesStyle = { backgroundColor: "#fff8f1" };
const kingCoinsStyle = { backgroundColor: "#f7fff7" };

const filterOptions = [
  { value: "activeCustomer", label: "Active Customer" },
  { value: "availableCoins", label: "Available Coins > 0" },
  { value: "hasClub", label: "Club Owner" },
  { value: "hasPlacedOrder", label: "Has Placed Order" },
  { value: "hasRedeemedCoins", label: "Has Redeemed Coins" },
  { value: "inactiveCustomer", label: "In Active Customer" },
  { value: "noAvailableCoins", label: "No Available Coins" },
  { value: "noClub", label: "Not in Any Club" },
  { value: "noRedeemedCoins", label: "Never Redeemed Coins" },
  { value: "partOfClub", label: "Part of Club" },
  // { value: "coinStoreBlocked", label: "Coin Store Blocked" },
].sort((a, b) => a.label.localeCompare(b.label));
filterOptions.unshift({ value: "", label: "Select Filter" });

const filterDescriptions = {
  hasPlacedOrder: "Showing customers who have placed at least one order",
  availableCoins: "Showing customers who have KingCoins available to redeem",
  noAvailableCoins: "Showing customers who have zero KingCoins balance",
  activeCustomer:
    "Showing customers who have placed at least one order in the last 30 days",
  inactiveCustomer:
    "Showing customers who haven't placed any orders in the last 30 days",
  hasClub: "Showing customers who own a club",
  partOfClub:
    "Showing customers who are members of a club but do not have their own club",
  noClub: "Showing customers who neither own nor are part of any club",
  hasRedeemedCoins:
    "Showing customers who have redeemed KingCoins at least once",
  noRedeemedCoins: "Showing customers who have never redeemed any KingCoins",
  coinStoreBlocked: "Showing customers who have their coin store blocked",
};

// Add this style constant instead
const chipStyle = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
};

// Function to fetch customer data
const getData = async (params, signal) => {
  const response = await CustomerService.getCustomerInsights(params, {
    signal,
  });
  const data = Array.isArray(response.resp) ? response.resp : [];
  const fids = data.map((e) => e.underFranchise);
  if (fids?.length) {
    const franchisesResp = await FranchiseService.getFranchises({
      filter: {
        _id: { $in: fids },
      },
    });
    const franchises = Array.isArray(franchisesResp.resp)
      ? franchisesResp.resp
      : [];
    data.forEach((e) => {
      e.franchise = franchises.find((f) => f._id == e.underFranchise);
    });
  }
  return { data, raw: response };
};

// Function to get the count of customers
const getCount = async (params, signal) => {
  const response = await CustomerService.getCustomerInsights(
    {
      ...params,
      outputType: "count",
    },
    { signal }
  );
  return {
    count:
      Array.isArray(response.resp) && response.resp.length > 0
        ? response.resp[0].total
        : 0,
  };
};

// Function to prepare filter parameters
const prepareFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      franchise: {
        $in: filter.accessibleStoreIds,
      },
    },
    sort: { [sort.key]: sort.value == "asc" ? 1 : -1 },
    matchFilter: {},
    clubFilter: {},
  };

  if (filter.fromDate && filter.toDate) {
    p.filter.createdAt = {
      $gte: startOfDay(filter.fromDate),
      $lte: endOfDay(filter.toDate),
    };
  }

  if (filter.storeId) {
    p.filter.franchise = filter.storeId;
  }

  if (filter.gender) {
    p.filter.gender = filter.gender;
  }

  if (filter.cid) {
    p.filter._id = filter.cid;
  }

  // Handle filter options
  switch (filter.filterOption) {
    case "hasPlacedOrder":
      p.matchFilter = { tillDateOrderCount: { $gt: 0 } };
      break;
    case "availableCoins":
      p.matchFilter = { availableCoins: { $gt: 0 } };
      break;
    case "noAvailableCoins":
      p.matchFilter = { availableCoins: { $eq: 0 } };
      break;
    case "activeCustomer":
      p.clubFilter["lastOrder.orderDate"] = {
        $gte: startOfDay(sub(new Date(), { months: 1 })),
      };
      break;
    case "inactiveCustomer":
      p.clubFilter["lastOrder.orderDate"] = {
        $lt: startOfDay(sub(new Date(), { months: 1 })),
      };
      break;
    case "hasClub":
      p.clubFilter = { ownClub: { $ne: "" } };
      break;
    case "partOfClub":
      p.clubFilter = { partOfClub: { $ne: "" } };
      break;
    case "noClub":
      p.clubFilter = {
        ownClub: { $eq: "" },
        partOfClub: { $eq: "" },
      };
      break;
    case "hasRedeemedCoins":
      p.matchFilter = { tillNowRedeemedCoins: { $gt: 0 } };
      break;
    case "noRedeemedCoins":
      p.matchFilter = {
        tillNowRedeemedCoins: { $eq: 0 },
        availableCoins: { $gt: 0 },
      };
      break;
  }

  if (filter.searchText) {
    p.matchFilter.$or = [
      { customerName: { $regex: filter.searchText, $options: "i" } },
      { _id: filter.searchText },
    ];
  }

  if (filter.clubSearchText) {
    p.clubFilter.$or = [
      { partOfClub: { $regex: filter.clubSearchText, $options: "i" } },
      { ownClub: { $regex: filter.clubSearchText, $options: "i" } },
    ];
  }

  if (!Object.keys(p.matchFilter).length) {
    delete p.matchFilter;
  }

  if (!Object.keys(p.clubFilter).length) {
    delete p.clubFilter;
  }

  return p;
};

const CustomerInsight = ({
  fromDate,
  toDate,
  cid,
  cname,
  callback,
  storeId,
  storeName,
  accessibleStoreIds,
}) => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [records, setRecords] = useState({ loading: true, value: 0 });
  const [busyLoader, setBusyLoader] = useState({ show: false });
  const [filterOption, setFilterOption] = useState("");
  const [searchText, setSearchText] = useState("");
  const [clubSearchText, setClubSearchText] = useState("");

  const paginationRef = useRef({ ...defaultPaginationConfig });
  const filterDataRef = useRef({});
  const sortDataRef = useRef({
    key: "registeredOn",
    value: "desc",
  });

  const abortControllerRef = useRef(new AbortController());

  const debouncedApplyFilter = useCallback(
    debounce(() => {
      applyFilter();
    }, 500),
    []
  );

  const handleSearchChange = useCallback(
    (e) => {
      const value = e.target.value;
      setSearchText(value);
      if (value) {
        delete filterDataRef.current.fromDate;
        delete filterDataRef.current.toDate;
        delete filterDataRef.current.storeId;
      } else {
        filterDataRef.current.fromDate = fromDate;
        filterDataRef.current.toDate = toDate;
        filterDataRef.current.storeId = storeId;
      }
      filterDataRef.current.searchText = value;
      debouncedApplyFilter();
    },
    [debouncedApplyFilter, fromDate, toDate, storeId]
  );

  const handleClubSearchChange = useCallback(
    (e) => {
      const value = e.target.value;
      setClubSearchText(value);
      filterDataRef.current.clubSearchText = value;
      debouncedApplyFilter();
    },
    [debouncedApplyFilter]
  );

  const loadList = useCallback(async () => {
    const p = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortDataRef.current
    );
    setLoadingData(true);
    setData([]);
    const response = await getData(p, abortControllerRef.current.signal);
    setData(response.data);
    setLoadingData(false);
  }, []);

  const applyFilter = useCallback(async () => {
    abortControllerRef.current.abort(); // Abort any ongoing requests
    abortControllerRef.current = new AbortController(); // Create a new instance for future requests

    paginationRef.current = { ...defaultPaginationConfig };
    setLoadingData(true);
    setData([]);
    const p = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortDataRef.current
    );
    const r = await getCount(p, abortControllerRef.current.signal);
    setRecords({ loading: false, value: r.count });
    paginationRef.current.totalRecords = r.count;
    loadList();
  }, [loadList]);

  useEffect(() => {
    filterDataRef.current = {
      ...filterDataRef.current,
      fromDate,
      toDate,
      cid,
      storeId,
      accessibleStoreIds,
    };

    setSearchText("");
    setClubSearchText("");

    applyFilter();

    return () => {
      abortControllerRef.current.abort(); // Abort the ongoing requests
      abortControllerRef.current = new AbortController(); // Create a new instance for future requests
    };
  }, [accessibleStoreIds, applyFilter, cid, storeId, fromDate, toDate]);

  const paginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
  };

  const onSortCb = (data) => {
    sortDataRef.current = {
      key: data.key,
      value: data.value,
    };
    applyFilter();
  };

  const viewClub = (cid) => {
    callback({ action: "viewClub", id: cid });
  };

  const viewCustomerDetail = (cid) => {
    callback({ action: "viewCustomer", id: cid });
  };

  const downloadData = async () => {
    const p = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortDataRef.current
    );
    delete p.page;
    delete p.count;

    p.outputType = "download";

    setBusyLoader({ show: true });
    const response = await getData(p);
    setBusyLoader({ show: false });
    if (response.raw?.statusCode == 200) {
      const url = response.raw.resp?.downloadLink;
      if (url) {
        CommonService.downloadAsset(url);
      } else {
        Toaster.error("Failed to download data");
      }
    } else {
      Toaster.error(response.raw?.message || "Failed to download data");
    }
  };

  const viewOrder = (oid) => {
    callback({ action: "viewOrder", id: oid });
  };

  const handleFilterChange = (e) => {
    const selectedValue = e.target.value;
    setFilterOption(selectedValue);
    filterDataRef.current.filterOption = selectedValue;
    applyFilter();
  };

  return (
    <div>
      <div className="row my-2">
        <div className="col-auto">
          <PaginationSummary
            paginationConfig={paginationRef.current}
            loadingTotalRecords={records.loading}
            fwSize="md"
            className="mb-0"
          />
        </div>
        <div className="col-2 ms-auto">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Search by Customer Name/ID"
            value={searchText}
            onChange={handleSearchChange}
          />
        </div>
        <div className="col-2">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Search by Own Club"
            value={clubSearchText}
            onChange={handleClubSearchChange}
          />
        </div>
        <div className="col-auto">
          <select
            className="form-select form-select-sm"
            value={filterOption}
            onChange={handleFilterChange}
            style={{ width: "auto" }}
          >
            {filterOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-auto">
          <button
            className="btn btn-sm btn-outline-primary fs-val-md"
            onClick={downloadData}
          >
            <i className="bi bi-file-earmark-arrow-down" />
            Download
          </button>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-auto">
          <CoinsDashAppliedFilter
            cname={cname}
            fromDate={filterDataRef.current.fromDate}
            toDate={filterDataRef.current.toDate}
            storeName={filterDataRef.current.storeId ? storeName : ""}
          />
        </div>
        {filterOption && (
          <div className="col-auto">
            <span
              className="fs-val-sm badge badge-soft-primary"
              style={chipStyle}
            >
              <i className="bi bi-info-circle me-1"></i>
              <span>{filterDescriptions[filterOption]}</span>
            </span>
          </div>
        )}
      </div>

      <div className="table-responsive custom-scrollbar">
        <table
          className="table bg-white table-hover table-sm"
          style={tableStyle}
        >
          <TableHeader
            data={headers}
            noBg={true}
            sort={sortDataRef.current}
            sortCb={onSortCb}
          />
          <tbody className="fs-val-md">
            {loadingData ? (
              <TableSkeletonLoader
                cols={headers.length}
                rows={10}
                height={20}
              />
            ) : null}

            {!loadingData && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Data found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {data.map((e, index) => (
              <tr key={e._id + ":" + index}>
                <td>{paginationRef.current.startSlNo + index}</td>
                <td style={customerNameCol}>
                  <BtnLink
                    className="mb-1 fs-val-md"
                    onClick={() => viewCustomerDetail(e._id)}
                  >
                    {e.customerName}
                  </BtnLink>
                  <div className="text-black-50 fs-val-sm mb-1">
                    {e.customerDistrict}, {e.customerState} -{" "}
                    {e.customerPincode}
                  </div>
                </td>
                <td>
                  <BtnLink
                    className="fs-val-md"
                    onClick={() =>
                      NavService.openInNewTab(
                        `/pos/sales-analytics?fid=${e.underFranchise}`
                      )
                    }
                  >
                    {e.franchise?.name || "N/A"}
                  </BtnLink>
                </td>
                <td>
                  <DateFormatter date={e.registeredOn} format="dd MMM yyyy" />
                </td>
                <td className="text-center" style={totalOrdersStyle}>
                  {e.tillDateOrderCount || 0}
                </td>
                <td className="text-center" style={totalOrdersStyle}>
                  <Amount value={e.tillDateOrderValue || 0} />
                </td>
                <td style={orderDatesStyle}>
                  {e.firstOrder?.orderDate ? (
                    <>
                      <div className="mb-1">
                        <DateFormatter
                          date={e.firstOrder?.orderDate}
                          format="dd MMM yyyy"
                        />
                      </div>
                      <div>
                        <BtnLink
                          className="fs-val-md"
                          onClick={() => viewOrder(e.firstOrder?.orderId)}
                        >
                          {e.firstOrder?.orderId}
                        </BtnLink>
                      </div>
                    </>
                  ) : (
                    <div className="text-center">--</div>
                  )}
                </td>
                <td style={orderDatesStyle}>
                  {e.lastOrder?.orderDate ? (
                    <>
                      <div className="mb-1">
                        <DateFormatter
                          date={e.lastOrder?.orderDate}
                          format="dd MMM yyyy"
                          className="fw-bold"
                        />
                      </div>
                      <div>
                        <BtnLink
                          className="fs-val-md"
                          onClick={() => viewOrder(e.lastOrder?.orderId)}
                        >
                          {e.lastOrder?.orderId}
                        </BtnLink>
                      </div>
                    </>
                  ) : (
                    <div className="text-center">--</div>
                  )}
                </td>
                <td style={kingCoinsStyle} className="text-center">
                  {e.availableCoins || 0}
                </td>
                <td style={kingCoinsStyle} className="text-center text-danger">
                  {e.tillNowRedeemedCoins || 0}
                </td>
                <td style={kingCoinsStyle} className="text-center">
                  {e.tillNowRewardedCoins || 0}
                </td>
                <td>
                  {e.ownClub ? (
                    <BtnLink
                      className="fs-val-md"
                      onClick={() => viewClub(e._id)}
                    >
                      {e.ownClub || "N/A"}
                    </BtnLink>
                  ) : (
                    "--"
                  )}
                </td>
                <td>
                  {e.partOfClub ? (
                    <BtnLink
                      className="fs-val-md"
                      onClick={() => viewClub(e.parentCustomerId)}
                    >
                      {e.partOfClub || "N/A"}
                    </BtnLink>
                  ) : (
                    "--"
                  )}
                </td>
                <td>
                  <div className="btn-group" role="group">
                    <button
                      className="btn btn-sm btn-outline-primary"
                      onClick={() => viewCustomerDetail(e._id)}
                    >
                      <i className="bi bi-eye" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="text-end mt-2">
        <PaginationBlock
          loadingTotalRecords={records.loading}
          paginationCb={paginationCb}
          paginationConfig={paginationRef.current}
          size="sm"
        />
      </div>

      <BusyLoader show={busyLoader.show} />
    </div>
  );
};

export default CustomerInsight;
