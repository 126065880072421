import { DashboardService, NavService } from "@sk/services";
import { AppCard, Amount } from "@sk/uis";
import { useEffect, useState } from "react";
import { NEAR_EXPIRY } from "../constant";

const prepareFilterParams = (filters) => {
  let type = "getNearExpiryInventorySummary";

  // Change query type if store filter is enabled
  if (filters.store?.length > 0) {
    type = "getStoreNearExpiryInventorySummary";
  }

  const params = DashboardService.prepareInventoryAnalyticsParams(
    filters,
    type
  );

  return params;
};

const StatItem = ({ title, value, iconClass, precision, isAmount }) => (
  <div className="d-flex align-items-center bg-light rounded p-3">
    <div className="me-3">
      <i className={`bi ${iconClass} text-primary fs-4`}></i>
    </div>
    <div>
      <div className="text-muted small">{title}</div>
      <div className="fw-bold">
        {isAmount ? <Amount value={value} decimalPlace={precision} /> : value}
      </div>
    </div>
  </div>
);

const InventoryAgeingNearExpirySummary = ({ filterData }) => {
  const [nearExpiryData, setNearExpiryData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadNearExpiryData = async () => {
      try {
        setLoading(true);
        const params = prepareFilterParams(filterData);
        const response =
          filterData?.store?.length > 0
            ? await DashboardService.fetchStoreInventoryAnalytics(params)
            : await DashboardService.fetchInventoryAnalytics(params);

        // Check if response is valid, else set default values
        if (response && response.resp && response.resp.length > 0) {
          setNearExpiryData(response.resp[0]);
        } else {
          setNearExpiryData({
            totalNearExpiryProductIdCount: 0,
            totalNearExpiryQty: 0,
            totalNearExpiryValue: 0,
          });
        }
      } catch (error) {
        console.error("Error loading near expiry data:", error);
        // Set default values on error
        setNearExpiryData({
          totalNearExpiryProductIdCount: 0,
          totalNearExpiryQty: 0,
          totalNearExpiryValue: 0,
        });
      } finally {
        setLoading(false);
      }
    };

    loadNearExpiryData();
  }, [filterData]);

  const onProductCountClick = () => {
    const params = DashboardService.prepareNavParamsForInventoryAgeing(
      filterData,
      [
        { key: "stockMovement", value: NEAR_EXPIRY },
        { key: "tab", value: "sellableInventory" },
      ]
    );
    NavService.openInNewTab("/dashboard/inventory-ageing/products", params);
  };

  return (
    <AppCard title="Near Expiry Summary">
      <div className="fs-val-md text-secondary mb-3">
        This data considers products with inventory that has less than or equal
        to 20% of its shelf life remaining.
      </div>
      {loading ? (
        <div className="text-center py-3">
          <div
            className="spinner-border spinner-border-sm text-primary"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        nearExpiryData && (
          <div className="row g-3">
            <div className="col-12 col-sm-4">
              <StatItem
                title="Products Near Expiry"
                value={
                  <button
                    className="btn btn-link text-dark text-start p-0 fs-val-md fw-semibold flex-shrink-1"
                    onClick={onProductCountClick}
                  >
                    {nearExpiryData.totalNearExpiryProductIdCount}
                  </button>
                }
                iconClass="bi-clock-history"
              />
            </div>
            <div className="col-12 col-sm-4">
              <StatItem
                title="Near Expiry Quantity"
                value={nearExpiryData.totalNearExpiryQty}
                iconClass="bi-box-seam"
              />
            </div>
            <div className="col-12 col-sm-4">
              <StatItem
                title="Near Expiry Value"
                value={nearExpiryData.totalNearExpiryValue}
                iconClass="bi-cash-stack"
                precision={0}
                isAmount={true}
              />
            </div>
          </div>
        )
      )}
    </AppCard>
  );
};

export default InventoryAgeingNearExpirySummary;
