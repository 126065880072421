import { useAttachAdditionalData } from "@sk/hooks";
import { ProductService } from "@sk/services";
import { AppTitle, PageLoader, Toaster } from "@sk/uis";

import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import GroupCodeConfigTable from "../components/GroupCodeConfigTable";
import ReservationAuditLog from "../components/ReservationAuditLog";
import ViewConfigDetails from "../components/ViewConfigDetails";
import ViewProductDetails from "../components/ViewProductDetails";

const canvasStyle = { width: "55%" };

const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_modifiedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "productId",
    api: "product",
    loadingKey: "userLoading",
    dataKey: "_product",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name,_id,caseQty,innerPackQty",
    }),
  },
];

const ViewModal = ({ show, configId, callback }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [loading, setLoading] = useState(true);

  const [details, setDetails] = useState({});

  useEffect(() => {
    if (show) {
      loadDetails();
    }
  }, [loadDetails, show]);

  const loadDetails = useCallback(async () => {
    const errHandler = () => {
      Toaster.error("Failed to fetch data, please try again");
      callback({ action: "close" });
    };

    if (!configId) {
      errHandler();
      return;
    }

    setLoading(true);

    const r = await ProductService.getReservationList({
      filter: { _id: configId },
    });

    let d = {};
    if (r?.resp && Array.isArray(r?.resp) && r.resp.length > 0) {
      d = r.resp[0];
    }

    setDetails(d);

    setLoading(false);

    if (d._id) {
      let tmp = [];
      // Attach User Info
      setAdditionalData([d], attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          const t = [...attachAllData([d], tmp)][0];
          setDetails((v) => ({ ...v, ...t }));
        }
      });
    } else {
      errHandler();
    }
  }, [attachAllData, callback, configId, setAdditionalData]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={() => callback({ action: "close" })}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-white">
          <Offcanvas.Title>
            <AppTitle
              title={"Reservation Config Details " + " - " + configId}
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modal-bg">
          {show ? (
            <>
              {loading ? (
                <PageLoader />
              ) : (
                <>
                  <ViewProductDetails details={details} />
                  <ViewConfigDetails details={details} />
                  <GroupCodeConfigTable
                    mode="view"
                    groupId={details.configGroupId}
                  />
                  <ReservationAuditLog configId={details._id} />
                </>
              )}
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ViewModal);
