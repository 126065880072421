import { yupResolver } from "@hookform/resolvers/yup";
import { WarehouseStock } from "@sk/features";
import { ProductService } from "@sk/services";
import {
  AppCard,
  DateFormatter,
  HighlightText,
  KeyVal,
  PageLoader,
  TextInput,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import SoldInLast from "../components/SoldInLast";
import OpenPOTable from "../components/OpenPOTable";

const getReservationById = async (id) => {
  const r = await ProductService.getReservationDetails(id);
  const details = Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0] : {};

  if (details.productId) {
    const productDetails = await ProductService.getProduct(details.productId, {
      select: ["caseQty", "innerPackQty", "shelf_life"].join(","),
    });
    details.productDetails = productDetails.resp || {};
  }

  if (details.configGroupId) {
    const config = await ProductService.getReservationGroupConfig(
      details.configGroupId
    );
    const selectedConfig = (config.resp || []).find(
      (c) => c._id === details._id
    );
    return {
      details,
      config: selectedConfig || {},
    };
  }

  return { details };
};

const createValidationSchema = (openOrders = 0, innerPackQty = 1) => {
  return yup.object().shape({
    quantity: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required("Quantity is required")
      .integer("Quantity must be a whole number")
      .min(
        Math.max(openOrders, innerPackQty),
        `Quantity must be at least ${Math.max(
          openOrders,
          innerPackQty
        )} (greater than open orders and inner pack quantity)`
      )
      .typeError("Quantity must be a number"),
  });
};

const ReservationQuickEdit = ({ show, callback, configId, poDetails }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const openOrders = data?.details?.orderCount || 0;
  const innerPackQty = data?.details?.productDetails?.innerPackQty || 1;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createValidationSchema(openOrders, innerPackQty)),
    context: { openOrders, innerPackQty },
  });

  const init = useCallback(
    async (id) => {
      setLoading(true);
      const res = await getReservationById(id);
      setData(res);

      // Autofill quantity based on open PO quantity
      const openPOQty = poDetails?.qty || 0; // Assuming poDetails contains the open PO quantity
      const currentQty =
        res.details.orderCount ||
        res.details.productDetails.innerPackQty ||
        null;
      setValue("quantity", Math.max(openPOQty, currentQty)); // Set to the greater of open PO qty or current qty

      setLoading(false);
    },
    [setValue, poDetails] // Added poDetails to dependencies
  );

  useEffect(() => {
    if (show) {
      init(configId);
    }
  }, [show, configId, init]);

  const onClose = () => {
    if (submitting) return;
    callback({ action: "close" });
  };

  const onSubmit = async (formData) => {
    setSubmitting(true);

    const r = await ProductService.updateProductReserveQty({
      _id: configId,
      qty: formData.quantity,
    });

    setSubmitting(false);

    if (r.statusCode == 200) {
      Toaster.success(r.resp.message || "Updated successfully");
      callback({
        action: "save",
        status: "success",
        configId,
        qty: formData.quantity,
      });
    } else {
      Toaster.error(r.resp.message || "Failed to update");
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop={submitting ? "static" : true}
      keyboard={!submitting}
      size="lg"
      scrollable={true}
    >
      <Modal.Header closeButton={!submitting} className="border-bottom">
        <Modal.Title className="mb-0">
          <i className="bi bi-pencil-square me-2"></i>
          Edit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-bg">
        {loading ? <PageLoader /> : null}

        {!loading && data ? (
          <>
            <AppCard>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                  <div className="fw-semibold fs-val-lg mb-2">
                    {data?.details?.productName}
                  </div>
                  <div className="row g-2">
                    <div className="col-auto">
                      <HighlightText type="secondary" template={2}>
                        ID: {data?.details?._id}
                      </HighlightText>
                    </div>
                    <div className="col-auto">
                      <HighlightText type="warning" template={2}>
                        PID: {data?.details?.productId}
                      </HighlightText>
                    </div>
                    <div className="col-auto">
                      <HighlightText type="info" template={2}>
                        {data?.details?.groupCode || "N/A"}
                      </HighlightText>
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  <HighlightText
                    type={data?.config?._statusType || "info"}
                    template={2}
                  >
                    <i className="bi bi-circle-fill fs-val-sm me-1"></i>
                    {data?.config?.actualStatus || "N/A"}
                  </HighlightText>
                </div>
              </div>

              <div className="d-flex">
                <div>
                  <SoldInLast soldData={data?.config?.subOrderDetails} />
                </div>
                <div className="ms-auto">
                  <div className="fw-semibold fs-val-md mb-1">Validity</div>
                  <div className="text-muted fs-val-sm">
                    Ends on:{" "}
                    <DateFormatter
                      date={data?.config?.endDate}
                      format="dd MMM yyyy, hh:mm a"
                    />
                  </div>
                </div>
              </div>
            </AppCard>

            <AppCard className="mt-3">
              <div className="row">
                <div className="col-md-5">
                  <div className="border rounded p-3">
                    <div>
                      <h6 className="mb-2">Current Stock Details</h6>
                      <div className="row">
                        <div className="col-12">
                          <KeyVal
                            label="Reserve Quantity"
                            type="secondary"
                            labelCol="col-7"
                            contentCol="col-5"
                            noBottomSpace={true}
                            className="mb-1"
                          >
                            :{" "}
                            <span className="fw-semibold text-primary">
                              {data?.config?.preOrderLimit || 0}
                            </span>
                          </KeyVal>
                        </div>
                        <div className="col-12">
                          <KeyVal
                            label="Open Orders"
                            type="secondary"
                            labelCol="col-7"
                            contentCol="col-5"
                            noBottomSpace={true}
                            className="mb-1"
                          >
                            :{" "}
                            <span className="fw-semibold text-warning">
                              {openOrders || 0}
                            </span>
                            {openOrders > 0 && (
                              <button
                                className="btn btn-link p-0 ms-2 fs-val-xs text-muted"
                                onClick={(e) => {
                                  e.preventDefault();
                                  callback({
                                    action: "viewOrders",
                                    configId: data?.details?._id,
                                  });
                                }}
                              >
                                view orders
                              </button>
                            )}
                          </KeyVal>
                        </div>
                        <div className="col-12">
                          <KeyVal
                            label="Remaining Quantity"
                            type="secondary"
                            labelCol="col-7"
                            contentCol="col-5"
                            noBottomSpace={true}
                            className="mb-1"
                          >
                            :{" "}
                            <span
                              className={`fw-semibold text-${
                                data.remainingOrderCount > 0
                                  ? "success"
                                  : "danger"
                              }`}
                            >
                              {data?.config?.remainingOrderCount || 0}
                            </span>
                          </KeyVal>

                          <KeyVal
                            label={`Stock in ${data?.details?.whId}`}
                            type="secondary"
                            labelCol="col-7"
                            contentCol="col-5"
                          >
                            :{" "}
                            <span className="fw-semibold text-primary">
                              <WarehouseStock
                                whId={data?.details?.whId}
                                pid={data?.details?.productId}
                              />
                            </span>
                          </KeyVal>

                          <KeyVal
                            label="Shelf Life"
                            type="secondary"
                            labelCol="col-7"
                            contentCol="col-5"
                          >
                            :{" "}
                            <span className="fw-semibold text-primary">
                              {data?.details?.productDetails?.shelf_life || 0}{" "}
                              days
                            </span>
                          </KeyVal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="border rounded p-3 h-100">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <TextInput
                        label="New Reserve Quantity"
                        register={register}
                        name="quantity"
                        type="number"
                        defaultValue={data?.config?.preOrderLimit}
                        placeholder="Enter new quantity..."
                        isMandatory={true}
                        error={errors?.quantity?.message}
                        gap={1}
                      />

                      <div className="row mb-3">
                        <div className="col-auto">
                          <small className="text-primary fs-val-sm">
                            Case Qty:{" "}
                            <span className="fw-semibold">
                              {data?.details?.productDetails?.caseQty || "N/A"}
                            </span>
                          </small>
                        </div>
                        <div className="col-auto">
                          <small className="text-primary fs-val-sm">
                            Inner Pack Qty:{" "}
                            <span className="fw-semibold">
                              {data?.details?.productDetails?.innerPackQty ||
                                "N/A"}
                            </span>
                          </small>
                        </div>
                      </div>
                      <small className="text-muted fs-val-sm">
                        <i className="bi bi-info-circle me-1"></i>
                        New quantity should be greater than open orders and
                        inner pack quantity
                      </small>
                    </form>
                  </div>
                </div>
              </div>
            </AppCard>

            {poDetails?.qty > 0 && (
              <AppCard title="Open PO Details">
                <OpenPOTable poDetails={poDetails} />
              </AppCard>
            )}
          </>
        ) : null}
      </Modal.Body>
      {!loading && data ? (
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={handleSubmit(onSubmit)}
            disabled={submitting}
          >
            {submitting ? (
              <>
                <span className="spinner-border spinner-border-sm me-1" />
                Saving...
              </>
            ) : (
              <>
                <i className="bi bi-check-circle me-1"></i>
                Save Changes
              </>
            )}
          </button>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};

export default ReservationQuickEdit;
