import { CoinsDashService } from "@sk/services";
import { NavService } from "@sk/services";
import {
  DateFormatter,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { set } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import CoinsDashAppliedFilter from "./CoinsDashAppliedFilter";

const tableHeaders = [
  {
    label: "Sl No",
  },
  {
    label: "Customer",
    enableSort: true,
    key: "customerName",
    width: "15%",
  },
  {
    label: "Registered On",
    enableSort: true,
    key: "registeredOn",
  },
  {
    label: "Registered Store",
    enableSort: true,
    key: "franchiseData.name",
  },
  {
    label: "Own Club",
    width: "15%",
    enableSort: true,
    key: "ownClub",
  },
  {
    label: "Part of Club",
    width: "15%",
    enableSort: true,
    key: "partOfClub",
  },
  {
    label: "Coins Earned",
    enableSort: true,
    key: "coinsEarned",
  },
  {
    label: "Coins Redeemed",
    enableSort: true,
    key: "coinsRedeemed",
  },
  {
    label: "Orders",
    enableSort: true,
    key: "ordersCount",
  },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 30,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 30,
};

const getData = async (params, signal) => {
  const r = await CoinsDashService.getRegCustomerList(params, { signal });
  return { data: Array.isArray(r.resp) ? r.resp : [], raw: r.raw };
};

const getCount = async (params, signal) => {
  let p = { ...params, outputType: "count" };

  delete p.page;
  delete p.count;

  const r = await CoinsDashService.getRegCustomerList(p, { signal });
  let count = 0;
  if (Array.isArray(r.resp) && r.resp.length > 0) {
    count = r.resp[0].total;
  }
  return { count };
};

const prepareFilterParams = (
  { fromDate, toDate, cid, storeId, accessibleStoreIds, searchText },
  pagination,
  sort = {}
) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {},
  };

  if (fromDate && toDate) {
    p.filter.createdAt = {
      $gte: set(new Date(fromDate), {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(new Date(toDate), {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (sort?.key) {
    p.sort = { [sort.key]: sort.value == "asc" ? 1 : -1 };
  }

  if (cid) {
    p.filter._id = cid;
  }

  if (storeId) {
    p.filter["franchise"] = storeId;
  } else {
    p.filter["franchise"] = {
      $in: accessibleStoreIds,
    };
  }

  if (searchText) {
    p.filter.$or = [
      { _id: { $regex: searchText, $options: "i" } },
      { fName: { $regex: searchText, $options: "i" } },
      { clubName: { $regex: searchText, $options: "i" } },
      { partOfClub: { $regex: searchText, $options: "i" } },
    ];
    if (!isNaN(1 * searchText)) {
      p.filter.$or.push({ mobile: 1 * searchText });
    }

    // Remove the date filter from the parameters
    delete p.filter.createdAt;
  }

  return p;
};

const CoinDashRegCustomer = ({
  fromDate,
  toDate,
  cid,
  callback,
  storeId,
  storeName,
  cname,
  accessibleStoreIds,
}) => {
  const [loading, setLoading] = useState(true);

  const [ttlRecords, setTtlRecords] = useState({
    loading: true,
    value: 0,
  });

  const [data, setData] = useState([]);

  const filterDataRef = useRef({});

  const paginationConfigRef = useRef({ ...defaultPaginationConfig });

  const sortRef = useRef({
    key: "registeredOn",
    value: "desc",
  });

  const [searchText, setSearchText] = useState("");

  const abortControllerRef = useRef(new AbortController());

  const debouncedApplyFilter = useCallback(
    debounce(() => {
      applyFilter();
    }, 500),
    []
  );

  const handleSearchChange = useCallback(
    (e) => {
      const value = e.target.value;
      setSearchText(value);
      if (value) {
        delete filterDataRef.current.fromDate;
        delete filterDataRef.current.toDate;
        delete filterDataRef.current.storeId;
      } else {
        filterDataRef.current.fromDate = fromDate;
        filterDataRef.current.toDate = toDate;
        filterDataRef.current.storeId = storeId;
      }
      filterDataRef.current.searchText = value;
      debouncedApplyFilter();
    },
    [debouncedApplyFilter, fromDate, toDate, storeId]
  );

  useEffect(() => {
    filterDataRef.current = {
      fromDate,
      toDate,
      cid,
      storeId,
      accessibleStoreIds,
    };
    setSearchText("");
    applyFilter();

    return () => {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [cid, fromDate, applyFilter, storeId, toDate, accessibleStoreIds]);

  const applyFilter = useCallback(async () => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    paginationConfigRef.current = { ...defaultPaginationConfig };
    setLoading(true);
    setData([]);
    const t = await getCount(
      prepareFilterParams(filterDataRef.current),
      abortControllerRef.current.signal
    );
    paginationConfigRef.current.totalRecords = t.count;
    setTtlRecords({ loading: false, value: t.count });
    loadList();
  }, [loadList]);

  const loadList = useCallback(async () => {
    setLoading(true);
    setData([]);
    const p = prepareFilterParams(
      filterDataRef.current,
      paginationConfigRef.current,
      sortRef.current
    );
    const r = await getData(p, abortControllerRef.current.signal);
    if (r.raw.code === "ERR_CANCELED") {
      return;
    }
    setData(r.data);
    setLoading(false);
  }, []);

  const paginationCb = (data) => {
    paginationConfigRef.current.startSlNo = data.startSlNo;
    paginationConfigRef.current.endSlNo = data.endSlNo;
    paginationConfigRef.current.activePage = data.activePage;
    loadList();
  };

  const sortCb = (e) => {
    sortRef.current = e;
    paginationConfigRef.current = {
      ...defaultPaginationConfig,
      totalRecords: paginationConfigRef.current.totalRecords,
    };
    loadList();
  };

  return (
    <div>
      <div className="mb-2 row g-0 align-items-center">
        <div className="col-auto">
          <PaginationSummary
            loadingTotalRecords={ttlRecords.loading}
            paginationConfig={paginationConfigRef.current}
            className="me-2"
          />
        </div>
        <div className="col-4 ms-auto">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Search by Customer Name/ID/Mobile/Own Club"
            value={searchText}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <div className="mb-1">
        <CoinsDashAppliedFilter
          storeName={filterDataRef.current.storeId ? storeName : ""}
          cname={cname}
          fromDate={filterDataRef.current.fromDate}
          toDate={filterDataRef.current.toDate}
        />
      </div>

      <table className="table table-hover table-sm table-striped">
        <TableHeader
          data={tableHeaders}
          sortCb={sortCb}
          sort={sortRef.current}
          noBg={true}
        />
        <tbody className="fs-val-md">
          {!loading && !data.length ? (
            <tr>
              <td colSpan={tableHeaders.length}>
                <NoDataFound>No data to display</NoDataFound>
              </td>
            </tr>
          ) : null}

          {loading ? (
            <TableSkeletonLoader
              rows={10}
              height={30}
              cols={tableHeaders.length}
            />
          ) : null}

          {data.map((c, index) => (
            <tr key={c._id}>
              <td>{paginationConfigRef.current.startSlNo + index}</td>
              <td>
                <button
                  className="btn btn-link fs-val-md p-0 text-dark"
                  onClick={() =>
                    callback({ action: "viewCustomer", id: c._id })
                  }
                >
                  {c.customerName}
                </button>
                <div className="fs-val-sm text-muted">ID: {c._id}</div>
              </td>
              <td>
                <DateFormatter date={c.registeredOn} format={"dd MMM yyyy"} />
              </td>
              <td>
                <button
                  className="btn btn-link p-0 fs-val-md text-dark text-start"
                  onClick={() =>
                    NavService.openInNewTab(
                      `/pos/sales-analytics?fid=${c.franchiseData?._id}`
                    )
                  }
                >
                  {c.franchiseData?.name || "--"}
                </button>
              </td>
              <td>
                {c.ownClub ? (
                  <button
                    className="btn btn-link p-0 fs-val-md text-dark text-start"
                    onClick={() => callback({ action: "viewClub", id: c._id })}
                  >
                    {c.ownClub}
                  </button>
                ) : (
                  "--"
                )}
              </td>
              <td>
                {c.partOfClub ? (
                  <button
                    className="btn btn-link p-0 fs-val-md text-dark text-start"
                    onClick={() =>
                      callback({ action: "viewClub", id: c.parentCustomerId })
                    }
                  >
                    {c.partOfClub}
                  </button>
                ) : (
                  "--"
                )}
              </td>
              <td className="text-success">
                +{(c.coinsEarned || 0).toLocaleString()}
              </td>
              <td className="text-danger">
                -{(c.coinsRedeemed || 0).toLocaleString()}
              </td>
              <td>{c.ordersCount || 0}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationConfigRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={ttlRecords.loading}
      />
    </div>
  );
};

export default CoinDashRegCustomer;
