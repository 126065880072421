import { DashboardService } from "@sk/services";
import { Tabs } from "@sk/uis";
import { memo, useEffect, useState } from "react";
import { defaultDateRangeForOrderPlacedNotAllocated } from "../constantService";
import { produce } from "immer";
import { merge } from "lodash";
import { set } from "date-fns";

const MainTabs = ({ callback, activeTab, filterData }) => {
  const [tabs, setTabs] = useState([
    { key: "overview", tabName: "Overview" },
    { key: "stock", tabName: "Stock Not Allocated Order vs No PO" },
    {
      key: "hasStockNotAllocated",
      tabName: "Order Placed Has Stock Not Allocated",
      count: 0,
    },
  ]);

  const tabCb = (data) => {
    callback({ ...data.value });
  };

  useEffect(() => {
    const fetchNotAllocated = async () => {
      const defaultDateRange = [...defaultDateRangeForOrderPlacedNotAllocated];

      const p = DashboardService.prepareParamsForOrderPlacedNotAllocated(
        filterData,
        {}
      );

      const params = merge(
        { outputType: "count" },
        {
          filter: {
            createdAt: {
              $gte: set(defaultDateRange[0], {
                hours: 0,
                minutes: 0,
                seconds: 0,
              }),
              $lte: set(defaultDateRange[1], {
                hours: 23,
                minutes: 59,
                seconds: 59,
              }),
            },
          },
        },
        p
      );
      delete params.page;
      delete params.count;

      const response = await DashboardService.getHasStockNotAllocated(params);

      setTabs(
        produce((draft) => {
          draft[2].count = response?.resp?.[0]?.total || 0;
        })
      );
    };

    fetchNotAllocated();
  }, [filterData]);

  return (
    <div className="mb-4">
      <Tabs
        data={tabs}
        template={4}
        activeTab={activeTab}
        callback={tabCb}
        shadow={true}
      />
    </div>
  );
};

export default memo(MainTabs);
