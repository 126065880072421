import { CommonService } from "@sk/services";
import { Amount, InfoPopover } from "@sk/uis";

const styles = {
  cardTitle: {
    fontSize: "1vw",
    fontWeight: 500,
    color: "inherit",
    opacity: 0.8,
  },
  valueContainer: {
    display: "flex",
    alignItems: "baseline",
    gap: "8px",
  },
  value: {
    fontSize: "1.2vw",
    fontWeight: 600,
    lineHeight: 1,
  },
  percentage: {
    fontSize: ".8vw",
    opacity: 0.8,
  },
  label: {
    fontSize: ".7vw",
    opacity: 0.7,
    marginTop: "4px",
  },
  card: {
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  sellableCard: {
    backgroundColor: "#00A76F",
    color: "white",
  },
  nonSellableCard: {
    backgroundColor: "#FFAB00",
    color: "white",
  },
  totalCard: {
    backgroundColor: "#0C53B7",
    color: "white",
  },
};

const InventoryAgeingSummaryCard = ({
  title,
  value,
  percentage,
  products,
  quantity,
  quantityPercentage,
  icon,
  info,
  bgColor,
  valuePercentage,
  callback,
  noView,
}) => {
  return (
    <div
      className={`flex-1 flex-grow-1 rounded-3 ${bgColor} bg-opacity-25 p-3`}
    >
      <div className="d-flex align-items-center gap-2 mb-3">
        <i className={`bi ${icon}`}></i>
        <span style={styles.cardTitle} className="flex-grow-1">
          {title}
          <InfoPopover content={info} />
        </span>
        {!noView && (
          <div>
            <button
              className="btn btn-link text-dark text-start p-0 fs-val-sm"
              onClick={callback}
            >
              View Products
              <i className="bi bi-chevron-right ms-1 fw-bold"></i>
            </button>
          </div>
        )}
      </div>
      <div>
        <div className="d-flex align-items-baseline justify-content-between gap-4">
          <div>
            <div style={styles.valueContainer}>
              <div style={styles.value}>{products.toLocaleString()}</div>
              {percentage ? (
                <div style={styles.percentage}>{percentage}%</div>
              ) : null}
            </div>
            <div style={styles.label}>Products</div>
          </div>
          <div>
            <div style={styles.valueContainer}>
              <div style={styles.value}>
                {CommonService.roundedByDecimalPlace(
                  quantity,
                  2
                ).toLocaleString()}
              </div>
              {quantityPercentage ? (
                <div style={styles.percentage}>{quantityPercentage}%</div>
              ) : null}
            </div>
            <div style={styles.label}>Quantity</div>
          </div>
          <div>
            <div style={styles.valueContainer}>
              <div style={styles.value}>
                <Amount value={value} />
              </div>
              {valuePercentage ? (
                <div style={styles.percentage}>{valuePercentage}%</div>
              ) : null}
            </div>
            <div style={styles.label}>Value</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryAgeingSummaryCard;
