import { CommonService } from "@sk/services";
import { Amount, KeyVal, NoDataFound, TableHeader } from "@sk/uis";
import { memo, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
const canvasStyle = { width: "60%" };

const headers = [
  { label: "Sl No", width: "auto", isCentered: true },
  { label: "Type", isCentered: true },
  { label: "Total Orders", isCentered: true },
  { label: "Total Order Amount", isCentered: true },
  { label: "Total Club Owner Coins", isCentered: true },
  { label: "Total Club Member Coins", isCentered: true },
  { label: "Order ID's", isCentered: true },
];

const OrderDetailsModal = ({ show, details, callback }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    if (show && details) {
      setData({ ...details });
    }
  }, [show, details]);

  const getLabelByKey = (type) => {
    return CommonService.getCoinsDashboardTypeLabel(type || "");
  };
  return (
    <>
      <Offcanvas
        show={show}
        onHide={callback}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg">
              {data?.custData?.name} (#{data?.custData?.id}) - Club Member
              Details
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          <div className="row mb-3 fw-semibold">
            <div className="col-3 mb-4">
              <KeyVal label="Total Orders" template="col">
                <span className="text-primary"> {data.totalOrders}</span>
              </KeyVal>
            </div>
            <div className="col-3 mb-4">
              <KeyVal label="Total Order Amount" template="col">
                <span className="text-primary">
                  <Amount value={data.totalOrderAmount} decimalPlace={2} />
                </span>
              </KeyVal>
            </div>
            <div className="col-3 mb-4">
              <KeyVal label="Total Club Owner Coins" template="col">
                <span className="text-warning"> {data.totalParentCoins}</span>
              </KeyVal>
            </div>
            <div className="col-3 mb-4">
              <KeyVal label="Total Club Member Coins" template="col">
                <span className="text-warning"> {data.totalChildCoins}</span>
              </KeyVal>
            </div>
          </div>
          <table className="table table-bordered bg-white">
            <TableHeader data={headers} />
            <tbody className="fs-val-md">
              {/* When No loading */}
              {!data?.completeData?.length ? (
                <tr>
                  <td colSpan={headers.length}>
                    <NoDataFound>No Data Found </NoDataFound>
                  </td>
                </tr>
              ) : null}

              {data?.completeData?.length > 0 &&
                (data?.completeData || []).map((x, index) => (
                  <tr key={index}>
                    {/* SL No */}
                    <td className="text-center">{1 + index}</td>

                    <td className="text-center">
                      {getLabelByKey(x?._id?.type)}
                    </td>

                    <td className="text-center">{x?.totalOrders || "N/A"}</td>

                    <td className="text-center">
                      <Amount
                        value={x?.totalOrderAmount || 0}
                        decimalPlace={2}
                      />
                    </td>
                    <td className="text-center">{x?.totalParentCoins || 0}</td>

                    <td className="text-center">{x?.totalChildCoins || 0}</td>
                    <td className="text-center">
                      <div>
                        {x?.orderData.map((e, index) => (
                          <span
                            key={index}
                            className="fs-val-sm text-primary me-2"
                          >
                            {e?._id}
                            {x?.orderData?.length == index - 1 ? null : ","}
                          </span>
                        ))}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(OrderDetailsModal);
