import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ProductService } from "@sk/services";
import {
  DateFormatter,
  DatePickerInput,
  InputErrorMsg,
  Spinner,
  Toaster,
  AppCard,
  HighlightText,
} from "@sk/uis";
import { differenceInBusinessDays, differenceInMinutes } from "date-fns";
import { memo, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  extendDate: yup
    .date()
    .typeError("Extendable Date is required")
    .required()
    .label("Extendable Date"),
});

const ExtendConfigModal = ({ show, data = {}, callback }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      extendDate: [],
    },
    resolver: yupResolver(validationSchema),
  });

  const [submitting, setSubmitting] = useState(false);

  const endDateConfig = useMemo(() => {
    const diff = differenceInMinutes(new Date(data.endDate), new Date());
    return {
      minDate: diff > 0 ? data.endDate : new Date(),
      enableTime: true,
      dateFormat: "d M Y G:i K",
    };
  }, [data.endDate]);

  useEffect(() => {
    if (show) {
      setValue("extendDate", []);
    }
  }, [setValue, show]);

  const onHide = () => {
    callback({ status: "close" });
  };

  const onSubmit = async (formData) => {
    setSubmitting(true);

    const r = await ProductService.updateReserve(data._id, {
      ...data,
      status: "Pending",
      endDate: formData.extendDate,
      noOfDays: differenceInBusinessDays(
        new Date(formData.extendDate),
        new Date(data.endDate)
      ),
    });

    setSubmitting(false);

    if (r.statusCode != 200) {
      Toaster.error(r?.resp?.message || "Failed to extend, please try again");
      return;
    }

    // check in success response has any error
    if (
      r.resp?.response &&
      r.resp?.response.errorResponse &&
      r.resp?.response.errorResponse.length > 0
    ) {
      Toaster.error(
        r.resp?.response.errorResponse.map((e) => e.message).join(",")
      );
      return;
    }

    Toaster.success("Extended successfully");

    callback({ status: "submitted", formData: formData, data: { ...data } });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      keyboard={false}
      backdrop="static"
      className="extend-config-modal"
      enforceFocus={false}
    >
      <Modal.Header closeButton className="border-bottom py-2">
        <Modal.Title className="mb-0 fs-5">
          <i className="bi bi-calendar-plus me-2"></i>
          Extend Reservation - <span className="text-muted">#{data?._id}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        {show ? (
          <>
            <div className="p-3 border-bottom bg-light">
              <div className="product-info mb-3">
                <AppCard>
                  <div className="d-flex align-items-center mb-3">
                    <i className="bi bi-box-seam text-primary me-2"></i>
                    <div className="fw-semibold fs-val-lg">
                      {data.productName}
                      <span className="text-muted ms-1">
                        ({data.productId})
                      </span>
                    </div>
                  </div>

                  <div className="row g-3">
                    <div className="col-auto">
                      <HighlightText type="warning" template={2} isSoft={true}>
                        Reserved Qty: {data.preOrderLimit || 0}
                      </HighlightText>
                    </div>
                    <div className="col-auto">
                      <HighlightText
                        type="secondary"
                        template={2}
                        isSoft={true}
                      >
                        Open Order Qty: {data.orderCount || 0}
                      </HighlightText>
                    </div>
                    <div className="col-auto">
                      <HighlightText type="danger" template={2} isSoft={true}>
                        Remaining Qty: {data.remainingOrderCount || 0}
                      </HighlightText>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-6">
                      <div className="d-flex align-items-center">
                        <i className="bi bi-calendar-check text-success me-2"></i>
                        <div>
                          <div className="text-muted fs-val-sm">Start Date</div>
                          <div className="fw-medium fs-val-md">
                            <DateFormatter date={data.applicableFromDate} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex align-items-center">
                        <i className="bi bi-calendar-x text-danger me-2"></i>
                        <div>
                          <div className="text-muted fs-val-sm">
                            Current Expiry
                          </div>
                          <div className="fw-medium fs-val-md">
                            <DateFormatter date={data.endDate} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AppCard>
              </div>

              <div className="extend-date-section">
                <AppCard>
                  <label className="mb-2">
                    New Expiry Date <span className="text-danger">*</span>
                    <div className="text-muted small">
                      Select a new date to extend the reservation period
                    </div>
                  </label>
                  <Controller
                    control={control}
                    name="extendDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePickerInput
                        placeholder="Choose new expiry date"
                        value={value}
                        inpChange={onChange}
                        config={endDateConfig}
                      />
                    )}
                  />
                  <InputErrorMsg msg={errors?.extendDate?.message} />
                </AppCard>
              </div>
            </div>

            <div className="p-3">
              <div className="d-flex justify-content-end gap-2">
                <button
                  className="btn btn-light"
                  onClick={onHide}
                  disabled={submitting}
                >
                  <i className="bi bi-x me-1"></i>
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  disabled={submitting}
                  onClick={handleSubmit(onSubmit)}
                >
                  <i className="bi bi-check2 me-1"></i>
                  Extend {submitting ? <Spinner isSmall={true} /> : null}
                </button>
              </div>
            </div>
          </>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default memo(ExtendConfigModal);
