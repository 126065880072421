import totalImg from "../../../assets/imgs/total.svg";
import activeImg from "../../../assets/imgs/active.svg";
import inactiveImg from "../../../assets/imgs/inactive.svg";
import hasOffer from "../../../assets/imgs/not-linked.svg";

const listView = {
  breadcrumbs: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Rewarded Coins",
    },
  ],

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  //  Pagination Ends Here
  summaryCardData: [
    {
      label: "Total Count",
      key: "overall",
      filterParams: {},
      color: "primary",
      icon: "bi-check-all",
      img: totalImg,
    },
  ],
  // Summary Card Data ends Here
  formLabels: {
    // createdAt: {
    //   label: "Created At",
    //   type: "dateRange",
    // },
    // customer: {
    //   label: "Category",
    //   valuePath: "[0].value.name",
    // },
    // customerType: {
    //   label: "customerType",
    // },
  },
  //   Form Labels ends Here
  filterFormData: {
    createdAt: [],
    isClubCustomer: "",
    customer: [],
  },
};

export default listView;
