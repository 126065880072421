import { AppCard, DateInput, EntitySearchInput } from "@sk/uis";
import { Controller, useForm } from "react-hook-form";

export const Filter = () => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      store: null,
      dateRange: null,
    },
  });

  const onSubmit = (data) => {};

  const dateRangeConfig = {
    mode: "range",
    dateFormat: "d M, Y",
  };

  return (
    <AppCard>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <Controller
              name="store"
              control={control}
              render={({ field: { onChange, value } }) => (
                <EntitySearchInput
                  type="franchise"
                  label="Store Name"
                  placeholder="Search store..."
                  callback={onChange}
                  value={value}
                  gap={1}
                  uid="store"
                />
              )}
            />
          </div>
          <div className="col-md-6">
            <Controller
              name="dateRange"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DateInput
                  label="Date Range"
                  placeholder="Select date range"
                  value={value}
                  callback={onChange}
                  gap={1}
                  config={dateRangeConfig}
                />
              )}
            />
          </div>
        </div>
      </form>
    </AppCard>
  );
};
