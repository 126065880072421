import { CustomerService } from "@sk/services";
import {
  Amount,
  EntitySearchInput,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
} from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import OrderDetailsModal from "./OrderDetailsModal";
const canvasStyle = { width: "60%" };

const headers = [
  { label: "Sl No", width: "auto", isCentered: true },
  { label: "Club Member Details" },
  {
    label: "Total Orders",
    isCentered: true,
    key: "totalOrders",
    enableSort: true,
  },
  {
    label: "Total Order Amount",
    isCentered: true,
    key: "totalOrderAmount",
    enableSort: true,
  },
  {
    label: "Total Club Member Coins",
    isCentered: true,
    key: "totalChildCoins",
    enableSort: true,
  },
  {
    label: "Total Club Owner Coins",
    isCentered: true,
    key: "totalParentCoins",
    enableSort: true,
  },
  { label: "Action", isCentered: true },
];

const pagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const ChildCustomerModal = ({ show, cid, name, callback }) => {
  const formMethods = useForm({
    defaultValues: { customer: "", mobileNumber: null },
  });

  const [list, setList] = useState([]);

  const [loading, setLoading] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [showOrderDetails, setShowOrderDetails] = useState({
    show: false,
    data: {},
  });

  const detailsRef = useRef({ cid: "", name: "" });

  const paginationConfigRef = useRef({ ...pagination });

  const sortRef = useRef({});

  useEffect(() => {
    if (show && cid) {
      formMethods.reset();
      detailsRef.current = { cid: cid, name: name };
      applyFilter();
    }
  }, [show, cid, applyFilter]);

  const applyFilter = useCallback(() => {
    paginationConfigRef.current = { ...pagination };
    loadDetails();
    loadCount();
  }, [loadDetails]);

  const loadDetails = useCallback(async () => {
    setLoading(true);
    let p = getFilterParams();
    let r = await CustomerService.getReferralCoinsDataList(p);
    let li = Array.isArray(r.resp) && r?.resp?.length ? r.resp : [];
    li.forEach((e) => {
      e._type = e.completeData.length ? e.completeData[0]?._id?.type : "";
    });
    setList([...li]);
    setLoading(false);
  }, [setList, setLoading]);

  const loadCount = useCallback(async () => {
    let params = getFilterParams();
    // for total records
    setLoadingTotalRecords(true);
    delete params.page;
    delete params.count;
    let p = { ...params, outputType: "count" };
    const c = await CustomerService.getReferralCoinsDataList(p);

    // Setting Total Count
    const t =
      c.statusCode == 200 && Array.isArray(c.resp) ? c.resp[0]?.total : 0;
    paginationConfigRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, []);

  const getFilterParams = () => {
    let p = {
      page: paginationConfigRef.current.activePage,
      count: paginationConfigRef.current.rowsPerPage,
      queryType: "childSummary",
      filter: { senderRefId: detailsRef.current.cid },
    };

    if (sortRef.current?.key) {
      p.sort = {
        [sortRef.current.key]: sortRef.current.value == "asc" ? 1 : -1,
      };
    }
    let f = formMethods.getValues();
    if (f.customer?.length) {
      p.filter.reciverRefId = f.customer[0]?.value?._id;
    }
    if (f?.mobileNumber) {
      p.mobileNo = f.mobileNumber;
    }
    return p;
  };

  const paginationCb = useCallback((d) => {
    paginationConfigRef.current.startSlNo = d.startSlNo;
    paginationConfigRef.current.endSlNo = d.endSlNo;
    paginationConfigRef.current.activePage = d.activePage;
    loadDetails();
  }, []);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = data;
      applyFilter();
    },
    [applyFilter]
  );

  const viewDetails = (d) => {
    setShowOrderDetails({ show: true, data: d });
  };

  const viewDetailsCb = () => {
    setShowOrderDetails({ show: false, data: {} });
  };

  const onCustomerSearch = useCallback(
    (chngFn) => async (val) => {
      chngFn(val);
      applyFilter();
    },
    [applyFilter]
  );
  // For Searching via Brand
  const onSearch = useCallback(
    debounce(() => {
      applyFilter();
    }, 700),
    [applyFilter]
  );

  const rbac = {
    showMobileNo: ["showCoinsDashboardMobileNo"],
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={callback}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg">
              {name} (#{cid}) - Club Member Order List
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          <>
            <div className="row">
              <FormProvider {...formMethods}>
                <div className="col-4 mb-0">
                  <Controller
                    control={formMethods.control}
                    name="customer"
                    render={({ field: { onChange, value } }) => (
                      <EntitySearchInput
                        label=""
                        type="customer"
                        placeholder="Search by Club Member Name/ID"
                        value={value}
                        callback={onCustomerSearch(onChange)}
                        uid="customer"
                        // filterParams={franchiseSearchFilter}
                        emptyLabel="No Club Member found"
                      />
                    )}
                  />
                </div>

                <div className="col-4 mb-0">
                  <TextInput
                    name="mobileNumber"
                    type="number"
                    register={formMethods.register}
                    max={9999999999}
                    callback={onSearch}
                    placeholder="Search By Club Member Mobile No"
                  />
                </div>
              </FormProvider>
            </div>

            <PaginationSummary
              paginationConfig={paginationConfigRef.current}
              loadingTotalRecords={loadingTotalRecords}
            />

            <table className="table table-bordered bg-white">
              <TableHeader
                data={headers}
                sort={sortRef.current}
                sortCb={sortCb}
              />
              <tbody className="fs-val-md">
                {/*  When Table is Loading  */}
                {loading && (
                  <TableSkeletonLoader
                    rows={10}
                    cols={headers.length}
                    height={40}
                  />
                )}

                {/* When No loading */}
                {!loading && !list?.length ? (
                  <tr>
                    <td colSpan={headers.length}>
                      <NoDataFound>No Data Found </NoDataFound>
                    </td>
                  </tr>
                ) : null}

                {(list || []).map((x, index) => (
                  <tr key={index}>
                    {/* SL No */}
                    <td className="text-center">
                      {paginationConfigRef.current.startSlNo + index}
                    </td>

                    <td>
                      <div>{x?.custData?.name}</div>
                      <div className="mt-1 fs-val-sm text-muted">
                        {x?.custData?.district}, {x?.custData?.state}
                      </div>
                      <div className="mt-2 fs-val-sm text-primary">
                        ID : {x?.custData?.id}
                      </div>

                      <Rbac roles={rbac.showMobileNo}>
                        <div className="mt-2 fs-val-sm text-primary">
                          Mobile No : {x?.custData?.mobileNumber}
                        </div>
                      </Rbac>
                    </td>

                    <td className="text-center">{x?.totalOrders || 0}</td>
                    <td className="text-center">
                      <Amount value={x?.totalOrderAmount || 0} />
                    </td>

                    <td className="text-center">{x?.totalChildCoins || 0}</td>

                    <td className="text-center">{x?.totalParentCoins || 0}</td>

                    <td className="text-center">
                      <button
                        className="btn btn-sm btn-outline-primary me-1 fs-val-xs"
                        onClick={() => {
                          viewDetails(x);
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationBlock
              paginationConfig={paginationConfigRef.current}
              paginationCb={paginationCb}
              loadingTotalRecords={loadingTotalRecords}
            />
          </>
        </Offcanvas.Body>
      </Offcanvas>

      <OrderDetailsModal
        show={showOrderDetails.show}
        details={showOrderDetails.data}
        callback={viewDetailsCb}
      />
    </>
  );
};

export default memo(ChildCustomerModal);
