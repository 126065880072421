const SoldInLast = ({ soldData }) => {
  return (
    <>
      <div className="fw-semibold fs-val-md mb-1">Sold In Last</div>
      <div className="row gap-2 fs-val-sm">
        <div className="col-auto">
          Last 15 Days : {soldData.fifteendaysQty ?? 0}
        </div>
        <div className="col-auto">
          Last 30 Days : {soldData.thirtydaysQty ?? 0}
        </div>
        <div className="col-auto">
          Last 60 Days : {soldData.sixtydaysQty ?? 0}
        </div>
        <div className="col-auto">
          Last 90 Days : {soldData.ninetydaysQty ?? 0}
        </div>
      </div>
    </>
  );
};

export default SoldInLast;
