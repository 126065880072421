import { CommonService, DashboardService, NavService } from "@sk/services";
import { AppCard } from "@sk/uis";
import { useEffect, useRef, useState } from "react";
import InventoryAgeingSummaryCard from "./InventoryAgeingSummaryCard";
import { SELLABLE } from "../constant";

const getData = async (params, isStoreWise = false) => {
  const r = isStoreWise
    ? await DashboardService.fetchStoreInventoryAnalytics(params)
    : await DashboardService.fetchInventoryAnalytics(params);
  return r.resp;
};

const prepareFilterParams = (filters) => {
  let type = "getInventoryStatusSummary";

  // Change query type if store filter is enabled
  if (filters.store?.length > 0) {
    type = "getStoreWiseInventoryOverAllStocks";
  }

  const params = DashboardService.prepareInventoryAnalyticsParams(
    filters,
    type
  );

  return params;
};

const prepareData = (response) => {
  const sellable = {
    products: 0,
    quantity: 0,
    value: 0,
  };

  const nonSellable = {
    products: 0,
    quantity: 0,
    value: 0,
  };

  // Group and sum up values based on inventoryStatus
  response?.forEach((item) => {
    if (item.inventoryStatus === SELLABLE) {
      sellable.products += item.pIdCount || 0;
      sellable.quantity += item.totalQty || 0;
      sellable.value += item.totalValue || 0;
    } else {
      nonSellable.products += item.pIdCount || 0;
      nonSellable.quantity += item.totalQty || 0;
      nonSellable.value += item.totalValue || 0;
    }
  });

  // Calculate totals
  const total = {
    products: sellable.products + nonSellable.products,
    quantity: sellable.quantity + nonSellable.quantity,
    value: sellable.value + nonSellable.value,
  };

  // Calculate percentages
  sellable.productsPercentage = total.products
    ? CommonService.roundedByDecimalPlace(
        (sellable.products / total.products) * 100,
        2
      )
    : 0;
  sellable.quantityPercentage = total.quantity
    ? CommonService.roundedByDecimalPlace(
        (sellable.quantity / total.quantity) * 100,
        2
      )
    : 0;
  sellable.valuePercentage = total.value
    ? CommonService.roundedByDecimalPlace(
        (sellable.value / total.value) * 100,
        2
      )
    : 0;

  nonSellable.productsPercentage = total.products
    ? CommonService.roundedByDecimalPlace(
        (nonSellable.products / total.products) * 100,
        2
      )
    : 0;
  nonSellable.quantityPercentage = total.quantity
    ? CommonService.roundedByDecimalPlace(
        (nonSellable.quantity / total.quantity) * 100,
        2
      )
    : 0;
  nonSellable.valuePercentage = total.value
    ? CommonService.roundedByDecimalPlace(
        (nonSellable.value / total.value) * 100,
        2
      )
    : 0;

  // Round values to 2 decimal places
  sellable.products = CommonService.roundedByDecimalPlace(sellable.products, 2);
  sellable.quantity = CommonService.roundedByDecimalPlace(sellable.quantity, 2);
  sellable.value = CommonService.roundedByDecimalPlace(sellable.value, 2);

  nonSellable.products = CommonService.roundedByDecimalPlace(
    nonSellable.products,
    2
  );
  nonSellable.quantity = CommonService.roundedByDecimalPlace(
    nonSellable.quantity,
    2
  );
  nonSellable.value = CommonService.roundedByDecimalPlace(nonSellable.value, 2);

  total.products = CommonService.roundedByDecimalPlace(total.products, 2);
  total.quantity = CommonService.roundedByDecimalPlace(total.quantity, 2);
  total.value = CommonService.roundedByDecimalPlace(total.value, 2);

  return {
    sellable,
    nonSellable,
    total,
  };
};

const InventoryAgeingSummary = ({ filterData }) => {
  const [data, setData] = useState({
    sellable: {
      products: 0,
      quantity: 0,
      value: 0,
      productsPercentage: 0,
      quantityPercentage: 0,
      valuePercentage: 0,
    },
    nonSellable: {
      products: 0,
      quantity: 0,
      value: 0,
      productsPercentage: 0,
      quantityPercentage: 0,
      valuePercentage: 0,
    },
    total: {
      products: 0,
      quantity: 0,
      value: 0,
    },
  });

  const filterRef = useRef({});

  useEffect(() => {
    filterRef.current = filterData;
    const loadData = async () => {
      try {
        const r = await getData(
          prepareFilterParams(filterRef.current),
          filterRef.current.store?.length > 0
        );
        const processedData = prepareData(r);
        setData(processedData);
      } catch (error) {
        console.error("Error loading inventory summary:", error);
      }
    };
    loadData();
  }, [filterData]);

  const onLinkClick = (type) => {
    let params;

    const baseParams = [{ key: "tab", value: type }];

    params = DashboardService.prepareNavParamsForInventoryAgeing(
      filterRef.current,
      baseParams
    );

    NavService.openInNewTab("/dashboard/inventory-ageing/products", params);
  };

  return (
    <>
      <AppCard>
        {/* <div className="mb-3 d-flex justify-content-between align-items-center">
          <h5 className="my-0">Summary</h5>
        </div> */}
        <div className="d-flex gap-3">
          <InventoryAgeingSummaryCard
            title="TOTAL"
            icon="bi-boxes"
            info="In stock Products and the value is calculated based on 'B2B Price x Qty'."
            products={data.total.products}
            percentage={data.total.productsPercentage}
            quantity={data.total.quantity}
            quantityPercentage={data.total.quantityPercentage}
            value={data.total.value}
            valuePercentage={data.total.valuePercentage}
            bgColor="bg-primary"
            callback={() => onLinkClick("allInventory")}
          />

          <InventoryAgeingSummaryCard
            title="SELLABLE"
            icon="bi-boxes"
            info="In Stock Sellable Products and the value is calculated based on 'B2B Price x Qty'."
            products={data.sellable.products}
            percentage={data.sellable.productsPercentage}
            quantity={data.sellable.quantity}
            quantityPercentage={data.sellable.quantityPercentage}
            value={data.sellable.value}
            valuePercentage={data.sellable.valuePercentage}
            bgColor="bg-success"
            callback={() => onLinkClick("sellableInventory")}
          />

          <InventoryAgeingSummaryCard
            title="NON-SELLABLE"
            icon="bi-exclamation-triangle"
            info="In Stock Non-Sellable Products and the value is calculated based on 'B2B Price x Qty'."
            products={data.nonSellable.products}
            percentage={data.nonSellable.productsPercentage}
            quantity={data.nonSellable.quantity}
            quantityPercentage={data.nonSellable.quantityPercentage}
            value={data.nonSellable.value}
            valuePercentage={data.nonSellable.valuePercentage}
            bgColor="bg-warning"
            callback={() => onLinkClick("nonSellableInventory")}
          />
        </div>
      </AppCard>
    </>
  );
};

export default InventoryAgeingSummary;
