import { AuthService } from "@sk/services";
import { EntitySearchInput, SelectInput, TextInput } from "@sk/uis";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import AdvanceFilterModal from "./AdvanceFilterModal";
import { StoresModal } from "@sk/features";
import { produce } from "immer";

const defaultWarehouseOptions = [
  ...AuthService.getLoggedInEmpWh().map((x) => ({
    label: x.name,
    value: x._id,
  })),
];

const Filter = ({ callback, filterData }) => {
  const { register, control, getValues, setValue } = useForm({
    defaultValues: {
      warehouse: AuthService.getDefaultWhId(),
      product: [],
    },
  });

  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [showStoreModal, setShowStoreModal] = useState(false);
  const [warehouseOptions, setWarehouseOptions] = useState([
    ...defaultWarehouseOptions,
  ]);

  useEffect(() => {
    setValue("product", filterData.product);
    setValue("storeName", filterData.storeName);

    if (!filterData.store?.length && !filterData.warehouse) {
      setValue("warehouse", AuthService.getDefaultWhId());
    }
  }, [filterData, setValue]);

  const triggerCb = useCallback(
    (action, formData) => {
      callback({ action, formData });
    },
    [callback]
  );

  const onSearch = useCallback(
    (chngFn) => (r) => {
      chngFn(r);
      triggerCb("search", getValues());
    },
    [getValues, triggerCb]
  );

  const onAdvanceFilterCb = useCallback(
    (e) => {
      setShowAdvanceFilter(false);
      if (e.action === "apply" || e.action === "reset") {
        triggerCb(e.action, { ...getValues(), ...e.formData });
      }
    },
    [getValues, setShowAdvanceFilter, triggerCb]
  );

  const onWarehouseChange = useCallback(
    (value) => {
      setValue("warehouse", value);
      setValue("storeName", "");
      setValue("store", []);
      callback({ action: "apply", formData: getValues() });

      setWarehouseOptions(
        produce((draft) => {
          const i = draft.findIndex((e) => e.label === "Choose");
          if (i !== -1) {
            draft.splice(i, 1);
          }
        })
      );
    },
    [callback, getValues, setValue]
  );

  const storeModalCallback = (e) => {
    setShowStoreModal(false);
    if (e?.action === "selectedStore") {
      const store = e?.store?.[0] || {};
      if (store?._id) {
        setValue("warehouse", "");
        setValue("storeName", store.name);
        setValue("store", [{ label: store.name, value: { _id: store._id } }]);
        callback({ action: "apply", formData: getValues() });

        setWarehouseOptions(
          produce((draft) => {
            const i = draft.findIndex((e) => e.label === "Choose");
            if (i === -1) {
              draft.unshift({ label: "Choose", value: "" });
            }
          })
        );
      }
    }
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12">
          <div className="row g-3">
            <div className="col-3">
              <SelectInput
                name="warehouse"
                options={warehouseOptions}
                register={register}
                callback={onWarehouseChange}
                label="Warehouse"
                size="sm"
                gap={0}
              />
            </div>
            <div className="col-3" onClick={() => setShowStoreModal(true)}>
              <TextInput
                name="storeName"
                register={register}
                label="Store"
                placeholder="Search Store"
                readOnly
                size="sm"
              />
            </div>
            <div className="col-4">
              <Controller
                control={control}
                name="product"
                render={({ field: { value, onChange } }) => (
                  <EntitySearchInput
                    type="product"
                    uid="stockProduct"
                    callback={onSearch(onChange)}
                    label="Product"
                    placeholder="Search by Product Name/ID"
                    value={value}
                    size="sm"
                    gap={0}
                  />
                )}
              />
            </div>
            <div className="col-auto">
              <label className="d-block mb-1 fs-val-md">&nbsp;</label>
              <button
                className="btn btn-outline-secondary btn-sm"
                type="button"
                onClick={() => setShowAdvanceFilter(true)}
              >
                <i className="bi bi-funnel"></i> Filter
              </button>
            </div>
          </div>
        </div>
      </div>

      <StoresModal
        show={showStoreModal}
        callback={storeModalCallback}
        onlyAssignedStore={true}
        rbackKey="StockNotAllocatedDashboard"
      />

      <AdvanceFilterModal
        show={showAdvanceFilter}
        callback={onAdvanceFilterCb}
        formData={filterData}
      />
    </>
  );
};

export default memo(Filter);
