import { SelectInput } from "@sk/uis";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

const templateOptions = [
  { label: "In Active Customer", value: "inActiveCustomer" },
  { label: "First Order Customer", value: "firstOrderCustomer" },
  { label: "Second Order Customer", value: "secondOrderCustomer" },
  { label: "Coupon Customer", value: "couponCustomer" },
];

const notifyTypeOptions = [
  { label: "Email", value: "email" },
  { label: "SMS", value: "sms" },
  { label: "WhatsApp", value: "whatsapp" },
];

const CustomerLifeCycleNotifyModal = ({ show, callback, type }) => {
  const { register } = useForm();
  const onClose = () => {
    callback({ action: "close" });
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">
          {type === "bulk" ? "Bulk Notify" : "Notify"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-6">
            <SelectInput
              register={register}
              name="template"
              label="Select Template"
              options={templateOptions}
            />
          </div>
          <div className="col-6">
            <SelectInput
              register={register}
              name="notifyType"
              label="Notify Type"
              options={notifyTypeOptions}
            />
          </div>
        </div>

        <div className="mt-2 fs-val-md">
          <span>Total Customers: </span>
          <span className="fw-bold text-primary">
            {type === "bulk" ? "100" : "1"}
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-primary">Notify</button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomerLifeCycleNotifyModal;
