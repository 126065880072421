import { CoinsDashService } from "@sk/services";
import { SummaryCard } from "@sk/uis";
import { set } from "date-fns";
import { produce } from "immer";
import { get, merge } from "lodash";
import { useCallback, useEffect, useState } from "react";

const defaultSummaryData = [
  {
    label: "Total Coins Rewarded",
    value: 0,
    icon: "bi-coin",
    color: "success",
    key: "ttlCoinsReward",
    valuePath: "[0].resp[0].totalCoinsRewarded",
  },
  {
    label: "Total Coins Redeemed",
    value: 0,
    icon: "bi-arrow-down-left-circle",
    color: "danger",
    key: "ttlCoinsRedeem",
    valuePath: "[1].resp[0].totalCoinsRedeem",
  },
  {
    label: "Total Customer Registered",
    value: 0,
    icon: "bi-person-plus",
    color: "primary",
    key: "ttlCustReg",
    valuePath: "[2].resp[0].totalRegisteredCustomers",
  },
  {
    label: "Total Clubs Created",
    value: 0,
    icon: "bi-people",
    color: "info",
    key: "ttlClubCreate",
    valuePath: "[3].resp[0].totalClubCreated",
  },
  {
    label: "Total Orders",
    value: 0,
    icon: "bi-cart",
    color: "warning",
    key: "ttlOrders",
    valuePath: "[4].resp[0].totalOrders",
  },
  {
    label: "Total Customers placed orders",
    value: 0,
    icon: "bi-person-check",
    color: "primary",
    key: "ttlCustOrders",
    valuePath: "[4].resp[0].uniqueCustomerCount",
  },
];

export default function CoinsRewardSummary({
  fromDate,
  toDate,
  cid,
  storeId,
  accessibleStoreIds,
}) {
  const [summaryData, setSummaryData] = useState([...defaultSummaryData]);

  useEffect(() => {
    loadSummary();
  }, [cid, fromDate, loadSummary, toDate, storeId, accessibleStoreIds]);

  const loadSummary = useCallback(async () => {
    const p = {
      filter: {},
    };

    if (fromDate && toDate) {
      p.filter.createdAt = {
        $gte: set(new Date(fromDate), {
          hours: 0,
          minutes: 0,
          seconds: 0,
        }),
        $lte: set(new Date(toDate || fromDate), {
          hours: 23,
          minutes: 59,
          seconds: 59,
        }),
      };
    }

    let redwardFilter = {};
    let redeemFilter = {};
    let regFilter = {};
    let clubFilter = {};
    let orderFilter = {};

    if (cid) {
      redwardFilter = { filter: { ownerId: cid } };
      redeemFilter = { filter: { ownerId: cid } };
      regFilter = { filter: { _id: cid } };
      clubFilter = { matchFilter: { "customerInfo._id": cid } };
      orderFilter = { filter: { "customerInfo.id": cid } };
    }

    redwardFilter = merge({}, redwardFilter, {
      filter: {
        "ownerLocation.franchise": storeId
          ? storeId
          : { $in: accessibleStoreIds },
      },
    });
    redeemFilter = merge({}, redeemFilter, {
      filter: {
        "ownerLocation.franchise": storeId
          ? storeId
          : { $in: accessibleStoreIds },
      },
    });
    regFilter = merge({}, regFilter, {
      filter: {
        franchise: storeId ? storeId : { $in: accessibleStoreIds },
      },
    });
    clubFilter = merge({}, clubFilter, {
      matchFilter: {
        "customerInfo.franchise": storeId
          ? storeId
          : { $in: accessibleStoreIds },
      },
    });

    orderFilter = merge({}, orderFilter, {
      filter: {
        "franchiseInfo.id": storeId ? storeId : { $in: accessibleStoreIds },
      },
    });

    const r = await Promise.all([
      CoinsDashService.getCoinsRewardedCount(merge({}, p, redwardFilter)),
      CoinsDashService.getCoinsRedeemedCount(merge({}, p, redeemFilter)),
      CoinsDashService.getRegCustomersCount(merge({}, p, regFilter)),
      CoinsDashService.getClubCreatedCount(merge({}, p, clubFilter)),
      CoinsDashService.getTotalOrdersCount(merge({}, p, orderFilter)),
    ]);

    setSummaryData(
      produce((draft) => {
        draft.forEach((e) => {
          e.value = get(r, e.valuePath, 0);
        });
      })
    );
  }, [cid, fromDate, storeId, toDate, accessibleStoreIds]);

  return (
    <div className="coins-dash mb-3">
      <div className="row">
        {summaryData.map((e, k) => (
          <div className="col-6" key={k}>
            <SummaryCard
              icon={e.icon}
              value={e.value}
              title={e.label}
              valueColor={e.color}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
