import { appConfigs, DashboardService, NavService } from "@sk/services";
import {
  Amount,
  AppCard,
  DateFormatter,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
  BusyLoader,
  BtnLink,
  KeyVal,
  InfoPopover,
} from "@sk/uis";
import classNames from "classnames";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const containerStyle = { height: "550px", overflowY: "auto" };

// Define a style object for different groups
const groupStyles = {
  pending: {
    backgroundColor: "#fff3f3",
  },
  order: {
    backgroundColor: "#fffaf0",
  },
  po: {
    backgroundColor: "#f4fbff",
  },
  sales: {
    backgroundColor: "#f9f9f9", // Light color for sales-related cells
  },
};

// Keep original headers, just add Sl No at the beginning
const headers = [
  // Serial Number
  {
    label: "Sl No",
    key: "slNo",
    width: "5%", // Estimated width
    isSticky: true,
    stickyPosition: "left",
    isCentered: true,
  },

  // Product Information Group
  {
    label: "Product Name",
    key: "productName",
    isSticky: true,
    stickyPosition: "left",
    width: "25%",
    style: {
      left: "60px",
    },
  },
  {
    label: "Product ID",
    key: "productId",
    width: "10%", // Estimated width
  },

  {
    label: "Shelf Life",
    key: "shelfLife",
    enableSort: true,
    width: "10%", // Adjusted width
  },

  // Pending Orders by Channel Group
  {
    label: "Pending Ord. Qty",
    key: "pendingOrderQty",
    enableSort: true,
    width: "12%", // Estimated width
    isCentered: true,
  },

  {
    label: "Latest Ord. Date",
    key: "recentOrderDate",
    enableSort: true,
    width: "12%", // Estimated width
  },

  {
    label: "Oldest Ord. Date",
    key: "oldestOrderDate",
    enableSort: true,
    width: "12%", // Updated width
  },

  {
    label: "Latest PO Date",
    key: "latestPoDate",
    enableSort: true,
    width: "12%",
  },

  {
    label: "Latest PO ID",
    key: "latestPoId",
    enableSort: true,
    width: "12%",
  },

  {
    label: "Latest PO Qty",
    key: "latestPoQty",
    enableSort: true,
    width: "12%",
  },

  {
    label: "Vendor",
    key: "latestPoVendorName",
    enableSort: true,
    width: "20%",
  },

  {
    label: "Latest GRN Date",
    key: "latestGrnDate",
    enableSort: true,
    width: "12%",
  },
  {
    label: "Latest GRN ID",
    key: "latestGrnId",
    enableSort: true,
    width: "12%",
  },

  {
    label: "Latest GRN Qty",
    key: "latestGrnQty",
    enableSort: true,
    width: "12%",
  },

  {
    label: "PO Qty",
    key: "poQty",
    enableSort: true,
    width: "8%", // Estimated width
    isCentered: true,
  },

  // Stock Information Group
  {
    label: "In Stock Qty",
    key: "inStockQty",
    enableSort: true,
    width: "10%",
    isCentered: true,
  },

  // Moved columns to the end
  {
    label: "MRP",
    key: "mrp",
    enableSort: true,
    width: "8%",
    isCentered: true,
  },
  {
    label: "Inner Pack Qty",
    key: "innerPackQty",
    enableSort: true,
    width: "8%",
    isCentered: true,
  },
  {
    label: "Case Qty",
    key: "caseQty",
    enableSort: true,
    width: "8%",
    isCentered: true,
  },
  {
    label: "Last 30d Sales",
    key: "last30DaysSaleQty",
    isCentered: true,
    enableSort: true,
    width: "10%",
  },
  {
    label: "Last 60d Sales",
    key: "last60DaysSaleQty",
    isCentered: true,
    enableSort: true,
    width: "10%",
  },
  {
    label: "Last 90d Sales",
    key: "last90DaysSaleQty",
    isCentered: true,
    enableSort: true,
    width: "10%",
  },
  {
    label: "Menu Name",
    key: "menuName",
    enableSort: true,
    width: "15%",
  },
  {
    label: "Category Name",
    key: "categoryName",
    enableSort: true,
    width: "15%",
  },
  {
    label: "Brand Name",
    key: "brandName",
    enableSort: true,
    width: "12%",
  },
  {
    label: "Company Name",
    key: "companyName",
    enableSort: true,
    width: "15%",
  },
];

const getData = async (params, abortSignal) => {
  const response = await DashboardService.fetchStockAnalytics(params, {
    signal: abortSignal,
  });
  return {
    data: Array.isArray(response.resp) ? response.resp : [],
    raw: response.raw,
  };
};

const getCount = async (params, abortSignal) => {
  const p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;
  const response = await DashboardService.fetchStockAnalytics(p, {
    signal: abortSignal,
  });
  return response?.resp?.[0]?.totalDocs || 0;
};

const prepareFilterParams = (filterData, pagination, sort) => {
  const params = DashboardService.prepareStockNotAllocatedSummaryParams(
    filterData,
    "getUnallocatedStockWithoutPOProductWise"
  );

  const search = (filterData.search || "").trim();
  if (search) {
    params.filter.$or = [
      { "productInfo.name": { $regex: search, $options: "i" } },
      { productId: search },
      { "productInfo.companyName": { $regex: search, $options: "i" } },
    ];
  }

  const vendorSearch = (filterData.vendorSearch || "").trim();
  if (vendorSearch) {
    params.filter.$or = [
      ...(params.filter.$or || []),
      { "latestPoInfo.vendorId": vendorSearch },
      { "latestPoInfo.vendorName": { $regex: vendorSearch, $options: "i" } },
    ];
  }

  if (pagination) {
    params.page = pagination.activePage;
    params.count = pagination.rowsPerPage;
  }

  if (sort) {
    params.sort = {
      [sort.key]: sort.value === "asc" ? 1 : -1,
    };
  }

  return params;
};

const tableStyle = {
  minWidth: "4000px", // Updated width
};

const stickySerialNumberStyle = {
  left: 0,
};

const stickyProductNameStyle = {
  left: "60px", // Adjusted left position for product name
};

const StockNotAllocatedNoPoTable = ({ filterData, callback }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState({ loading: true, value: 0 });
  const [search, setSearch] = useState("");
  const [vendorSearch, setVendorSearch] = useState("");
  const filterRef = useRef(filterData);
  const paginationRef = useRef({ ...defaultPaginationConfig });
  const sortDataRef = useRef({ key: "menuName", value: "desc" });
  const [busyLoader, setBusyLoader] = useState({ show: false });

  const loadList = useCallback(async (abortSignal) => {
    setLoading(true);
    setData([]);
    try {
      const r = await getData(
        prepareFilterParams(
          filterRef.current,
          paginationRef.current,
          sortDataRef.current
        ),
        abortSignal
      );
      if (r.resp?.raw?.code == appConfigs.AJAX_REQ_CANCEL) {
        return;
      }
      setData(r.data);
      setLoading(false);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted:", error);
      } else {
        console.error("Error fetching data:", error);
      }
    }
  }, []);

  const applyFilter = useCallback(
    async (abortSignal) => {
      paginationRef.current = { ...defaultPaginationConfig };

      loadList(abortSignal);

      setRecords({ loading: true, value: 0 });
      try {
        const count = await getCount(
          prepareFilterParams(
            filterRef.current,
            paginationRef.current,
            sortDataRef.current
          ),
          abortSignal
        );
        setRecords({ loading: false, value: count });
        paginationRef.current.totalRecords = count;
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Count fetch aborted:", error);
        } else {
          console.error("Error fetching count:", error);
          setRecords({ loading: false, value: 0 });
        }
      }
    },
    [loadList]
  );

  useEffect(() => {
    const abortController = new AbortController();
    filterRef.current = filterData;
    applyFilter(abortController.signal);
    setSearch("");
    setVendorSearch("");
    return () => {
      abortController.abort();
    };
  }, [filterData, applyFilter]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortDataRef.current = data;
      applyFilter();
    },
    [applyFilter]
  );

  const searchDebounceCb = useCallback(
    debounce(() => {
      applyFilter();
    }, 500),
    []
  );

  const onSearch = useCallback(
    (e) => {
      filterRef.current = {
        ...filterRef.current,
        search: e.target.value,
      };
      setSearch(e.target.value);
      searchDebounceCb();
    },
    [searchDebounceCb]
  );

  const onVendorSearch = useCallback(
    (e) => {
      const value = e.target.value;
      setVendorSearch(value);
      filterRef.current = {
        ...filterRef.current,
        vendorSearch: value,
      };
      searchDebounceCb();
    },
    [searchDebounceCb]
  );

  const handleFilterClick = useCallback(
    (type, value) => {
      const formData = {
        ...filterData,
        [type]: [
          {
            label: value.name,
            value: {
              _id: value._id,
              name: value.name,
            },
          },
        ],
      };

      callback({ formData });
    },
    [filterData, callback]
  );

  const handleDownload = useCallback(async () => {
    setBusyLoader({ show: true });
    const params = prepareFilterParams(filterRef.current);
    params.outputType = "download";
    params.count = 100000000;
    const res = await getData(params);
    if (res.raw?.data?.filePath) {
      window.open(
        DashboardService.getDealStockMasterDownloadLink(res.raw?.data?.filePath)
      );
      Toaster.success("Download started");
    } else {
      Toaster.error("Something went wrong");
    }
    setBusyLoader({ show: false });
  }, []);

  const viewProduct = (productId) => {
    NavService.openInNewTab(`/cms/product/view`, {
      id: productId,
    });
  };

  const viewVendorDetails = (vendorId) => {
    NavService.openInNewTab(`/vendor/view`, {
      id: vendorId,
    });
  };

  const viewGrnDetails = (grnId) => {
    NavService.openInNewTab(`/purchase-order/grn/view`, {
      id: grnId,
    });
  };

  return (
    <>
      <AppCard title="Product wise Stock Not Allocated">
        <div className="row mb-3 align-items-center">
          <PaginationSummary
            loadingTotalRecords={records.loading}
            paginationConfig={paginationRef.current}
            className="col"
          />
          <div className="col-4 ms-auto">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search by Product Name/ID"
                value={search}
                onChange={onSearch}
              />
            </div>
          </div>
          <div className="col-4 ms-auto">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search by Vendor Name/ID"
                value={vendorSearch}
                onChange={onVendorSearch}
              />
            </div>
          </div>
          <div className="col-auto">
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={handleDownload}
            >
              <i className="bi bi-download me-2"></i>
              Download
            </button>
          </div>
        </div>

        <div
          className="tbl-scroll-container custom-scrollbar thin-scrollbar fixed-width-table"
          style={containerStyle}
        >
          <table
            className="table table-sm mb-0 bg-white table-hover"
            style={tableStyle}
          >
            <TableHeader
              data={headers}
              sortCb={sortCb}
              sort={sortDataRef.current}
              isSticky={true}
            />
            <tbody className="fs-val-sm">
              {loading ? (
                <TableSkeletonLoader cols={headers.length} rows={10} />
              ) : data.length === 0 ? (
                <tr>
                  <td colSpan={headers.length} className="text-center">
                    <NoDataFound>No data found</NoDataFound>
                  </td>
                </tr>
              ) : (
                data.map((item, index) => (
                  <tr key={index}>
                    {/* Serial Number */}
                    <td
                      className="sticky-column bg-white text-center"
                      style={stickySerialNumberStyle}
                    >
                      {paginationRef.current.startSlNo + index}
                    </td>

                    {/* Product Information */}
                    <td
                      style={stickyProductNameStyle}
                      className="sticky-column bg-white"
                    >
                      <button
                        className="btn btn-link text-dark text-start fs-val-sm p-0 text-wrap"
                        onClick={() => viewProduct(item.productId)}
                      >
                        {item.productName}
                      </button>
                    </td>

                    <td>{item.productId}</td>

                    <td>{(item.shelfLife || 0).toLocaleString()} days</td>

                    <td className="text-center" style={groupStyles.pending}>
                      {item.pendingOrderQty || 0}

                      <InfoPopover
                        content={
                          <div>
                            <div className="fs-val-md mb-3">
                              Sub-Type wise Pending Order Qty
                            </div>
                            <KeyVal
                              label="COCO"
                              labelCol="col-6"
                              contentCol="col-6"
                            >
                              : {item.pendingOrderQtyCOCO}
                            </KeyVal>
                            <KeyVal
                              label="BPCL"
                              labelCol="col-6"
                              contentCol="col-6"
                            >
                              : {item.pendingOrderQtyBPCL}
                            </KeyVal>
                            <KeyVal
                              label="BrandSF"
                              labelCol="col-6"
                              contentCol="col-6"
                            >
                              : {item.pendingOrderQtyBrandSF}
                            </KeyVal>
                            <KeyVal
                              label="OLA"
                              labelCol="col-6"
                              contentCol="col-6"
                            >
                              : {item.pendingOrderQtyOlasf}
                            </KeyVal>
                            <KeyVal
                              label="Pincode"
                              labelCol="col-6"
                              contentCol="col-6"
                            >
                              : {item.pendingOrderQtyPincodeSF}
                            </KeyVal>
                            <KeyVal
                              label="SMARTSF"
                              labelCol="col-6"
                              contentCol="col-6"
                            >
                              : {item.pendingOrderQtySmartSF}
                            </KeyVal>
                          </div>
                        }
                      />
                    </td>

                    <td style={groupStyles.order}>
                      <DateFormatter
                        date={item.recentOrderDate}
                        format="dd MMM yyyy"
                      />
                      <div className="text-muted fs-val-sm d-inline-block">
                        ,{" "}
                        <DateFormatter
                          date={item.recentOrderDate}
                          format="hh:mm a"
                        />
                      </div>
                    </td>

                    <td style={groupStyles.order}>
                      <DateFormatter
                        date={item.oldestOrderDate}
                        format="dd MMM yyyy"
                      />
                      <div className="text-muted fs-val-sm d-inline-block">
                        ,{" "}
                        <DateFormatter
                          date={item.oldestOrderDate}
                          format="hh:mm a"
                        />
                      </div>
                    </td>

                    <td style={groupStyles.po}>
                      {item.latestPoDate ? (
                        <>
                          <DateFormatter
                            date={item.latestPoDate}
                            format="dd MMM yyyy"
                          />
                          <div className="text-muted fs-val-sm d-inline-block">
                            ,{" "}
                            <DateFormatter
                              date={item.latestPoDate}
                              format="hh:mm a"
                            />
                          </div>
                        </>
                      ) : (
                        <span className="text-muted">--</span>
                      )}
                    </td>
                    <td style={groupStyles.po}>{item.latestPoId || "--"}</td>
                    <td style={groupStyles.po}>{item.latestPoQty || "--"}</td>
                    <td>
                      {item.latestPoVendorName ? (
                        <>
                          <BtnLink
                            className="fs-val-sm text-wrap"
                            onClick={(e) => {
                              e.stopPropagation();
                              viewVendorDetails(item.latestPoVendorId);
                            }}
                          >
                            {item.latestPoVendorName}
                          </BtnLink>
                          <div className="fs-val-sm text-muted">
                            ID: {item.latestPoVendorId}
                          </div>
                        </>
                      ) : (
                        <span className="text-muted">--</span>
                      )}
                    </td>
                    <td style={groupStyles.po}>
                      {item.latestGrnDate ? (
                        <>
                          <DateFormatter
                            date={item.latestGrnDate}
                            format="dd MMM yyyy"
                          />
                          <div className="text-muted fs-val-sm d-inline-block">
                            ,{" "}
                            <DateFormatter
                              date={item.latestGrnDate}
                              format="hh:mm a"
                            />
                          </div>
                        </>
                      ) : (
                        <span className="text-muted">--</span>
                      )}
                    </td>
                    <td style={groupStyles.po}>
                      {item.latestGrnId ? (
                        <BtnLink
                          className="fs-val-sm text-wrap"
                          onClick={(e) => {
                            e.stopPropagation();
                            viewGrnDetails(item.latestGrnId);
                          }}
                        >
                          {item.latestGrnId}
                        </BtnLink>
                      ) : (
                        <span className="text-muted">--</span>
                      )}
                    </td>
                    <td style={groupStyles.po}>{item.latestGrnQty || "--"}</td>

                    <td className="text-center" style={groupStyles.po}>
                      {item.poQty || 0}
                    </td>

                    <td className="text-center">{item.inStockQty || 0}</td>

                    <td className="text-center">
                      <Amount value={item.mrp} decimalPlace={2} />
                    </td>
                    <td className="text-center">{item.innerPackQty}</td>
                    <td className="text-center">{item.caseQty}</td>
                    <td
                      className={classNames("text-center", {
                        "text-muted": !item.last30DaysSaleQty,
                      })}
                      style={groupStyles.sales}
                    >
                      {item.last30DaysSaleQty}
                    </td>
                    <td
                      className={classNames("text-center", {
                        "text-muted": !item.last60DaysSaleQty,
                      })}
                      style={groupStyles.sales}
                    >
                      {item.last60DaysSaleQty}
                    </td>
                    <td
                      className={classNames("text-center", {
                        "text-muted": !item.last90DaysSaleQty,
                      })}
                      style={groupStyles.sales}
                    >
                      {item.last90DaysSaleQty}
                    </td>
                    <td>
                      <button
                        className="btn btn-link text-dark text-start fs-val-sm p-0 text-wrap"
                        onClick={() =>
                          handleFilterClick("menu", {
                            name: item.menuName,
                            _id: item.menuId,
                          })
                        }
                      >
                        {item.menuName}
                      </button>
                    </td>

                    <td>
                      <button
                        className="btn btn-link text-dark text-start fs-val-sm p-0 text-wrap"
                        onClick={() =>
                          handleFilterClick("category", {
                            name: item.categoryName,
                            _id: item.categoryId,
                          })
                        }
                      >
                        {item.categoryName}
                      </button>
                    </td>

                    {/* Brand Name */}
                    <td>
                      <button
                        className="btn btn-link text-dark text-start fs-val-sm p-0 text-wrap"
                        onClick={() =>
                          handleFilterClick("brand", {
                            name: item.brandName,
                            _id: item.brandId,
                          })
                        }
                      >
                        {item.brandName}
                      </button>
                    </td>

                    <td>
                      <button
                        className="btn btn-link text-dark text-start fs-val-sm p-0 text-wrap"
                        onClick={() =>
                          handleFilterClick("companyName", {
                            name: item.companyName,
                            _id: item.companyId,
                          })
                        }
                      >
                        {item.companyName}
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className="text-end mt-3">
          <PaginationBlock
            paginationCb={paginationCb}
            loadingTotalRecords={records.loading}
            paginationConfig={paginationRef.current}
            size="sm"
          />
        </div>
      </AppCard>
      <BusyLoader show={busyLoader.show} />
    </>
  );
};

export default StockNotAllocatedNoPoTable;
