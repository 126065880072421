import { DashboardService, NavService } from "@sk/services";
import { useEffect, useState } from "react";
import InventoryAgeingSummaryCard from "./InventoryAgeingSummaryCard";
import { NON_SELLABLE } from "../constant";

const getData = async (params, isStoreWise = false) => {
  const r = isStoreWise
    ? await DashboardService.fetchStoreInventoryAnalytics(params)
    : await DashboardService.fetchInventoryAnalytics(params);
  return Array.isArray(r?.resp) ? r?.resp : [];
};

const prepareFilterParams = (filters) => {
  let type = "getInventoryStatusSummary";

  // Change query type if store filter is enabled
  if (filters.store?.length > 0) {
    type = "getStoreInventoryStatusSummary"; // Dummy type for now
  }

  const params = DashboardService.prepareInventoryAnalyticsParams(
    filters,
    type
  );

  return params;
};

const InventoryAgeingNonSellableSummary = ({ filterData }) => {
  const [data, setData] = useState({
    products: 0,
    quantity: 0,
    value: 0,
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        const params = prepareFilterParams(filterData);
        const response = await getData(params, filterData?.store?.length > 0);
        const nonSellableData =
          response.find((item) => item.inventoryStatus === NON_SELLABLE) || {};
        setData({
          products: nonSellableData?.pIdCount || 0,
          quantity: nonSellableData?.totalQty || 0,
          value: nonSellableData?.totalValue || 0,
        });
      } catch (error) {
        console.error("Error loading non-sellable inventory summary:", error);
        setData({
          products: 0,
          quantity: 0,
          value: 0,
        });
      }
    };
    loadData();
  }, [filterData]);

  const onLinkClick = () => {
    const params = DashboardService.prepareNavParamsForInventoryAgeing(
      filterData,
      [{ key: "tab", value: "nonSellableInventory" }]
    );
    NavService.openInNewTab("/dashboard/inventory-ageing/products", params);
  };

  return (
    <InventoryAgeingSummaryCard
      title="NON-SELLABLE"
      icon="bi-box-seam"
      info="In Stock Non-Sellable Products"
      products={data.products}
      quantity={data.quantity}
      value={data.value}
      bgColor="bg-warning"
      noView={false}
      callback={onLinkClick}
    />
  );
};

export default InventoryAgeingNonSellableSummary;
