import React, { useState, useMemo } from "react";
import { AppCard, TableHeader, Amount } from "@sk/uis";

const salesTypes = ["Products", "Menus", "Brands", "Categories"];

const columns = {
  Products: [
    { key: "name", label: "Product Name", width: "40%" },
    { key: "quantity", label: "Quantity Sold", width: "20%" },
    { key: "revenue", label: "Revenue", width: "20%" },
  ],
  Menus: [
    { key: "name", label: "Menu Name", width: "40%" },
    { key: "itemsSold", label: "Product Sold", width: "20%" },
    { key: "revenue", label: "Revenue", width: "20%" },
  ],
  Brands: [
    { key: "name", label: "Brand Name", width: "40%" },
    { key: "productsSold", label: "Products Sold", width: "20%" },
    { key: "revenue", label: "Revenue", width: "20%" },
  ],
  Categories: [
    { key: "name", label: "Category Name", width: "40%" },
    { key: "productsSold", label: "Products Sold", width: "20%" },
    { key: "revenue", label: "Revenue", width: "20%" },
  ],
};

const mockData = {
  Products: [
    { name: "iPhone 13 Pro", quantity: 1500, revenue: 1875000 },
    { name: "Samsung Galaxy S21", quantity: 1200, revenue: 1080000 },
    { name: "MacBook Air M1", quantity: 800, revenue: 960000 },
    { name: "AirPods Pro", quantity: 2000, revenue: 500000 },
    { name: "iPad Air", quantity: 950, revenue: 570000 },
    { name: "Sony PlayStation 5", quantity: 600, revenue: 300000 },
    { name: "Nintendo Switch", quantity: 1100, revenue: 385000 },
    { name: "LG OLED TV", quantity: 400, revenue: 600000 },
    { name: "Dyson V11 Vacuum", quantity: 750, revenue: 450000 },
    { name: "Bose QuietComfort 45", quantity: 900, revenue: 315000 },
  ],
  Menus: [
    { name: "Breakfast Combo", itemsSold: 3500, revenue: 87500 },
    { name: "Lunch Special", itemsSold: 4200, revenue: 168000 },
    { name: "Family Dinner Pack", itemsSold: 1800, revenue: 144000 },
    { name: "Vegetarian Delight", itemsSold: 2200, revenue: 66000 },
    { name: "Seafood Feast", itemsSold: 1500, revenue: 150000 },
    { name: "Kids Meal", itemsSold: 2800, revenue: 56000 },
    { name: "Dessert Platter", itemsSold: 1900, revenue: 47500 },
    { name: "Healthy Salad Bowl", itemsSold: 2500, revenue: 75000 },
    { name: "Gourmet Burger Combo", itemsSold: 3100, revenue: 124000 },
    { name: "Pizza Party Pack", itemsSold: 1600, revenue: 128000 },
  ],
  Brands: [
    { name: "Apple", productsSold: 5250, revenue: 3905000 },
    { name: "Samsung", productsSold: 4800, revenue: 2880000 },
    { name: "Sony", productsSold: 3200, revenue: 1600000 },
    { name: "LG", productsSold: 2800, revenue: 1680000 },
    { name: "Microsoft", productsSold: 2100, revenue: 1050000 },
    { name: "Dell", productsSold: 1900, revenue: 1140000 },
    { name: "HP", productsSold: 1700, revenue: 850000 },
    { name: "Lenovo", productsSold: 1500, revenue: 750000 },
    { name: "Asus", productsSold: 1300, revenue: 650000 },
    { name: "Acer", productsSold: 1100, revenue: 550000 },
  ],
  Categories: [
    { name: "Smartphones", productsSold: 12000, revenue: 9600000 },
    { name: "Laptops", productsSold: 8000, revenue: 8000000 },
    { name: "Smart Home Devices", productsSold: 15000, revenue: 3000000 },
    { name: "Audio Equipment", productsSold: 10000, revenue: 2500000 },
    { name: "Gaming Consoles", productsSold: 5000, revenue: 2250000 },
    { name: "Wearables", productsSold: 9000, revenue: 1800000 },
    { name: "Cameras", productsSold: 3500, revenue: 1750000 },
    { name: "TVs", productsSold: 4000, revenue: 4000000 },
    { name: "Home Appliances", productsSold: 6000, revenue: 3600000 },
    { name: "Computer Accessories", productsSold: 20000, revenue: 1000000 },
  ],
};

export default function TopSales() {
  const [selectedType, setSelectedType] = useState(salesTypes[0]);
  const [sort, setSort] = useState({ key: "", value: "asc" });

  const sortedData = useMemo(() => {
    const data = mockData[selectedType];
    if (sort.key) {
      return [...data].sort((a, b) => {
        if (a[sort.key] < b[sort.key]) return sort.value === "asc" ? -1 : 1;
        if (a[sort.key] > b[sort.key]) return sort.value === "asc" ? 1 : -1;
        return 0;
      });
    }
    return data;
  }, [selectedType, sort]);

  const renderTableRow = (item, index) => {
    switch (selectedType) {
      case "Products":
        return (
          <tr key={index}>
            <td>{item.name}</td>
            <td>{item.quantity.toLocaleString()}</td>
            <td>
              <Amount value={item.revenue} />
            </td>
          </tr>
        );
      case "Menus":
        return (
          <tr key={index}>
            <td>{item.name}</td>
            <td>{item.itemsSold.toLocaleString()}</td>
            <td>
              <Amount value={item.revenue} />
            </td>
          </tr>
        );
      case "Brands":
      case "Categories":
        return (
          <tr key={index}>
            <td>{item.name}</td>
            <td>{item.productsSold.toLocaleString()}</td>
            <td>
              <Amount value={item.revenue} />
            </td>
          </tr>
        );
    }
  };

  return (
    <AppCard title="Top 20 Catalog Sales">
      <div className="mb-3">
        <select
          className="form-select"
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
        >
          {salesTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
      <div
        className="table-responsive custom-scrollbar"
        style={{
          height: "300px",
          overflowY: "auto",
        }}
      >
        <table className="table table-hover table-sm">
          <TableHeader
            data={columns[selectedType]}
            sort={sort}
            sortCb={setSort}
            fw="semi"
          />
          <tbody className="fs-val-md">
            {sortedData.slice(0, 20).map(renderTableRow)}
          </tbody>
        </table>
      </div>
    </AppCard>
  );
}
