import { NavService } from "@sk/services";
import {
  Amount,
  AppCard,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { useNavigate } from "react-router-dom";

const headers = [
  {
    label: "Store Details",
    width: "30%",
  },
  {
    label: "Town",
  },
  {
    label: "Customers",
    isCentered: true,
  },
  {
    label: "Unique Customers",
    isCentered: true,
  },
  {
    label: "Orders",
    isCentered: true,
  },
  {
    label: "Order Value",
    isCentered: true,
  },
  {
    label: "Coins Rewarded",
    isCentered: true,
  },
  {
    label: "Coins Redeemed",
    isCentered: true,
  },
];

function StoreDashboardTable({ data = [], loading = false }) {
  const navigate = useNavigate();

  const handleStoreClick = (id) => {
    NavService.to(navigate, `/pos/sales-analytics`, {
      fid: id,
    });
  };

  return (
    <AppCard>
      <table className="table table-sm table-striped bg-white">
        <TableHeader data={headers} noBg />
        <tbody>
          {loading ? <TableSkeletonLoader cols={headers.length} /> : null}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No data to display</NoDataFound>
              </td>
            </tr>
          ) : null}

          {data.map((e) => (
            <tr key={e._id} className="fs-val-md">
              <td>
                <button
                  onClick={() => handleStoreClick(e._id)}
                  className="mb-1 fw-semibold text-primary btn btn-link text-dark text-left p-0 fs-val-md"
                >
                  {e._fran?.name}
                </button>
                <div className="fs-val-sm">
                  <span>
                    {e._fran?.district}, {e._fran?.state}
                  </span>
                  <span> - {e._fran?.pincode} </span>
                </div>
              </td>
              <td>{e._fran?.town}</td>
              <td className="text-center">{e.totalCustomers || 0}</td>
              <td className="text-center text-danger">
                {e.totalUniqueCustomers || 0}
              </td>
              <td className="text-center">{e.totalOrderCount || 0}</td>
              <td className="text-center">
                <Amount value={e.totalOrderValue || 0} />
              </td>
              <td className="text-center text-primary">
                {e.totalKCRewardedCustomer || 0}
              </td>
              <td className="text-center text-danger">
                {e.totalKCRedeemed || 0}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </AppCard>
  );
}

export default StoreDashboardTable;
