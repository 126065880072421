import { PageInfo } from "@sk/uis";
import { Filter } from "./components/Filter";
import { SalesTable } from "./components/SalesTable";
import Summary from "./components/Summary";
import TopSales from "./components/TopSales";
import TopCustomers from "./components/TopCustomers";
import TopStore from "./components/TopStore";
import FrequentSales from "./components/FrequentSales";
import Sales from "./components/Sales";

const breadcrumbs = [{ name: "Home", link: "/" }, { name: "Sales Dashboard" }];

export default function SalesDashboard() {
  return (
    <>
      <PageInfo breadcrumbs={breadcrumbs} title="Sales Dashboard" />
      <Filter />
      <Summary />

      <div className="row">
        <div className="col-12">
          <SalesTable />
        </div>
        <div className="col-6">
          <TopSales />
        </div>
        <div className="col-6">
          <TopCustomers />
        </div>
        <div className="col-12">
          <TopStore />
        </div>
        <div className="col-12">
          <FrequentSales />
        </div>
        <div className="col-12">
          <Sales />
        </div>
      </div>
    </>
  );
}
