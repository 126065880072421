import { useAttachAdditionalData } from "@sk/hooks";
import { ProductService } from "@sk/services";
import {
  AppCard,
  DateFormatter,
  NoDataFound,
  PaginationBlock,
  TableHeader,
} from "@sk/uis";
import { format } from "date-fns";
import { each } from "lodash";

import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

const tableHeaders = [
  {
    label: "Sl No",
    width: "8%",
    key: "slNo",
  },
  {
    label: "Type",
    width: "20%",
    key: "key",
  },
  {
    label: "Message",
    key: "remarks",
  },
  {
    label: "Created On",
    width: "20%",
    key: "createdOn",
  },
];

const subTableHeaders = [
  {
    label: "Key",
    width: "20%",
    key: "key",
  },
  {
    label: "Old Value",
    width: "30%",
    key: "oldValue",
  },
  {
    label: "New Value",
    width: "30%",
    key: "newValue",
  },
];

const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_user",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const ReservationAuditLog = ({ configId }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();
  const [data, setData] = useState([]);
  const [loadingTotalRecords, setLoadingTotalRecord] = useState(false);

  const paginationRef = useRef({
    ...defaultPagination,
  });

  useEffect(() => {
    if (configId) {
      applyFilter();
    }
  }, [applyFilter, configId]);

  const loadAuditLog = useCallback(async () => {
    const r = await ProductService.getReservationAuditLog({
      page: paginationRef.current.activePage,
      count: paginationRef.current.rowsPerPage,
      filter: {
        refNo: configId,
      },
    });

    let d = (r?.resp || []).map((x) => {
      (x.changeLogList || []).forEach((x) => {
        each(x, (v, k) => {
          if (k == "status") {
            v.oldValue = ProductService.getReservationDisplayStatus(v.oldValue);
            v.newValue = ProductService.getReservationDisplayStatus(v.newValue);
          }
          try {
            if (k == "startDate" || k == "endDate") {
              v.oldValue = format(new Date(v.oldValue), "dd MMM yyyy hh:mm a");
              v.newValue = format(new Date(v.newValue), "dd MMM yyyy hh:mm a");
            }
          } catch (error) {
            console.warn(error);
          }
        });
      });

      return x;
    });

    setData(d);

    if (d.length) {
      let tmp = [];
      // Attach User Info
      setAdditionalData(d, attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          const t = [...attachAllData(d, tmp)];
          setData([...t]);
        }
      });
    }
  }, [attachAllData, configId, setAdditionalData]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPagination };

    loadAuditLog();

    setLoadingTotalRecord(true);
    const c = await getCount();
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecord(false);
  }, [getCount, loadAuditLog]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadAuditLog();
    },
    [loadAuditLog]
  );

  const getCount = useCallback(async () => {
    const r = await ProductService.getReservationAuditLogCount({
      filter: {
        refNo: configId,
      },
    });
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  }, [configId]);

  return (
    <>
      <AppCard title="Audit Information">
        <table className="table table-sm">
          <TableHeader data={tableHeaders} noBg />
          <tbody>
            {/* LOADER */}

            {/* NO DATA FOUND */}
            {data.length == 0 ? (
              <tr>
                <td colSpan={tableHeaders.length}>
                  <NoDataFound> No Data Found </NoDataFound>
                </td>
              </tr>
            ) : null}

            {(data || []).map((d, i) => (
              <tr key={i}>
                <td className="fs-val-md text-center">
                  {paginationRef.current.startSlNo + i}
                </td>
                <td className="fs-val-md text-center text-uppercase">
                  {d.type}
                </td>
                <td className="fs-val-md">
                  <div className="my-2">{d.message}</div>

                  {d.changeLogList.length > 0 ? (
                    <table className="table table-sm bg-light">
                      <TableHeader data={subTableHeaders} noBg fwSize="sm" />
                      <tbody>
                        {(d.changeLogList || []).map((x, i1) => (
                          <tr key={i1}>
                            {Object.keys(x).map((k, i2) => (
                              <Fragment key={i2}>
                                <td className="fs-val-sm">{k}</td>
                                <td className="fs-val-sm">{x[k].oldValue}</td>
                                <td className="fs-val-sm">{x[k].newValue}</td>
                              </Fragment>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : null}
                </td>
                <td className="fs-val-md">
                  <DateFormatter date={d.createdAt} />
                  <div>by {d?._user?.name || d.createdBy}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {data.length > 0 ? (
          <div className="px-4 py-3 mb-3">
            <PaginationBlock
              paginationConfig={paginationRef.current}
              paginationCb={paginationCb}
              loadingTotalRecords={loadingTotalRecords}
            />
          </div>
        ) : null}
      </AppCard>
    </>
  );
};

export default memo(ReservationAuditLog);
