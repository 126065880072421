import { SummaryCard } from "@sk/uis";

const summaryData = [
  {
    label: "Total Sales",
    value: 1000000, // $1,000,000
    icon: "bi-cash-stack",
    color: "success",
    isValueAmt: true,
    info: {
      header: "Total Sales",
      content: "Total revenue generated from all orders",
    },
  },
  {
    label: "Total Orders",
    value: 5000, // 5,000 orders
    icon: "bi-cart",
    color: "primary",
    isValueAmt: false,
    info: {
      header: "Total Orders",
      content: "Total number of orders processed",
    },
  },
  {
    label: "Average Order Value",
    value: 200, // $200
    icon: "bi-receipt",
    color: "info",
    isValueAmt: true,
    info: {
      header: "Average Order Value",
      content: "Average revenue per order (Total Sales / Total Orders)",
    },
  },
  {
    label: "Unique Customers",
    value: 3500, // 3,500 unique customers
    icon: "bi-people",
    color: "warning",
    isValueAmt: false,
    info: {
      header: "Unique Customers",
      content: "Total number of distinct customers who have placed orders",
    },
  },
];

const Summary = () => {
  // ... existing code ...

  return (
    <div className="row">
      {summaryData.map((item, index) => (
        <div className="col-md-3" key={index}>
          <SummaryCard
            template={1}
            title={item.label}
            value={item.value}
            isValueAmt={item.isValueAmt}
            icon={item.icon}
            valueColor={item.color}
            data={item.percentage ? { percentage: item.percentage } : undefined}
            info={item.info} // You can add more detailed info if needed
          />
        </div>
      ))}
    </div>
  );
};

export default Summary;
