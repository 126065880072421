import { yupResolver } from "@hookform/resolvers/yup";
import { AuthService, ProductService } from "@sk/services";
import {
  Alert,
  AppCard,
  AppTitle,
  BooleanCheckboxInput,
  BusyLoader,
  EntitySearchInput,
  PageLoader,
  SelectInput,
  Toaster,
} from "@sk/uis";
import { differenceInMinutes } from "date-fns";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import GroupCodeConfigTable from "../components/GroupCodeConfigTable";
import formValidation from "../validations/formValidation";
import groupCodeValidation from "../validations/groupCodeValidation";
import classNames from "classnames";

import styles from "../scss/Reservation.module.scss";

const canvasStyle = { width: "62%" };

const getProductDetails = async (id) => {
  const r = await ProductService.getList({
    filter: {
      _id: id,
      disableForReserve: false,
    },
  });
  return {
    data: (r.resp || []).map((x) => ({
      label: x.name + " - (" + x._id + ")",
      value: x,
    })),
  };
};

const emp = AuthService.getLoggedInEmp();

const defaultWarehouse = emp?.defaultWh;

const shipmentDaysOpt = [
  { label: "Choose", value: "" },
  { label: "1 day", value: 1 },
  { label: "2 days", value: 2 },
  { label: "3 days", value: 3 },
  { label: "4 days", value: 4 },
  { label: "5 days", value: 5 },
  { label: "6 days", value: 6 },
  { label: "7 days", value: 7 },
];

const states = AuthService.getLoggedInEmpWhStates().map((x) => ({
  label: x,
  value: x,
}));

const ManageReserveConfigModal = ({ show, closeModal, mode, configId }) => {
  // form configuration
  const {
    reset,
    getValues,
    setValue,
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      product: [],
      state: defaultWarehouse?.state || "",
      warehouse: defaultWarehouse?._id || "",
      mode: mode,
      isReserveOrderPriority: false,
      allowOnlyReserveOrders: false,
      groupId: "",
      shipmentTat: "",
      configs: [],
      warehouses: [],
    },
    resolver: yupResolver(formValidation),
  });

  const [pageLoading, setPageLoading] = useState(false);

  const [busyLoader, setBusyLoader] = useState({ loading: false });

  const [reloadConfig, setReloadConfig] = useState(false);

  const groupId = useWatch({ control, name: "groupId" });

  const [subOrderDetails, setSubOrderDetails] = useState({});

  const productDetails = useWatch({
    control,
    name: "product",
  });

  const isReserveOrderPriority = useWatch({
    control,
    name: "isReserveOrderPriority",
  });

  const warehouses = useWatch({
    control,
    name: "warehouses",
  });

  const detailsRef = useRef({});

  useEffect(() => {
    if (show) {
      // reset the form
      resetFormData();

      // if mode is edit then fetch the details of configuration
      if (mode == "edit") {
        loadDetails();
      }
    }
  }, [loadDetails, mode, resetFormData, show]);

  const onStateChangeCb = useCallback(
    (val) => {
      // get warehouses based on state
      const whs = AuthService.getWhOnState(val).map((x) => ({
        label: x.name,
        value: x._id,
      }));
      setValue("warehouses", [...whs]);

      // set default warehouse
      setValue("warehouse", whs.length > 0 ? whs[0].value : "");

      prepareGroupId();
    },
    [prepareGroupId, setValue]
  );

  // fetch the details based on config id and update the form data
  const loadDetails = useCallback(async () => {
    detailsRef.current = {};

    setPageLoading(true);

    // get configuration details
    const r = await ProductService.getReservationList({
      filter: { _id: configId },
    });

    let d = {};
    if (r?.resp && Array.isArray(r?.resp) && r.resp.length > 0) {
      d = r.resp[0];
    }

    if (!d._id) {
      Toaster.error("Failed to load data, please try again");
      return;
    }

    detailsRef.current = d;
    setSubOrderDetails(d.subOrderDetails);

    // get product details
    const prdResp = await getProductDetails(d.productId);
    setValue("product", prdResp.data.length > 0 ? prdResp.data : []);

    // update the form data
    const whs = AuthService.getLoggedInEmpWh() || [];
    const wh = whs.find((x) => x._id == d.whId) || {};
    setValue("warehouses", [
      ...AuthService.getWhOnState(wh.state).map((x) => ({
        label: x.name,
        value: x._id,
      })),
    ]);
    setValue("state", wh.state || "");
    setValue("warehouse", wh._id || "");
    setValue("shipmentTat", d.shippmentTat);
    setValue("applicableFromDate", [d.applicableFromDate]);
    setValue("endDate", [d.endDate]);
    setValue("mode", "edit");
    setValue("isReserveOrderPriority", d.isReserveOrderPriority);
    setValue("allowOnlyReserveOrders", d.allowOnlyReserveOrders);

    prepareGroupId();

    setPageLoading(false);
  }, [configId, prepareGroupId, setValue]);

  const preparePayload = (data) => {
    const configData = (data.configs || []).filter((x) => !x.isInactive);
    let c = [];

    if (mode == "edit") {
      const d = detailsRef.current;
      if (
        Math.abs(
          differenceInMinutes(new Date(data.endDate[0]), new Date(d.endDate))
        ) ||
        data.isReserveOrderPriority != d.isReserveOrderPriority ||
        data.allowOnlyReserveOrders != d.allowOnlyReserveOrders
      ) {
        c = configData.filter((x) => x.selected || x.alreadyConfigured);
      } else {
        c = configData.filter((x) => x.selected);
      }
    } else {
      c = configData.filter((x) => x.selected);
    }

    const p = c.map((x) => {
      let temp = {
        allowReserveOrder: true,
        preOrderLimit: x._preOrderLimit,
        productId: data.product[0].value._id,
        productName: data.product[0].value?.name,
        groupCode: x.groupCode,
        whId: data.warehouse,
        isReservedChecked: true,
        shippmentTat: data.shipmentTat,
        applicableFromDate: new Date(x._applicableFromDate[0]),
        endDate: new Date(x._endDate[0]),
        // groupConfigId: groupId,
      };

      if (x._id) {
        temp._id = x._id;
      }

      if (x.status == "Ended") {
        temp.status = "Pending";
      }

      temp.isReserveOrderPriority = data.isReserveOrderPriority;

      if (!temp.isReserveOrderPriority) {
        temp.allowOnlyReserveOrders = false;
      } else {
        temp.allowOnlyReserveOrders = data.allowOnlyReserveOrders;
      }

      return temp;
    });
    return p;
  };

  // for reseting the form data and its dependent state
  const resetFormData = useCallback(() => {
    const state = states[0]?.value || "";
    setSubOrderDetails({});
    reset({
      mode: "add",
      state: state,
      product: [],
    });
    // trigger state to update warehouse input
    onStateChangeCb(state);
  }, [onStateChangeCb, reset]);

  const onSubmit = async () => {
    setReloadConfig(false);

    const f = getValues();
    const c = f.configs.filter((x) => x.selected);

    let errMsg = "";
    try {
      await groupCodeValidation.validate({
        configs: c,
        mode: mode,
      });
    } catch (error) {
      errMsg = error.message || "";
    }

    if (errMsg) {
      Toaster.error(errMsg);
      return;
    }

    const alrt = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (alrt.isConfirmed) {
      const p = preparePayload(getValues());

      setBusyLoader({ loading: true });

      const r = await ProductService.createReserve(groupId, p);

      setBusyLoader({ loading: false });

      if (r.statusCode !== 200) {
        Toaster.error(r.resp.message || "Failed to update");
        return;
      }

      // check in success response has any error
      if (
        r.resp?.response &&
        r.resp?.response.errorResponse &&
        r.resp?.response.errorResponse.length > 0
      ) {
        Toaster.error(
          r.resp?.response.errorResponse.map((e) => e.message).join(",")
        );
        setReloadConfig(true);
        return;
      }

      const ids = (r.resp?.response?.successResponse || []).map((x) => x.id);

      Toaster.success("Updated Successfully");
      triggerCloseModal("submit", { ids: ids, mode });

      resetFormData();
    }
  };

  const triggerCloseModal = (status = "cancel", data = {}) => {
    closeModal({ status, data });
  };

  // for preparing group id based on the product and warehouse id
  const prepareGroupId = useCallback(() => {
    const v = getValues();
    if (v.product && v.product.length > 0 && v.warehouse) {
      const p = v.product[0].value;
      setValue("groupId", "CGID" + p._id + v.warehouse);
    } else {
      setValue("groupId", "");
    }
  }, [getValues, setValue]);

  const onProductChange = useCallback(
    (changFn) => {
      return async (val) => {
        if (Array.isArray(val) && val.length > 0) {
          const v = val[0];
          let msg = "";
          if (!v.value.is_active) {
            msg = v.label + " product is not active";
          } else if (v.value.disableForReserve == true) {
            msg = v.label + " has been disabled for Reserve";
          } else {
            msg = "";
          }
          if (msg) {
            Toaster.error(msg);
            changFn([]);
            prepareGroupId();
            return;
          }
        }
        changFn(val);
        prepareGroupId();
      };
    },
    [prepareGroupId]
  );

  const warehouseChangeCb = useCallback(() => {
    prepareGroupId();
  }, [prepareGroupId]);

  const updateGroupCodeConfig = useCallback(
    (r) => {
      setValue("configs", r.data);
    },
    [setValue]
  );

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        placement="end"
        style={canvasStyle}
        enforceFocus={false}
      >
        <Offcanvas.Header closeButton className="bg-white">
          <AppTitle
            title={
              mode == "edit" ? "Edit Reserve Config" : "Add Reserve Config"
            }
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="modal-bg">
          {show ? (
            <>
              {pageLoading ? (
                <PageLoader />
              ) : (
                <>
                  <AppCard>
                    <div className="row">
                      <div className="col-12">
                        <Controller
                          control={control}
                          name="product"
                          render={({ field: { onChange, value } }) => (
                            <EntitySearchInput
                              type="product"
                              label="Search for Product"
                              error={errors?.product?.message}
                              placeholder="Search by Name/ID"
                              isMandatory={true}
                              value={value}
                              callback={onProductChange(onChange)}
                              uid="product"
                              // filterParams={productSearchParams}
                              disabled={mode == "edit"}
                            />
                          )}
                        />

                        {productDetails && productDetails[0]?.value?._id ? (
                          <div className="fs-val-md pb-3 row border-b mb-3">
                            <div className="col-auto">
                              <span className="badge badge-soft-primary">
                                <span>Case Qty: </span>
                                <span className="fw-bold">
                                  {productDetails[0]?.value?.caseQty || 0}
                                </span>
                              </span>
                            </div>
                            <div className="col-auto">
                              <span className="badge badge-soft-primary">
                                <span>Inner Case Qty: </span>
                                <span className="fw-bold">
                                  {productDetails[0]?.value?.innerPackQty || 0}
                                </span>
                              </span>
                            </div>

                            {mode == "edit" && (
                              <div className="col-auto ms-auto">
                                <div className="row">
                                  <div className="col-auto">
                                    <div className="fw-semibold  fs-val-md">
                                      Product Sold in:
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <span
                                      className={classNames(
                                        styles.lbl,
                                        "fs-val-md"
                                      )}
                                    >
                                      30 Days :{" "}
                                    </span>
                                    <span className="fw-semibold fs-val-md me-2">
                                      {subOrderDetails?.thirtydaysQty ?? 0}
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    <span
                                      className={classNames(
                                        styles.lbl,
                                        "fs-val-md"
                                      )}
                                    >
                                      60 Days :{" "}
                                    </span>
                                    <span className="fw-semibold fs-val-md me-2">
                                      {subOrderDetails?.sixtydaysQty ?? 0}
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    <span
                                      className={classNames(
                                        styles.lbl,
                                        "fs-val-md"
                                      )}
                                    >
                                      90 Days :{" "}
                                    </span>
                                    <span className="fw-semibold fs-val-md">
                                      {subOrderDetails?.ninetydaysQty ?? 0}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>

                      {/* STATES */}
                      <div className="col-4 mb-2">
                        <SelectInput
                          label="Select States"
                          callback={onStateChangeCb}
                          register={register}
                          name="state"
                          options={states}
                          isMandatory={true}
                          error={errors?.state?.message}
                          disabled={mode == "edit"}
                        />
                      </div>

                      {/* WAREHOUSE */}
                      <div className="col-4 mb-2">
                        <SelectInput
                          label="Select Warehouse"
                          register={register}
                          name="warehouse"
                          isMandatory={true}
                          options={warehouses}
                          callback={warehouseChangeCb}
                          error={errors?.warehouse?.message}
                          disabled={mode == "edit"}
                        />
                      </div>

                      {/* SHIPMENT TAT */}
                      <div className="col-4 mb-2">
                        <SelectInput
                          label="Shipment Tat (in Days)"
                          register={register}
                          name="shipmentTat"
                          error={errors?.shipmentTat?.message}
                          options={shipmentDaysOpt}
                          isMandatory={true}
                          disabled={mode == "edit"}
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      {/* Is Reserved Order priority */}
                      <div className="col-4 align-self-end">
                        <Controller
                          name="isReserveOrderPriority"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <BooleanCheckboxInput
                              label="Is Reserved Order Priority"
                              name="isReserveOrderPriority"
                              isMandatory={false}
                              callback={onChange}
                              value={value}
                            />
                          )}
                        />
                      </div>

                      {/* ALlowed Only Reserved Order  */}
                      {isReserveOrderPriority ? (
                        <div className="col-4 align-self-end">
                          <Controller
                            name="allowOnlyReserveOrders"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <BooleanCheckboxInput
                                label="Allowed Only Reserved Order"
                                name="allowOnlyReserveOrders"
                                isMandatory={false}
                                callback={onChange}
                                value={value}
                              />
                            )}
                          />
                        </div>
                      ) : null}
                    </div>
                  </AppCard>

                  {groupId ? (
                    <div>
                      <div className="fw-semibold fs-val-md py-3">
                        Configure Group Code
                      </div>
                      <GroupCodeConfigTable
                        mode={mode}
                        groupId={groupId}
                        callback={updateGroupCodeConfig}
                        reInit={reloadConfig}
                        productInfo={productDetails[0]?.value}
                      />
                    </div>
                  ) : null}

                  <div className="py-3 text-end">
                    <button
                      className="btn btn-outline-danger me-2"
                      onClick={() => triggerCloseModal("close")}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Submit
                    </button>
                  </div>
                </>
              )}
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
      <BusyLoader show={busyLoader.loading} />
    </>
  );
};

export default memo(ManageReserveConfigModal);
