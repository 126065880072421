import {
  Amount,
  BtnLink,
  DateFormatter,
  HighlightText,
  NoDataFound,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import classNames from "classnames";
import { memo, useMemo } from "react";

import { WarehouseStock } from "@sk/features";
import { NavService } from "@sk/services";
import { rbac } from "../reserveConstants";
import styles from "../scss/Reservation.module.scss";

const tableStyle = {
  minWidth: "1700px",
};

const reserveQtyStyle = {
  backgroundColor: "#f8f9fa",
};

const stickyProductNameStyle = {
  zIndex: 1,
  left: 0,
};

const remainingQtyStyle = {
  backgroundColor: "#e6ffed",
};

const containerStyle = {
  height: "calc(100vh - 300px)",
  overflowY: "auto",
};

const ReservationTable = ({
  reservationData,
  callback,
  sort,
  sortCb,
  startSlNo,
  showSelectAll,
}) => {
  const handleProductRedirect = (productId) => {
    NavService.openInNewTab("/cms/product/view", { id: productId });
  };

  const tableHeader = useMemo(() => {
    let headers = [
      { label: "Sl.no", key: "slNo", width: "40px" },
      { label: "Config ID", key: "_id", width: "100px" },
      {
        label: "Product Name",
        key: "productName",
        width: "300px",
        isSticky: true,
        stickyPosition: "left",
      },
      { label: "Product ID", key: "productId", width: "100px" },
      { label: "Reserve Qty", key: "preOrderLimit", width: "120px" },
      { label: "Open Orders", key: "orderCount", width: "120px" },
      { label: "Remaining Qty", key: "remainingOrderCount", width: "120px" },
      { label: "Open PO", key: "poDetail.qty", width: "80px" },
      { label: "Validity From", key: "applicableFromDate", width: "180px" },
      { label: "Validity To", key: "endDate", width: "180px" },
      { label: "Status", key: "actualStatus", width: "140px" },
      {
        label: "Sold in 15d",
        key: "subOrderDetails.fifteendaysQty",
        width: "80px",
      },
      {
        label: "Sold in 30d",
        key: "subOrderDetails.thirtydaysQty",
        width: "80px",
      },
      {
        label: "Sold in 60d",
        key: "subOrderDetails.sixtydaysQty",
        width: "80px",
      },
      {
        label: "Sold in 90d",
        key: "subOrderDetails.ninetydaysQty",
        width: "80px",
      },
      { label: "Warehouse", key: "whId", width: "80px" },
      { label: "Warehouse Stock", key: "warehouseStock", width: "120px" },
      { label: "MRP", key: "dealDetail.dealMrp", width: "100px" },
      { label: "B2BPrice", key: "dealDetail.dealB2bPrice", width: "100px" },
      { label: "Margin", key: "margin", width: "100px" },
      { label: "Menu", key: "menuName", width: "120px" },
      { label: "Brand", key: "brandName", width: "120px" },
      { label: "Category", key: "categoryName", width: "120px" },
      { label: "Actions", key: "actions", width: "150px" },
    ];

    if (showSelectAll) {
      headers[0].hasCustomTemplate = true;
      headers[0].template = () => (
        <input
          type="checkbox"
          className="me-1 align-middle"
          onChange={(e) =>
            callback({ action: "bulkAction", data: e.target.checked })
          }
        />
      );
    }

    return headers;
  }, [showSelectAll, callback]);

  return (
    <>
      <div
        className="tbl-scroll-container custom-scrollbar fixed-width-table"
        style={containerStyle}
      >
        <table
          className="table table-hover table-sm bg-white"
          style={tableStyle}
        >
          <TableHeader
            data={tableHeader}
            sort={sort}
            sortCb={sortCb}
            noBg
            isSticky={true}
          />
          <tbody className="fs-val-md">
            {reservationData.loading && (
              <TableSkeletonLoader cols={tableHeader.length} rows={5} />
            )}

            {!reservationData.loading &&
              reservationData?.data?.length === 0 && (
                <tr>
                  <td colSpan={tableHeader.length}>
                    <NoDataFound>No data to display</NoDataFound>
                  </td>
                </tr>
              )}

            {(reservationData.data || []).map((d, i) => (
              <tr
                key={i}
                className={classNames(
                  "fs-val-md",
                  d.status === "Ended" ? styles["expired-record"] : "",
                  d.highlightRow ? styles["highlight-row"] : ""
                )}
              >
                <td>
                  {d.showCheckbox ? (
                    <input
                      type="checkbox"
                      checked={d._selected}
                      className="me-1 align-middle"
                      onChange={(e) =>
                        callback({
                          action: "select",
                          data: e.target.checked,
                          index: i,
                        })
                      }
                    />
                  ) : null}
                  {startSlNo + i}
                </td>
                <td>{d._id}</td>
                <td
                  style={stickyProductNameStyle}
                  className="sticky-column bg-white"
                >
                  <BtnLink
                    className="fs-val-md text-wrap"
                    onClick={() => handleProductRedirect(d.productId)}
                  >
                    {d.productName}
                  </BtnLink>
                </td>
                <td>{d.productId}</td>
                <td style={reserveQtyStyle}>
                  {d.preOrderLimit}
                  {d._canEdit && (
                    <Rbac roles={rbac.edit}>
                      <BtnLink
                        className="ms-2 align-middle fs-val-xs"
                        onClick={() =>
                          callback({ action: "quickEdit", data: d })
                        }
                      >
                        Edit
                      </BtnLink>
                    </Rbac>
                  )}
                </td>
                <td>
                  {d.orderCount ? (
                    <button
                      className="btn fs-val-md btn-link text-danger p-0"
                      onClick={() =>
                        callback({ action: "subOrderList", data: d })
                      }
                    >
                      {d.orderCount || 0}
                    </button>
                  ) : (
                    0
                  )}
                </td>
                <td style={remainingQtyStyle}>{d.remainingOrderCount ?? 0}</td>
                <td>
                  <button
                    className="btn fs-val-md btn-link p-0 text-dark"
                    onClick={() => callback({ action: "viewPO", data: d })}
                  >
                    {d?.poDetail?.qty ?? 0}
                  </button>
                </td>
                <td>
                  <DateFormatter date={d.applicableFromDate} />
                </td>
                <td>
                  <DateFormatter date={d.endDate} />
                </td>

                <td>
                  <HighlightText
                    type={d._statusType}
                    template={2}
                    isSoft={true}
                  >
                    <i className="bi bi-circle-fill fs-val-sm me-1"></i>
                    {d.actualStatus}
                  </HighlightText>
                  {d.isExtended && (
                    <div className="d-inline-block ms-1">
                      <HighlightText type="warning" template={2} isSoft={true}>
                        Extended
                      </HighlightText>
                    </div>
                  )}
                </td>
                <td>{d.subOrderDetails.fifteendaysQty ?? 0}</td>
                <td>{d.subOrderDetails.thirtydaysQty ?? 0}</td>
                <td>{d.subOrderDetails.sixtydaysQty ?? 0}</td>
                <td>{d.subOrderDetails.ninetydaysQty ?? 0}</td>
                <td>{d.whId}</td>
                <td>
                  <WarehouseStock whId={d.whId} pid={d.productId} />
                  <span className="text-muted fs-val-xs"> units</span>
                </td>
                <td>
                  <Amount
                    value={d?.dealDetail?.dealMrp ?? 0}
                    decimalPlace={2}
                  />
                </td>
                <td>
                  <Amount
                    value={d?.dealDetail?.dealB2bPrice ?? 0}
                    decimalPlace={2}
                  />
                </td>
                <td>
                  <span className="text-success">{d?.margin} %</span>
                </td>
                <td>
                  <BtnLink
                    className="fs-val-md text-wrap"
                    onClick={() =>
                      callback({
                        action: "menuFilter",
                        data: [{ label: d.menuName, value: { _id: d.menuId } }],
                      })
                    }
                  >
                    {d.menuName}
                  </BtnLink>
                </td>
                <td>
                  <BtnLink
                    className="fs-val-md text-wrap"
                    onClick={() =>
                      callback({
                        action: "brandFilter",
                        data: [
                          { label: d.brandName, value: { _id: d.brandId } },
                        ],
                      })
                    }
                  >
                    {d.brandName}
                  </BtnLink>
                </td>
                <td>
                  <BtnLink
                    className="fs-val-md text-wrap"
                    onClick={() =>
                      callback({
                        action: "categoryFilter",
                        data: [
                          {
                            label: d.categoryName,
                            value: { _id: d.categoryId },
                          },
                        ],
                      })
                    }
                  >
                    {d.categoryName}
                  </BtnLink>
                </td>
                <td>
                  <Rbac roles={rbac.view}>
                    <button
                      className="btn btn-sm btn-outline-primary me-1 fs-val-sm"
                      onClick={() =>
                        callback({ action: "viewConfig", data: d })
                      }
                      title="View Config"
                    >
                      <i className="bi bi-eye"></i>
                    </button>
                  </Rbac>

                  {d._canEdit && (
                    <Rbac roles={rbac.edit}>
                      <button
                        className="btn btn-sm btn-outline-warning me-1 fs-val-sm"
                        onClick={() =>
                          callback({ action: "editConfig", data: d })
                        }
                        title="Edit Config"
                      >
                        <i className="bi bi-pencil-fill"></i>
                      </button>
                    </Rbac>
                  )}

                  {!d._selected && d._canStop && (
                    <Rbac roles={rbac.stop}>
                      <button
                        className="btn btn-sm btn-outline-danger fs-val-sm"
                        onClick={() =>
                          callback({ action: "stopConfig", data: d })
                        }
                        title="Stop Config"
                      >
                        <i className="bi bi-stop-circle"></i>
                      </button>
                    </Rbac>
                  )}

                  {!d._selected && d._canExtend && (
                    <Rbac roles={rbac.edit}>
                      <button
                        className="btn btn-outline-primary btn-sm fs-val-sm"
                        onClick={() =>
                          callback({ action: "extendConfig", data: d })
                        }
                        title="Extend Config"
                      >
                        <i className="bi bi-arrow-right-circle"></i>
                      </button>
                    </Rbac>
                  )}

                  {d._canStart && (
                    <Rbac roles={rbac.start}>
                      <button
                        className="btn btn-outline-primary btn-sm fs-val-sm"
                        onClick={() =>
                          callback({ action: "startConfig", data: d })
                        }
                        title="Start Config"
                      >
                        <i className="bi bi-play-circle"></i>
                      </button>
                    </Rbac>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default memo(ReservationTable);
