import { MarketingDashboardNavComponent } from "@sk/features";
import { useAttachAdditionalData } from "@sk/hooks";
import { CustomerService } from "@sk/services";
import { PageInfo, PaginationSummary, Toaster } from "@sk/uis";
import { set } from "date-fns";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FilterForm from "./components/FilterForm";
import SummaryCardBox from "./components/SummaryCardBox";
import Table from "./components/Table";
import listView from "./constantService";
import AdvanceFilterModal from "./modals/AdvanceFilterModal";
import ChildCustomerModal from "./modals/ChildCustomerModal";

const breadcrumbs = listView.breadcrumbs;

const defaultFormData = listView.filterFormData;

const defaultPageOpt = listView.pagination;

const defaultSortOpt = {};

// To Prepare params from API
const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
    queryType: "parentSummary",
  };

  if (sort?.key) {
    p.sort = {
      [sort.key]: sort.value == "asc" ? 1 : -1,
    };
  }

  //   if (filter.status) {
  //     p.filter.status = filter.status;
  //   }

  if (filter?.dateRange?.length) {
    p.filter.createdAt = {
      $gte: set(filter.dateRange[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.dateRange[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (filter.customerMobile?.length) {
    p.filter.reciverRefId = filter.customerMobile[0]?.value?.custData?.id;
  }

  if (filter.franchise?.length) {
    p.franchiseId = filter.franchise[0]?.value?._id;
  }

  if (filter.parentCustomer?.length) {
    p.filter.senderRefId = filter.parentCustomer[0]?.value?._id;
  }

  if (filter.mobileNumber) {
    // p.filter.$or = [
    //   { "parentData.mobileNumber": filter.mobileNumber },
    //   { "custData.mobileNumber": filter.mobileNumber },
    // ];
    p.mobileNo = filter.mobileNumber;
  }
  return p;
};

const CoinsDashboard = () => {
  const formMethods = useForm({
    defaultValues: defaultFormData,
  });

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);

  const [showChildModal, setShowChildModal] = useState({
    show: false,
    cid: "",
    name: "",
  });

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const filterDataRef = useRef(defaultFormData);

  const sortRef = useRef({ ...defaultSortOpt });

  const paginationRef = useRef({ ...defaultPageOpt });

  const advanceFilterRef = useRef({});

  const filterParams = useRef({});

  const navigate = useNavigate();

  const applyFilter = useCallback(
    async (resetPagination = true) => {
      if (resetPagination) {
        paginationRef.current = { ...defaultPageOpt };
      }
      filterParams.current = getFilterParams();
      loadList();

      loadCount();
    },
    [loadList, loadCount]
  );

  const loadCount = useCallback(async () => {
    const params = getFilterParams();
    // for total records
    setLoadingTotalRecords(true);
    delete params.page;
    delete params.count;

    const c = await getCount(params);

    // Setting Total Count
    const t =
      c.statusCode == 200 && Array.isArray(c.resp) ? c.resp[0]?.total : 0;

    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, []);

  const init = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, []);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    let par = getFilterParams();
    const r = await getData(par);
    if (r.statusCode !== 200) {
      Toaster.error("Failed to fetch list,Please Reload");
      setData([]);
      setLoadingData(false);
      return;
    }
    let d = Array.isArray(r.resp) ? r.resp : [];
    // const u = await CommonService.attachUserInfo(d, ["modifiedBy"]);

    let tmp = [];
    d.forEach((e) => {
      e.franchiseId = e.parentData.franchiseId;
    });
    // Attaching Additional Data
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setData([...d]);
    setLoadingData(false);
  }, [getFilterParams]);

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  const paginationCb = useCallback((data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
  }, []);

  const getData = (params) => {
    return CustomerService.getReferralCoinsDataList(params);
  };

  const getCount = (params) => {
    return CustomerService.getReferralCoinsDataList({
      ...params,
      outputType: "count",
    });
  };

  const filterCb = useCallback(() => {
    filterDataRef.current = {
      ...formMethods.getValues(),
      ...advanceFilterRef.current,
    };
    applyFilter();
  }, [applyFilter, formMethods]);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = data;
      applyFilter();
    },
    [applyFilter]
  );

  const onAdvanceFilterClickCb = useCallback(() => {
    setShowAdvanceFilter(true);
  }, []);

  const onAdvanceFilterCb = useCallback(
    (r) => {
      const status = r.status;
      if (status == "applied" || status == "reset") {
        advanceFilterRef.current = { ...r.formData };
        filterDataRef.current = { ...formMethods.getValues(), ...r.formData };
        setShowAdvanceFilter(false);
        applyFilter();
      }
      if (status == "closed") {
        setShowAdvanceFilter(false);
      }
    },
    [applyFilter, formMethods]
  );

  const viewCb = (d) => {
    setShowChildModal({
      show: true,
      cid: d?.parentData?.id,
      name: d?.parentData?.name,
    });
  };

  const viewModalCb = () => {
    setShowChildModal({ show: false, cid: "", name: "" });
  };
  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Marketing Dashboard - Coins Dashboards"
            breadcrumbs={breadcrumbs}
            navigate={navigate}
          />
        </div>
      </div>

      <MarketingDashboardNavComponent
        activeTab="smartcoin"
        router={navigate}
        subTypeKey="dashboard"
      />

      {/* <div className="p-3 pb-0 fs-val-lg  fw-semibold text-primary">
        Referral Coins Dashboard
      </div> */}

      <div className="p-3 py-2 ">
        <FormProvider {...formMethods}>
          <FilterForm
            callback={filterCb}
            openAdvFilterModal={onAdvanceFilterClickCb}
            isDirty={formMethods.formState.isDirty}
          />
        </FormProvider>
      </div>

      <div className="p-3  pt-0 bg-white">
        {/* SUMMARY OVERVIEW */}
        <SummaryCardBox filterParams={filterParams.current} />

        {/* {filterLabels.length > 0 ? (
          <div className="mb-3">
            <AppliedFilterLabel
              labels={filterLabels}
              callback={onAdvanceFilterCb}
            />
          </div>
        ) : null} */}

        <div className="my-2 fw-semibold fs-val-lg">Club Owner List</div>
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />

        <Table
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
          paginationCb={paginationCb}
          sort={sortRef.current}
          sortCb={sortCb}
          viewCb={viewCb}
        />
      </div>

      {/* ADVANCE FILTER */}
      <AdvanceFilterModal
        show={showAdvanceFilter}
        callback={onAdvanceFilterCb}
        formData={advanceFilterRef.current}
      />

      <ChildCustomerModal
        show={showChildModal.show}
        cid={showChildModal.cid}
        name={showChildModal.name}
        callback={viewModalCb}
      />
    </>
  );
};

export default memo(CoinsDashboard);
