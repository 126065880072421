import React from "react";
import { DateFormatter, TableHeader, HighlightText } from "@sk/uis";

const headerData = [
  { label: "PO ID", key: "_id" },
  { label: "Vendor", key: "vendorName" },
  { label: "Requested Date", key: "requestedDate" },
  { label: "Quantity", key: "qty" },
];

const OpenPOTable = ({ poDetails }) => {
  return (
    <>
      <div className="mb-3">
        <HighlightText type="warning" template={2}>
          Total Open PO Qty: {poDetails.qty}
        </HighlightText>
      </div>
      <table className="table table-sm table-striped">
        <TableHeader data={headerData} />
        <tbody className="fs-val-sm">
          {poDetails &&
            poDetails.vendorList.map((vendor) => (
              <tr key={vendor._id}>
                <td>{vendor._id}</td>
                <td>{vendor.vendorName}</td>
                <td>
                  <DateFormatter date={vendor.poDate} format="dd MMM yyyy" />
                </td>
                <td>{vendor.poQty}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default OpenPOTable;
