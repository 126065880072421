import { appConfigs, DashboardService } from "@sk/services";
import {
  Amount,
  AppCard,
  DateFormatter,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const tableStyle = {
  height: "310px",
  display: "flex",
  flexDirection: "column",
  position: "relative",
};

const tableBodyStyle = {
  flex: 1,
  overflowY: "auto",
};

const headers = [
  {
    label: "Order Date",
    key: "orderDate",
  },
  {
    label: "Products",
    key: "products",
    isCentered: true,
  },
  {
    label: "Order Qty",
    key: "orderQty",
    isCentered: true,
  },
  {
    label: "Order Value",
    key: "orderValue",
    isRightAligned: true,
  },
  {
    label: "Percentage",
    isRightAligned: true,
    key: "percentage",
  },
];

const getData = async (params, abortSignal) => {
  const r = await DashboardService.fetchStockAnalytics(params, {
    signal: abortSignal,
  });

  const data = Array.isArray(r.resp) && r.resp.length ? r.resp : [];

  return { data, raw: r?.raw };
};

const prepareFilterParams = (filterData) => {
  const p = DashboardService.prepareStockNotAllocatedSummaryParams(
    filterData,
    "getUnallocatedStockByOrderDate"
  );

  p.sort = {
    orderDate: 1,
  };

  return p;
};

const StockNotAllocatedDateWise = ({ filterData }) => {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);

  const summary = useMemo(() => {
    let temp = {
      totalPId: 0,
      totalOrderQty: 0,
      totalValue: 0,
    };

    data.forEach((x) => {
      temp.totalPId += x?.pIdCount || 0;
      temp.totalOrderQty += x?.pIdQty || 0;
      temp.totalValue += x?.value || 0;
    });

    return temp;
  }, [data]);

  const dataWithPercentage = useMemo(() => {
    return data.map((item) => ({
      ...item,
      percentage: summary.totalValue
        ? ((item.value || 0) / summary.totalValue) * 100
        : 0,
    }));
  }, [data, summary.totalValue]);

  const filterRef = useRef(filterData);

  useEffect(() => {
    const abortController = new AbortController();
    filterRef.current = filterData;
    loadList(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [filterData, loadList]);

  const loadList = useCallback(async (abortSignal) => {
    setLoading(true);
    setData([]);
    let p = prepareFilterParams(filterRef.current);
    try {
      let r = await getData(p, abortSignal);
      if (r?.raw?.code == appConfigs.AJAX_REQ_CANCEL) {
        return;
      }
      setData(r.data);
      setLoading(false);
    } catch (error) {
      setData([]);
      setLoading(false);
    }
  }, []);

  return (
    <AppCard title="Date Wise Stock Summary">
      <div
        className="table-responsive custom-scrollbar thin-scrollbar"
        style={tableStyle}
      >
        <table className="table table-sm mb-0">
          <TableHeader data={headers} isSticky noBg />
        </table>
        <div style={tableBodyStyle} className="thin-scrollbar">
          <table className="table table-sm mb-0">
            <tbody className="fs-val-md">
              {loading ? (
                <TableSkeletonLoader cols={headers.length} rows={4} />
              ) : dataWithPercentage.length === 0 ? (
                <tr>
                  <td colSpan={5} className="text-center">
                    <NoDataFound>
                      <p>No data found</p>
                    </NoDataFound>
                  </td>
                </tr>
              ) : (
                dataWithPercentage.map((item, index) => (
                  <tr key={index}>
                    <td style={{ width: "20%" }}>
                      <DateFormatter
                        date={item?.orderDate}
                        format="MMM dd, yyyy"
                      />
                    </td>
                    <td style={{ width: "20%" }} className="text-center">
                      {item?.pIdCount || 0}
                    </td>
                    <td style={{ width: "20%" }} className="text-center">
                      {item?.pIdQty || 0}
                    </td>
                    <td style={{ width: "20%" }} className="text-end">
                      <Amount value={item?.value || 0} />
                    </td>
                    <td style={{ width: "20%" }} className="text-end">
                      {item.percentage.toFixed(2)}%
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <table className="table table-sm mb-0">
          <tfoot className="bg-white">
            <tr className="bg-light">
              <td style={{ width: "20%" }} className="fw-bold">
                Total
              </td>
              <td style={{ width: "20%" }} className="text-center fw-bold">
                {summary.totalPId || 0}
              </td>
              <td style={{ width: "20%" }} className="text-center fw-bold">
                {summary.totalOrderQty || 0}
              </td>
              <td style={{ width: "20%" }} className="text-end fw-bold">
                <Amount value={summary.totalValue || 0} />
              </td>
              <td style={{ width: "20%" }} className="text-end fw-bold">
                100%
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </AppCard>
  );
};

export default StockNotAllocatedDateWise;
