// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zn3bkgq2FIVl97JUJWUK:hover{border-color:var(--bs-primary) !important}.p8tBbg6uuuLPFg8FxyGd{background:#ecffec}.dJKN8X03Iim8zYEMscpD{border-left:2px solid var(--bs-secondary) !important}.anSOP5bro34QRWmmDbX6{color:#5c5c5c}.AYikwABKS2Y8Zn7Ibb8W{background-color:snow}.jgsaPOPlayU3Cxa4n3BQ{background-color:#007bff}.Boahu2aoE9S25sXp0DET{background-color:#f7f7f7}`, "",{"version":3,"sources":["webpack://./src/pages/reservation/scss/Reservation.module.scss"],"names":[],"mappings":"AACE,4BACE,yCAAA,CAIJ,sBACE,kBAAA,CAGF,sBACE,oDAAA,CAGF,sBACE,aAAA,CAGF,sBACE,qBAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,wBAAA","sourcesContent":[".summary-card {\n  &:hover {\n    border-color: var(--bs-primary) !important;\n  }\n}\n\n.highlight-row {\n  background: #ecffec;\n}\n\n.highlight-tapped {\n  border-left: 2px solid var(--bs-secondary) !important;\n}\n\n.lbl {\n  color: #5c5c5c;\n}\n\n.expired-record {\n  background-color: #fffafa;\n}\n\n.active {\n  background-color: #007bff;\n}\n\n.inactive {\n  background-color: #f7f7f7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summary-card": `zn3bkgq2FIVl97JUJWUK`,
	"highlight-row": `p8tBbg6uuuLPFg8FxyGd`,
	"highlight-tapped": `dJKN8X03Iim8zYEMscpD`,
	"lbl": `anSOP5bro34QRWmmDbX6`,
	"expired-record": `AYikwABKS2Y8Zn7Ibb8W`,
	"active": `jgsaPOPlayU3Cxa4n3BQ`,
	"inactive": `Boahu2aoE9S25sXp0DET`
};
export default ___CSS_LOADER_EXPORT___;
