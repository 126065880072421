import { CommonService, CustomerService, FranchiseService } from "@sk/services"; // Import CustomerService and FranchiseService
import { AppCard, DateInput, InfoBlk, PageInfo, TextInput } from "@sk/uis"; // Assuming these components are available
import { endOfDay, startOfDay, sub } from "date-fns"; // Import date functions
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form"; // Import useForm and Controller
import CustomerMissedCallTable from "./components/CustomerMissedCallTable";

const CustomerMissedCall = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const paginationRef = useRef({ ...defaultPagination });

  const filterRef = useRef({});

  const { control, register, getValues } = useForm({
    defaultValues: {
      search: "",
      dateRange: [sub(new Date(), { days: 30 }), new Date()], // Default date range
    },
  });

  const debounceSearch = useCallback(
    debounce((cb) => {
      cb();
    }, 500),
    []
  );

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPagination };
    loadList();
    setLoadingTotalRecords(true);
    const c = await getCount(
      getFilterParams(filterRef.current, paginationRef.current)
    );
    const t = c.statusCode === 200 && c.resp ? c.resp : 100;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, [loadList]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    setLoadingData(true);
    const r = await getData(
      getFilterParams(filterRef.current, paginationRef.current)
    );
    const d = r.data || [];
    setData(d);
    setLoadingData(false);
  }, []);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const handleSearch = () => {
    debounceSearch(() => {
      filterRef.current = {
        ...filterRef.current,
        ...getValues(),
      };
      applyFilter();
    });
  };

  const onDateChange = (chngFn) => (val) => {
    chngFn(val);
    filterRef.current = {
      ...filterRef.current,
      ...getValues(),
    };
    applyFilter();
  };

  return (
    <>
      <div>
        <PageInfo title="Customer Missed Calls" breadcrumbs={breadcrumbs} />
      </div>

      <InfoBlk>These are the registrations via missed calls.</InfoBlk>

      <AppCard>
        <div className="row g-2">
          <div className="col-4">
            <TextInput
              label="Search"
              placeholder="Search by Customer Name/ID"
              name="search"
              register={register}
              callback={handleSearch}
              gap={0}
            />
          </div>
          <div className="col-4">
            <Controller
              control={control}
              name="dateRange"
              render={({ field }) => (
                <DateInput
                  label="Date Range"
                  callback={onDateChange(field.onChange)}
                  value={field.value}
                  template={2}
                  hideClose={true}
                  gap={0}
                />
              )}
            />
          </div>
        </div>
      </AppCard>
      <AppCard>
        <CustomerMissedCallTable
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
        />
      </AppCard>
    </>
  );
};

const breadcrumbs = [
  { name: "Home", link: "/auth/init" },
  { name: "Customer Missed Calls" },
];

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

// Helper functions for data fetching
const getData = async (params) => {
  const response = await CustomerService.getList(params); // Fetch customer data
  const data = response.resp || [];

  // Collect franchise IDs from the customer response
  const fids = data.map((customer) => customer.franchise).filter(Boolean);

  if (fids.length) {
    // Fetch franchise details using the collected IDs
    const franchisesResponse = await FranchiseService.getList({
      filter: { _id: { $in: fids } },
      select: "name",
    });
    const franchises = franchisesResponse.resp || [];

    // Merge franchise details into customer data
    data.forEach((customer) => {
      customer.franchiseDetails =
        franchises.find((franchise) => franchise._id === customer.franchise) ||
        {};
    });
  }

  return { data }; // Return data with total records
};

const getCount = (params) => {
  return CustomerService.getCount(params); // Use CustomerService
};

const getFilterParams = (filter, pagination) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      registrationMethod: "SIGNUP_VIA_CALL",
    },
  };

  if (filter.search) {
    if (CommonService.isValidMobileNumber(filter.search)) {
      p.filter.mobile = filter.search;
    } else {
      p.filter.$or = [
        { _id: filter.search },
        { fName: { $regex: filter.search, $options: "i" } },
        { lName: { $regex: filter.search, $options: "i" } },
      ];
    }
  }

  if (filter.dateRange?.length) {
    p.filter.createdAt = {
      $gte: startOfDay(filter.dateRange[0]),
      $lte: endOfDay(filter.dateRange[1]),
    };
  }

  return p;
};

export default CustomerMissedCall;
