import { DashboardService } from "@sk/services";
import {
  Amount,
  AppCard,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
  ChartRender,
} from "@sk/uis";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const tableStyle = {
  height: "180px",
  overflowY: "auto",
};

const chartStyle = {
  height: "120px",
  marginTop: "10px",
};

const headers = [
  {
    label: "Orders In",
    key: "orderAgeing",
  },
  {
    label: "Products",
    isCentered: true,
    key: "products",
  },
  {
    label: "Order Qty",
    isCentered: true,
    key: "qty",
  },
  {
    label: "Order Value",
    key: "value",
    isRightAligned: true,
  },
  {
    label: "Percentage",
    isRightAligned: true,
    key: "percentage",
  },
];

const getData = async (params, abortSignal) => {
  const r = await DashboardService.fetchStockAnalytics(params, {
    signal: abortSignal,
  });

  const data = Array.isArray(r.resp) && r.resp.length ? r.resp : [];

  const picker = [
    {
      label: "0 to 24 hrs",
      value: "0-24",
    },
    {
      label: "25 to 48 hrs",
      value: "24-48",
    },
    {
      label: "49 to 72 hrs",
      value: "48-72",
    },
    {
      label: "73 hrs and above",
      value: "72-",
    },
  ];

  return picker.map((x) => {
    const d = data.find((y) => y.orderCategory == x.value) || {};
    return {
      label: x.label,
      ...d,
    };
  });
};

const prepareFilterParams = (filterData) => {
  return DashboardService.prepareStockNotAllocatedSummaryParams(
    filterData,
    "getAgeingStockSummary"
  );
};

const AgeingTable = ({ filterData }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const summary = useMemo(() => {
    let temp = {
      totalPId: 0,
      totalOrderQty: 0,
      totalValue: 0,
    };

    data.forEach((x) => {
      temp.totalPId += x?.pIdCount || 0;
      temp.totalOrderQty += x?.pIdQty || 0;
      temp.totalValue += x?.value || 0;
    });

    return temp;
  }, [data]);

  const dataWithPercentage = useMemo(() => {
    return data.map((item) => ({
      ...item,
      percentage: summary.totalValue
        ? ((item.value || 0) / summary.totalValue) * 100
        : 0,
    }));
  }, [data, summary.totalValue]);

  const filterRef = useRef(filterData);

  const loadList = useCallback(async (abortSignal) => {
    setLoading(true);
    setData([]);
    let p = prepareFilterParams(filterRef.current);
    try {
      let r = await getData(p, abortSignal);
      setData(r);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted:", error);
      } else {
        console.error("Error fetching data:", error);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    filterRef.current = filterData;
    loadList(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [filterData, loadList]);

  const chartConfig = useMemo(() => {
    return {
      data: {
        labels: data.map((item) => item.label),
        datasets: [
          {
            label: "Products",
            data: data.map((item) => item?.pIdCount || 0),
            backgroundColor: "rgba(75, 192, 192, 0.7)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
          {
            label: "Order Quantity",
            data: data.map((item) => item?.pIdQty || 0),
            backgroundColor: "rgba(153, 102, 255, 0.7)",
            borderColor: "rgba(153, 102, 255, 1)",
            borderWidth: 1,
          },
          {
            label: "Order Value",
            data: data.map((item) => item?.value || 0),
            backgroundColor: "rgba(255, 159, 64, 0.7)",
            borderColor: "rgba(255, 159, 64, 1)",
            borderWidth: 1,
            type: "line",
            yAxisID: "y1",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
            labels: {
              boxWidth: 12,
              padding: 8,
              font: {
                size: 11,
              },
            },
          },
          title: {
            display: false,
          },
        },
        scales: {
          x: {
            title: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 10,
              },
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: false,
            },
            grid: {
              drawBorder: false,
            },
            ticks: {
              font: {
                size: 10,
              },
            },
          },
          y1: {
            beginAtZero: true,
            position: "right",
            title: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 10,
              },
            },
          },
        },
        barPercentage: 0.8,
        categoryPercentage: 0.9,
      },
    };
  }, [data]);

  return (
    <>
      <AppCard title="Ageing Stock Summary">
        <div className="custom-scrollbar thin-scrollbar" style={tableStyle}>
          <table className="table table-sm mb-0">
            <TableHeader data={headers} noBg />
            <tbody className="fs-val-md">
              {loading ? (
                <TableSkeletonLoader cols={headers.length} rows={4} />
              ) : dataWithPercentage.length === 0 ? (
                <tr>
                  <td colSpan={5} className="text-center">
                    <NoDataFound>
                      <p>No data found</p>
                    </NoDataFound>
                  </td>
                </tr>
              ) : (
                <>
                  {dataWithPercentage.map((item, index) => (
                    <tr key={index}>
                      <td className="fw-semibold">{item.label}</td>
                      <td className="text-center">{item?.pIdCount || 0}</td>
                      <td className="text-center">{item?.pIdQty || 0}</td>
                      <td className="text-end">
                        <Amount value={item?.value || 0} />
                      </td>
                      <td className="text-end">
                        {item.percentage.toFixed(2)}%
                      </td>
                    </tr>
                  ))}
                  <tr className="bg-light">
                    <td className="fw-bold">Total</td>
                    <td className="text-center fw-bold">
                      {summary.totalPId || 0}
                    </td>
                    <td className="text-center fw-bold">
                      {summary.totalOrderQty || 0}
                    </td>
                    <td className="text-end fw-bold">
                      <Amount value={summary.totalValue || 0} />
                    </td>
                    <td className="text-end fw-bold">100%</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>

        {/* Chart Section */}
        {!loading && dataWithPercentage.length > 0 && (
          <div style={chartStyle}>
            <ChartRender
              data={chartConfig.data}
              type="bar"
              options={chartConfig.options}
            />
          </div>
        )}
      </AppCard>
    </>
  );
};

export default AgeingTable;
