import { Amount, AppCard, HighlightText, KeyVal } from "@sk/uis";
import { memo } from "react";

const ViewProductDetails = ({ details }) => {
  return (
    <AppCard title="Product Details">
      {/* Product Header Section */}
      <div className="border-bottom pb-3 mb-3">
        <div className="d-flex align-items-center mb-2">
          <div className="fs-val-normal fw-semibold">{details.productName}</div>
        </div>

        <div className="row g-2">
          <div className="col-auto">
            <HighlightText type="primary" isSoft={true} template={2}>
              ID: {details?.productId}
            </HighlightText>
          </div>

          <div className="col-auto">
            <HighlightText type="primary" isSoft={true} template={2}>
              Case Qty: {details?._product?.caseQty || 0}
            </HighlightText>
          </div>
          <div className="col-auto">
            <HighlightText type="primary" isSoft={true} template={2}>
              Inner Case Qty: {details?._product?.innerPackQty || 0}
            </HighlightText>
          </div>
          <div className="col-auto">
            <HighlightText type="primary" isSoft={true} template={2}>
              MRP:{" "}
              <Amount
                value={details?.dealDetail?.dealMrp || 0}
                decimalPlace={2}
              />
            </HighlightText>
          </div>
          <div className="col-auto">
            <HighlightText type="primary" isSoft={true} template={2}>
              B2B Price:{" "}
              <Amount
                value={details?.dealDetail?.dealB2bPrice ?? 0}
                decimalPlace={2}
              />
            </HighlightText>
          </div>
          <div className="col-auto">
            <HighlightText type="primary" isSoft={true} template={2}>
              Margin: {details?.margin ?? 0}%
            </HighlightText>
          </div>
        </div>
      </div>

      <div className="row">
        {/* Product Classification */}
        <div className="col-9">
          <div className="row">
            <div className="col-7">
              <KeyVal label="Menu" template="col">
                <div className="text-truncate">
                  <span className="fw-medium">{details?.menuName}</span>
                  <small className="text-muted ms-1">({details?.menuId})</small>
                </div>
              </KeyVal>
            </div>
            <div className="col-5">
              <KeyVal label="Brand" template="col">
                <div className="text-truncate">
                  <span className="fw-medium">{details?.brandName}</span>
                  <small className="text-muted ms-1">
                    ({details?.brandId})
                  </small>
                </div>
              </KeyVal>
            </div>
            <div className="col-6">
              <KeyVal label="Category" template="col">
                <div className="text-truncate">
                  <span className="fw-medium">{details?.categoryName}</span>
                  <small className="text-muted ms-1">
                    ({details?.categoryId})
                  </small>
                </div>
              </KeyVal>
            </div>
          </div>
        </div>

        {/* Sales History */}
        <div className="col-3">
          <div className="border-start ps-3">
            <div className="fs-val-md mb-1">Sales History</div>
            <div className="row g-0 fs-val-md">
              <div className="col-12">
                <KeyVal
                  label="Last 15 Days"
                  labelCol="col-8"
                  contentCol="col-4"
                  noBottomSpace={true}
                >
                  : {details?.subOrderDetails?.fifteenDaysQty ?? 0}
                </KeyVal>
              </div>
              <div className="col-12">
                <KeyVal
                  label="Last 30 Days"
                  labelCol="col-8"
                  contentCol="col-4"
                  noBottomSpace={true}
                >
                  : {details?.subOrderDetails?.thirtydaysQty ?? 0}
                </KeyVal>
              </div>
            </div>
            <div className="col-12">
              <KeyVal
                label="Last 60 Days"
                labelCol="col-8"
                contentCol="col-4"
                noBottomSpace={true}
              >
                : {details?.subOrderDetails?.sixtydaysQty ?? 0}
              </KeyVal>
            </div>
            <div className="col-12">
              <KeyVal
                label="Last 90 Days"
                labelCol="col-8"
                contentCol="col-4"
                noBottomSpace={true}
              >
                : {details?.subOrderDetails?.ninetydaysQty ?? 0}
              </KeyVal>
            </div>
          </div>
        </div>
      </div>
    </AppCard>
  );
};

export default memo(ViewProductDetails);
