import React from "react";
import { AppCard, TableHeader, Amount } from "@sk/uis";

const topCustomersData = [
  {
    id: 1,
    name: "John Doe",
    totalOrders: 150,
    totalSpent: 15000,
    avgOrderValue: 100,
  },
  {
    id: 2,
    name: "Jane Smith",
    totalOrders: 120,
    totalSpent: 13500,
    avgOrderValue: 112.5,
  },
  {
    id: 3,
    name: "Bob Johnson",
    totalOrders: 100,
    totalSpent: 12000,
    avgOrderValue: 120,
  },
  {
    id: 4,
    name: "Alice Williams",
    totalOrders: 95,
    totalSpent: 11400,
    avgOrderValue: 120,
  },
  {
    id: 5,
    name: "Charlie Brown",
    totalOrders: 88,
    totalSpent: 10560,
    avgOrderValue: 120,
  },
  {
    id: 6,
    name: "Eva Davis",
    totalOrders: 82,
    totalSpent: 9840,
    avgOrderValue: 120,
  },
  {
    id: 7,
    name: "Frank Miller",
    totalOrders: 75,
    totalSpent: 9375,
    avgOrderValue: 125,
  },
  {
    id: 8,
    name: "Grace Lee",
    totalOrders: 70,
    totalSpent: 8750,
    avgOrderValue: 125,
  },
  {
    id: 9,
    name: "Henry Wilson",
    totalOrders: 65,
    totalSpent: 8125,
    avgOrderValue: 125,
  },
  {
    id: 10,
    name: "Ivy Chen",
    totalOrders: 60,
    totalSpent: 7800,
    avgOrderValue: 130,
  },
];

const tableColumns = [
  { label: "Customer Name", key: "name", enableSort: true },
  { label: "Total Orders", key: "totalOrders", enableSort: true },
  { label: "Total Order Value", key: "totalSpent", enableSort: true },
  { label: "Avg Order Value", key: "avgOrderValue", enableSort: true },
];

const TopCustomers = () => {
  return (
    <AppCard title="Top 20 Customers">
      <div
        className="table-responsive custom-scrollbar"
        style={{
          height: "350px",
          overflowY: "auto",
        }}
      >
        <table className="table table-hover table-sm">
          <TableHeader data={tableColumns} />
          <tbody className="fs-val-md">
            {topCustomersData.map((customer) => (
              <tr key={customer.id}>
                <td>{customer.name}</td>
                <td>{customer.totalOrders.toLocaleString()}</td>
                <td>
                  <Amount value={customer.totalSpent} />
                </td>
                <td>
                  <Amount value={customer.avgOrderValue} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AppCard>
  );
};

export default TopCustomers;
