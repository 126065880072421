import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FranchiseService, ProductService } from "@sk/services";
import {
  BooleanCheckboxInput,
  DateFormatter,
  DateInput,
  HighlightText,
  NoDataFound,
  Spinner,
  TabContent,
  Tabs,
  TextInput,
  Toaster,
} from "@sk/uis";
import { add, differenceInDays, differenceInMinutes } from "date-fns";
import debounce from "lodash/debounce";
import sortBy from "lodash/sortBy";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import groupCodeValidation from "../validations/groupCodeValidation";

let defaultTableHeaders = [
  {
    label: "Details",
    key: "groupCode",
    width: "40%",
  },
  // {
  //   label: "Validity",
  //   key: "validity",
  //   width: "30%",
  // },
  // {
  //   label: "Order Received Qty",
  //   key: "orderReceivedQty",
  // },
  // {
  //   label: "Remaining Qty",
  //   key: "remainingQty",
  // },
  {
    label: "Status",
    key: "status",
    isCentered: true,
    width: "5%",
  },
];

// returns the active master group codes
const getGroupCodes = async () => {
  const codesResp = await FranchiseService.getGroupCodeList({
    page: 1,
    count: 100,
    select: "groupCode",
    filter: { isActive: true },
  });
  return Array.isArray(codesResp.resp)
    ? codesResp.resp.map((x) => ({
        _id: "",
        groupCode: x.groupCode,
        preOrderLimit: "",
        remainingQuantity: 0,
        status: "",
      }))
    : [];
};

// based on the config id returns currently running configurations
const getConfigs = async (groupId, params) => {
  const configsResp = await ProductService.getReservationGroupConfig(
    groupId,
    params
  );

  return Array.isArray(configsResp?.resp)
    ? configsResp.resp.map((x) => ({
        ...x,
        selected: false,
        alreadyConfigured: true,
      }))
    : [];
};

const prepareDefaultEndDateConfig = (startDate, endDate) => {
  let minDate = new Date();
  let disable = [];
  // if the start date config is greater than today then make end date minDate = startDate
  const dtDiff = differenceInMinutes(new Date(startDate), new Date());

  if (dtDiff > 0) {
    minDate = new Date(startDate);
  }

  // if the end date is lesser than today then make the days disable which occurs between the end date and today
  const diffEndDateToday = differenceInDays(new Date(endDate), new Date());
  if (diffEndDateToday <= 0) {
    minDate = new Date(endDate);
    for (let i = 1; i < Math.abs(diffEndDateToday); i++) {
      disable.push(add(new Date(endDate), { days: i }));
    }
  }

  return {
    enableTime: true,
    dateFormat: "d M Y G:i K",
    minDate,
    disable,
  };
};

// TAB CONFIG
const tabs = [
  {
    tabName: "Live Config",
    key: "live",
  },
  {
    tabName: "Other Config",
    key: "other",
  },
];

// RETURNS THE CONFIG BASED ON SELECTED TAB
const prepareConfinOnTabKey = (configs, tabKey) => {
  return configs.filter((x) => {
    if (tabKey == "live") {
      return x.status == "Started" || !x.status;
    } else {
      return x.status && x.status != "Started";
    }
  });
};

const GroupCodeConfigTable = ({
  mode,
  groupId,
  callback,
  reInit = false,
  productInfo = {},
}) => {
  const {
    register,
    setValue,
    control,
    getValues,
    formState: { errors },
    trigger,
    resetField,
  } = useForm({
    defaultValues: {
      configs: [],
      tabBasedConfigs: [],
      activeTab: tabs[0],
    },
    resolver: yupResolver(groupCodeValidation),
  });

  const [loading, setLoading] = useState(true);

  // a flag to show sellect all in the table header
  const [showSelectAll, setShowSelectAll] = useState(false);

  // reference variable to store the actual config list
  const configsRef = useRef([]);

  const liveConfigsRef = useRef([]);

  const otherConfigsRef = useRef([]);

  // WATCHING THE CONFIG
  const configs = useWatch({ control, name: "configs" });

  // WATCHING THE ACTIVE TAB
  const activeTab = useWatch({ control, name: "activeTab" });

  // preparing table headers
  const tableHeaders = useMemo(() => {
    if (showSelectAll) {
      let t = [...defaultTableHeaders];
      if (configs.length > 0) {
        t.unshift({
          label: "",
          key: "selectAll",
          width: "3%",
          hasCustomTemplate: true,
          template: () => (
            <Controller
              control={control}
              name="selectAll"
              render={({ field: { value } }) => (
                <BooleanCheckboxInput
                  gap="0"
                  value={value}
                  callback={selectAllCb}
                />
              )}
            />
          ),
        });
      }

      return t;
    } else {
      return [...defaultTableHeaders];
    }
  }, [configs.length, control, selectAllCb, showSelectAll]);

  useEffect(() => {
    init();
  }, [init, groupId]);

  useEffect(() => {
    if (reInit) {
      init();
    }
  }, [init, reInit]);

  // if there is a config changes trigger callback - only on add/edit mode
  useEffect(() => {
    if (mode == "add" || mode == "edit") {
      if (activeTab.key == "live") {
        liveConfigsRef.current = [...configs];
      } else {
        otherConfigsRef.current = [...configs];
      }
      callback({
        data: [...liveConfigsRef.current, ...otherConfigsRef.current],
      });
    }
  }, [activeTab.key, callback, configs, mode]);

  const init = useCallback(async () => {
    if (!groupId) {
      setLoading(false);
      setValue("configs", []);
      return;
    }

    setLoading(true);

    let codes = [];
    if (mode == "add" || mode == "edit") {
      codes = await getGroupCodes();
    }

    let p = {};
    if (mode == "add" || mode == "edit") {
      p = {
        filter: { status: { $nin: ["Stopped"] }, isValidExpired: true },
      };
    }

    let configs = await getConfigs(groupId, p);

    if (mode == "add" || mode == "edit") {
      codes.forEach((x) => {
        const i = configs.findIndex((e) => e.groupCode == x?.groupCode);
        if (i == -1) {
          configs.push({ ...x });
        }
      });

      // format the config response
      configs.forEach((x) => {
        // if the config is not found in master mark group code as inactive
        const i = codes.findIndex((e) => e.groupCode === x.groupCode);
        x.isInactive = i === -1;

        // prepare the form keys
        x._applicableFromDate = x.applicableFromDate
          ? [x.applicableFromDate]
          : [];
        x._endDate = x.endDate ? [x.endDate] : [];
        x._preOrderLimit = x.preOrderLimit || 0;

        // configure the start date picker config
        x._startDateConfig = {
          enableTime: true,
          dateFormat: "d M Y G:i K",
          minDate: mode == "edit" && x._id ? null : new Date(),
        };

        // check wheter start date can be editable or not
        x._blockStartDate = x._id && mode == "edit" ? true : false;

        x._endDateConfig = prepareDefaultEndDateConfig(
          x.applicableFromDate,
          x.endDate
        );
      });
    }

    const c = sortBy(configs, ["groupCode"]);

    configsRef.current = [...c];

    liveConfigsRef.current = prepareConfinOnTabKey(configsRef.current, "live");
    otherConfigsRef.current = prepareConfinOnTabKey(
      configsRef.current,
      "other"
    );

    setValue("configs", liveConfigsRef.current);

    setLoading(false);

    if (mode == "add" || mode == "edit") {
      setShowSelectAll(true);
    }
  }, [groupId, mode, setValue]);

  const revertInputValues = useCallback(
    (index) => {
      const v = getValues("configs");
      setValue("configs." + index + "._preOrderLimit", v[index].preOrderLimit);
      setValue(
        "configs." + index + "._applicableFromDate",
        v[index].applicableFromDate
          ? [new Date(v[index].applicableFromDate)]
          : []
      );
      setValue(
        "configs." + index + "._endDate",
        v[index].endDate ? [new Date(v[index].endDate)] : []
      );
    },
    [getValues, setValue]
  );

  const selectAllCb = useCallback(
    async (val) => {
      setValue("selectAll", val);
      const c = getValues("configs");
      c.forEach((x, i) => {
        if (!x.isInactive) {
          setValue("configs." + i + ".selected", val);
          if (!val) {
            revertInputValues(i);
          }
        }
      });
      await trigger();
    },
    [getValues, revertInputValues, setValue, trigger]
  );

  const onConfigCheckboxChange = async (val, index) => {
    setValue("configs." + index + ".selected", val);
    if (!val) {
      revertInputValues(index);
    }
    const c = getValues("configs");
    setValue("selectAll", c.filter((x) => x.selected).length == c.length);
    // await trigger("configs." + index);
    await trigger();
  };

  const onInpClick = async (index) => {
    const c = configs[index];
    if (!c.selected && !c.isInactive) {
      setValue("configs." + index + ".selected", !c.selected);
    }
    await trigger();
  };

  const onInpChange = useCallback(
    debounce(async (index) => {
      const c = getValues("configs");
      let q = c[index]._preOrderLimit || "";
      const orderQty = c[index].totalOrderQuantity || 0;
      if (q < orderQty) {
        q = orderQty;
        Toaster.error("Qty cannot be lesser than Order Received Qty");
      }

      if (q < productInfo?.innerPackQty) {
        q = productInfo.innerPackQty;
        Toaster.error(
          "Qty cannot be lesser than Product Inner Case Qty " +
            productInfo.innerPackQty
        );
      }

      if (q) {
        setValue("configs." + index + "._preOrderLimit", Math.ceil(q));
      }
      await trigger();
    }, 800),
    [productInfo]
  );

  const onStartDtCb = async (index, val) => {
    setValue("configs." + index + "._applicableFromDate", val);
    setValue("configs." + index + "._endDate", [], { shouldValidate: true });
    const c = getValues("configs." + index + "._endDateConfig") || {};
    setValue("configs." + index + "._endDateConfig", {
      ...c,
      minDate: add(val[0], { minutes: 10 }),
    });
    await trigger();
  };

  const onEndDtCb = async (index, val) => {
    setValue("configs." + index + "._endDate", val);
    await trigger();
  };

  const onTabClickCb = useCallback(
    async (e) => {
      const t = e.value;

      setValue("activeTab", t);

      let c = [];

      if (t.key == "live") {
        c = liveConfigsRef.current;
      }

      if (t.key == "other") {
        c = otherConfigsRef.current;
      }

      resetField("configs");

      if (c.filter((e) => e.selected).length == c.length) {
        setValue("selectAll", true);
      } else {
        setValue("selectAll", false);
      }

      setValue(
        "configs",
        [...c].map((x) => {
          if (typeof x.selected == "undefined") {
            x.selected = false;
          }
          return x;
        })
      );

      await trigger();
    },
    [resetField, setValue, trigger]
  );

  return (
    <div className="mb-3">
      <Tabs
        data={tabs}
        activeTab={activeTab}
        callback={onTabClickCb}
        className="px-3 pt-2"
      />
      <TabContent className="p-3">
        {loading ? (
          <div className="text-center py-4">
            <Spinner />
          </div>
        ) : configs.length === 0 ? (
          <NoDataFound>No configurations available</NoDataFound>
        ) : (
          <div className="table-responsive">
            {configs.map((config, index) => (
              <div
                key={index}
                className={`border rounded ${
                  index !== configs.length - 1 ? "mb-2" : ""
                }`}
              >
                {/* Header Row */}
                <div className="d-flex align-items-center p-2 border-bottom bg-light">
                  {(mode === "edit" || mode === "add") &&
                    !config.isInactive && (
                      <div className="me-2">
                        <Controller
                          control={control}
                          name={`configs.${index}.selected`}
                          render={({ field: { value } }) => (
                            <BooleanCheckboxInput
                              gap="0"
                              value={value}
                              callback={(v) => onConfigCheckboxChange(v, index)}
                            />
                          )}
                        />
                      </div>
                    )}
                  <div className="me-3">
                    <span className="fw-semibold fs-val-normal">
                      {config.groupCode || "N/A"}
                    </span>
                    {config.isInactive && (
                      <span className="badge bg-danger-subtle text-danger ms-2">
                        Inactive
                      </span>
                    )}
                    {config._id && (
                      <span className="text-muted fs-val-sm ms-2">
                        - {config._id}
                      </span>
                    )}
                  </div>
                  {config.status && (
                    <div className="ms-auto">
                      <HighlightText type={config._statusType} template={2}>
                        <i className="bi bi-circle-fill fs-val-sm me-1"></i>
                        {config._displayStatus}
                      </HighlightText>
                    </div>
                  )}
                </div>

                {/* Form Fields Row */}
                <div className="p-3">
                  <div className="d-flex gap-3 fs-val-md mb-2">
                    <HighlightText type="primary" template={2} isSoft={true}>
                      Open Orders: {config.openOrders || 0}
                    </HighlightText>
                    <HighlightText type="danger" template={2} isSoft={true}>
                      Remaining Qty: {config.remainingQuantity || 0}
                    </HighlightText>
                  </div>
                  <div className="row g-2">
                    <div className="col-md-4">
                      {mode === "view" ? (
                        <div>
                          <label className="form-label text-muted fs-val-md mb-1">
                            Reserve Qty
                          </label>
                          <div className="form-control">
                            {config.preOrderLimit || "N/A"}
                          </div>
                        </div>
                      ) : (
                        <div
                          onClick={() => onInpClick(index)}
                          className="cursor-pointer"
                        >
                          <TextInput
                            label="Reserve Qty"
                            isMandatory={true}
                            register={register}
                            name={`configs.${index}._preOrderLimit`}
                            disabled={!config.selected}
                            placeholder={
                              config.selected
                                ? "Enter quantity"
                                : "Select to enter"
                            }
                            type="number"
                            callback={() => onInpChange(index)}
                            error={
                              errors?.configs?.[index]?._preOrderLimit?.message
                            }
                            gap={0}
                          />
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      {mode === "view" || config._blockStartDate ? (
                        <div>
                          <label className="form-label text-muted fs-val-md mb-1">
                            Start Date
                          </label>
                          <div className="form-control">
                            <DateFormatter
                              date={config.applicableFromDate}
                              className="py-1 d-block"
                            />
                          </div>
                        </div>
                      ) : (
                        <Controller
                          control={control}
                          name={`configs.${index}._applicableFromDate`}
                          render={({ field: { value } }) => (
                            <DateInput
                              label="Start Date"
                              isMandatory={true}
                              placeholder="Select date"
                              value={value}
                              callback={(v) => onStartDtCb(index, v)}
                              config={config._startDateConfig}
                              disabled={!config.selected}
                              error={
                                errors?.configs?.[index]?._applicableFromDate
                                  ?.message
                              }
                              gap={0}
                            />
                          )}
                        />
                      )}
                    </div>

                    <div className="col-md-4">
                      {mode === "view" ? (
                        <div>
                          <label className="form-label text-muted fs-val-md mb-1">
                            End Date
                          </label>
                          <div className="form-control">
                            <DateFormatter
                              date={config.endDate}
                              className="py-1 d-block"
                            />
                          </div>
                        </div>
                      ) : (
                        <Controller
                          control={control}
                          name={`configs.${index}._endDate`}
                          render={({ field: { value } }) => (
                            <DateInput
                              label="End Date"
                              isMandatory={true}
                              placeholder="Select date"
                              value={value}
                              callback={(v) => onEndDtCb(index, v)}
                              config={config._endDateConfig}
                              disabled={!config.selected}
                              hideClose={true}
                              error={
                                errors?.configs?.[index]?._endDate?.message
                              }
                              gap={0}
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </TabContent>
    </div>
  );
};

export default memo(GroupCodeConfigTable);
