import {
  NoDataFound,
  PaginationBlock,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo } from "react";

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "Club Owner Details" },
  { label: "Franchise Details" },
  {
    label: "Total Club Members",
    isCentered: true,
    key: "uniqueChildCount",
    enableSort: true,
  },
  {
    label: "Total Club Members Orders",
    isCentered: true,
    key: "uniqueOrderCount",
    enableSort: true,
  },
  {
    label: "Total Coins Earned By Club Members",
    isCentered: true,
    key: "totalChildCoins",
    enableSort: true,
  },
  {
    label: "Total Coins Earned By Club Owners",
    isCentered: true,
    key: "totalParentCoins",
    enableSort: true,
  },
  { label: "Action", isCentered: true },
];

const rbac = {
  showMobileNo: ["showCoinsDashboardMobileNo"],
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  viewCb,
  sort = {},
  sortCb,
}) => {
  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />
        <tbody>
          {loading ? (
            <TableSkeletonLoader
              cols={headers.length}
              rows={paginationConfig.rowsPerPage}
            />
          ) : null}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound> No Data Found </NoDataFound>
              </td>
            </tr>
          ) : null}
          {!loading &&
            data.map((x, index) => (
              <tr key={index} className="fs-val-md">
                {/* serial no. */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                <td>
                  <div>{x?.parentData?.name}</div>
                  <div className="mt-1 fs-val-sm text-muted">
                    {x?.parentData?.district}, {x?.parentData?.state}
                  </div>
                  <div className="mt-2 fs-val-sm text-primary">
                    ID : {x?.parentData?.id}
                  </div>
                  <Rbac roles={rbac.showMobileNo}>
                    <div className="mt-1 fs-val-sm ">
                      Mobile No : {x?.parentData?.mobileNumber}
                    </div>
                  </Rbac>
                </td>

                <td>
                  <div>
                    {" "}
                    {x?._franchise?.name} -{x?.parentData.franchiseId}
                  </div>
                  <div className="mt-1 fs-val-sm text-muted">
                    {x?._franchise?.town}, {x?._franchise?.district},{" "}
                    {x?._franchise?.state}
                  </div>
                </td>

                <td className="text-center">{x?.uniqueChildCount || 0}</td>

                <td className="text-center">{x?.uniqueOrderCount || 0}</td>

                <td className="text-center">{x?.totalChildCoins || 0}</td>

                <td className="text-center">{x?.totalParentCoins || 0}</td>

                <td className="text-center">
                  {/* <Rbac roles={rbac.viewCategory}> */}
                  <button
                    className="btn btn-sm btn-outline-primary me-1 fs-val-xs"
                    onClick={() => {
                      viewCb(x);
                    }}
                  >
                    View
                  </button>
                  {/* </Rbac> */}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {/* pagination block */}
      {!loading && data.length > 0 && (
        <PaginationBlock
          paginationConfig={paginationConfig}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
        />
      )}
    </>
  );
};

export default memo(Table);
