import { NavService, ProductService } from "@sk/services";
import {
  AppCard,
  AppTitle,
  DateFormatter,
  HighlightText,
  PageLoader,
  TableHeader,
} from "@sk/uis";
import { memo, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const tableHeaders = [
  {
    label: "#",
    key: "slNo",
    width: "5%",
  },
  {
    label: "Order ID",
    key: "orderId",
  },
  {
    label: "Suborder ID",
    key: "suborderId",
  },
  {
    label: "Open Order Qty",
    key: "openOrder",
    isCentered: true,
  },
  {
    label: "Ordered On",
    key: "orderedOn",
  },
  {
    label: "Status",
    key: "status",
  },
  {
    label: "Franchise",
    key: "franchise",
  },
];

const canvasStyle = {
  width: "70%",
};

const SubOrderListModal = ({ show, callback, reserveId = null }) => {
  const [product, setProduct] = useState({ loading: true, data: {} });

  const [orders, setOrders] = useState({ loading: true, data: {}, raw: [] });

  const [details, setDetails] = useState({});

  useEffect(() => {
    if (reserveId && show) {
      const loadData = async () => {
        const r = await ProductService.getReservationList({
          filter: { _id: reserveId },
        });

        let details = r?.resp?.[0] || {};

        if (details.productId) {
          const product = await ProductService.getProduct(details.productId, {
            select: "innerPackQty,caseQty,name",
          });
          details.product = product?.resp || {};
        }
        console.log(details);

        setDetails(details);
      };

      loadData();
    }
  }, [reserveId, show]);

  useEffect(() => {
    if (show) {
      const loadData = async () => {
        setProduct({ loading: true, data: {} });
        const r = await ProductService.getProduct(details.productId, {
          select: "innerPackQty,caseQty,name",
        });
        setProduct({ loading: false, data: r?.resp || {} });
        const o = details.subOrderDetails?.orderLists || [];
        setOrders({ data: o, raw: o });
      };

      loadData();
    }
  }, [details.productId, details.subOrderDetails?.orderLists, show]);

  const hideCb = () => {
    callback({ status: "close" });
  };

  const viewOrder = (orderId) => {
    NavService.openInNewTab(`/oms/orders/details?id=${orderId}`);
  };

  return (
    <Offcanvas show={show} placement="end" style={canvasStyle} onHide={hideCb}>
      <Offcanvas.Header closeButton className="text-white bg-primary">
        <AppTitle title={"Open Order List - " + details?._id} />
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        {show ? (
          <>
            {product.loading ? (
              <PageLoader />
            ) : (
              <>
                <AppCard title="Product Info">
                  <div className="d-flex align-items-center mb-2">
                    <div className="fs-val-normal">
                      {details?.product?.name}
                    </div>
                    <span className="text-muted ms-2 align-self-end">
                      ({details?.product?._id})
                    </span>
                  </div>
                  <div className="row g-2 mb-2">
                    <div className="col-auto">
                      <HighlightText type="primary" isSoft={true} template={2}>
                        Case Qty: {details?.product?.caseQty || 0}
                      </HighlightText>
                    </div>
                    <div className="col-auto">
                      <HighlightText type="primary" isSoft={true} template={2}>
                        Inner Case Qty: {details?.product?.innerPackQty || 0}
                      </HighlightText>
                    </div>
                  </div>
                </AppCard>
                {/* PRODUCT INFO END */}

                {/* ORDER DETAILS START */}
                <AppCard title="Order Details">
                  <div className="mb-2">
                    <HighlightText type="danger" isSoft={true} template={2}>
                      Total Open Order Qty: {details.totalOrderQuantity}
                    </HighlightText>
                  </div>
                  <table className="table table-sm table-striped">
                    <TableHeader data={tableHeaders} noBg />
                    <tbody className="fs-val-md">
                      {orders.data.map((x, i) => (
                        <tr key={x.subOrderId}>
                          <td>{i + 1}</td>
                          <td>
                            <button
                              className="btn text-dark btn-link p-0 text-start fs-val-md"
                              onClick={() => viewOrder(x.orderId)}
                            >
                              {x.orderId}
                            </button>
                          </td>
                          <td>{x.subOrderId}</td>
                          <td className="text-center text-danger">
                            {x.quantity}
                          </td>
                          <td>
                            <DateFormatter
                              date={x.orderDate}
                              format="dd MMM yyyy"
                            />
                          </td>
                          <td>
                            <HighlightText status={x.orderStatus} />
                          </td>
                          <td>
                            <div>
                              {x.franchise?.name} ({x.franchise?.id})
                            </div>
                            <div className="text-muted fs-val-sm">
                              {x.franchise?.town}, {x.franchise?.district},{" "}
                              {x.franchise?.state} {x.franchise?.pincode}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </AppCard>
                {/* ORDER DETAILS END */}
              </>
            )}
          </>
        ) : null}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default memo(SubOrderListModal);
