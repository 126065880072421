import { CoinsDashService } from "@sk/services";
import {
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { set } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import CoinsDashAppliedFilter from "./CoinsDashAppliedFilter";

// Updated table headers with sorting keys
const tableHeaders = [
  {
    label: "Sl No",
    width: "5%",
  },
  {
    label: "Club Name",
    enableSort: true,
    key: "clubName",
    width: "15%",
  },
  {
    label: "Club Owner Name",
    enableSort: true,
    key: "clubOwnerName",
    width: "15%",
  },
  {
    label: "Total Members",
    width: "15%",
    isCentered: true,
    enableSort: true,
    key: "totalmembers",
  },
  {
    label: "Coins Earned",
    enableSort: true,
    isCentered: true,
    key: "coinsEarned",
  },
  {
    label: "Coins Redeemed",
    enableSort: true,
    isCentered: true,
    key: "coinsRedeemed",
  },
  {
    label: "Orders",
    enableSort: true,
    isCentered: true,
    key: "totalOrders",
  },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 30,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 30,
};

const getData = async (params) => {
  const r = await CoinsDashService.getClubWise(params);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const getCount = async (params) => {
  let p = { ...params, outputType: "count" };

  delete p.page;
  delete p.count;

  const r = await CoinsDashService.getClubWise(p);
  let count = 0;
  if (Array.isArray(r.resp) && r.resp.length > 0) {
    count = r.resp[0].total;
  }
  return { count };
};

const prepareFilterParams = (
  { fromDate, toDate, cid, storeId, accessibleStoreIds, searchText },
  pagination,
  sort = {}
) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {},
  };

  if (fromDate && toDate) {
    p.filter.createdAt = {
      $gte: set(new Date(fromDate), {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(new Date(toDate), {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (sort?.key) {
    p.sort = { [sort.key]: sort.value == "asc" ? 1 : -1 };
  }

  if (cid) {
    p.filter.customerId = cid;
  }

  if (storeId) {
    p.matchFilter = {
      "customerInfo.franchise": storeId,
    };
  } else {
    p.matchFilter = {
      "customerInfo.franchise": {
        $in: accessibleStoreIds,
      },
    };
  }

  if (searchText) {
    p.matchFilter.$or = [
      { "customerInfo.fName": { $regex: searchText, $options: "i" } },
      { "customerInfo.clubName": { $regex: searchText, $options: "i" } },
    ];
  }

  return p;
};

const CoinsDashClubList = ({
  fromDate,
  toDate,
  cid,
  cname,
  storeId,
  callback,
  storeName,
  accessibleStoreIds,
}) => {
  const [loading, setLoading] = useState(true);
  const [ttlRecords, setTtlRecords] = useState({
    loading: true,
    value: 0,
  });
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const filterDataRef = useRef({});
  const paginationConfigRef = useRef({ ...defaultPaginationConfig });
  const sortRef = useRef({});

  const debouncedSearch = useCallback(
    debounce((value) => {
      filterDataRef.current = {
        ...filterDataRef.current,
        searchText: value,
      };
      applyFilter();
    }, 500),
    []
  );

  const handleSearchChange = useCallback(
    (e) => {
      const value = e.target.value;
      setSearchText(value);
      if (value) {
        delete filterDataRef.current.fromDate;
        delete filterDataRef.current.toDate;
        delete filterDataRef.current.storeId;
      } else {
        filterDataRef.current.fromDate = fromDate;
        filterDataRef.current.toDate = toDate;
        filterDataRef.current.storeId = storeId;
      }
      filterDataRef.current.searchText = value;
      debouncedSearch(value);
    },
    [debouncedSearch, fromDate, toDate, storeId]
  );

  useEffect(() => {
    filterDataRef.current = {
      fromDate,
      toDate,
      cid,
      storeId,
      accessibleStoreIds,
    };
    setSearchText("");
    init();
  }, [cid, fromDate, init, storeId, toDate, accessibleStoreIds]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(async () => {
    paginationConfigRef.current.activePage = 1;
    loadList();
    setTtlRecords({ loading: true, value: 0 });
    const t = await getCount(prepareFilterParams(filterDataRef.current));
    paginationConfigRef.current.totalRecords = t.count;
    setTtlRecords({ loading: false, value: t.count });
  }, []);

  const loadList = async () => {
    setLoading(true);
    setData([]);
    const p = prepareFilterParams(
      filterDataRef.current,
      paginationConfigRef.current,
      sortRef.current
    );
    const r = await getData(p);
    setData(r.data);
    setLoading(false);
  };

  const paginationCb = (data) => {
    paginationConfigRef.current.startSlNo = data.startSlNo;
    paginationConfigRef.current.endSlNo = data.endSlNo;
    paginationConfigRef.current.activePage = data.activePage;
    loadList();
  };

  const sortCb = (e) => {
    sortRef.current = e;
    applyFilter();
  };

  const cb = (e) => {
    callback(e);
  };

  return (
    <div>
      <div className="mb-2 row g-0 align-items-center">
        <div className="col-auto">
          <PaginationSummary
            loadingTotalRecords={ttlRecords.loading}
            paginationConfig={paginationConfigRef.current}
            className="me-4"
          />
        </div>
        <div className="col-4 ms-auto">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Search by Club Name/Owner Name"
            value={searchText}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <div className="mb-1">
        <CoinsDashAppliedFilter
          cname={cname}
          fromDate={filterDataRef.current.fromDate}
          toDate={filterDataRef.current.toDate}
          storeName={filterDataRef.current.storeId ? storeName : ""}
        />
      </div>

      <table className="table table-hover table-sm table-striped">
        <TableHeader
          data={tableHeaders}
          sortCb={sortCb}
          sort={sortRef.current}
          noBg={true}
        />
        <tbody className="fs-val-md">
          {!loading && !data.length ? (
            <tr>
              <td colSpan={tableHeaders.length}>
                <NoDataFound>No data to display</NoDataFound>
              </td>
            </tr>
          ) : null}

          {loading ? (
            <TableSkeletonLoader
              rows={10}
              height={30}
              cols={tableHeaders.length}
            />
          ) : null}

          {data.map((c, index) => (
            <tr key={index}>
              <td>{paginationConfigRef.current.startSlNo + index}</td>
              <td>
                <button
                  className="btn fs-val-md btn-link text-dark p-0 text-start"
                  onClick={() => cb({ action: "viewClub", id: c.clubOwnerId })}
                >
                  {c.clubName}
                </button>
              </td>
              <td>
                <button
                  className="btn fs-val-md btn-link text-dark p-0 text-start"
                  onClick={() =>
                    cb({ action: "viewCustomer", id: c.clubOwnerId })
                  }
                >
                  {c.clubOwnerName}
                </button>
              </td>
              <td className="text-center">{c.totalmembers || 0}</td>
              <td className="text-success text-center">
                {(c.coinsEarned || 0).toLocaleString()}
              </td>
              <td className="text-danger text-center">
                {(c.coinsRedeemed || 0).toLocaleString()}
              </td>
              <td className="text-center">{c.totalOrders || 0}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationConfigRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={ttlRecords.loading}
      />
    </div>
  );
};

export default CoinsDashClubList;
