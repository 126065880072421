import { Amount, InfoPopover, Spinner, TableHeader } from "@sk/uis";
import classNames from "classnames";
import { useState, useEffect } from "react";
import { DashboardService, NavService } from "@sk/services";
import { produce } from "immer";
import { movementStatusDescriptions } from "../constant";

// Define constants for non-sellable types
export const EXPIRED = "Expired";
export const DAMAGED = "Damaged";

const columns = [
  { label: "Non Sellable Locations", key: "nonSellableType", width: "20%" },
  { label: "Products", key: "totalProducts", isCentered: true },
  { label: "Units", key: "totalUnits", isCentered: true },
  { label: "Value", key: "totalValue", isCentered: true },
];

const prepareFilterParams = (filters) => {
  let type = "getNonSellableInventorySummary";

  // Change query type if store filter is enabled
  if (filters.store?.length > 0) {
    type = "getStoreNonSellableInventorySummary"; // Dummy type for now
  }

  const params = DashboardService.prepareInventoryAnalyticsParams(
    filters,
    type
  );

  return params;
};

const getData = async (params, isStoreWise = false) => {
  const response = isStoreWise
    ? await DashboardService.fetchStoreInventoryAnalytics(params)
    : await DashboardService.fetchInventoryAnalytics(params);
  return response.resp;
};

const InventoryAgeingNonSellableTypeSummary = ({ filterData }) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([
    {
      icon: "bi bi-calendar-x", // Icon for Expired
      nonSellableType: "Expiry",
      typeVal: "expired",
      paths: [
        {
          key: "totalProducts",
          value: "totalExpiredProductIdCount",
        },
        {
          key: "totalUnits",
          value: "totalExpiredQty",
        },
        {
          key: "totalValue",
          value: "totalExpiredValue",
        },
      ],
      totalProducts: 0,
      totalUnits: 0,
      totalValue: 0,
      iconColor: "danger",
    },
    {
      icon: "bi bi-shield-exclamation", // Icon for Damaged
      nonSellableType: "Damage",
      typeVal: "damaged",
      totalProducts: 0,
      totalUnits: 0,
      totalValue: 0,
      iconColor: "warning",
      paths: [
        {
          key: "totalProducts",
          value: "totalDamagedProductIdCount",
        },
        {
          key: "totalUnits",
          value: "totalDamagedQty",
        },
        {
          key: "totalValue",
          value: "totalDamagedValue",
        },
      ],
    },
  ]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const params = prepareFilterParams(filterData);
        const apiData = await getData(params, filterData?.store?.length > 0);
        setLoading(false);

        // Check if apiData is empty
        if (!apiData || !Array.isArray(apiData) || apiData.length === 0) {
          setData((prevData) =>
            prevData.map((item) => ({
              ...item,
              totalProducts: 0,
              totalUnits: 0,
              totalValue: 0,
            }))
          );
          return;
        }

        setData(
          produce((draft) => {
            draft.forEach((item) => {
              item.paths.forEach((path) => {
                item[path.key] = apiData.reduce(
                  (acc, curr) => acc + curr[path.value],
                  0
                );
              });
            });
          })
        );
      } catch (error) {
        setLoading(false);
        setData((prevData) =>
          prevData.map((item) => ({
            ...item,
            totalProducts: 0,
            totalUnits: 0,
            totalValue: 0,
          }))
        );
      }
    };
    loadData();
  }, [filterData]);

  const onNonSellableTypeClick = (typeVal) => {
    const params = DashboardService.prepareNavParamsForInventoryAgeing(
      filterData,
      [
        { key: "nonSellableType", value: typeVal },
        { key: "tab", value: "nonSellableInventory" },
      ]
    );
    NavService.openInNewTab("/dashboard/inventory-ageing/products", params);
  };

  return (
    <>
      <table className="table table-sm">
        <TableHeader data={columns} noBg />
        <tbody className="fs-val-md">
          {loading ? (
            <tr>
              <td colSpan={columns.length} className="text-center">
                <Spinner />
              </td>
            </tr>
          ) : (
            data.map((item, index) => (
              <tr key={index}>
                <td className="fw-semibold">
                  <div className="d-inline-flex align-items-center">
                    <i
                      className={classNames(
                        "bi me-1",
                        item.icon,
                        "text-" + item.iconColor
                      )}
                    />

                    <button
                      className="btn btn-link text-dark text-start p-0 fs-val-md"
                      onClick={() => onNonSellableTypeClick(item.typeVal)}
                    >
                      {item.nonSellableType}
                    </button>
                    <InfoPopover
                      content={movementStatusDescriptions[item.nonSellableType]}
                    ></InfoPopover>
                  </div>
                </td>
                <td className="text-center">{item.totalProducts}</td>
                <td className="text-center">{item.totalUnits}</td>
                <td className="text-center">
                  <Amount value={item.totalValue} />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </>
  );
};

export default InventoryAgeingNonSellableTypeSummary;
