import { CoinsDashService } from "@sk/services";
import { AppCard, Spinner, TableHeader } from "@sk/uis";
import { set } from "date-fns";
import { produce } from "immer";
import { useCallback, useEffect, useRef, useState } from "react";
import CoinsDashAppliedFilter from "./CoinsDashAppliedFilter";

const tableHeaders = [
  {
    label: "Category",
  },
  {
    label: "Description",
  },
  {
    label: "Coins Rewarded",
  },
];

const cstmStyleBlk = {
  height: "240px",
  overflowY: "auto",
};

const CoinsDashCategory = ({
  fromDate,
  toDate,
  cid,
  cname,
  storeId,
  storeName,
  accessibleStoreIds,
}) => {
  const [rewardCategories, setRewardCategories] = useState([
    {
      name: "Order Placement",
      value: 0,
      description: "Coins awarded for each successful order placement",
      type: [],
      loading: true,
    },
    {
      name: "Referral Reward",
      value: 0,
      description: "Bonus coins for referring new customers to the platform",
      type: ["IndirectInviteFirstorder"],
      loading: true,
    },
    {
      name: "Joining Reward",
      value: 0,
      description: "Bonus coins awarded for registering on the platform.",
      type: ["NoInviteFirstorder"],
      loading: true,
    },
    {
      name: "Owner Reward",
      value: 0,
      description: "Coins earned when a referred customer makes a purchase",
      type: ["ParentCoinReward"],
      loading: true,
    },
    {
      name: "Coupon Reward",
      value: 0,
      description: "Coins rewarded to the customer upon using a coupon",
      type: ["ReferralCoupon"],
      loading: true,
    },
  ]);

  const filterDataRef = useRef({});

  useEffect(() => {
    filterDataRef.current = { fromDate, toDate, cid, storeId };
    init();
  }, [cid, fromDate, init, storeId, toDate, accessibleStoreIds]);

  const init = useCallback(async () => {
    setRewardCategories(
      produce((draft) => {
        draft.forEach((e) => {
          e.loading = true;
        });
      })
    );

    const f = filterDataRef.current;
    const p = {
      filter: {},
    };

    if (f.fromDate && f.toDate) {
      p.filter.createdAt = {
        $gte: set(new Date(f.fromDate), {
          hours: 0,
          minutes: 0,
          seconds: 0,
        }),
        $lte: set(new Date(f.toDate), {
          hours: 23,
          minutes: 59,
          seconds: 59,
        }),
      };
    }

    let orderCatFilter = structuredClone(p);

    if (f.cid) {
      orderCatFilter.filter.ownerId = f.cid;
      p.filter.$or = [{ reciverRefId: f.cid }, { senderRefId: f.cid }];
      // p.filter.ownerId = f.cid;
    }

    orderCatFilter.filter["ownerLocation.franchise"] = f.storeId
      ? f.storeId
      : { $in: accessibleStoreIds };
    p.filter.franchiseId = f.storeId ? f.storeId : { $in: accessibleStoreIds };

    const r = await CoinsDashService.getCoinsCategorySummary(p);

    const r2 = await CoinsDashService.getOrderCategoryData(orderCatFilter);

    const d = Array.isArray(r.resp) ? r.resp : [];

    setRewardCategories(
      produce((draft) => {
        draft.forEach((e, k) => {
          e.loading = false;

          if (k == 0) {
            if (Array.isArray(r2.resp) && r2.resp.length > 0) {
              e.value = r2.resp[0].totalRewardedCoins || 0;
            } else {
              e.value = 0;
            }
            return;
          }
          e.value = 0;
          d.forEach((item) => {
            item.typeData.forEach((typeObj) => {
              if (e.type.includes(typeObj.type)) {
                if (f.cid) {
                  e.value += typeObj.totalChildCoins || 0;
                } else {
                  e.value += typeObj.totalChildCoins || 0;
                  e.value += typeObj.totalParentCoins || 0;
                }
              }
            });
          });
        });
      })
    );
  }, [accessibleStoreIds]);

  return (
    <AppCard title="Reward Categories">
      <div className="custom-scrollbar" style={cstmStyleBlk}>
        <div className="mb-2">
          <CoinsDashAppliedFilter
            cname={cname}
            storeName={storeName}
            fromDate={fromDate}
            toDate={toDate}
          />
        </div>
        <table className="table table-striped table-sm">
          <TableHeader data={tableHeaders} noBg={true} />
          <tbody className="fs-val-md">
            {rewardCategories.map((e, k) => (
              <tr key={k}>
                <td>{e.name}</td>
                <td className="text-muted">{e.description}</td>
                <td className="text-success">
                  {e.loading ? <Spinner isSmall={true} /> : e.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AppCard>
  );
};

export default CoinsDashCategory;
