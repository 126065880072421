/* eslint-disable jsx-a11y/label-has-associated-control */
import { appConfigs } from "@sk/services";
import { DatePickerInput, EntitySearchInput, SelectInput } from "@sk/uis";
import { memo, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";

const createdOnDateConfig = { mode: "range" };

const validityDateConfig = { mode: "range" };

const canvasStyle = { width: "30%" };

const hasOfferOptions = [
  { label: "All", value: "" },
  { label: "With Offer", value: "YES" },
  { label: "Without Offer", value: "NO" },
];

const fixedPriceOptions = [
  { label: "All", value: "" },
  { label: "With Fixed Price", value: "YES" },
  { label: "Without Fixed Price", value: "NO" },
];

const statusOptions = [
  { label: "All", value: "" },
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

// const groupTypeOptions = [
//   { label: "ALL", value: "" },
//   { label: "FOFO", value: "FOFO" },
//   { label: "COCO", value: "COCO" },
// ];

const franchiseSearchFilter = {
  filter: {
    "sk_franchise_details.franchise_sub_type": {
      $in: appConfigs.DARKSTORE_SUB_TYPES,
    },
  },
};

const defaultFormValues = {
  hasOffer: "",
  createdAt: [],
  validityPeriod: [],
  franchise: [],
};

const AdvanceFilterModal = ({ show, callback, formData = {} }) => {
  const { register, control, setValue, getValues, reset } = useForm({
    defaultValues: defaultFormValues,
  });

  const showOfferDate = useWatch({ control, name: "hasOffer" });

  useEffect(() => {
    // for autofilling the previous filtered values when the modal is openened
    if (show) {
      const keys = Object.keys(formData);
      if (keys.length > 0) {
        keys.forEach((x) => {
          setValue(x, formData[x]);
        });
      } else {
        // reset the form if there is no value selected earlier
        reset();
      }
    }
  }, [formData, setValue, show, reset]);

  const onReset = () => {
    reset(defaultFormValues);
    callback({ status: "reset", formData: getValues() });
  };

  const onApply = () => {
    callback({ status: "applied", formData: getValues() });
  };

  const onHide = () => {
    callback({ status: "closed" });
  };

  return (
    <Offcanvas show={show} onHide={onHide} placement="end" style={canvasStyle}>
      <Offcanvas.Header closeButton className="bg-light">
        <Offcanvas.Title>
          <div className="fs-val-lg"> Advance Filter </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {show ? (
          <>
            <div className="row">
              {/* <div className="col-6 mb-2">
                <SelectInput
                  label="Group Type"
                  register={register}
                  name="groupType"
                  options={groupTypeOptions}
                />
              </div> */}

              {/* STATUS */}
              <div className="col-6  mb-2">
                <SelectInput
                  label="Status"
                  register={register}
                  name="status"
                  options={statusOptions}
                />
              </div>

              {/* HAS OFFER */}
              <div className="col-6 mb-2">
                <SelectInput
                  label="Has Offer"
                  register={register}
                  name="hasOffer"
                  options={hasOfferOptions}
                />
              </div>

              {/* Is Fixed Price */}
              <div className="col-6 mb-2">
                <SelectInput
                  label="Is Fixed Price"
                  register={register}
                  name="isFixedPrice"
                  options={fixedPriceOptions}
                />
              </div>

              {/* CREATED ON DATE FILTER */}
              <div className="col-12 mb-3">
                {" "}
                {/* Old Value => col-6  */}
                <label className="fw-semibold mb-1 fs-val-md">
                  {" "}
                  Created On{" "}
                </label>
                <Controller
                  name="createdAt"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerInput
                      placeholder="Choose"
                      value={value}
                      inpChange={onChange}
                      config={createdOnDateConfig}
                    />
                  )}
                />
              </div>

              {showOfferDate == "yes" ? (
                <div className="col-12 mb-3">
                  <label className="fw-semibold mb-1 fw-semibold mb-1 fs-val-md">
                    Offer Validity Period
                  </label>
                  <Controller
                    name="validityPeriod"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <DatePickerInput
                        placeholder="Select Validity Period"
                        value={value}
                        inpChange={onChange}
                        config={validityDateConfig}
                      />
                    )}
                  />
                </div>
              ) : null}

              {/* franchise / smart stores search */}
              {/* <div className="col-12 mb-3">
                <Controller
                  control={control}
                  name="franchise"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="franchise"
                      label="Search for Smart Stores"
                      placeholder="Search by Name/ID"
                      value={value}
                      callback={onChange}
                      uid="franchise"
                      filterParams={franchiseSearchFilter}
                      emptyLabel="No Smart Stores found"
                    />
                  )}
                />
              </div> */}
            </div>
          </>
        ) : null}
        <div className="row justify-content-end">
          <div className="col-auto">
            <button
              className="btn btn-warning btn-sm fs-val-md me-1"
              onClick={onReset}
            >
              Reset
            </button>
            <button
              className="btn btn-primary btn-sm fs-val-md"
              onClick={onApply}
            >
              Apply
            </button>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default memo(AdvanceFilterModal);
