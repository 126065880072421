import {
  Amount,
  AppCard,
  AppTitle,
  DateFormatter,
  HighlightText,
  KeyVal,
  TableHeader,
} from "@sk/uis";
import { memo } from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = {
  width: "55%",
};

const header = [
  { label: "#", isCentered: true },
  { label: "PO ID", isCentered: true },
  { label: "Vendor" },
  { label: "Requested PO", isCentered: true },
  { label: "Pending PO to receive", isCentered: true },
  { label: "Date", isCentered: true },
];
const ViewOpenPoModal = ({ show, closeModal, details = {} }) => {
  const poDetails = details.poDetail;
  return (
    <Offcanvas
      show={show}
      placement="end"
      style={canvasStyle}
      onHide={closeModal}
    >
      <Offcanvas.Header closeButton className="bg-white">
        <AppTitle title={"Open PO Details"} />
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        <AppCard>
          <div className="mb-2 fs-val-normal fw-semibold">
            {details.productName}
          </div>
          <div className="row fs-val-md">
            <div className="col-auto">
              <HighlightText type="primary" template={2} isSoft={true}>
                PID: {details.productId}
              </HighlightText>
            </div>
            <div className="col-auto">
              <HighlightText type="primary" template={2} isSoft={true}>
                MRP:{" "}
                <Amount
                  value={details?.dealDetail?.dealMrp ?? 0}
                  decimalPlace={2}
                />
              </HighlightText>
            </div>
            <div className="col-auto">
              <HighlightText type="primary" template={2} isSoft={true}>
                B2B Price:{" "}
                <Amount
                  value={details?.dealDetail?.dealB2bPrice ?? 0}
                  decimalPlace={2}
                />
              </HighlightText>
            </div>
            <div className="col-auto">
              <HighlightText type="primary" template={2} isSoft={true}>
                Margin: {details?.margin} %
              </HighlightText>
            </div>
          </div>
        </AppCard>

        <AppCard title="Vendor Details">
          <table className="table table-sm table-striped">
            <TableHeader data={header} noBg />
            <tbody>
              {!poDetails?.vendorList?.length && (
                <tr className="fs-val-md">
                  <td colSpan={header.length} className="text-center">
                    No Vendor List Found
                  </td>
                </tr>
              )}

              {poDetails?.vendorList?.length &&
                poDetails?.vendorList.map((d, i) => (
                  <tr key={i + "vendor"} className="fs-val-md">
                    <td className="text-center">{i + 1}</td>
                    <td className="text-center">{d._id}</td>
                    <td>
                      {d.vendorName} - {d.vendorId}
                    </td>
                    <td className="text-center">{d.poQty}</td>
                    <td className="text-center">{d.pendingQty}</td>
                    <td className="text-center">
                      <DateFormatter date={d.poDate} format="dd MMM yyyy" />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </AppCard>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default memo(ViewOpenPoModal);
