import { AppCard, DateFormatter, HighlightText, KeyVal } from "@sk/uis";
import { memo } from "react";

const ViewConfigDetails = ({ details }) => {
  return (
    <AppCard title="Configuration Details">
      {/* Main Details Section */}
      <div className="row g-4 mb-4">
        <div className="col-4">
          <div className="h-100 border p-3">
            <KeyVal label="Linked Group Code" template="col" className="mb-3">
              <span className="fw-medium">{details?.groupCode}</span>
            </KeyVal>
            <KeyVal label="Pre Order Limit" template="col" className="mb-3">
              <span className="fw-medium">{details?.preOrderLimit || 0}</span>
            </KeyVal>
            <KeyVal label="Status" template="col">
              <HighlightText
                type={details._statusType}
                template={2}
                isBadge={true}
              >
                <i className="bi bi-circle-fill fs-val-sm me-1"></i>
                {details._displayStatus}
              </HighlightText>
            </KeyVal>
          </div>
        </div>

        <div className="col-4">
          <div className="h-100 border p-3">
            <KeyVal label="Remaining Qty" template="col" className="mb-3">
              <span className="fw-medium">
                {details?.remainingQuantity || 0}
              </span>
            </KeyVal>
            <KeyVal
              label="No. of days (in live)"
              template="col"
              className="mb-3"
            >
              <span className="fw-medium">{details?.noOfDays || 0}</span>
            </KeyVal>
            <KeyVal label="Shipment TAT" template="col">
              <span className="fw-medium">
                {details.shippmentTat || 0} days
              </span>
            </KeyVal>
          </div>
        </div>

        {/* Date Information */}
        <div className="col-4">
          <div className="h-100 border p-3">
            <KeyVal label="Start Date" template="col" className="mb-3">
              <DateFormatter
                date={details.applicableFromDate}
                className="fw-medium"
              />
            </KeyVal>
            <KeyVal label="End Date" template="col">
              <DateFormatter date={details.endDate} className="fw-medium" />
            </KeyVal>
          </div>
        </div>
      </div>

      {/* Reserve Order Settings */}
      <div className="mb-4">
        <div className="border-bottom pb-2 mb-3">
          <h6 className="mb-0">Reserve Order Settings</h6>
        </div>
        <div className="row g-3">
          {/* <div className="col-4">
            <div className="border p-3 h-100">
              <KeyVal label="Allow Reserve Order" template="col">
                <HighlightText
                  type={details?.allowReserveOrder ? "success" : "danger"}
                  template={2}
                  isBadge={true}
                  className="px-3"
                >
                  {details?.allowReserveOrder ? "Yes" : "No"}
                </HighlightText>
              </KeyVal>
            </div>
          </div> */}

          <div className="col-4">
            <div className="border p-3 h-100">
              <KeyVal label="Reserve Order Priority" template="col">
                <HighlightText
                  type={details?.isReserveOrderPriority ? "success" : "danger"}
                  template={2}
                  isBadge={true}
                  className="px-3"
                >
                  {details?.isReserveOrderPriority ? "Yes" : "No"}
                </HighlightText>
              </KeyVal>
            </div>
          </div>

          <div className="col-4">
            <div className="border p-3 h-100">
              <KeyVal label="Allow Only Reserve Order" template="col">
                <HighlightText
                  type={details?.allowOnlyReserveOrders ? "success" : "danger"}
                  template={2}
                  isBadge={true}
                  className="px-3"
                >
                  {details?.allowOnlyReserveOrders ? "Yes" : "No"}
                </HighlightText>
              </KeyVal>
            </div>
          </div>
        </div>
      </div>

      {/* Audit Information */}
      <div>
        <div className="border-bottom pb-2 mb-3">
          <h6 className="mb-0">Audit Information</h6>
        </div>
        <div className="row g-3">
          <div className="col-3">
            <div className="border p-3 h-100">
              <KeyVal label="Created By" template="col">
                <span className="fw-medium">
                  {details?._createdBy?.name || details.createdBy}
                </span>
              </KeyVal>
            </div>
          </div>
          <div className="col-3">
            <div className="border p-3 h-100">
              <KeyVal label="Created On" template="col">
                <DateFormatter date={details.createdAt} className="fw-medium" />
              </KeyVal>
            </div>
          </div>
          <div className="col-3">
            <div className="border p-3 h-100">
              <KeyVal label="Last Updated By" template="col">
                <span className="fw-medium">
                  {details?._modifiedBy?.name || details.modifiedBy}
                </span>
              </KeyVal>
            </div>
          </div>
          <div className="col-3">
            <div className="border p-3 h-100">
              <KeyVal label="Last Updated On" template="col">
                <DateFormatter
                  date={details.modifiedAt}
                  className="fw-medium"
                />
              </KeyVal>
            </div>
          </div>
        </div>
      </div>
    </AppCard>
  );
};

export default memo(ViewConfigDetails);
